import IMenuItem from "../../../../../model/interface/ui/IMenuItem";
import IconBuilder, {getIconLibrary} from "../../../../../utils/IconBuilder";
import React from "react";
import SettingsService from "../../../../../model/service/SettingsService";
import MenuItemLink from "./MenuItemLink";
import {History} from "history";

const MenuGroupBuilder = {
    buildChildren: (items: IMenuItem[], parent: string | null, onClick: (item: IMenuItem, parent?: boolean) => void, history: History) => {
        const roots = parent ? items.filter(value => value.parent && value.parent === parent)
            : items.filter(value => !value.parent)
        return roots
            .sort((a, b) => (a.weight > b.weight) ? 1 : ((b.weight > a.weight) ? -1 : 0))
            .map((item) => {
                    const color = item.iconColor || SettingsService.getThemeColor()
                    const icon = {
                        ion: IconBuilder(item.icon, undefined, {color}),
                        ant: IconBuilder(item.icon, undefined, {style: {color}}),
                    }[getIconLibrary(item.icon || '')]
                    return <MenuItemLink items={items} key={item.uuid} item={item} icon={icon} onItemCLick={onClick}
                                         history={history}/>
                }
            )
    }
}

export default MenuGroupBuilder