import React, {RefObject} from 'react'
import {Button, Card, Form, FormInstance, Input, message, Spin} from 'antd';
import {CodeOutlined, CopyOutlined, EyeOutlined, ToolOutlined} from "@ant-design/icons";
import _ from "underscore"
import IFormOptions from "../../../../model/interface/form/IFormOptions";
import IFormStructureNode from "../../../../model/interface/form/IFormStructureNode";
import {IFormStepProps} from "./FormConfiguration";
import FormElement from "./FormElement";
import IField from "../../../../model/interface/dataStorage/IField";
import IAction from "../../../../model/interface/dataStorage/IAction";
import FormElementType from "./FormElement/FormElementType";
import FormElementList from "./FormElement/FormElementList";
import IWidget from "../../../../model/interface/form/IWidget";
import FieldEditor from "./FormElement/optionEditor/FieldEditor";
import IFormFieldOptions from "../../../../model/interface/form/elementOptions/IFieldOptions";
import IContainerOptions from "../../../../model/interface/form/elementOptions/IContainerOptions";
import ContainerEditor from "./FormElement/optionEditor/ContainerEditor";
import FormFieldType from "./FormElement/formField/FormFieldType";
import SubmitEditor from "./FormElement/optionEditor/SubmitEditor";
import ISubmitOptions from "../../../../model/interface/form/elementOptions/ISubmitOptions";
import TreeStructure from "../../../../utils/TreeStructure";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import Widget from "../widget/Widget";
import FormElementGallery from "./FormElement/FormElementGallery";
import ICommentOptions from "../../../../model/interface/form/elementOptions/ICommentOptions";
import CommentEditor from "../widget/optionEditor/CommentEditor";
import ActionWidgetEditor from "../widget/optionEditor/ActionWidgetEditor";
import IActionOptions from "../../../../model/interface/widget/option/IActionOptions";
import EntityTableEditor from "./FormElement/optionEditor/EntityTableEditor";
import IEntityTableOptions from "../../../../model/interface/form/elementOptions/IEntityTableOptions";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import Utils from "../../../../utils";
import {IActionResult} from "../../../../model/service/dataStorage/ActionsService";
import IFormElementFunctions from "../../../../model/interface/form/IFormElementFunctions";

interface IState {
    activeId: string | null,
    mode: string,
    structure: { [uuid: string]: IFormStructureNode }
    actions: IAction[],
    fields: IField[],
    optionEditor?: JSX.Element | null
    formRef: RefObject<FormInstance>
    formRefCode: RefObject<FormInstance>,
    loading: boolean
}

interface IProps extends IFormStepProps {
    findContentType: (name: string) => IContentType
    findContentTypeByUuid: (uuid: string) => IContentType
}

class FormEditor extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const containerUuid = Utils.uuid()
        this.state = {
            structure: {
                [containerUuid]: {...FormElementList.getByType(FormElementType.CONTAINER), id: containerUuid}
            },
            activeId: null,
            fields: [],
            actions: [],
            mode: 'editor',
            optionEditor: null,
            formRef: React.createRef(),
            formRefCode: React.createRef(),
            loading: true
        };
    }

    componentDidMount() {
        const contentType = this.getContentType()
        this.setState({fields: contentType.fields, actions: contentType.actions})
        let structure = FormEditor.widgetsToFormStructure(this.props.form.widgets, contentType)
        this.props.form.widgets.length > 0 ? this.setState({structure, loading: false},
            this.state.formRef.current?.resetFields
        ) : this.setState({loading: false})
    }

    static widgetsToFormStructure(widgets: IWidget[], contentType: IContentType) {
        let structure = {} as { [id: string]: IFormStructureNode }
        widgets.forEach(widget => {
            structure[widget.uuid] = {
                ...widget,
                id: widget.uuid,
                children: widgets.filter(value => value.parent === widget.uuid).map(wd => wd.uuid),
                parent: widget.parent,
                field: _.findWhere(contentType.fields, {uuid: widget.field}),
                fieldUuid: widget.field,
                action: _.findWhere(contentType.actions, {uuid: widget.action})
            }
        })
        return structure;
    }

    static isWidget(value: any): value is IWidget {
        return value && value.uuid;
    }

    setMode(mode: string) {
        this.setState({
            mode
        })
    }

    setActiveId(activeId: string) {
        this.setState({activeId})
    }

    moveUp(id: string) {
        this.movePosition(id)
    }

    moveDown(id: string) {
        this.movePosition(id, false);
    }

    movePosition(id: string, up = true) {
        this.setState({
            structure: TreeStructure.updateChildPosition(id, this.state.structure, up, 'weight')
        }, this.confirm)
    }

    removeNodeById(id: string) {
        let structure = {...this.state.structure}
        if (structure[id]) {
            structure = this.removeChildren(id, structure)
            const parent = structure[id].parent
            if (parent && this.state.structure[parent]) {
                structure[parent].children = _.without(structure[parent].children, id)
            }
            delete structure[id]
            this.setState({structure}, this.confirm)
        }
    }

    removeChildren(id: string, structure: { [id: string]: IFormStructureNode }) {
        for (const child of FormEditor.getSortedChildren(id, structure)) {
            structure = this.removeChildren(child.id, structure)
            delete structure[child.id]
        }
        return structure
    }

    editOptions(node: IFormStructureNode, contentType: IContentType) {
        const onFinish = (values?: IFormOptions, field?: IField) => {
            if (values) {
                for (const property in values) {
                    if (values.hasOwnProperty(property) && values[property] === undefined) {
                        values[property] = ''
                    }
                }
                if (node.field && values) {
                    this.state.formRef.current?.setFieldsValue({
                        [node.field?.name]: FormFieldType.formatToForm(values.type, values.initialValue)
                    })
                }
                this.saveNode({...node, field: field || node.field}, values);
            }
            this.setState({optionEditor: null})
        }
        let optionEditor = this.getOptionEditor(node, onFinish, contentType);
        this.setState({optionEditor}, () => {
            !optionEditor && this.saveNode(node)
            this.confirm()
        });
    }

    saveNode(node: IFormStructureNode, values: IFormOptions = {}) {
        const id = node.id
        let structure = this.state.structure;
        node.options = values
        if (!structure[id] && node.parent) {
            node.weight = structure[node.parent].children.push(id)
        }
        structure[id] = node
        this.setState({structure}, () => {
            this.state.formRef.current?.resetFields()
            this.confirm()
        })
    }

    getOptionEditor(node: IFormStructureNode, onFinish: (values?: IFormOptions) => void, contentType: IContentType) {
        switch (node.type) {
            case FormElementType.FIELD:
                const nodeFieldUuid = typeof node.field === 'object' ? node.field.uuid : node.field
                const field = contentType.fields.find(field => {
                    return field.uuid === nodeFieldUuid
                })
                if (!field) {
                    throw new Error('Field not found')
                }
                return field && <FieldEditor contentType={contentType} fields={this.getStructureFieldNodes()}
                                             onFinish={onFinish} field={field}
                                             options={node.options as IFormFieldOptions}
                                             match={this.props.match} history={this.props.history}/>
            case FormElementType.ACTION:
                return <ActionWidgetEditor onFinish={onFinish} action={node.action as IAction}
                                           options={node.options as IActionOptions}/>
            case FormElementType.CONTAINER:
                return <ContainerEditor options={node.options as IContainerOptions} onFinish={onFinish}
                                        fieldNodes={this.getStructureFieldNodes()} fields={contentType.fields}
                />
            case FormElementType.SUBMIT:
                return <SubmitEditor options={node.options as ISubmitOptions} onFinish={onFinish}/>
            case FormElementType.COMMENT:
                return <CommentEditor options={node.options as ICommentOptions} onFinish={onFinish}
                                      editableLabel={true}/>
            case FormElementType.ENTITY_TABLE:
                return <EntityTableEditor options={node.options as IEntityTableOptions} fields={this.state.fields}
                                          onFinish={onFinish}/>
            default:
                return Widget.getOptionEditor(node.type, node.options, onFinish)
        }
    }

    getStructureFieldNodes() {
        return Object.entries(this.state.structure)
            .filter(value => value[1].field
                && value[1].options.type
                && this.state.fields
                && typeof this.state.fields.find === 'function'
                && this.state.fields.find(field => value[1].field && field.id === value[1].field.id)
            )
            .map(value => value[1]);
    }

    appendNode(id: string, group?: string, field?: IField) {
        let contentType = this.getFieldContentType(field);
        (new FormElementGallery(
            (type: string, action, field) =>
                this.appendNodeExecute(id, type, contentType, action, field),
            FormElementList,
            contentType,
            this.actionOrFieldExists
        )).show(group)
    }

    getFieldContentType(field: IField | undefined) {
        let contentType = this.getContentType()
        if (field && field.targetEntity) {
            contentType = this.props.findContentType(field.targetEntity)
        }
        return contentType
    }

    getContentType() {
        const {form, findContentTypeByUuid} = this.props
        return findContentTypeByUuid(form.contentType);
        //TODO this loads the contentType from redux not the one being edited with possible changes, maybe pass here the editing one ???
    }

    actionOrFieldExists = (group: string, id: number, type?: string) => {
        return !!Object.entries(this.state.structure)
            .find(value => (type && value[1].type === type) || value[1][group as 'action' | 'field']?.id === id)
    }

    appendNodeExecute(id: string, type: string, contentType: IContentType, action?: IAction, field?: IField) {
        let node = {...FormElementList.getByType(type), parent: id, id: Utils.uuid()}
        node = action ? {...node, action, label: action.label || action.name} : field ? {
            ...node,
            field,
            label: field.label || field.name
        } : node
        this.editOptions(node, contentType)
    }

    static getSortedChildren(id: string, structure: { [id: string]: IFormStructureNode }) {
        return TreeStructure.sortChildren(id, structure, 'weight') as IFormStructureNode[]
    }

    getCurrentValue(field: string) {
        return this.state.formRef.current?.getFieldValue(field)
    }

    valueUpdated = () => {
        this.forceUpdate()
    }

    applyCode(values: any) {
        let code = JSON.parse(values["code"])
        if (code) {
            this.formatStructureFromTree(code)
        }
        message.success('Formulář aktualizován').then();
    }

    formatJsonTree() {
        return TreeStructure.buildJson(this.state.structure, null, 2)
    }

    formatStructureFromTree(tree: [IFormStructureNode]) {
        this.setState({
            structure: TreeStructure.destruct(tree)
        })
    }

    confirm = (stepBack: boolean = false) => {
        let {structure} = {...this.state}
        let widgets: IWidget[] = []
        Object.entries(structure).forEach(([, node]) => {
            widgets.push({
                ...node,
                id: null,
                uuid: node.id,
                children: [],
                action: node.action?.uuid,
                field: node.field?.uuid
            })
        })
        this.props.onChange({...this.props.form, widgets}, undefined, stepBack, stepBack)
    }

    getInitialValues() {
        let initialValues = {} as { [name: string]: any }
        const structure = this.state.structure
        Object.keys(structure).forEach((key) => {
            const fieldNode = structure[key]
            const field = fieldNode.field
            if (field && fieldNode.options && fieldNode.options.initialValue) {
                initialValues[field.name] = FormFieldType
                    .formatToForm(fieldNode.options.type, (fieldNode.options as IFormOptions).initialValue)
            }
        })
        return initialValues
    }

    copyCode = () => {
        navigator.clipboard.writeText(this.state.formRefCode.current?.getFieldValue('code'))
            .then(() => {
                message.success('Zkopírováno').then()
            })
    }

    render() {
        const {mode, structure, optionEditor, formRef, formRefCode, loading} = this.state
        const {history, match, form} = this.props
        const initialValues = this.getInitialValues()

        return (
            <>
                {optionEditor && (optionEditor)}
                <Card>
                    <Button.Group>
                        <Button type={mode === 'editor' ? 'primary' : 'default'} onClick={() => this.setMode('editor')}
                                icon={<ToolOutlined/>}> Editor</Button>
                        <Button type={mode === 'preview' ? 'primary' : 'default'}
                                onClick={() => this.setMode('preview')} icon={<EyeOutlined/>}> Náhled</Button>
                        <Button type={mode === 'code' ? 'primary' : 'default'} onClick={() => this.setMode('code')}
                                icon={<CodeOutlined/>}> Kód</Button>
                    </Button.Group>
                    <div>
                        {loading && <Spin/>}
                        {(mode === 'editor' || mode === 'preview') && !loading && (
                            <Form layout={"vertical"} ref={formRef} name={'form_' + form.id}
                                  initialValues={initialValues} onValuesChange={this.valueUpdated}>
                                {Object.entries(structure).map(([key, node]) => {
                                    if (node && !node.parent) {
                                        let functions = {
                                            getNode: (id: string) => structure[id],
                                            getContentType: () => this.getContentType(),
                                            activate: (id: string) => this.setActiveId(id),
                                            editOptions: (id: string, contentType?: IContentType) => this.editOptions(structure[id], contentType || this.getContentType()),
                                            delete: (id: string) => this.removeNodeById(id),
                                            appendNode: (id: string, group?: string, field?: IField) =>
                                                this.appendNode(id, group, field),
                                            moveUp: (id: string) => this.moveUp(id),
                                            moveDown: (id: string) => this.moveDown(id),
                                            appendNodeExecute: (id: string, type: string) =>
                                                this.appendNodeExecute(id, type, this.getContentType()),
                                            getSortedChildren: (id: string) =>
                                                FormEditor.getSortedChildren(id, structure),
                                            getFormRef: () => formRef,
                                            getCurrentValue: (field: string) => this.getCurrentValue(field),
                                            getResource: () => ({}),
                                            onActionStart: () => Promise.resolve({} as IActionResult),
                                            onActionFinish: () => Promise.resolve()
                                        } as IFormElementFunctions
                                        return (
                                            <FormElement
                                                {...node}
                                                field={undefined} // field is loaded from options
                                                id={key}
                                                children={node.children}
                                                key={key}
                                                preview={mode === 'preview'}
                                                functions={functions}
                                                history={history}
                                                match={match}
                                            />
                                        )
                                    }
                                    return null
                                })}
                            </Form>
                        )}
                        {mode === 'code' && (
                            <Form onFinish={(values) => this.applyCode(values)} ref={formRefCode}
                                  initialValues={{code: this.formatJsonTree()}}>
                                <Form.Item name={"code"}>
                                    <Input.TextArea rows={15}/>
                                </Form.Item>
                                <Button htmlType={"submit"} type={"primary"}>Aktualizovat</Button>
                                <Button className={'ml-1'} type={"dashed"}
                                        onClick={this.copyCode} icon={<CopyOutlined/>}/>
                            </Form>
                        )}
                    </div>
                </Card>
                <Button onClick={() => this.confirm(true)}>Předchozí krok</Button>
            </>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {

    return {
        findContentType: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name),
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(FormEditor)