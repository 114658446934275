import React, {RefObject} from "react";
import {Button, Form, FormInstance, Input, Modal, Slider, Switch} from "antd";
import ICommentOptions from "../../../../../model/interface/widget/option/ICommentOptions";
import Utils from "../../../../../utils";
import FormFieldWYSIWYGEditor from "../../form/FormElement/formField/editor/FormFieldWYSIWYGEditor";

interface IProps {
    options: ICommentOptions
    onFinish: (values?: ICommentOptions) => void,
    editableLabel?: boolean
}

class CommentEditor extends React.Component<IProps, ICommentOptions> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            wysiwygPackage: 'basic',
            limit: 5,
            ...props.options,
            replyAble: Utils.toBoolean(props.options.replyAble),
            wysiwyg: Utils.toBoolean(props.options.wysiwyg),
            attachments: Utils.toBoolean(props.options.attachments),
            editable: Utils.toBoolean(props.options.editable),
        }
    }

    static defaultProps = {
        editableLabel: false
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then((values: ICommentOptions) => {
            this.props.onFinish({...this.state, ...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    valueUpdated = (changes: any) => {
        this.setState({...this.state, ...changes})

    }

    getTipFormatter = (value?: number) => {
        return value && value > 50 ?
            <strong className={'text-danger'}>{`${value} (časově náročné)`}</strong> : value;
    }

    render() {

        const {wysiwyg} = this.state
        const {editableLabel} = this.props

        return (
            <Modal
                title={'Upravit komentáře'}
                okText={'Uložit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form initialValues={this.state || {}} ref={this.formRef} onValuesChange={this.valueUpdated}>
                    {editableLabel && (
                        <Form.Item name={'label'} label={'Titulek'}>
                            <Input/>
                        </Form.Item>
                    )}
                    <Form.Item name={'replyAble'} label={'Možnost odpovědět'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'editable'} label={'Možnost editace komentáře'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'attachments'} label={'Přílohy'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'readonly'} label={'Pouze ke čtení'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'wysiwyg'} label={'Wysiwyg'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    {wysiwyg && (
                        <FormFieldWYSIWYGEditor options={this.state}/>
                    )}
                    <Form.Item name={'limit'} label={'Limit položek na jedno načtení'}>
                        <Slider tipFormatter={this.getTipFormatter} min={3} max={100}
                                marks={{
                                    3: {label: 3},
                                    50: {style: {color: '#f50'}, label: <strong className={'text-danger'}>50+</strong>},
                                }}/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default CommentEditor