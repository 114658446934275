import React from "react";
import WidgetTool from "./WidgetTool";
import WidgetChildren from "./WidgetChildren";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import IContainerOptions from "../../../../model/interface/widget/option/IContainerOptions";
import {Card as CardElement} from "antd";

class ContainerWidget extends React.Component<IWidgetProps<IWidgetPropsFunctions, IContainerOptions>> {
    render() {
        const {display, bordered, padding} = this.props.options
        const Container = bordered ? CardElement : 'div'
        return (
            <Container>
                <WidgetTool {...this.props} add={true} edit={true}/>
                <WidgetChildren {...this.props}
                                className={display + ' w-100 flex-wrap' + (padding !== undefined ? ' p-' + padding : ' p-1')}/>
            </Container>
        );
    }
}

export default ContainerWidget