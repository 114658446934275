import React from "react";
import {Form, Input, Switch} from "antd";
import IconPicker from "../../../../shared/IconPicker";
import ButtonTypePicker from "../../../../shared/button/ButtonTypePicker";
import IButtonOptions from "../../../../../model/interface/widget/option/IButtonOptions";
import ColorPicker from "../../../../shared/pickers/ColorPicker";

interface IProps {
    options: IButtonOptions
}

interface IState {
}

class ButtonProperties extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <div>
                <Form.Item name={'label'} label={'Zvolte titulek'} rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'icon'} label={'Zvolte ikonu'}>
                    <IconPicker/>
                </Form.Item>
                <Form.Item name={'danger'} label={'Vystrazne'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'type'} label={'Zvolte typ tlacika'} rules={[{required: true}]}>
                    <ButtonTypePicker/>
                </Form.Item>
                <Form.Item name={'color'} label={'Zvolte barvu tlacitka'}>
                    <ColorPicker/>
                </Form.Item>
                <Form.Item name={'textColor'} label={'Zvolte barvu textu tlacitka'}>
                    <ColorPicker/>
                </Form.Item>
            </div>
        )
    }
}

export default ButtonProperties