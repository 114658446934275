import WidgetType from "./WidgetType";
import ReportWidgetType from "../report/widget/ReportWidgetType";
import IFormStructureNode from "../../../../model/interface/form/IFormStructureNode";
import {antIcons} from "../../../../utils/Icons/AntIcons";


export interface IWidgetListItem {
    type: string,
    label: string
    icon?: JSX.Element
    group: string,
    hidden?: boolean
}

const widgets = [
    {
        label: 'Container',
        type: WidgetType.CONTAINER,
        icon: antIcons.BorderOuterOutlined,
        group: WidgetType.GROUP_CONSTRUCT
    },
    {
        label: 'Záložky',
        type: WidgetType.TABS,
        icon: antIcons.BookOutlined,
        group: WidgetType.GROUP_CONSTRUCT
    },
    {
        label: 'Záložka',
        type: WidgetType.TAB,
        icon: antIcons.BookOutlined,
        group: WidgetType.GROUP_CONSTRUCT,
        hidden: true
    },
    {
        label: 'Sloupce',
        type: WidgetType.COLUMNS,
        icon: antIcons.AppstoreOutlined,
        group: WidgetType.GROUP_CONSTRUCT
    },
    {
        label: 'Sloupec',
        type: WidgetType.COLUMN,
        icon: antIcons.AppstoreOutlined,
        group: WidgetType.GROUP_CONSTRUCT,
        hidden: true
    },
    {
        label: 'Text',
        type: WidgetType.TEXT,
        icon: antIcons.FontSizeOutlined,
        group: WidgetType.GROUP_STATIC
    },
    {
        type: WidgetType.COMMENT,
        group: WidgetType.GROUP_DYNAMIC,
        label: 'Komentáře',
        icon: antIcons.CommentOutlined,
        hidden: true
    },
    {
        type: WidgetType.BACK_BUTTON,
        group: WidgetType.GROUP_STATIC,
        label: 'Tlačítko zpět',
        icon: antIcons.RollbackOutlined
    },
    {
        type: WidgetType.PRINT,
        group: WidgetType.GROUP_STATIC,
        label: 'Tisk',
        icon: antIcons.PrinterOutlined
    }
] as IWidgetListItem[]

export interface IWidgetList {
    getAll: () => IWidgetListItem[],
    getGrouped: () => { [x: string]: { label: string, widgets: IWidgetListItem[] } }
    getByType: (type: string) => any
    getItemByType: (type: string) => IWidgetListItem
}

const WidgetList: IWidgetList = {
    getAll: () => widgets,
    getGrouped: () => {
        let groups = {
            [WidgetType.GROUP_CONSTRUCT]: {label: 'Modifikace uživatelské rozhraní', widgets: [] as IWidgetListItem[]},
            [WidgetType.GROUP_STATIC]: {label: 'Statická data', widgets: [] as IWidgetListItem[]},
            [ReportWidgetType.GROUP_DYNAMIC]: {label: 'Dynamické struktury', widgets: []}
        }
        widgets.filter(widget => !widget.hidden).forEach(widget => {
            groups[widget.group].widgets.push(widget)
        })
        return groups
    },
    getByType: (type: string) => {
        const item = widgets.find(widget => widget.type === type) as IWidgetListItem
        console.log('getByType', item)
        if (item) {
            const {label, type} = item
            return {label, type, weight: 1, children: [], id: '', options: {}, parent: null} as IFormStructureNode
        }
        throw new Error(`Widget type[${type}] does not exist!`)
    },
    getItemByType: (type: string) => {
        const item = widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item) {
            return item
        }
        throw new Error(`Widget type[${type}] does not exist!`)
    }
}

export default WidgetList
