import React from "react";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import IWorkflow from "../../../../../../model/interface/dataStorage/workflow/IWorkflow";
import IWorkflowState from "../../../../../../model/interface/dataStorage/workflow/IState";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import Utils from "../../../../../../utils";
import ProSelect from "../../../../../shared/input/ProSelect";

export interface IWorkflowStateFieldOptions {
    contentTypeId?: string,
    onChange?: (value: any) => void
}

interface IProps {
    options: IFieldOptions
    findContentTypeBy: (uuid: string) => IContentType
    findWorkflowByContentType: (contentType: IContentType) => IWorkflow
    value?: number | IWorkflowState | string | (number | string | IWorkflowState)[]
    onChange?: (value?: number) => void
    className?: string
}

interface IState {
    value?: number | number[]
}

class FormFieldWorkflowState extends React.Component<IProps, IState> {


    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            value: Utils.parseObjectToIdentifier(props.value)
        }
    }

    onChange = (value: any) => {
        this.props.options.onChange?.(value)
        this.props.onChange?.(value)
        this.setState({value: value})
    }

    render() {
        const {options, findWorkflowByContentType, findContentTypeBy, className} = this.props
        const {value} = this.state
        if (!options.contentTypeId) {
            return (<>Chyba konfigurace</>)
        }
        let workflow: IWorkflow
        try {
            workflow = findWorkflowByContentType(findContentTypeBy(options.contentTypeId))
        } catch (e) {
            return (<>Pro tento typ obsahu neexistuje žádný workflow</>)
        }
        const selectOptions = workflow.activeVersion?.states.map(state => ({
            value: state.id!,
            label: state.name
        })) || []

        return (
            <ProSelect mode={options.multiple ? 'multiple' : undefined} className={className}
                       disabled={options.disabled}
                       allowClear={options.showClear} placeholder={options.placeholder}
                       allowSelectAll={options.allowSelectAll}
                       filterOption={(input, option) =>
                           Utils.stringContains(option?.label, input)
                       }
                       value={value} onChange={this.onChange} options={selectOptions}/>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => ({
    findContentTypeBy: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
    findWorkflowByContentType: (contentType: IContentType) => selectors.workflow.findOneByContentType(state, contentType)
})

export default connect(mapStateToProps)(FormFieldWorkflowState)