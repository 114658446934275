import React, {RefObject} from "react";
import {Button, Form, FormInstance, Modal, Select, Switch} from "antd";
import ICardWidgetWorkflowTransactionConditions
    from "../../../../../../../model/interface/dataStorage/card/ICardWidgetWorkflowTransactionConditions";


interface IProps {
    options: ICardWidgetWorkflowTransactionConditions
    onFinish: (values?: ICardWidgetWorkflowTransactionConditions) => void
}

interface IState extends ICardWidgetWorkflowTransactionConditions {
}

class WorkflowTransactionConditionsEditor extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then((values: ICardWidgetWorkflowTransactionConditions) => {
            this.props.onFinish({...values})
        })
    }

    valueUpdated = (changes: any) => {
        const values = {...this.state, ...changes}
        this.setState(values)
    }

    onCancel = () => {
        this.props.onFinish()
    }

    render() {
        const initialValues = {
            background: '',
            ...this.state
        }

        return (
            <Modal
                title={'Upravit vlasnost'}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                width={700}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form initialValues={initialValues} ref={this.formRef} onValuesChange={this.valueUpdated}>
                    <Form.Item name={'notMetOnly'} label={'Pouze nesplněné podmínky'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'showIcon'} label={'Zobrazit ikony'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'showColor'} label={'Zobrazit barvy'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'background'} label={'Barva pozadí'}>
                        <Select options={[{value: '', label: 'Bílá'}, {value: 'bg-gray-lightest', label: 'Šedá'}]}/>
                    </Form.Item>

                </Form>
            </Modal>
        )
    }
}

export default WorkflowTransactionConditionsEditor
