import React from "react";
import {Button, Empty} from "antd";
import IFormElementProps from "../../../../../model/interface/form/IFormElementProps";
import FormElement from "../FormElement";
import FormElementType from "./FormElementType";
import WidgetTool from "../../widget/WidgetTool";

class FormElementButtons extends React.Component<IFormElementProps> {
    render() {
        const {id, functions, preview} = this.props
        const children = functions.getSortedChildren(id)
        return (
            <div>
                <WidgetTool {...this.props} functions={{
                    ...functions,
                    appendNode: (id) => functions.appendNode(id, FormElementType.GROUP_ACTION)
                }} add={true}/>
                {children.length > 0 ? (
                    <div className={!preview ? "d-flex" : ""}>
                        {children.map(child => {
                            return (
                                <FormElement
                                    {...this.props}
                                    {...child}
                                    key={child.id}
                                    id={child.id}
                                />
                            )
                        })}
                    </div>
                ) : !preview && (
                    <Empty description={
                        <Button type={"link"} onClick={() => functions.appendNode(id, FormElementType.GROUP_ACTION)}>
                            Přidat akci
                        </Button>
                    }/>
                )}
            </div>
        );
    }
}

export default FormElementButtons