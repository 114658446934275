import React from "react";
import {ICardStepProps} from "./CardModal";
import Card from "./Card";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import {Form, Input} from "antd";
import RoutePicker from "../../../../shared/pickers/RoutePicker";

interface IState {

}

interface IProps extends ICardStepProps, IBaseProps {

}

export default class CardSummary extends React.Component<IProps, IState> {

    render() {
        const {card, match, history} = this.props

        return (
            <div>
                <Form.Item label={'Nazev'}>
                    <Input value={card.name} disabled/>
                </Form.Item>
                <Form.Item label={'Cesta'}>
                    <RoutePicker disabled={true} value={card.route}/>
                </Form.Item>
                <Card editor={true} card={card} match={match} history={history}/>
            </div>
        )
    }
}