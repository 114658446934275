import {Form, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import PhoneNumberCountryCodePicker, {COUNTRY_CODES} from "../../../../../shared/pickers/PhoneNumberCountryCodePicker";

export interface IFieldPhoneNumberOptions {
    phoneNumberLink?: boolean
    phoneNumberAllowedCountryCodes?: string[],
    phoneNumberDisabledCountryCode?: boolean,
    phoneNumberDefaultCountryCode?: string
}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {
}

class FieldDateOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }

    static getDefaultOptions(): IFieldPhoneNumberOptions {
        return {phoneNumberDefaultCountryCode: COUNTRY_CODES.CZ}
    }

    render() {
        const {options} = this.props

        return (
            <div>
                <Form.Item name={'phoneNumberLink'} label={'Zobrazit jako odkaz'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'phoneNumberDisabledCountryCode'} label={'Zakázat kód země'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                {!options.phoneNumberDisabledCountryCode && (
                    <>
                        <Form.Item name={'phoneNumberAllowedCountryCodes'} label={'Povolit kódy zemí'}>
                            <PhoneNumberCountryCodePicker multiple={true} allowClear={true}/>
                        </Form.Item>
                        <Form.Item name={'phoneNumberDefaultCountryCode'} label={'Výchozí kód země'}>
                            <PhoneNumberCountryCodePicker allowClear={true}/>
                        </Form.Item>
                    </>
                )}
            </div>
        )
    }
}


export default FieldDateOptionsEditor