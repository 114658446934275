import {
  TOGGLE_COLLAPSED_NAV,
  // SIDE_NAV_STYLE_CHANGE,
  CHANGE_LOCALE,
  NAV_TYPE_CHANGE,
  // TOP_NAV_COLOR_CHANGE,
  // HEADER_NAV_COLOR_CHANGE,
  // TOGGLE_MOBILE_NAV,
  // SWITCH_THEME,
  // DIRECTION_CHANGE,
  MARGIN_CHANGE,
  FONT_SIZE_CHANGE,
  TOGGLE_MOBILE_NAV, TABLE_SIZE_CHANGE, LOAD_THEME
} from '../constants/Theme';
import {ITheme} from "../../model/interface/security/ITheme";

export function toggleCollapsedNav(navCollapsed: boolean) {
  return {
    type: TOGGLE_COLLAPSED_NAV,
    navCollapsed
  };
}

// export function onNavStyleChange(sideNavTheme) {
//   return {
//     type: SIDE_NAV_STYLE_CHANGE,
//     sideNavTheme
//   };
// }

export function onLocaleChange(locale: string) {
  return {
    type: CHANGE_LOCALE,
    locale
  };
}

export function onNavTypeChange(navType: string) {
  return {
    type: NAV_TYPE_CHANGE,
    navType
  };
}

// export function onTopNavColorChange(topNavColor) {
//   return {
//     type: TOP_NAV_COLOR_CHANGE,
//     topNavColor
//   };
// }
//
// export function onHeaderNavColorChange(headerNavColor) {
//   return {
//     type: HEADER_NAV_COLOR_CHANGE,
//     headerNavColor
//   };
// }

export function onMobileNavToggle(mobileNav: boolean) {
  return {
    type: TOGGLE_MOBILE_NAV,
    mobileNav
  };
}

// export function onSwitchTheme(currentTheme: string) {
//   return {
//     type: SWITCH_THEME,
//     currentTheme
//   };
// }

// export function onDirectionChange(direction: string) {
//   return {
//     type: DIRECTION_CHANGE,
//     direction
//   };
// }


export function onMarginChange(margin: string) {
  return {
    type: MARGIN_CHANGE,
    margin
  };
}

export function onFontSizeChange(fontSize: string) {
  return {
    type: FONT_SIZE_CHANGE,
    fontSize
  };
}

export function onTableSizeChange(tableSize: string) {
  return {
    type: TABLE_SIZE_CHANGE,
    tableSize
  };
}

export function loadTheme(theme: ITheme) {
  return {
    type: LOAD_THEME,
    theme
  };
}

export default {
  toggleCollapsedNav,
  // onNavStyleChange,
  onLocaleChange,
  // onNavTypeChange,
  // onTopNavColorChange,
  // onHeaderNavColorChange,
  onMobileNavToggle,
  // onSwitchTheme,
  // onDirectionChange
  onMarginChange,
  onFontSizeChange,
  onTableSizeChange,
  loadTheme,
}
