import React, {Ref} from "react";
import Utils from "../../../../../../utils";
import WidgetTool from "../../../widget/WidgetTool";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import IconBuilder from "../../../../../../utils/IconBuilder";
import {FilePdfOutlined, HeartFilled, HeartOutlined} from "@ant-design/icons";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import {IFieldLikeOptions} from "../../field/optionEditors/FieldLikeOptionsEditor";
import IUser from "../../../../../../model/interface/security/IUser";
import ILike from "../../../../../../model/interface/dataStorage/extension/ILike";
import {ISetupState} from "../../../../../../redux/reducers/Setup";
import LikesService from "../../../../../../model/service/dataStorage/extension/LikesService";
import {Button, Card, Dropdown, Input, List, Tooltip} from "antd";
import UsersService from "../../../../../../model/service/security/UsersService";
import {API_FILTER_TYPE} from "../../../../../../model/constants/ApiConstant";
import EmployeeAvatar from "../../../../company/employees/partials/EmployeeAvatar";
import moment from "moment";
import ScrollContainer from "../../../../../shared/scrollContainer/ScrollContainer";

interface IProps extends ICardWidgetProps<IFieldLikeOptions> {
}

interface IState {
    saving: boolean,
    loading: boolean,
    users: IUser[]
    ref: Ref<any>
}

class CardWidgetPrint extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            saving: false,
            loading: false,
            users: [],
            ref: React.createRef(),
        }
    }

    print() {
        // @ts-ignore
        let element: HTMLElement = this.state.ref!.current
        while(element.parentElement) {
            if(element.classList.contains('print-container')) {
                break;
            }
            element = element.parentElement
        }

        var printWindow = window.open('', '', 'height=600,width=900');
        if(printWindow) {
            printWindow.document.write('<html lang="cs"><head><title>PDF</title>');
            printWindow.document.write('<link rel="stylesheet" href="/css/light-theme.css">');
            printWindow.document.write('<style>');
            printWindow.document.write('.ant-col { width: 100%; }');
            printWindow.document.write('.ant-col-8 { width: 300px; }');
            printWindow.document.write('.ant-col-sm-8 { width: 300px; }');
            printWindow.document.write('.ant-col-12 { width: 450px; }');
            printWindow.document.write('.ant-col-sm-12 { width: 450px; }');
            printWindow.document.write('.ant-col-16 { width: 600px; }');
            printWindow.document.write('.ant-col-sm-16 { width: 600px; }');
            printWindow.document.write('.ant-col-24 { width: 900px; }');
            printWindow.document.write('.ant-col-sm-24 { width: 900px; }');
            printWindow.document.write('</style>');
            printWindow.document.write('</head><body class="print" onload="window.print()">');
            printWindow.document.write('<div style="width: 900px">');
            printWindow.document.write(element.innerHTML);
            printWindow.document.write('</div>');
            printWindow.document.write('</div>');
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            // setTimeout(() => {
            //     printWindow!.print();
            // }, 300)
        }
    }

    render() {
        return (
            <div className={"noPrint"} ref={this.state.ref}>
                <WidgetTool {...this.props}/>
                <Button type={"default"} onClick={() => this.print()} icon={<FilePdfOutlined />}>Tisk</Button>
            </div>
        );
    }
}


export default CardWidgetPrint
