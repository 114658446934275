import React, {RefObject} from 'react'
import {Button, Card, Form, FormInstance, Input, message, Modal} from 'antd';
import {CodeOutlined, CopyOutlined, EyeOutlined, LeftOutlined, RightOutlined, ToolOutlined} from "@ant-design/icons";
import ICardWidget from "../../../../../model/interface/dataStorage/card/ICardWidget";
import {ICardStepProps} from "./CardModal";
import CardWidgetType from "./widget/CardWidgetType";
import CardWidget from "./widget/CardWidget";
import CardWidgetList from "./widget/CardWidgetList";
import TreeStructure from "../../../../../utils/TreeStructure";
import IWidgetOptions from "../../../../../model/interface/widget/IWidgetOptions";
import Widget from "../../widget/Widget";
import ICard from "../../../../../model/interface/dataStorage/ICard";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import CardWidgetGallery from "./widget/CardWidgetGallery";
import IField from "../../../../../model/interface/dataStorage/IField";
import ICardWidgetScalarField from "../../../../../model/interface/dataStorage/card/ICardWidgetScalarField";
import ScalarFieldEditor from "./widget/editor/ScalarFieldEditor";
import RelationFieldEditor from "./widget/editor/RelationFieldEditor";
import ICardWidgetRelationField from "../../../../../model/interface/dataStorage/card/ICardWidgetRelationField";
import _ from "underscore";
import WidgetType from "../../widget/WidgetType";
import ActionWidgetEditor from "../../widget/optionEditor/ActionWidgetEditor";
import IActionOptions from "../../../../../model/interface/widget/option/IActionOptions";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import Utils from "../../../../../utils";
import ICardWidgetPropsFunctions from "../../../../../model/interface/dataStorage/card/ICardWidgetPropsFunctions";
import ICardWidgetContainer from "../../../../../model/interface/dataStorage/card/ICardWidgetContainer";
import ContainerEditor from "../../widget/optionEditor/ContainerEditor";
import Title from "antd/es/typography/Title";
import ConditionEditor from "../../form/FormElement/optionEditor/ConditionEditor";
import WorkflowTransactionConditionsEditor from "./widget/editor/WorkflowTransactionConditionsEditor";
import ICardWidgetWorkflowTransactionConditions
    from "../../../../../model/interface/dataStorage/card/ICardWidgetWorkflowTransactionConditions";


interface IState {
    mode: 'editor' | 'preview' | 'code' | 'blank',
    structure: { [id: string]: ICardWidget }
    optionEditor: JSX.Element | null
    formRefCode: RefObject<FormInstance>
}

interface IProps extends ICardStepProps, IBaseProps {
    findContentTypeByUuid: (uuid: string) => IContentType
}

class CardEditor extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const containerUuid = Utils.uuid()
        this.state = {
            structure: {
                [containerUuid]: {
                    ...CardWidgetList.getByType(CardWidgetType.CONTAINER),
                    id: containerUuid,
                    label: 'Container'
                }
            },
            mode: 'editor',
            optionEditor: null,
            formRefCode: React.createRef()
        };
    }

    componentDidMount() {
        let structure = CardEditor.widgetsToStructure(this.props.card.widgets)
        this.props.card.widgets.length > 0 && this.setState({structure})

    }

    getContentType() {
        const {card, findContentTypeByUuid} = this.props
        return findContentTypeByUuid(card.contentType);
    }

    static widgetsToStructure(widgets: ICardWidget[]) {
        let structure = {} as { [id: string]: ICardWidget }
        widgets.forEach(widget => {
            structure[widget.uuid] = {
                ...widget,
                id: widget.uuid,
                children: widgets.filter(value => value.parent === widget.uuid).map(wd => wd.uuid),
                parent: widget.parent
            }
        })
        return structure;
    }

    static isWidget(value: any): value is ICardWidget {
        return value && value.uuid;
    }

    setMode(mode: 'editor' | 'preview' | 'code' | 'blank') {
        this.setState({
            mode
        })
    }

    moveUp(id: string) {
        this.movePosition(id)
    }

    moveDown(id: string) {
        this.movePosition(id, false);
    }

    movePosition(id: string, up = true) {
        this.setState({
            structure: TreeStructure.updateChildPosition(id, this.state.structure, up, 'weight')
        })
    }

    removeNodeById(id: string) {
        let structure = {...this.state.structure}
        if (structure[id]) {
            structure = this.removeChildren(id, structure)
            const parent = structure[id].parent
            if (parent && this.state.structure[parent]) {
                structure[parent].children = _.without(structure[parent].children, id)
            }
            delete structure[id]
            this.setState({structure})
        }
    }

    removeChildren(id: string, structure: { [id: string]: ICardWidget }) {
        for (const child of this.getSortedChildren(id, structure)) {
            structure = this.removeChildren(child.id, structure)
            delete structure[child.id]
        }
        return structure
    }

    editOptions(widget: ICardWidget) {
        const onFinish = (values?: IWidgetOptions) => {
            console.log('editOptions onFinish', values)
            if (values) {
                for (const property in values) {
                    if (values.hasOwnProperty(property) && values[property] === undefined) {
                        values[property] = ''
                    }
                }
                this.saveWidget(widget, values);
            }
            this.setState({optionEditor: null})
        }
        let optionEditor = this.getOptionEditor(widget, onFinish);
        this.setState({optionEditor}, () => !optionEditor && this.saveWidget(widget));
    }

    saveWidget(widget: ICardWidget, values: IWidgetOptions = {}) {
        this.setState({mode: 'blank'})
        const id = widget.id
        let structure = this.state.structure;
        widget.options = values
        if (!structure[id] && widget.parent) {
            widget.weight = structure[widget.parent].children.push(id)
        }
        structure[id] = widget
        this.setState({structure, mode: "editor"})
    }

    getOptionEditor(node: ICardWidget, onFinish: (values?: IWidgetOptions) => void) {
        const field = this.getField(node.options.field)
        const action = this.getAction(node.options.actionId)
        const {history, match} = this.props
        switch (node.type) {
            case CardWidgetType.RELATION_FIELD:
                return field ? <RelationFieldEditor options={node.options as ICardWidgetRelationField} field={field}
                                                    onFinish={onFinish}/> :
                    this.showWarning('Field not found, recreate this widget!')
            case CardWidgetType.SCALAR_FIELD:
                return field ?
                    <ScalarFieldEditor
                        history={history}
                        match={match}
                        options={node.options as ICardWidgetScalarField}
                        field={field}
                        fields={this.getContentType().fields}
                        onFinish={onFinish}
                    /> :
                    this.showWarning('Field not found, recreate this widget!')
            case(WidgetType.ACTION):
                return action ?
                    <ActionWidgetEditor options={node.options as IActionOptions} action={action}
                                        onFinish={onFinish}/> :
                    this.showWarning('Action not found, recreate this widget!')
            case(WidgetType.CONTAINER):
                return <ContainerEditor options={node.options as ICardWidgetContainer}
                                        onFinish={onFinish}>
                    <Title level={4}>Collapsable conditions</Title>
                    <Form.Item name={'conditions'} noStyle={true}>
                        <ConditionEditor fields={this.getContentType().fields}/>
                    </Form.Item>
                </ContainerEditor>
            case(WidgetType.WORKFLOW_TRANSACTION_CONDITIONS):
                return <WorkflowTransactionConditionsEditor
                    options={node.options as ICardWidgetWorkflowTransactionConditions} onFinish={onFinish}/>
            default:
                return Widget.getOptionEditor(node.type, node.options, onFinish)
        }
    }

    showWarning(title: string) {
        Modal.error({
            title,
        })
        return null
    }

    getField(uuid: string) {
        return _.findWhere(this.getContentType().fields, {uuid});
    }

    getAction(uuid: string) {
        return _.findWhere(this.getContentType().actions, {uuid});
    }

    appendNode(id: string, group?: string) {
        (new CardWidgetGallery(
            (type: string, field, action) =>
                this.appendNodeExecute(id, type, field, action),
            CardWidgetList,
            this.getContentType(),
            this.actionOrFieldExists
        )).show(group)
    }

    actionOrFieldExists = (group: string, id: number, type?: string) => {
        return !!Object.entries(this.state.structure)
            .find(value => (type && value[1].type === type)
                || value[1].options[group === 'action' ? 'actionId' : 'field'] === id)
    }

    appendNodeExecute(id: string, type: string, field?: IField, action?: IAction) {
        let node = {...CardWidgetList.getByType(type), parent: id, id: Utils.uuid()}
        node = field ? {
            ...node,
            options: {field: field.uuid, ...field.options},
            label: field.label || field.name
        } : action ? {
            ...node,
            options: {actionId: action.uuid},
            label: action.label
        } : node
        this.editOptions(node)
    }

    getSortedChildren(id: string, structure: { [id: string]: ICardWidget }) {
        return TreeStructure.sortChildren(id, structure, 'weight') as ICardWidget[]
    }

    formatJsonTree() {
        return TreeStructure.buildJson(this.state.structure, null, 2)
    }

    formatStructureFromTree(tree: [ICardWidget]) {
        this.setState({
            structure: TreeStructure.destruct(tree)
        })
    }

    applyCode(values: any) {
        let code = JSON.parse(values["code"])
        if (code) {
            this.formatStructureFromTree(code)
        }
        message.success('Karta je aktualizována').then();
    }

    confirm = (stepBack: boolean = false) => {
        let card = {...this.props.card} as ICard
        card.widgets = this.mapWidgets()
        this.props.onChange(card, stepBack)
    }

    mapWidgets() {
        let widgets: ICardWidget[] = []
        Object.entries(this.state.structure).forEach(([, widget]) => {
            widgets.push({
                ...widget,
                children: [],
                id: null as any,
                uuid: widget.id
            })
        })
        return widgets;
    }

    copyCode = () => {
        navigator.clipboard.writeText(this.state.formRefCode.current?.getFieldValue('code'))
            .then(() => {
                message.success('Zkopírováno').then()
            })
    }

    render() {
        const {mode, structure, optionEditor, formRefCode} = this.state

        return (
            <>
                {optionEditor && (optionEditor)}
                <Card>
                    <Button.Group>
                        <Button type={mode === 'editor' ? 'primary' : 'default'} onClick={() => this.setMode('editor')}
                                icon={<ToolOutlined/>}> Editor</Button>
                        <Button type={mode === 'preview' ? 'primary' : 'default'}
                                onClick={() => this.setMode('preview')} icon={<EyeOutlined/>}> Náhled</Button>
                        <Button type={mode === 'code' ? 'primary' : 'default'} onClick={() => this.setMode('code')}
                                icon={<CodeOutlined/>}> Kód</Button>
                    </Button.Group>

                    {(mode === 'editor' || mode === 'preview') && Object.entries(structure).map(([, widget]) => {
                            if (widget && !widget.parent) {
                                let functions = {
                                    getNode: (id: string) => structure[id],
                                    editOptions: (id: string) => this.editOptions(structure[id]),
                                    delete: (id: string) => this.removeNodeById(id),
                                    appendNode: (id: string, group?: string) => this.appendNode(id, group),
                                    moveUp: (id: string) => this.moveUp(id),
                                    moveDown: (id: string) => this.moveDown(id),
                                    appendNodeExecute: (id: string, type: string) => this.appendNodeExecute(id, type),
                                    getSortedChildren: (id: string) => this.getSortedChildren(id, structure),
                                    getField: (uuid: string) => this.getField(uuid),
                                    getAction: (uuid: string) => this.getAction(uuid),
                                    getContentType: () => this.getContentType(),
                                    updateValue: () => undefined,
                                    getValue: () => undefined
                                } as ICardWidgetPropsFunctions
                                return (
                                    <CardWidget
                                        {...widget}
                                        key={widget.id}
                                        preview={mode === 'preview'}
                                        functions={functions}
                                        match={this.props.match}
                                        history={this.props.history}
                                        editor={true}
                                    />
                                )
                            }
                            return null
                        }
                    )}
                    {mode === 'code' && (
                        <Form onFinish={(values) => this.applyCode(values)} ref={formRefCode}
                              initialValues={{code: this.formatJsonTree()}}>
                            <Form.Item name={"code"}>
                                <Input.TextArea rows={15}/>
                            </Form.Item>
                            <Button htmlType={"submit"} type={"primary"}>Aktualizovat</Button>
                            <Button className={'ml-1'} type={"dashed"}
                                    onClick={this.copyCode} icon={<CopyOutlined/>}/>
                        </Form>
                    )}
                </Card>
                <Button icon={<LeftOutlined/>} onClick={() => this.confirm(true)}>Předchozí krok</Button>
                <Button icon={<RightOutlined/>} type={"primary"} onClick={() => this.confirm()}>
                    Následující krok
                </Button>
            </>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(CardEditor)