import React, {RefObject} from "react"
import IMenuItem, {MENU_ITEM_TYPES} from "../../../../../model/interface/ui/IMenuItem";
import {Link} from "react-router-dom";
import MenuItemsService from "../../../../../model/service/ui/MenuItemsService";
import {LoadingOutlined} from "@ant-design/icons";
import {History} from "history";
import {Menu, Tooltip} from "antd";
import MenuGroupBuilder from "./MenuGroupBuilder";


interface IProps {
    item: IMenuItem,
    icon?: JSX.Element,
    onItemCLick: (item: IMenuItem, parent?: boolean) => void
    history: History,
    items: IMenuItem[]
}

interface IState {
    loading: boolean
    isEllipsisActive: boolean
}

class MenuItemLink extends React.Component<IProps, IState> {
    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loading: false,
            isEllipsisActive: false
        }
    }

    onClickScript = (item: IMenuItem) => {
        const {onItemCLick, history} = this.props
        this.setState({loading: true})
        MenuItemsService.runScript(item.menu, item.uuid).then(response => {
            onItemCLick(response)
            this.setState({loading: false})
            if (response.url) {
                if (this.isExternalLink(response.url)) {
                    window.open(response.url, response.blank ? '_blank' : '_self')
                    return
                }
                response.blank ? window.open(response.url) : history.push(response.url)
            }
        })
    }

    isExternalLink(url: string) {
        return (new RegExp('^(?:[a-z+]+:)?//', 'i')).test(url)
            && new URL(url).origin !== window.location.origin;
    }

    itemRef: RefObject<HTMLSpanElement> = React.createRef()

    setEllipsisActive = (active?: boolean) => {
        this.setState({isEllipsisActive: active !== undefined ? active : this.isEllipsisActive()})
    }

    render() {
        const {item, icon, onItemCLick, items, history, ...other} = this.props
        const {loading, isEllipsisActive} = this.state
        const finalIcon = loading ? <LoadingOutlined/> : icon

        return (
            <Tooltip title={item.title}
                     visible={isEllipsisActive}
                     placement={"right"} mouseEnterDelay={0.1}>
                {items.filter(value => value.parent && value.parent === item.uuid).length > 0 ?
                    <Menu.SubMenu
                        {...other}
                        icon={icon}
                        onTitleMouseEnter={() => this.setEllipsisActive()}
                        onTitleMouseLeave={() => this.setEllipsisActive(false)}
                        style={{background: item.backgroundColor}}
                        key={item.uuid}
                        title={<span ref={this.itemRef}>{item.title}</span>}
                        onTitleClick={() => onItemCLick(item, true)}
                    >
                        {MenuGroupBuilder.buildChildren(items, item.uuid, onItemCLick, history)} :
                    </Menu.SubMenu> : <Menu.Item {...other} icon={finalIcon} key={item.uuid}
                                                 style={{background: item.backgroundColor}}>
                        <span onMouseEnter={() => this.setEllipsisActive()}
                              onMouseLeave={() => this.setEllipsisActive(false)} ref={this.itemRef}>
                            {{
                                [MENU_ITEM_TYPES.ROUTE]: item.route &&
                                <Link onClick={() => onItemCLick(item)} to={(item.route).url}
                                      target={item.blank ? '_blank' : undefined}>
                                    <span>{item.title}</span>
                                </Link>,
                                [MENU_ITEM_TYPES.URL]: this.buildLink(item, finalIcon, onItemCLick),
                                [MENU_ITEM_TYPES.SCRIPT]: this.buildLink(item, finalIcon, onItemCLick),
                                [MENU_ITEM_TYPES.ONCLICK_SCRIPT]: <span onClick={() => this.onClickScript(item)}>
                            {item.title}
                        </span>
                            }[item.type]}
                        </span>
                    </Menu.Item>
                }
            </Tooltip>
        )
    }

    isEllipsisActive() {
        const parentElement = this.itemRef.current?.parentElement
        return (parentElement?.scrollWidth || 0) > (parentElement?.clientWidth || 0);
    }

    buildLink(item: IMenuItem, finalIcon: JSX.Element | undefined, onClick: (item: IMenuItem, parent?: boolean) => void) {
        return item.url && (this.isExternalLink(item.url) ?
            <a href={item.url} target={item.blank ? '_blank' : undefined} className={'d-block'} rel="noreferrer">
                <span>{item.title}</span>
            </a> :
            <Link onClick={() => onClick(item)} to={item.url} target={item.blank ? '_blank' : undefined}>
                <span>{item.title}</span>
            </Link>)
    }
}

export default MenuItemLink