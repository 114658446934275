import WidgetType from "../../widget/WidgetType";

class ReportWidgetType extends WidgetType{
    static VIEW = 'view'

    static IMAGE = 'image'

    static EMPLOYEE_LOOKUP = 'employee-lookup'

    static NOTIFICATIONS = 'notifications'

    static RESOURCE_FINDER_BY_CODE = 'resource-finder-by-code'
}

export default ReportWidgetType