import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IUser from "../../interface/security/IUser";
import IRepositoryService from "../../interface/IRepositoryService";
import IPresenter from "../../interface/dataStorage/IPresenter";
import _ from "underscore"
import IField from "../../interface/dataStorage/IField";
import IFile from "../../interface/file/IFile";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";

export interface IRestUsersServiceCollectionResponse extends IRestServiceCollectionResponse {
  results: Array<IUser>
}

interface IRestUsersService extends IRepositoryService {
  collectionList(options?: IRestServiceOptions): Promise<IRestUsersServiceCollectionResponse>,
  collectionCreate(data: any): Promise<IUser>,
  resourceRetrieve(id: number|string): Promise<IUser>,
  resourceUpdate(id: number|string, data: any): Promise<IUser>,
  resourceDelete(id: number|string): Promise<void>,
  saveUser: (formValues: {[name: string]: string|null|IFile}, user?: IUser) => Promise<IUser>
}

const UsersService_COLLECTION = 'users'
const UsersService_CHOICES = 'users-choices/'

const presenters = [
  {
    type: 'label',
    name: 'Username',
    label: 'Username',
    options: {
      label: "#username"
    }
  } as IPresenter,
  {
    type: 'avatar_with_label',
    name: 'Avatar and full name',
    label: 'Avatar and full name',
    options: {
      label: "#fullName",
      avatar: "avatar"
    }
  } as IPresenter,
  {
    type: 'label',
    name: 'employee',
    label: 'Username with employees names',
    options: {
      label: ""
    }
  } as IPresenter,
]

const UsersService: IRestUsersService = {
  getFields(): IField[] {
    // TODO
    return [
      {
        uuid: '',
        name: 'username',
        mode: "scalar",
        type: 'string',
        targetEntity: null,
        options: [],
        weight: 1,
        contentTypeId: null,
        contentTypeName: false,
        locked: true,
      },
      {
        uuid: '',
        name: 'email',
        mode: "scalar",
        type: 'string',
        targetEntity: null,
        options: [],
        weight: 1,
        contentTypeId: null,
        contentTypeName: false,
        locked: true,
      }
    ];
  },
  getRecordClassName() {
    return 'App\\Security\\Entity\\User'
  },
  getTitle(): string {
    return "Uživatel";
  },
  collectionList: function (options?) {
    return RestService.collectionList(UsersService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestUsersServiceCollectionResponse>
  },
  collectionCreate: function ( data ) {
    return RestService.collectionCreate(UsersService_COLLECTION, data) as Promise<IUser>
  },
  resourceRetrieve: function (id) {
    return RestService.resourceRetrieve(UsersService_COLLECTION, id) as Promise<IUser>
  },
  resourceDelete: function (id) {
    return RestService.resourceDelete(UsersService_COLLECTION, id)
  },
  resourceUpdate: function (id, data) {
    return RestService.resourceUpdate(UsersService_COLLECTION, id, data, {fileUpload: true}) as Promise<IUser>
  },
  getPresenter(name: string): IPresenter | null {
    return _.findWhere(presenters, {name}) || null
  },
  getDefaultPresenter(): IPresenter {
    return presenters[0]
  },
  getStringValue(item: IUser): string {
    return item.username
  },
  getPresenterList() {
    return presenters.map(presenter => {return {value: presenter.name, label: presenter.label}})
  },
  saveUser(formValues: {[name: string]: string|null|IFile}, user?: IUser) {
    const values: any = {}
    for(const name in formValues) {
      const formValue = formValues[name]
      if(formValue !== null) {
        if(Object.prototype.toString.call(formValue) === '[object Array]') {
          values[name] = formValue
        } else if(typeof formValue === 'object' && formValue['id']) {
          values[name] = formValue['id']
        } else {
          values[name] = formValue
        }
      }
    }

    if(values.password) {
      values.rawPassword = values.password
    }
    delete values.password
    delete values.confirm
    return (user && user.id) ? UsersService.resourceUpdate(user!.id!, values) : UsersService.collectionCreate(values)
  },
  choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
    return RestService.collectionList(UsersService_CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
  }
}

export default UsersService