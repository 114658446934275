import React from "react"
import {Spin} from "antd";
import {Moment} from "moment";
import Header from "./Header";
import Days from "./Days";
import MonthYear from "./MonthYear";

export type CalendarMode = 'year' | 'month' | 'week' | 'day'

export interface IEvent {
    startAt: Moment,
    endAt?: Moment,
    color: string,
    label: string | JSX.Element,
    key: string | number,
    [name: string]: any
}

interface IProps {
    modes?: CalendarMode[],
    date: Moment
    customHeader?: JSX.Element
    customHeaderItems?: JSX.Element[]
    loading?: boolean,
    onChange?: (date: Moment, min: Moment, max: Moment, mode: CalendarMode) => void,
    onSelect?: (start: Moment, end: Moment) => void,
    defaultMode?: CalendarMode,
    eventRender?: <T extends IEvent>(object: T) => string | JSX.Element
    events?: IEvent[]
}

interface IState {
    mode: CalendarMode,
    fullScreen: boolean
}

class Calendar extends React.Component<IProps, IState> {

    container: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);
        this.state = {
            mode: props.defaultMode || 'month',
            fullScreen: false
        }
        this.container = React.createRef();
    }

    static defaultProps = {
        loading: false
    }

    componentDidMount() {
        const {date} = this.props;
        this.onChange(date)
    }

    onChange = (date: Moment, mode?: CalendarMode) => {
        const {onChange} = this.props;
        if (mode) {
            this.setState({mode: mode})
        } else {
            mode = this.state.mode
        }
        let min: Moment, max: Moment
        switch (mode) {
            case 'month':
                min = this.getMinMonthDate(date)
                max = this.getMaxMonthDate(date)
                break
            default:
                min = date.clone().startOf(mode)
                max = date.clone().endOf(mode)
        }
        onChange?.(date, min, max, mode)
    }

    goFullScreen = () => {
        const {fullScreen} = this.state
        const elem = this.container.current as any
        this.setState({fullScreen: !fullScreen})
        if (fullScreen) {
            document.exitFullscreen().then()
        } else {
            elem?.requestFullscreen().then()
        }
    }

    getMinMonthDate(month: Moment) {
        const weekDay = month.clone().startOf('month').weekday()

        return month.clone().startOf('month').subtract(weekDay + 1, "d")
    }

    getMaxMonthDate(month: Moment) {
        return this.getMinMonthDate(month).add(42, 'd')
    }

    render() {
        const {mode, fullScreen} = this.state;
        const {customHeader, customHeaderItems, loading, date, events, eventRender, modes, onSelect} = this.props;
        const sharedProps = {
            date,
            eventRender,
            events: events?.sort((a, b) => a.startAt.valueOf() - (b.endAt?.valueOf() || 0)),
            onSelect: onSelect
        }

        return (
            <div ref={this.container} className={'bg-white'} style={{overflowY: "auto", overflowX: "hidden"}}>
                <Spin spinning={loading}>
                    {customHeader || <Header date={date} mode={mode} modes={modes} customItems={customHeaderItems}
                                             onChange={this.onChange} fullScreenChange={this.goFullScreen}
                                             fullScreen={fullScreen}/>}
                    {{
                        month: <MonthYear mode={'month'} {...sharedProps}/>,
                        year: <MonthYear mode={'year'} {...sharedProps}/>,
                        week: <Days mode={'week'} {...sharedProps}/>,
                        day: <Days mode={'single'} {...sharedProps}/>
                    }[mode]}
                </Spin>
            </div>
        )
    }
}

export default Calendar