import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IContentType from "../../interface/dataStorage/IContentType";
import fetch from "../../auth/ETagCachedFetchInterceptor";
import {
    ContentTypeChange
} from "../../../components/app/configuration/content-type/maintenace/import/MaintenaceImportChange";
import IContentTypeStatistics from "../../interface/dataStorage/contentType/IContentTypeStatistics";
import {RELATION_FIELD_TYPE} from "../../interface/dataStorage/IField";

interface IRestContentTypesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IContentType>
}

interface IRestContentTypesService {
    collectionList(options?: IRestServiceOptions): Promise<IRestContentTypesServiceCollectionResponse>,

    collectionCreate(data: any, options?: IRestServiceOptions): Promise<IContentType>,

    resourceRetrieve(id: number | string, options?: IRestServiceOptions): Promise<IContentType>,

    resourceUpdate(id: number | string, data: any, options?: IRestServiceOptions): Promise<IContentType>,

    resourceDelete(id: number | string): Promise<void>,

    getFieldRelationTypes(): Promise<{ value: string, label: string }[]>,

    exportToZip(id: number | string): Promise<any>

    compare(id: number | string, file: number | string): Promise<{
        contentType: IContentType,
        changes: ContentTypeChange[]
    }>

    import(id: number | string, file: number | string, changeKeys: string[]): Promise<IContentType>

    statistics(id: number | string, options?: any): Promise<IContentTypeStatistics>
}

const ContentTypesService_COLLECTION = 'content-types'
const ContentTypesService_EXPORT = 'export'
const ContentTypesService_IMPORT = 'import'
const ContentTypesService_COMPARE = 'compare'
const ContentTypesService_STATISTICS = 'statistics'

const ContentTypesService: IRestContentTypesService = {
    collectionList: function (options?) {
        return RestService.collectionList(ContentTypesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestContentTypesServiceCollectionResponse>
    },
    collectionCreate: function (data, options?: IRestServiceOptions) {
        return RestService.collectionCreate(ContentTypesService_COLLECTION, data, options) as Promise<IContentType>
    },
    resourceRetrieve: function (id, options?: IRestServiceOptions) {
        return RestService.resourceRetrieve(ContentTypesService_COLLECTION, id, options) as Promise<IContentType>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(ContentTypesService_COLLECTION, id)
    },
    resourceUpdate: function (id, data, options?: IRestServiceOptions) {
        return RestService.resourceUpdate(ContentTypesService_COLLECTION, id, data, options) as Promise<IContentType>
    },
    getFieldRelationTypes(): Promise<{ value: string; label: string }[]> {
        return new Promise(resolve => {
            resolve([
                {
                    value: RELATION_FIELD_TYPE.ONE_TO_MANY,
                    label: '1:N'
                },
                {
                    value: RELATION_FIELD_TYPE.MANY_TO_MANY,
                    label: 'M:N'
                },
                {
                    value: RELATION_FIELD_TYPE.MANY_TO_ONE,
                    label: 'N:1'
                }
            ])
        })
    },
    exportToZip(id): Promise<any> {
        return RestService.resourceDownload('/' + ContentTypesService_COLLECTION + '/' + id + '/' + ContentTypesService_EXPORT)
    },
    compare(id, file): Promise<any> {
        return fetch({
            method: 'get',
            url: '/' + ContentTypesService_COLLECTION + '/' + id + '/' + ContentTypesService_COMPARE + '/' + file,
        })
    },
    import(id, file, changeKeys): Promise<IContentType> {
        return fetch({
            method: 'post',
            url: '/' + ContentTypesService_COLLECTION + '/' + id + '/' + ContentTypesService_IMPORT + '/' + file,
            data: {changeKeys}
        }).then(response => {
            // @ts-ignore // TODO
            return response.resource;
        })
    },
    statistics(id, options): Promise<IContentTypeStatistics> {
        return fetch({
            method: 'post',
            url: '/' + ContentTypesService_COLLECTION + '/' + id + '/' + ContentTypesService_STATISTICS,
            data: options
        }).then(response => {
            // @ts-ignore // TODO
            return response.resource;
        })
    }
}

export default ContentTypesService