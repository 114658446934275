import React, {Component, RefObject} from 'react';
import {Button, FormInstance, Input, Select} from "antd";
import {ActionType} from "../../../model/interface/dataStorage/IAction";
import Utils from "../../../utils";
import IRestServiceChoiceListResponse from "../../../model/interface/api/IRestServiceChoiceListResponse";
import ILabelValue from "../../../model/interface/util/ILabelValue";
import IRestResource from "../../../model/interface/api/IRestResource";
import {LoadingOutlined, SearchOutlined} from "@ant-design/icons";

interface IProps {
    value?: string | IRestResource | (string | IRestResource)[]
    onChange?: (resource?: string | string[]) => void
    className?: string
    multiple?: boolean
    style?: React.CSSProperties,
    disabled?: boolean
    output?: 'uuid' | 'name' | string,
    defaultType?: ActionType
    placeholder?: string,
    fetchChoices: () => Promise<IRestServiceChoiceListResponse>,
    showSearch?: boolean,
    clearSearch?: boolean
}

interface IState {
    value?: string
    formRef: RefObject<FormInstance>,
    loading: boolean,
    choices?: ILabelValue[]
}

class ResourcePicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            value: Utils.parseObjectToIdentifier(props.value, 'uuid'),
            formRef: React.createRef(),
            loading: false
        }
    }

    static defaultProps = {
        output: "uuid"
    }

    componentDidMount() {
        this.fetch()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {value} = this.props
        if (prevProps.value !== value) {
            this.setState({value: Utils.parseObjectToIdentifier(value, 'uuid')})
        }
    }

    onPickerChoose = (resource: string) => {
        this.setState({value: resource})
        resource && this.updateParent(resource)
    }

    updateParent(resource: string) {
        const {onChange, output} = this.props
        const {choices} = this.state
        if (onChange && resource) {
            switch (output) {
                case "uuid":
                    return onChange(resource)
                case "name":
                    return onChange(choices?.find(c => c.value === resource)?.label.toString())
            }
        }
    }

    fetch = () => {
        this.setState({loading: true})
        const {fetchChoices} = this.props
        const {choices} = this.state
        choices ? this.setState({loading: false}) : fetchChoices().then(response => {
            this.setState({
                loading: false,
                choices: Object.entries(response.results).map(([value, label]) => ({value, label}))
            })
        })
    }

    render() {
        const {value, loading, choices} = this.state
        const {className, style, disabled, placeholder, multiple, showSearch, clearSearch} = this.props

        return (
            <Input.Group style={style} compact={true} className={'d-flex w-100'}>
                <Select
                    allowClear
                    className={(className || '') + ' flex-grow-1 min-w-0'}
                    showSearch
                    autoClearSearchValue={clearSearch}
                    notFoundContent={''}
                    loading={showSearch ? undefined : loading}
                    disabled={disabled}
                    dropdownMatchSelectWidth={false}
                    value={loading ? undefined : value}
                    mode={multiple ? "multiple" : undefined}
                    placeholder={placeholder}
                    onChange={this.onPickerChoose}
                    filterOption={(input, option) => Utils.stringContains(option?.label, input)}
                    options={choices}
                />
                {showSearch && <Button disabled={true} icon={loading ? <LoadingOutlined/> : <SearchOutlined/>}/>}
            </Input.Group>
        )
    }
}

export default ResourcePicker
