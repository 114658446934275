import React from "react";
import {Typography} from "antd";
import IActionOptions from "../../../../../../model/interface/widget/option/IActionOptions";
import WidgetTool from "../../../widget/WidgetTool";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import ActionButton from "../../../../action/ActionButton";
import IAction from "../../../../../../model/interface/dataStorage/IAction";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import {IActionResult} from "../../../../../../model/service/dataStorage/ActionsService";

interface IProps extends ICardWidgetProps<IActionOptions> {
    extractRouteParametersFromUrl: (url: string) => null | { id: number, parameters: { [name: string]: any } },
}

class CardWidgetAction extends React.Component<IProps> {
    renderAction = (action: IAction, options: IActionOptions) => {
        const {extractRouteParametersFromUrl, history, resource, match} = this.props
        return (
            <ActionButton
                history={history}
                match={match}
                wrapperClassName={'mb-3'}
                onClick={(executeAction: () => Promise<void>) => this.execute(executeAction)}
                resources={resource && [resource]}
                action={action}
                options={options}
                onFinish={result => this.onFinish(result)}
                payload={{route: extractRouteParametersFromUrl(history.location.pathname)}}
            />
        )
    }

    onFinish (result?: IActionResult): Promise<void> {
        if(this.props.functions.onFinishAction) {
            return this.props.functions.onFinishAction(result)
        } else {
            return this.props.functions.onReload ? this.props.functions.onReload() : new Promise(resolve => resolve())
        }
    }

    execute(executeAction: () => Promise<void>): Promise<void> {
        const promise = this.props.functions.onLoading
        ? this.props.functions.onLoading() //todo do we need to start the loading upon execution ?? what if its cancelled or terminated..
        : Promise.resolve()
        return promise.then(() => {
            return executeAction().catch(() => {
                this.props.functions.onFinishAction?.(undefined, true)
                return Promise.reject()
            })
        })
    }

    render() {
        const {functions, options,resource} = this.props
        const action = functions.getAction(options.actionId || '')
        if(action && resource?._permissions[action.name] === false) {
            return <></>
        }
        return (
            <div>
                <WidgetTool {...this.props} edit={true}/>
                {action ? this.renderAction(action, options)
                    : <Typography.Text type={"warning"}>Action not found, reset it!</Typography.Text>}
            </div>
        );
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        extractRouteParametersFromUrl: (url: string) => selectors.routes.extractRouteParametersFromUrl(store, url),
    }
}

export default connect(mapStateToProps)(CardWidgetAction)
