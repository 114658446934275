import React from "react";
import {Drawer} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import MenuContent from "./MenuContent";
import {onMobileNavToggle} from "redux/actions/Theme";
import Logo from "./Logo";
import {ArrowLeftOutlined} from "@ant-design/icons";
import ISettings from "../../model/interface/ui/ISettings";
import Bookmarks from "./headerNav/Bookmarks";
import IBaseProps from "../../model/interface/IBaseProps";

interface IProps extends IBaseProps{
  mobileNav: boolean
  projectSettings: ISettings
  onMobileNavToggle: (open: boolean) => void
}

class MobileNav extends React.Component<IProps> {
  render() {
    const { mobileNav, projectSettings, history, match } = this.props;

    console.log('mobileNav', projectSettings)

    const onClose = () => {
      this.props.onMobileNavToggle(false);
    };

    return (
        <Drawer
            placement="left"
            closable={false}
            onClose={onClose}
            visible={mobileNav}
            bodyStyle={{ padding: 5 }}
        >
          <div className={"d-flex flex-column h-100"}>
            <div className={"d-flex justify-content-between align-items-center"}>
              <Logo square={false} isMobile={true} settings={projectSettings} />
              <div className="nav-close" onClick={() => onClose()}>
                <ArrowLeftOutlined />
              </div>
            </div>
            <div className="mobile-nav-menu">
              <MenuContent _history={history} _match={match} hideGroupTitle={true} isMobile={true} />
            </div>
          </div>
        </Drawer>
    );
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  const { navCollapsed, sideNavTheme, mobileNav } = state.theme;
  return {
    navCollapsed,
    sideNavTheme,
    mobileNav,
    settings: state.setup.settings
  };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MobileNav);
