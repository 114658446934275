import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {NOTIFICATIONS_CHANGE_STATE, NOTIFICATIONS_LOAD, NOTIFICATIONS_RELOAD} from '../constants/Notifications';
import {load, save, saveOne} from "../actions/Notifications";

import MyNotificationsService from 'model/service/ui/MyNotificationsService';
import IRestServiceCollectionResponse from "../../model/interface/api/IRestServiceCollectionResponse";
import {STATES} from "../../model/interface/ui/INotification";

export function* notificationsLoad() {
    yield takeEvery(NOTIFICATIONS_LOAD, function* () {
        const response: IRestServiceCollectionResponse = yield call(MyNotificationsService.collectionList, {limit: 0})
        yield put(save(response));
    });
}

export function* notificationsReload() {
    yield takeEvery(NOTIFICATIONS_RELOAD, function* () {
        yield put(load());
    });
}

export function* notificationsChangeState() {
    yield takeEvery(NOTIFICATIONS_CHANGE_STATE, function* ({notification, state}: any) {
        notification.state = STATES.STATE_LOADING;
        yield put(saveOne(notification))
        yield call(MyNotificationsService.resourceUpdate, notification.id, {state})
        notification.state = state;
        yield put(saveOne(notification))
    });
}

export default function* rootSaga() {
    yield all([
        fork(notificationsLoad),
        fork(notificationsReload),
        fork(notificationsChangeState)
    ]);
}
