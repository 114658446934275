import React, {RefObject} from 'react'
import {Form, FormInstance, Spin} from 'antd';
import IBaseProps from "../../../model/interface/IBaseProps";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IRestResource from "../../../model/interface/api/IRestResource";
import Action from "../action/Action";
import FormElementField from "../configuration/form/FormElement/FormElementField";
import IField, {IFieldOptions} from "../../../model/interface/dataStorage/IField";
import FieldEditor from "../configuration/form/FormElement/optionEditor/FieldEditor";
import IRepositoryService from "../../../model/interface/IRepositoryService";
import IAction from "../../../model/interface/dataStorage/IAction";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import {debounce} from "underscore";
import DataStorageHelper from "../../../utils/DataStorageHelper";
import FormFieldType from "../configuration/form/FormElement/formField/FormFieldType";

interface IState {
    formRef: RefObject<FormInstance>
    updating: boolean
}

interface IProps extends IBaseProps {
    onFinish: (result?: IActionResult) => void | Promise<void>,
    resource: IRestResource
    contentType: IContentType
    field: IField
    doAction?: string
    type?: string
    preview?: boolean
    extractRouteParametersFromUrl: (url: string) => null | { id: number, parameters: { [name: string]: any } },
    findContentTypeByUuid: (uuid: string) => IContentType
    onUpdate?: (value: any) => void
    findServiceByClassName: (name: string) => IRepositoryService
    reload?: boolean
    noAction?: boolean,
    fieldOptions?: IFieldOptions
}

class FormInlineEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            updating: false,
        }
        const fieldType = props.type || FieldEditor.detectType(props.field)
        this.inPlaceEdit = !!fieldType && ['input', 'WYSIWYG', 'number'].indexOf(fieldType) >= 0 ? debounce(this.inPlaceEdit, props.noAction ? 400 : 800) : this.inPlaceEdit;
    }

    static isAllowed(resource?: IRestResource, action?: IAction) {
        return !resource || (!action || (resource._permissions && resource._permissions[action.name]))
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {reload} = this.props
        if (prevProps.reload !== reload && reload === false) {
            this.state.formRef.current?.resetFields()
        }
    }

    inPlaceEdit = (values: any) => {
        const {doAction, contentType, resource, onUpdate, noAction} = this.props
        const dataAction = doAction ? contentType.actions.find(action => action.uuid === doAction) : undefined
        const fieldName = Object.keys(values)[0]
        const value = values[fieldName]
        const updateState = () => {
            this.setState({updating: false})
            onUpdate && onUpdate(value)
        }
        if (noAction) {
            return updateState()
        }
        this.setState({updating: true})
        if (dataAction) {
            Action.doAction(dataAction, [resource], this.props.history, {
                route: this.props.extractRouteParametersFromUrl(this.props.history.location.pathname),
                data: DataStorageHelper.buildFormData({[fieldName]: value}, contentType)
            }, false).then((result) => {
                updateState()
                this.props.onFinish(result);
            }).catch(() => {
                this.setState({updating: false})
            })
        } else {
            const service = this.getService();
            service.resourceUpdate(this.props.resource!.uuid!,
                DataStorageHelper.buildFormData({[fieldName]: value}, contentType)).then(() => {
                    updateState()
                })
        }
    }

    getService() {
        const className = this.props.resource!._class
        return this.props.findServiceByClassName(className);
    }

    render() {
        const {resource, type, field, preview, contentType,fieldOptions} = this.props
        const {updating, formRef} = this.state
        const fieldType = type || FieldEditor.detectType(field)
        const values = {
            ...resource,
            [field.name]: FormFieldType.formatToForm(FieldEditor.detectType(field), resource[field.name])
        }

        return (
            <Spin wrapperClassName={'d-block flex-grow-1'} spinning={updating}>
                <Form
                    ref={formRef}
                    initialValues={values}
                    onValuesChange={changes => !preview && this.inPlaceEdit(changes)}
                >
                    <FormElementField
                        className={'m-0'}
                        options={{
                            type: fieldType,
                            ...fieldOptions
                        }}
                        functions={{getContentType: () => contentType}}
                        preview={true}
                        field={field}
                    />
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {

    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        extractRouteParametersFromUrl: (url: string) => selectors.routes.extractRouteParametersFromUrl(state, url),
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name)
    }
}

export default connect(mapStateToProps)(FormInlineEdit)