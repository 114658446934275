export type Interval = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year'

export const INTERVAL_LIST: Interval[] = [
    'second', 'minute', 'hour', 'day', 'week', 'month', 'year'
]

type Locale = {
    [interval in Interval]?: { [amount: number]: string }
}

const CZ_DEFAULT: Locale = {
    day: {
        0: 'dní',
        1: 'den',
        2: 'dny',
        5: 'dní'
    },
    hour: {
        0: 'hodin',
        5: 'hodin',
        1: 'hodina',
        2: 'hodiny'
    },
    minute: {
        0: 'minut',
        1: 'minuta',
        2: 'minuty',
        5: 'minut',
    },
    second: {
        0: 'sekund',
        1: 'sekunda',
        2: 'sekundy',
        5: 'sekund',
    },
    week: {
        0: 'týdnů',
        1: 'týden',
        2: 'týdny',
        5: 'týdnů',
    },
    month: {
        1: 'měsíc',
        2: 'měsíce',
        5: 'měsíců',
        0: 'měsíců'
    },
    year: {
        1: 'rok',
        2: 'roky',
        5: 'let',
        0: 'let'
    }
}

const ratio = (from: Interval, to: Interval) => {
    return {
        second: {
            second: 1,
            minute: 60,
            hour: 3600,
            day: 3600 * 24,
            week: 3600 * 24 * 7,
            month: 3600 * 24 * 30,
            year: 3600 * 24 * 365
        }[to],
        minute: {
            second: 1 / 60,
            minute: 1,
            hour: 60,
            day: 60 * 24,
            week: 60 * 24 * 7,
            month: 60 * 24 * 30,
            year: 60 * 24 * 365
        }[to],
        hour: {
            second: 1 / 3600,
            minute: 1 / 60,
            hour: 1,
            day: 24,
            week: 24 * 7,
            month: 24 * 30,
            year: 24 * 365
        }[to],
        day: {
            second: 1 / (3600 * 24),
            minute: 1 / (60 * 24),
            hour: 1 / 24,
            day: 1,
            week: 7,
            month: 30,
            year: 365
        }[to],
        week: {
            second: 1 / (3600 * 24 * 7),
            minute: 1 / (60 * 24 * 7),
            hour: 1 / (24 * 7),
            day: 1 / 7,
            week: 1,
            month: 30 / 7,
            year: 365 / 7
        }[to],
        month: {
            second: 1 / (30 * 24 * 3600),
            minute: 1 / (30 * 24 * 60),
            hour: 1 / (30 * 24),
            day: 1 / 30,
            week: 7 / 30,
            month: 1,
            year: 12
        }[to],
        year: {
            second: 1 / (365 * 24 * 3600),
            minute: 1 / (365 * 24 * 60),
            hour: 1 / (365 * 24),
            day: 1 / 365,
            week: 7 / 365,
            month: 1 / 12,
            year: 1
        }[to]
    }[from]
}

function buildString(value: number, locale?: { [amount: number]: string }, following?: number, separator = ',') {
    let localeString = ''
    locale && Object.entries(locale).sort(([a], [b]) => Number(a) - Number(b))
        .forEach(([amount, string], index, array) => {
            if ((((value >= Number(amount) || value < 0) && (!array[index + 1] || value < Number(array[index + 1][0])))
                || Object.keys(locale).length === 1) && !localeString) {
                localeString = string
            }
        })
    return value !== 0 || following === undefined ? value + ` ${localeString}${following ? separator + " " : ""}` : "";
}

export const humanSeconds = (seconds: number, locale?: Locale) => {
    return humanTime(seconds, 'second', ["hour", "minute", "second"], ',', locale)
}

export const humanShiftHoursInDays = (hours: number, shift: number = 8, locale?: Locale) => {
    let d = Math.floor(hours / shift);
    let h = Math.ceil(hours % shift);
    locale = {...CZ_DEFAULT, ...locale}

    return buildString(d, locale["day"], h || undefined) + (h ? buildString(h, locale["hour"]) : '');
}


export const humanTime = (amount: number, interval: Interval, format: Interval[] | string, separator: string = ',', locale?: Locale) => {
    locale = {...CZ_DEFAULT, ...locale}
    const dividers: number[] = []
    const values: { value: number, type: Interval }[] = []
    let intervals
    if (typeof format === "string") {
        intervals = Array.from(format.matchAll(new RegExp(INTERVAL_LIST.join('|'), 'g')), m => m[0]) as Interval[]
    } else {
        intervals = format
    }

    intervals.forEach((item, index) => {
        dividers.push(ratio(interval, item))
        const rawValue = dividers.reduce((prev, curr, i) => i + 1 === dividers.length ? prev / curr : prev % curr, amount)
        const value = index + 1 === format.length ? Math.ceil(rawValue) : Math.floor(rawValue)
        values.push({value, type: item})
    })

    if (typeof format === "string") {
        let result = format
        values.forEach(value => {
            result = result.replaceAll(value.type, buildString(value.value, locale?.[value.type]))
        })
        return result
    }
    return values.reduce((prev, curr, i) => prev + buildString(curr.value, locale?.[curr.type], values[i + 1]?.value, separator), '')
}