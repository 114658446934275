export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
// export const SIDE_NAV_STYLE_CHANGE = 'SIDE_NAV_STYLE_CHANGE';
export const NAV_TYPE_CHANGE = 'NAV_TYPE_CHANGE';
// export const TOP_NAV_COLOR_CHANGE = 'TOP_NAV_COLOR_CHANGE';
// export const HEADER_NAV_COLOR_CHANGE = 'HEADER_NAV_COLOR_CHANGE';
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV';
// export const SWITCH_THEME = 'SWITCH_THEME';
// export const DIRECTION_CHANGE = 'DIRECTION_CHANGE';
export const MARGIN_CHANGE = 'MARGIN_CHANGE';
export const FONT_SIZE_CHANGE = 'FONT_SIZE_CHANGE';
export const TABLE_SIZE_CHANGE = 'TABLE_SIZE_CHANGE';
export const LOAD_THEME = 'LOAD_THEME';
