import React from "react";
import IContainerOptions from "../../../../../model/interface/form/elementOptions/IContainerOptions";
import Utils from "../../../../../utils";
import {motion} from "framer-motion";
import IWidgetProps from "../../../../../model/interface/widget/IWidgetProps";
import IFormElementFunctions from "../../../../../model/interface/form/IFormElementFunctions";
import WidgetTool from "../../widget/WidgetTool";
import WidgetChildren from "../../widget/WidgetChildren";
import {Card} from "antd";
import ConditionEditor from "./optionEditor/ConditionEditor";

interface IState{
    conditionMet: boolean
}

class ContainerWidget extends React.Component<IWidgetProps<IFormElementFunctions, IContainerOptions>, IState> {
    constructor(props: IWidgetProps<IFormElementFunctions, IContainerOptions>) {
        super(props);
        this.state = {
            conditionMet: false
        }
    }

    componentDidMount() {
        const state = ContainerWidget.getDerivedStateFromProps(this.props)
        this.setState({conditionMet: !!state?.conditionMet})
    }

    static getDerivedStateFromProps(props: IWidgetProps<IFormElementFunctions, IContainerOptions>) {
        const {functions, preview, options} = props
        if (preview && Utils.toBoolean(options.collapsable)) {
            return {conditionMet: ConditionEditor.evaluateConditions(options.conditions, functions.getCurrentValue)}
        }
        return null
    }

    render() {
        const {preview, options} = this.props
        const {bordered} = options
        const {conditionMet} = this.state

        const content = <>
            <WidgetTool {...this.props} add={true} edit={true}/>
            <WidgetChildren {...this.props}  className={options.display + ' w-100 flex-wrap'}/>
        </>

        return (
            <motion.div className={!preview ? '' : "overflow-hidden"}
                 initial={{height: conditionMet ? "0px" : "auto", display: conditionMet ? "none" : "block"}}
                 transition={{duration: 0.5}}
                 animate={{
                     height:  conditionMet ? "0px" : "auto",
                     transitionEnd: {
                         display: conditionMet ? "none" : "block",
                     }
                 }}
            >
                {bordered ? (
                    <Card>
                        {content}
                    </Card>
                ) : (
                    content
                )}
            </motion.div>
        );
    }
}

export default ContainerWidget