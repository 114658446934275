import {Form, Input, Select} from 'antd';
import React from "react";
import FormFieldType from "../../../form/FormElement/formField/FormFieldType";
import moment from "moment";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import {PickerMode} from 'rc-picker/lib/interface';

export interface IFieldDateOptions {
    datePicker?: PickerMode | 'dateTime'
    dateFormat?: string
}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {
    format?: string
    type?: string
}

class FieldDateOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            format: props.options.dateFormat
        };
    }

    setFormat = (value?: string) => {
        this.setState({format: value})
    }

    setType = (value?: string) => {
        this.setState({type: value})
    }

    static getDefaultOptions(): IFieldDateOptions {
        return {datePicker: FormFieldType.FIELD_DATE_DATE_PICKER, dateFormat: 'LL'}
    }

    render() {
        const {format} = this.state

        return (
            <div>
                <Form.Item name={'datePicker'} label={'Typ vstupu'} rules={[{required: true}]}>
                    <Select>
                        {FormFieldType.FIELD_DATE_PICKER_TYPES.map(type => (
                            <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={'Formát zobrazení'}>
                    <Input.Group>
                        <Form.Item name={'dateFormat'} noStyle>
                            <Input onChange={e => this.setFormat(e.target.value)} className={'w-50'}/>
                        </Form.Item>
                        <Input disabled className={'w-50'} value={moment().format(format)}/>
                    </Input.Group>
                    <a href={'https://momentjs.com/'} rel="noreferrer" target={'_blank'}>Více formátů zde</a>
                </Form.Item>
            </div>
        )
    }
}


export default FieldDateOptionsEditor