import {Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase from "./IFilterBase";
import {API_FILTER_TYPE} from "../../model/constants/ApiConstant";
import FilterDropDown from "./FilterDropDown";
import FormFieldContentType from "../../components/app/configuration/form/FormElement/formField/FormFieldContentType";
import WorkflowStatesService from "../../model/service/file/WorkflowStatesService";
import FormFieldWorkflowState
    from "../../components/app/configuration/form/FormElement/formField/FormFieldWorkflowState";
import UnitsService from "../../model/service/company/UnitsService";
import FormFieldCompanyStructure
    from "../../components/app/configuration/form/FormElement/formField/FormFieldCompanyStructure";
import FormFieldType from "../../components/app/configuration/form/FormElement/formField/FormFieldType";
import JobsService from "../../model/service/company/JobsService";
import CompaniesService from "../../model/service/company/CompaniesService";
import EmployeesService from "../../model/service/company/EmployeesService";
import {IFieldOptions} from "../../model/interface/dataStorage/IField";

interface IFilterChoose extends IFilterBase {
    setSearch: { (search: string, setSelectedKeys: any): void },
}

const ChooseFilter: IFilterChoose = {
    handleSearch(setSelectedKeys, selectedKeys, confirm, setValue) {
        setValue(selectedKeys)
        confirm();
    },

    handleReset(clearFilters, setValue) {
        clearFilters();
        setValue([])
    },

    setSearch(search: string, setSelectedKeys: any) {
        setSelectedKeys([{search}])
    },

    build(item, field, state, setValue): IFilterFunctions {
        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                const onChange = (value: any) => {
                    if (value && (!Array.isArray(value) || value.length)) {
                        setSelectedKeys([{
                            value,
                            type: Array.isArray(value) ? API_FILTER_TYPE.IN : API_FILTER_TYPE.EQUAL
                        }])
                    } else {
                        setSelectedKeys([{
                            value: '',
                            type: API_FILTER_TYPE.IS_NULL
                        }])
                    }
                }

                const globalOptions: IFieldOptions & {label: string} = {
                    showClear: true,
                    multiple: true,
                    companyStructureMultiple: true,
                    autoFocus: true,
                    label: '',
                    companyStructureMode: 'list',
                    allowSelectAll: field.options.allowSelectAll !== false,
                    ...field.options,
                    ...item.options?.fieldOptions
                }

                const selectedValue = selectedKeys[0]?.value && Array.isArray(selectedKeys[0].value) &&
                selectedKeys[0].value.length ? selectedKeys[0].value : undefined
                const inputRef = null
                const sharedProps = {inputRef, onChange, value: selectedValue}

                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                        {field.targetEntity && field.contentTypeId ? {
                            [field.targetEntity]: <FormFieldContentType
                                {...sharedProps}
                                options={globalOptions}
                                field={field}
                            />,
                            [UnitsService.getInstance().getRecordClassName()]:
                                <FormFieldCompanyStructure
                                    {...sharedProps}
                                    options={{
                                        companyStructureAccepts: 'unit',
                                        ...globalOptions,
                                        type: FormFieldType.FIELD_COMPANY_STRUCTURE
                                    }}
                                    onChange={onChange}
                                />,
                            [JobsService.getInstance().getRecordClassName()]:
                                <FormFieldCompanyStructure
                                    {...sharedProps}
                                    options={{
                                        companyStructureAccepts: 'job',
                                        ...globalOptions,
                                        type: FormFieldType.FIELD_COMPANY_STRUCTURE
                                    }}
                                />,
                            [CompaniesService.getInstance().getRecordClassName()]:
                                <FormFieldCompanyStructure
                                    {...sharedProps}
                                    options={{
                                        companyStructureAccepts: 'company',
                                        ...globalOptions,
                                        label: '',
                                        type: FormFieldType.FIELD_COMPANY_STRUCTURE
                                    }}
                                />,
                            [EmployeesService.getInstance().getRecordClassName()]:
                                <FormFieldCompanyStructure
                                    {...sharedProps}
                                    options={{
                                        ...globalOptions,
                                        label: '',
                                        type: FormFieldType.FIELD_COMPANY_STRUCTURE,
                                        companyStructureAccepts: 'employee',
                                        companyStructureMode: 'list'
                                    }}
                                    onChange={onChange}
                                />,
                            [WorkflowStatesService.getRecordClassName()]:
                                <FormFieldWorkflowState
                                    {...sharedProps} className={'w-100'} options={{
                                    ...globalOptions,
                                    contentTypeId: field.contentTypeId,
                                    type: '',
                                }}/>
                        }[field.targetEntity] : 'Chyba konfigurace'}
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered) => {
                return (
                    <Tooltip title={filtered ? "Filtrováno: " + (state[0] ? state[0].value : '') : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            }
        }
    }
}

export default ChooseFilter