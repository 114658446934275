import {Form, Input, Select, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import DirectoryPicker from "../../../../../shared/pickers/file/DirectoryPicker";
import {IFilePickerWidgetType} from "../../../../../shared/pickers/file/FilePicker";

export interface IFieldFileOptions {
    fileWidget?: IFilePickerWidgetType
    fileCanChoose?: boolean
    fileCanUpload?: boolean
    fileCanDownload?: boolean
    fileCanRemove?: boolean
    fileMultiple?: boolean
    fileDisabled?: boolean
    fileDirectory?: number
    fileExtensions?: string[]
}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {
}

class FieldFileOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    static getDefaultOptions(): IFieldFileOptions {
        return {
            fileCanChoose: true,
            fileCanDownload: false,
            fileCanRemove: false,
            fileCanUpload: true,
            fileWidget: 'simple'
        }
    }

    render() {

        const types: { code: IFilePickerWidgetType, label: string }[] = [
            {code: "simple", label: 'Jednoduchý'},
            {code: "extended", label: 'Rozšířený'}
        ]

        const switches = [
            {label: 'Nahrání', code: 'fileCanUpload'},
            {label: 'Prohlížeč', code: 'fileCanChoose'},
            {label: 'Stahování', code: 'fileCanDownload'},
            {label: 'Odstranění', code: 'fileCanRemove'}
        ]

        return (
            <div>
                <Form.Item name={'fileWidget'} label={'Vyberte režim výběru souborů'} rules={[{required: true}]}>
                    <Select>
                        {types.map(type => (
                            <Select.Option key={type.code} value={type.code}>{type.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={'Možnosti'}>
                    <Input.Group>
                        {switches.map(switchItem => (
                            <Form.Item name={switchItem.code} valuePropName={'checked'} noStyle>
                                <Switch className={'mr-2'}
                                        unCheckedChildren={switchItem.label}
                                        checkedChildren={switchItem.label}
                                />
                            </Form.Item>
                        ))}
                    </Input.Group>
                </Form.Item>
                <Form.Item name={'fileDirectory'} label={'Omezit na jednu složku'}>
                    <DirectoryPicker defaultRoot={false}/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldFileOptionsEditor