import React from 'react'
import {APP_NAME} from 'configs/AppConfig';
import {connect, RootStateOrAny} from "react-redux";
import processLogo from '../../assets/images/proces_logo-sm.png';
import processSquareLogo from '../../assets/images/logo_sq.png';
import ISettings from "../../model/interface/ui/ISettings";

interface IProps {
    square: boolean,
    isMobile: boolean
    login: boolean

    // navCollapsed: boolean
    // navType: string
    //
    // // logoType: string
    // mobileLogo?: string
    settings: ISettings
    className?: string
    // isMobile: boolean
}

class Logo extends React.Component<IProps> {
    getLogoDisplay (isMobile: boolean, mobileLogo: any) {
        if (isMobile && !mobileLogo) {
            return 'd-none'
        } else {
            return 'logo'
        }
    }

    getLogo () {
        const {settings} = this.props
        if (settings.ui?.logo?.thumbnailUrl) {
            return settings.ui.logo.thumbnailUrl
        }
        return processLogo
    }

    getLoginLogo () {
        const {settings} = this.props
        if (settings.ui?.loginLogo?.thumbnailUrl) {
            return settings.ui.loginLogo.thumbnailUrl
        }
        return this.getLogo()
    }

    getMobileLogo () {
        const {settings} = this.props
        if (settings.ui?.mobileLogo?.thumbnailUrl) {
            return settings.ui.mobileLogo.thumbnailUrl
        }
        return processSquareLogo
    }

    // getLogoWidthGutter () {
    //     const {type, isMobile} = this.props;
    //     if (isMobile && !mobileLogo) {
    //         return 0
    //     }
    //     if (navCollapsed) {
    //         return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    //     } else {
    //         return `${SIDE_NAV_WIDTH}px`
    //     }
    // }

    render() {
        const {isMobile, square, className, login} = this.props
        return (
            <div
                className={"logo justify-content-center "+className}
                // style={{width: `${this.getLogoWidthGutter()}`}}
            >
                {square ? (
                    <>
                        <img src={this.getMobileLogo()} alt={`${APP_NAME} logo`}/>
                    </>
                ) : (
                    <>
                        <img src={login ? this.getLoginLogo() : this.getLogo()} alt={`${APP_NAME} logo`}/>
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = ({theme}: RootStateOrAny) => {
    const {navCollapsed, navType} = theme;
    return {navCollapsed, navType}
};

export default connect(mapStateToProps)(Logo);
