import React from "react";
import {CloseOutlined, EditOutlined, LockOutlined, PlusOutlined} from "@ant-design/icons";
import IPresenter from "../../../../model/interface/dataStorage/IPresenter";
import {Modal, Row, Table, Tooltip} from "antd";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import Utils from "../../../../utils";
import ContentTypePresentersForm from "./presenter/ContentTypePresentersForm";
import {ColumnsType} from "antd/es/table";
import Hotkey from "../../../shared/hotkey/Hotkey";
import Button from "../../../shared/button/Button";

interface IState {
    presenter?: IPresenter
}

export default class ContentTypePresenters extends React.Component<IContentTypeStepProps, IState> {

    constructor(props: IContentTypeStepProps, context: any) {
        super(props, context);
        this.state = {}
    }

    edit = (presenter: IPresenter) => {
        this.setState({presenter})
    }

    create() {
        const presenter: IPresenter = {
            uuid: Utils.uuid(),
            type: 'label',
            name: '',
            label: '',
            options: {}
        }
        this.setState({presenter})
    }

    cancel() {
        this.setState({presenter: undefined})
    }

    submit = (presenter?: IPresenter) => {
        if (presenter) {
            this.save(presenter)
        }
        this.cancel()
    }

    save(values: any) {
        let presenters = this.props.resource.presenters
        const resource: IPresenter = this.state.presenter!
        if (!resource) {
            throw new Error("Resource is empty")
        }
        const index = Utils.findIndex(presenters, {uuid: resource.uuid})
        const presenter = {...resource, ...values}

        if (index < 0) {
            presenters.push(presenter)
        } else {
            presenters[index] = presenter
        }

        this.props.onValuesChange({presenters})
        this.cancel()
    }

    delete({uuid}: IPresenter) {
        this.props.onValuesChange({
            presenters: Utils.removeFromArrayWhere(this.props.resource.presenters, {uuid})
        })
    }

    render() {
        const presenterColumns: ColumnsType<IPresenter> = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: <LockOutlined/>,
                dataIndex: 'default',
                render: (flag: string) => (
                    <>
                        {flag && <LockOutlined/>}
                    </>
                ),
            },
            {
                title: 'Titulek',
                dataIndex: 'label'
            },
            {
                title: 'Název',
                dataIndex: 'name'
            },
            {
                title: 'Typ',
                dataIndex: 'type'
            },
            {
                title: 'Akce',
                key: 'action',
                dataIndex: 'action',
                render: (actionUuid: string) => {
                    if (!actionUuid) {
                        return <></>;
                    }
                    const action = this.props.resource.actions.find(action => action.uuid === actionUuid)!
                    return (
                        <>{action.label || action.name}</>
                    )
                }
            },
            {
                title: <Row justify={"end"}>
                    <Hotkey help={"Přidat zobrazení"} keys={["Alt", "p"]} trigger={() => this.create()}>
                        <Button type={"success"} onClick={() => this.create()} icon={<PlusOutlined/>}>
                            <u className={'pl-2'}>P</u>řidat
                        </Button>
                    </Hotkey>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_: any, elm: IPresenter) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Upravit"}>
                            <Button onClick={() => this.edit(elm)} type="link" className="mr-2" icon={<EditOutlined/>}
                                    size="small"/>
                        </Tooltip>
                        <Tooltip title={"Odstranit"}>
                            <Button danger type="link" onClick={() => Modal.confirm({
                                content: "Opravdu odstranit?",
                                onOk: () => this.delete(elm)
                            })} className="mr-2" icon={<CloseOutlined/>} size="small"/>
                        </Tooltip>
                    </div>
                )
            }
        ]
        const {resource} = this.props
        const {presenter} = this.state
        const {presenters} = resource

        return (
            <>
                {presenter && <ContentTypePresentersForm modal={true} contentType={resource} resource={presenter!}
                                                         saveMode={'none'} onSubmit={this.submit}/>}
                <Table
                    pagination={{pageSize: 10, showSizeChanger: true}}
                    columns={presenterColumns}
                    dataSource={[...presenters]}
                    rowKey={row => row.uuid || row.name}
                />
            </>
        )
    }
}