import React from "react";
import ReportWidgetType from "./ReportWidgetType";
import Widget from "../../widget/Widget";
import ImageWidget from "./ImageWidget";
import IReportWidgetImage from "../../../../../model/interface/report/IReportWidgetImage";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import ViewWidget from "./ViewWidget";
import IReportWidgetView from "../../../../../model/interface/report/IReportWidgetView";
import EmployeeLookupWidget from "./EmployeeLookupWidget";
import NotificationsWidget from "./NotificationsWidget";
import ResourceFinderByCodeWidget from "./ResourceFinderByCodeWidget";

class ReportWidget extends React.Component<IReportWidgetProps> {

    static getWidget(type: string, props: IReportWidgetProps) {
        switch (type) {
            case(ReportWidgetType.IMAGE):
                return <ImageWidget {...props as IReportWidgetProps<IReportWidgetImage>}/>
            case(ReportWidgetType.VIEW):
                return <ViewWidget {...props as IReportWidgetProps<IReportWidgetView>}/>
            case(ReportWidgetType.EMPLOYEE_LOOKUP):
                return <EmployeeLookupWidget {...props}/>
            case(ReportWidgetType.NOTIFICATIONS):
                return <NotificationsWidget {...props}/>
            case(ReportWidgetType.RESOURCE_FINDER_BY_CODE):
                return <ResourceFinderByCodeWidget {...props}/>
            default:
                return Widget.getWidget(type, props)
        }
    }

    render(): JSX.Element {
        return Widget.renderWidget({...this.props, customWidget: ReportWidget}, ReportWidget.getWidget)
    }
}

export default ReportWidget