import {Form, Select, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../../FieldOptionsEditor";
import ActionPicker from "../../../../../../shared/pickers/ActionPicker";
import EmployeesService from "../../../../../../../model/service/company/EmployeesService";
import IContentType from "../../../../../../../model/interface/dataStorage/IContentType";

export interface ICompositeFieldApprovalOptions {
    action?: string
    allowChange?: boolean
    allowDenial?: boolean
    allowJustification?: boolean
    requireJustification?: boolean,
    authorPresenter?: string
}

interface IProps extends IFieldOptionsEditorProps {
    contentType: IContentType
}

interface IState {
    format?: string
    type?: string
}

class CompositeFieldApprovalOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    static getDefaultOptions(): ICompositeFieldApprovalOptions {
        return {
            authorPresenter: EmployeesService.getInstance().getDefaultPresenter().name
        }
    }

    render() {
        const {contentType} = this.props

        return (
            <div>
                <Form.Item name={'icon'} label={'Zvolte iconu'}>
                    <ActionPicker output={"name"} contentTypeUuid={contentType.uuid}/>
                </Form.Item>
                <Form.Item name={'allowChange'} label={'Povolit změnu'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'allowDenial'} label={'Povolit zamítnutí'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'allowJustification'} label={'Povolit přidat zdůvodnění'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'requireJustification'} label={'Vyžadovat zdůvodnění'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'authorPresenter'} label={'Zobrazení schvalovatele'} valuePropName={'checked'}>
                    <Select
                        dropdownMatchSelectWidth={false}
                        options={EmployeesService.getInstance().getPresenterList()}
                    />
                </Form.Item>
            </div>
        )
    }
}


export default CompositeFieldApprovalOptionsEditor