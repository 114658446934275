import React from "react"
import {Calendar as AntdCalendar} from "antd";
import moment, {Moment} from "moment";
import {IEvent} from "./Calendar";
import Cell from "./Cell";
import {CalendarMode} from "antd/es/calendar/generateCalendar";


interface IState {
    selectStart?: Moment
    selectEnd?: Moment
    newEvent?: IEvent
}

interface IProps {
    date: Moment
    cell?: (date: Moment) => JSX.Element
    onSelect?: (start: Moment, end: Moment) => void
    onChange?: (date: Moment) => void,
    mode: CalendarMode
    eventRender?: <T extends IEvent>(object: T) => string | JSX.Element
    events?: IEvent[]
}

class MonthYear extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    selectByDrag = (date: Moment, done: boolean = false) => {
        const unit = this.getCurrentModeUnit()
        this.props.onSelect && this.setState(state => {
            if (state.selectStart && state.newEvent) {
                const [start, end] = [moment.min(state.selectStart, date), moment.max(state.selectStart, date)]
                if (done) {
                    this.onSelect(start.startOf(unit), end.endOf(unit))
                }
                return {
                    selectEnd: date,
                    newEvent: {
                        ...state.newEvent,
                        startAt: start.startOf(unit),
                        endAt: end.endOf(unit)
                    }
                }
            }
            document.onkeydown = (evt) => {
                if ("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) {
                    this.unsetDragSelect()
                    document.onkeydown = null
                }
            }
            return {
                selectStart: date,
                newEvent: {
                    startAt: date.startOf(unit), color: '#17bcff', label: '(bez názvu)', key: '',
                    endAt: date.clone().endOf(unit)
                }
            }
        })
    }

    unsetDragSelect = () => {
        this.setState({selectStart: undefined, selectEnd: undefined, newEvent: undefined})
    }

    dateFullCellRender = (date: Moment, mode: CalendarMode) => {
        const {newEvent} = this.state
        let className = 'ant-picker-cell-inner ant-picker-calendar-date h-100 prevent-select'
        className += date.isSame(moment(), 'd') ? ' ant-picker-calendar-date-today' : ''
        return <div className={className} onMouseDown={() => this.selectByDrag(date)}
                    onMouseUp={newEvent ? () => this.selectByDrag(date, true) : undefined}
                    onMouseOver={newEvent ? () => this.selectByDrag(date) : undefined}
                    style={{cursor: newEvent ? 'move' : 'pointer'}}>
            <div className="ant-picker-calendar-date-value">{{
                year: date.format('MMM'),
                month: date.format('DD')
            }[mode]}</div>
            <div className="ant-picker-calendar-date-content">
                {this.dateCellRender(date)}
            </div>
        </div>
    }

    dateCellRender = (date: Moment) => {
        const {newEvent} = this.state
        const {eventRender, events} = this.props;
        const allEvents = newEvent ? [...events || [], newEvent] : events || []
        return <Cell date={date} onMouseUp={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}
                     className={newEvent && date.isBetween(newEvent?.startAt, newEvent?.endAt, 'd', '[]') ? 'border' : ''}
                     events={allEvents.sort((a, b) => a.startAt.valueOf() - (b.endAt?.valueOf() || 0))
                         .filter(e => date.isBetween(moment(e.startAt), moment(e.endAt), 'day', '[]'))}
                     eventRender={eventRender}></Cell>
    }

    onSelect = (start: Moment, end: Moment) => {
        this.props.onSelect?.(start, end)
    }

    getCurrentModeUnit() {
        const {mode} = this.props
        return mode === 'month' ? 'day' : 'month';
    }

    render() {
        const {date, mode, onChange} = this.props;
        return (
            <div onMouseLeave={this.unsetDragSelect}>
                <AntdCalendar
                    value={date}
                    mode={mode as 'month' | 'year'}
                    headerRender={() => <></>}
                    dateFullCellRender={date => this.dateFullCellRender(date, mode)}
                    monthFullCellRender={date => this.dateFullCellRender(date, mode)}
                    onChange={onChange}
                />
            </div>
        )
    }
}

export default MonthYear