import {InputNumber, Slider, Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase, {IFilterBaseValue} from "./IFilterBase";
import {API_FILTER_TYPE} from "../../model/constants/ApiConstant";
import FilterDropDown from "./FilterDropDown";

const RangeFilter: IFilterBase = {
    handleSearch(setSelectedKeys, selectedKeys, confirm, setValue) {
        const finalKeys = selectedKeys.filter(key => key.value)
        setValue(finalKeys)
        setSelectedKeys(finalKeys)
        confirm();
    },

    handleReset(clearFilters, setValue) {
        clearFilters();
        setValue([])
    },

    build(item, field, state, setValue, values): IFilterFunctions {
        const dataIndex = field.name
        let inputMin: any
        const findMin = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.GREATER_OR_EQUAL)
        }
        const findMax = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.LESSER_OR_EQUAL)
        }

        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                const minValue = findMin(selectedKeys)?.value as undefined | number
                const maxValue = findMax(selectedKeys)?.value as undefined | number
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                        <Slider
                            range
                            min={values.length ? Math.min(...values.map(value => value.value) as number[]) : undefined}
                            max={values.length ? Math.max(...values.map(value => value.value) as number[]) : undefined}
                            onChange={(filterValues) =>
                                setSelectedKeys([
                                    {type: API_FILTER_TYPE.GREATER_OR_EQUAL, value: filterValues[0]},
                                    {type: API_FILTER_TYPE.LESSER_OR_EQUAL, value: filterValues[1]}
                                ])
                            }
                        />
                        <div className={'pb-2'}>
                            <InputNumber
                                ref={node => {
                                    inputMin = node;
                                }}
                                placeholder={'Min'}
                                value={minValue as number}
                                onChange={min => {
                                    setSelectedKeys([
                                        ...selectedKeys.filter(key => key.type !== API_FILTER_TYPE.GREATER_OR_EQUAL),
                                        {type: API_FILTER_TYPE.GREATER_OR_EQUAL, value: min}
                                    ])
                                }}
                                onPressEnter={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}
                            />
                            <InputNumber
                                placeholder={'Max'}
                                value={maxValue as number}
                                onChange={max => setSelectedKeys([
                                    ...selectedKeys.filter(key => key.type !== API_FILTER_TYPE.LESSER_OR_EQUAL),
                                    {type: API_FILTER_TYPE.LESSER_OR_EQUAL, value: max}
                                ])}
                                onPressEnter={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}
                            />
                        </div>
                    </FilterDropDown>
                )
            },
           filterIcon: (filtered: boolean): JSX.Element => {
                const minValue = findMin(state)?.value as number
                const maxValue = findMax(state)?.value as number
                return (
                    <Tooltip title={filtered ? "Filtrováno: " + (minValue || '') + ' - ' + (maxValue || '') : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                const recordValue = parseInt(record[dataIndex].toString(), 10)

                return value.type === API_FILTER_TYPE.LESSER_OR_EQUAL ?
                    (!value.value || parseInt(value.value.toString(), 10) >= recordValue) :
                    (!value.value || parseInt(value.value.toString(), 10) >= recordValue)
            },
            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible && inputMin) {
                    setTimeout(() => inputMin.select());
                }
            }
        }
    }
}

export default RangeFilter