import IViewEntryAction from "../../../model/interface/dataStorage/view/IViewEntryAction";
import React from "react";
import ActionButton from "../action/ActionButton";
import IActionOptions from "../../../model/interface/widget/option/IActionOptions";
import IRestResource from "../../../model/interface/api/IRestResource";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IAction from "../../../model/interface/dataStorage/IAction";
import IBaseProps from "../../../model/interface/IBaseProps";

interface IState {
}

interface IProps extends IBaseProps {
    action: IViewEntryAction,
    onFinish: (result?: IActionResult) => Promise<void>,
    onClick?: (execute: (customPayload?: any, customAction?: IAction, customResources?: IRestResource[]) => Promise<void>) => Promise<void>
    resources?: IRestResource[]
    payload?: any
    findContentTypeByUuid: (uuid: string) => IContentType
    disabled?: boolean,
    className?: string
}

class ViewAction extends React.Component<IProps, IState> {
    render() {

        const {
            action,
            onFinish,
            onClick,
            findContentTypeByUuid,
            disabled,
            resources,
            className
        } = this.props;
        const contentAction = findContentTypeByUuid(action.contentType).actions.find(a => a.uuid === action.action)
        const buttonAppearance = action.buttonAppearance

        const options = {
            tooltip: action.tooltip,
            type: buttonAppearance?.type || 'primary',
            label: action.label,
            icon: action.icon,
            actionId: action.uuid
        } as IActionOptions

        return contentAction && (
            <div className={className}>
                <ActionButton
                    onClick={onClick}
                    disabled={disabled}
                    resources={resources}
                    action={contentAction}
                    onFinish={onFinish}
                    options={options}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ViewAction)