import React, {RefObject} from "react";
import {Button, Form, FormInstance, Input, Modal, Switch} from "antd";
import IFormOptions from "../../../../../model/interface/form/IFormOptions";
import ITextOptions from "../../../../../model/interface/form/elementOptions/ITextOptions";
import ITabOptions from "../../../../../model/interface/form/elementOptions/ITabOptions";
import ITabsOptions from "../../../../../model/interface/widget/option/ITabsOptions";

interface IProps {
    options: ITabsOptions
    onFinish: (values?: IFormOptions) => void
}

class TabsWidgetEditor extends React.Component<IProps, ITabsOptions> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options,
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then((values: ITabsOptions) => {
            console.log('onOk', values)
            this.props.onFinish({...this.state, ...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    render() {
        return (
            <Modal
                title={'Upravit zalozku'}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form initialValues={this.state || {}} ref={this.formRef}>
                    <Form.Item name={'closed'} label={'Defaultně zavřeno'}>
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default TabsWidgetEditor