import React from "react"
import {Col, Row, Tag, Timeline, Typography} from "antd";
import moment, {Moment} from "moment";
import {IEvent} from "./Calendar";


interface IState {
    selectStart?: Moment
    selectEnd?: Moment
    newEvent?: IEvent
}

interface IProps {
    date: Moment
    cell?: (date: Moment) => JSX.Element
    onSelect?: (start: Moment, end: Moment) => void
    mode: 'single' | 'week'
    eventRender?: <T extends IEvent>(object: T) => string | JSX.Element
    events?: IEvent[]
}

const HOUR_HEIGHT = 40

class Days extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    onSelect = (start: Moment, end: Moment) => {
        const {onSelect} = this.props;
        onSelect?.(start, end)
    }

    getDays() {
        const {date, mode} = this.props;
        if (mode === 'single') {
            return [date]
        }

        return Array.from(Array({single: 1, week: 7}[mode]).keys()).map(day => date.clone().day(day))
    }

    getSelectedHour(e: React.MouseEvent<HTMLElement>) {
        const rect = e.currentTarget.getBoundingClientRect()
        const hours = (e.clientY - rect.top) / HOUR_HEIGHT
        const wholeHours = Math.floor(hours)
        return wholeHours + ((Math.ceil(((hours - wholeHours) * 60) / 15) * 15) / 60)
    }

    selectByDrag = (e: React.MouseEvent<HTMLElement>, date: Moment, done: boolean = false) => {
        date = date.clone().add(this.getSelectedHour(e), 'hour')
        this.props.onSelect && this.setState(state => {
            if (state.selectStart && state.newEvent) {
                const [start, end] = [moment.min(state.selectStart, date), moment.max(state.selectStart, date)]
                if (done) {
                    this.onSelect(start, end.isSame(start) ? end.add(15, 'minute') : end)
                }
                return {
                    selectEnd: date,
                    newEvent: {
                        ...state.newEvent,
                        startAt: start,
                        endAt: end
                    }
                }
            }
            document.onkeydown = (evt) => {
                if ("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) {
                    this.unsetDragSelect()
                    document.onkeydown = null
                }
            }
            return {
                selectStart: date,
                newEvent: {
                    startAt: date, color: '#17bcff', label: '(bez názvu)', key: '',
                    endAt: date.clone().add(15, 'minute')
                }
            }
        })
    }

    unsetDragSelect = () => {
        this.setState({newEvent: undefined, selectEnd: undefined, selectStart: undefined})
    }

    render() {
        const {events, eventRender} = this.props
        const {newEvent} = this.state
        const days = this.getDays()

        const allEvents = newEvent ? [...(events || []), newEvent] : events

        return (
            <div className={'w-100'} onMouseLeave={this.unsetDragSelect}>
                <Row className={'w-100 prevent-select'}>
                    <Col span={3}></Col>
                    {days.map(day => (
                        <Col onClick={() => this.onSelect(day.clone().startOf('d'), day.clone().endOf('d'))}
                             span={Math.floor(21 / days.length)}
                             className={'text-center border-left cursor-pointer'}>
                            <Typography.Text>{day.format('dddd')}</Typography.Text>
                            <Typography.Title className={'mt-2 '} level={3}>
                                <span className={'p-1 rounded '
                                    + (moment().isSame(day, 'D') ? 'bg-info text-white' : '')}>
                                    {day.format('D')}
                                </span>
                            </Typography.Title>
                        </Col>
                    ))}
                </Row>
                <Row className={'w-100'}>
                    <Col span={3} className={'prevent-select'}>
                        <Timeline mode={'right'} className={'pr-3'}>
                            {Array.from(Array(25).keys()).map(hour => (
                                <Timeline.Item>
                                    <div style={{height: HOUR_HEIGHT - 20 + 'px'}}>
                                        {hour.toString().padStart(2, '0')}
                                    </div>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Col>
                    {days.map(day => (
                        <Col onMouseUp={e => newEvent ? this.selectByDrag(e, day, true) : undefined}
                             span={Math.floor(21 / days.length)}
                             onMouseDown={e => this.selectByDrag(e, day)}
                             onMouseMove={newEvent ? (e) => this.selectByDrag(e, day) : undefined}
                             className={'text-center border-left position-relative'}
                             style={{height: HOUR_HEIGHT * 24 + 'px', cursor: newEvent ? 'move' : 'pointer'}}>
                            {allEvents?.filter(e => day.isBetween(e.startAt, e.endAt, 'day', '[]'))
                                .map((e, index) => {
                                        const overlapping = allEvents?.filter(secE => secE.startAt <= e.startAt)
                                            .filter(secE => e.endAt && secE.endAt && secE.startAt <= e.endAt && secE.endAt >= e.startAt)
                                        const dayEnd = e.endAt?.isAfter(day.clone().endOf('day')) ? day.clone().endOf('day') : e.endAt
                                        const dayStart = e.startAt?.isBefore(day.clone().startOf('day')) ? day.clone().startOf('day') : e.startAt
                                        return (
                                            <Tag className={'position-absolute border'}
                                                 onMouseUp={e => !newEvent && e.stopPropagation()} onMouseDown={e => !newEvent && e.stopPropagation()}
                                                 style={{
                                                     transition: 'none',
                                                     ...(Math.abs(dayStart.diff(dayEnd, 'h', true)) ? {height: Math.abs(dayStart.diff(dayEnd, 'h', true)) * HOUR_HEIGHT + 'px'} : {}),
                                                     top: dayStart.diff(dayStart.clone().startOf('D'), 'h', true) * HOUR_HEIGHT + 'px',
                                                     left: (100 / (overlapping.length)) * overlapping.findIndex(o => o.key === e.key) + '%',
                                                     width: (100 / (overlapping.length)) + '%',
                                                     zIndex: index
                                                 }} color={e.color}>
                                                {eventRender ? eventRender(e) : (
                                                    <span>{e.startAt.format('LT') + " "}{e.label}</span>)}
                                            </Tag>
                                        )
                                    }
                                )}
                        </Col>
                    ))}
                </Row>
            </div>
        )
    }
}

export default Days