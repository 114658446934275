import React from 'react'
import LoginForm from "./LoginForm";
import {Card, Col, Row} from "antd";
import ISettings from "../../model/interface/ui/ISettings";
import Logo from "../layout/Logo";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps<any> {
    settings: ISettings
}

interface IState {
}

class Login extends React.Component<IProps, IState> {
    getBackgroundColor() {
        if (this.props.settings
            && this.props.settings.ui
            && this.props.settings.ui!.background) {
            return this.props.settings.ui!.background;
        }
        return '#ccc';
    }

    render() {
        const {settings} = this.props

        return (
            <div className="h-100" style={{backgroundColor: this.getBackgroundColor()}}>
                <div className="container d-flex flex-column justify-content-center h-100">
                    <Row justify="center">
                        <Col style={{maxWidth: 420}}>
                            <Card className={"p-2"}>
                                <div className="d-flex justify-content-center">
                                    <Logo login={true} square={false} isMobile={false} settings={settings}
                                          className={"logo-big p-0"}/>
                                </div>
                                <LoginForm {...this.props}/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Login
