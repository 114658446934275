import React from "react";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import WidgetTool from "../../../widget/WidgetTool";
import {Typography} from "antd";
import ICardWidgetRelationField from "../../../../../../model/interface/dataStorage/card/ICardWidgetRelationField";
import View from "../../../../view/View";


class RelationFieldWidget extends React.Component<ICardWidgetProps<ICardWidgetRelationField>> {

    render() {
        const {view, filters, relationField, title, titleLevel, allowSettings, allowExport} = this.props.options
        const {value, editor, reload} = this.props

        if (filters && relationField) {
            const index = filters.findIndex(filter => filter.field === relationField)
            if (index > -1 && value) {
            // if (index > -1 && value && !Utils.toBoolean(filters[index].value)) {
                filters[index].value = value
            }
        }
        let filterMap = filters ? Object
            .fromEntries((editor ? filters.filter(filter => filter.field !== relationField) : filters)
            .map((filter, index) => (['filter' + index, filter]))) : {}

        return (
            <div className={'mb-2'}>
                <WidgetTool {...this.props} edit={true}/>
                {editor && relationField && <Typography.Text type="warning">
                    Editor vynechává relační filtr pro zobrazení dat, všechny ostatní filtry jsou aktivní
                </Typography.Text>}
                {view ? (
                    <View
                        standalone={false}
                        uuid={view}
                        filters={{...filterMap}}
                        match={this.props.match} history={this.props.history}
                        title={title}
                        reload={reload}
                        titleLevel={titleLevel}
                        allowSettings={allowSettings}
                        allowExport={allowExport}
                        onFinishAction={this.props.functions.onFinishAction}
                    />
                ): (
                    <Typography.Text type="danger">Chyba konfigurace #2</Typography.Text>
                )}
            </div>
        );
    }
}

export default RelationFieldWidget