import React from "react";
import {connect, RootStateOrAny} from "react-redux";
import {Alert, Layout, Menu, Tooltip} from "antd";
import {CalendarOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';
import {Link} from "react-router-dom"
// import NavPanel from './NavPanel';
import NavSearch from './NavSearch';
import {onMobileNavToggle, toggleCollapsedNav} from 'redux/actions/Theme';
import {NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH} from 'model/constants/ThemeConstant';
import utils from 'utils'
import NavTools from "./NavTools";
import IBaseProps from "../../model/interface/IBaseProps";
import ISettings from "../../model/interface/ui/ISettings";
import NavTheme from "./NavTheme";
import Bookmarks from "./headerNav/Bookmarks";
import Bookmark from "../app/bookmark/Bookmark";
import {ISetupState} from "../../redux/reducers/Setup";
import settingsService from "../../model/service/SettingsService";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import SettingsService from "../../model/service/SettingsService";
import Trigger from "../app/extension/time-tracker/Trigger";
import NavManual from "./headerNav/NavManual";

const {Header} = Layout;

interface IProps extends IBaseProps {
    navCollapsed?: boolean,
    mobileNav?: boolean,
    navType?: string,
    headerNavColor?: string,
    toggleCollapsedNav?: (toggle: boolean) => void,
    onMobileNavToggle?: (toggle: boolean) => void,
    isMobile: boolean,
    currentTheme?: string,
    direction?: string
    settings: ISettings,
    licensed: boolean
    headerRef: React.RefCallback<HTMLDivElement>
}

interface IState {
}

class HeaderNav extends React.Component<IProps, IState> {
    state = {
        searchActive: false
    }

    onSearchClose = () => {
        this.setState({searchActive: false})
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (!this.props.isMobile && this.state.searchActive) {
            this.onSearchClose()
        }
    }

    render() {
        const {
            navCollapsed,
            mobileNav,
            navType,
            headerNavColor,
            toggleCollapsedNav,
            onMobileNavToggle,
            isMobile,
            currentTheme,
            settings,
            history,
            match,
            licensed
        } = this.props;
        const onToggle = () => {
            if (!isMobile) {
                toggleCollapsedNav && toggleCollapsedNav(!navCollapsed)
            } else {
                onMobileNavToggle && onMobileNavToggle(!mobileNav)
            }
        }
        const isNavTop = navType === NAV_TYPE_TOP
        const mode = () => {
            if (!headerNavColor) {
                return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
            }
            return utils.getColorContrast(headerNavColor)
        }
        const navMode = mode()
        const getNavWidth = () => {
            if (isNavTop || isMobile) {
                return '0px'
            }
            if (navCollapsed) {
                return `${SIDE_NAV_COLLAPSED_WIDTH}px`
            } else {
                return `${SIDE_NAV_WIDTH}px`
            }
        }
        return (
            <Header className={`app-header ${navMode} d-block`} id={'header-nav'}
                    style={{
                        backgroundColor: SettingsService.getValue('ui-theme', 'header') || headerNavColor,
                        height: "auto"
                    }}>
                <div ref={this.props.headerRef}>
                    {!licensed && licensed !== undefined && <Alert className={'centered line-break'}
                                                                   message="Vaše licence není platná. Prosím kontaktujte administrátora systému."
                                                                   banner/>}
                    <div className={`app-header-wrapper w-100 ${isNavTop ? 'layout-top-nav' : ''}`}>
                        <div style={{width: getNavWidth()}}>
                            <Link to={{pathname: "/app/"}}>
                                {(navCollapsed || !isMobile) && (
                                    <Logo square={(navCollapsed && !isMobile) || (!navCollapsed && isMobile)}
                                          isMobile={isMobile}
                                          settings={settings}/>
                                )}
                            </Link>
                        </div>
                        <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
                            {/*<div className="nav" style={{flex: '1 1 0', minWidth: 0}}>*/}
                            <div className="nav-left" style={{flex: '1 1 0', minWidth: 0}}>
                                <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                                    {
                                        isNavTop && !isMobile ?
                                            null
                                            :
                                            <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {
                                                onToggle()
                                            }}>
                                                {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon"/> :
                                                    <MenuFoldOutlined className="nav-icon"/>}
                                            </li>
                                    }
                                    {/*{*/}
                                    {/*  isMobile ?*/}
                                    {/*  <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onSearchActive()}}>*/}
                                    {/*    <SearchOutlined />*/}
                                    {/*  </li>*/}
                                    {/*  :*/}
                                    {/*  <li className="ant-menu-item ant-menu-item-only-child" style={{cursor: 'auto'}}>*/}
                                    {/*    <SearchInput mode={mode} isMobile={isMobile} />*/}
                                    {/*  </li>*/}
                                    {/*}*/}
                                </ul>
                                {!isMobile && <Bookmarks history={history} match={match} isMobile={isMobile}/>}
                            </div>
                            <div className="nav-right">
                                <Menu mode="horizontal" className={"text-right"}>
                                    {!isMobile && (
                                        <Menu.Item>
                                            <Bookmark history={history} match={match} style={{fontSize: 20}}/>
                                        </Menu.Item>
                                    )}
                                    {settingsService.getValue('personalCalendar', 'enable') && (
                                        <Menu.Item>
                                            <Tooltip title={'Osobní kalendář'} placement={"bottom"}>
                                                <Link to={`${APP_PREFIX_PATH}/personal-calendar`} className={'d-block'}>
                                                    <CalendarOutlined style={{fontSize: 20}} className={'m-0'}/>
                                                </Link>
                                            </Tooltip>
                                        </Menu.Item>
                                    )}
                                    {settingsService.getValue('timeTracker', 'enabled') && (
                                        <Menu.Item>
                                                <Trigger iconStyle={{fontSize: 20}} history={history} match={match}/>
                                        </Menu.Item>
                                    )}
                                    <Menu.Item>
                                        <NavTheme/>
                                    </Menu.Item>
                                    {settingsService.isEnabled('ui', 'allow-manuals') && (
                                        <Menu.Item>
                                            <NavManual {...this.props}/>
                                        </Menu.Item>
                                    )}
                                    <Menu.Item>
                                        <NavNotification/>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <NavTools/>
                                    </Menu.Item>
                                    {/*<Menu.Item>*/}
                                    {/*  <NavReload />*/}
                                    {/*</Menu.Item>*/}
                                    <Menu.Item className={"px-0"}>
                                        <NavProfile/>
                                    </Menu.Item>
                                </Menu>
                                {/*<NavLanguage />*/}
                                {/*<NavPanel direction={direction} />*/}
                            </div>
                            {this.state.searchActive &&
                                <NavSearch active={this.state.searchActive} close={this.onSearchClose}/>
                            }
                        </div>
                    </div>
                </div>

            </Header>
        )
    }


    // const [searchActive, setSearchActive] = useState(false)
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {licensed} = state.setup as ISetupState
    const {theme} = state
    const {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction} = theme;
    return {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, licensed}
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle})(HeaderNav);