import {Card, Form, Select, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import Wysiwyg from "../../../../../shared/input/Wysiwyg";

export interface IFieldWYSIWYGOptions {
    wysiwygPackage?: 'basic' | 'medium' | 'full',
    wysiwygInlineFile?: boolean
}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {

}

class FieldCompanyOptionEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    static getDefaultOptions(): IFieldWYSIWYGOptions {
        return {
            wysiwygPackage: 'basic'
        }
    }

    render() {
        return (
            <Card title={'Wysiwyg'}>
                <Form.Item name={'wysiwygPackage'} label={'Zvolte wysiwyg balicek'} rules={[{required: true}]}>
                    <Select>
                        {Wysiwyg.MODE_OPTIONS.map(type => (
                            <Select.Option key={type.code} value={type.code}>{type.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={'wysiwygInlineFile'} label={'Vkládání souboru'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </Card>
        )
    }
}

export default FieldCompanyOptionEditor