import {Card, Form, FormInstance, InputNumber, Select, Spin} from 'antd';
import React, {RefObject} from "react";
import IContentType from "../../../../../../../model/interface/dataStorage/IContentType";
import IField from "../../../../../../../model/interface/dataStorage/IField";
import {API_FILTER_TYPE} from "../../../../../../../model/constants/ApiConstant";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../../../../redux/reducers/Setup";
import IFieldOptions from "../../../../../../../model/interface/form/elementOptions/IFieldOptions";

export interface IAutocompleteEditorOptions {
    autocompleteCollection?: string
    autocompleteField?: string
    autocompleteMode?: "beginning" | "like" | "strict"
    autocompleteMin?: number
}

interface IProps  {
    onChange: (options: IFieldOptions) => void
    options: IAutocompleteEditorOptions
    formRef: RefObject<FormInstance>
    contentTypes:IContentType[]
}

interface IState {
    contentTypes?: IContentType[]
    fields?: IField[]
}

class FormFieldAutocompleteEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            contentTypes: props.contentTypes,
            fields: undefined
        };
    }

    componentDidMount() {
        const {autocompleteCollection} = this.props.options
        if (autocompleteCollection) {
            this.setFields(autocompleteCollection)
        }
    }

    onContentTypeSelect = (collection: string) => {
        this.setFields(collection);
        this.props.formRef.current?.setFieldsValue({autocompleteField: undefined})
    }

    setFields(collection: string) {
        const contentType = this.state.contentTypes?.find(type => type.collection === collection)
        this.setState({fields: contentType?.fields})
    }

    render() {
        const {fields, contentTypes} = this.state

        return (
            contentTypes ? (
                <Card>
                    <Form.Item label={"Typ obsahu"} name={"autocompleteCollection"} rules={[{required: true}]}>
                        <Select onChange={this.onContentTypeSelect}>
                            {contentTypes.map(type =>
                                <Select.Option key={type.id} value={type.collection}>
                                    {`${type.name} [${type.collection}]`}
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                    {fields && (
                        <Form.Item label={"Vlastnost obsahu"} name={"autocompleteField"} rules={[{required: true}]}>
                            <Select>
                                {fields.map(field =>
                                    <Select.Option key={field.id} value={field.name}>
                                        {`${field.label} [${field.name}]`}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item label={"Vyhledavani"} name={"autocompleteMode"} rules={[{required: true}]}>
                        <Select>
                            <Select.Option value={API_FILTER_TYPE.LIKE}>{'Uvnitř'}</Select.Option>
                            <Select.Option value={API_FILTER_TYPE.LIKE_BEGINNING}>
                                {'Začátek'}
                            </Select.Option>
                            <Select.Option
                                value={API_FILTER_TYPE.EQUAL}>{'Presna hodnota'}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={"Minimum znaků"} name={"autocompleteMin"} rules={[{required: true}]}>
                        <InputNumber/>
                    </Form.Item>
                </Card>
            ) : <Spin/>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {contentTypes} = state.setup as ISetupState

    return {
        contentTypes
    }
}

export default connect(mapStateToProps)(FormFieldAutocompleteEditor)