import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IJob from "../../interface/company/IJob";
import IField from "../../interface/dataStorage/IField";
import IRepositoryService from "../../interface/IRepositoryService";
import IUnit from "../../interface/company/IUnit";
import IPresenter from "../../interface/dataStorage/IPresenter";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";

interface IRestJobsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IJob>
}

interface IRestJobsService extends IRepositoryService {
    collectionList(options?: IRestServiceOptions): Promise<IRestJobsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IJob>,

    resourceRetrieve(id: number | string): Promise<IJob>,

    resourceUpdate(id: number | string, data: any): Promise<IJob>,

    resourceDelete(id: number | string): Promise<void>,
}

const JobsService_COLLECTION = 'company/jobs'
const JobsService_CHOICES = 'company/jobs-choices/'

class JobsService implements IRestJobsService {
    private static instance?: JobsService

    public static getInstance(): JobsService {
        if (!JobsService.instance) {
            JobsService.instance = new JobsService()
        }
        return JobsService.instance
    }

    collectionList(options?: IRestServiceOptions): Promise<IRestJobsServiceCollectionResponse> {
        return RestService.collectionList(JobsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestJobsServiceCollectionResponse>
    }
    collectionCreate(data: any) {
        return RestService.collectionCreate(JobsService_COLLECTION, data) as Promise<IJob>
    }
    resourceRetrieve(id: number | string, options?: IRestServiceOptions) {
        return RestService.resourceRetrieve(JobsService_COLLECTION, id) as Promise<IJob>
    }
    resourceDelete(id: number | string) {
        return RestService.resourceDelete(JobsService_COLLECTION, id)
    }
    resourceUpdate(id: number | string, data: any) {
        return RestService.resourceUpdate(JobsService_COLLECTION, id, data) as Promise<IJob>
    }
    getFields(): IField[] {
        return [
            {
                uuid: '',
                name: 'name',
                label: 'Název',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'code',
                label: 'Kód',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            }
        ];
    }
    getRecordClassName() {
        return 'App\\Company\\Entity\\Job'
    }
    getTitle(): string {
        return "Pozice";
    }
    getStringValue(company: IUnit): string {
        return company.name
    }
    getPresenter(name: string): IPresenter | null {
        return this.getDefaultPresenter()
    }
    getDefaultPresenter(): IPresenter {
        return {
            type: 'label',
            name: 'default',
            label: 'Výchozí',
            options: {
                label: "#name"
            }
        } as IPresenter
    }
    getPresenterList() {
        //TODO ADD PRESENTERS ?
        return [this.getDefaultPresenter()].map(presenter => {
            return {value: presenter.name, label: presenter.label}
        })
    }
    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(JobsService_CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
    }
}

export default JobsService