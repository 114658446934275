import React from 'react';
import {connect, RootStateOrAny} from "react-redux";
import {Alert, Button, Col, Divider, Form, Input, Modal, Row, Tooltip} from "antd";
import Icon, {LockOutlined, MailOutlined, UserOutlined} from '@ant-design/icons';
import {Motion} from 'react-motion';
// import { GoogleSVG, FacebookSVG } from 'assets/svg/icon';
// import CustomIcon from 'old/util-components/CustomIcon'
import {storeToken} from 'redux/actions/Auth';
import {FacebookSVG, GoogleSVG} from "../../assets/svg/icon";
import GoogleLogin, {GoogleLoginResponse, GoogleLoginResponseOffline} from "react-google-login";
import FacebookLogin from '@greatsumini/react-facebook-login'
import TokenService from "model/service/security/TokenService";
import ITokenResponse from "../../model/interface/security/ITokenResponse";
import PasswordResetRequest from "./PasswordResetRequest";
import SignUpForm from "./SignUpForm";
import ISettings from "../../model/interface/ui/ISettings";
import SettingsService from "../../model/service/SettingsService";
import {RouteComponentProps} from "react-router-dom";
import IAdditionalAuthorizationLogin from "../../model/interface/security/IAdditionalAuthorizationLogin";
import AdditionalAuthorizationForm from "./AdditionalAuthorizationForm";

interface IProps extends RouteComponentProps<any> {
    // hideAuthMessage?: () => void,
    allowPasswordReset?: boolean,
    allowCreateAccount?: boolean,
    // showLoading: () => void,
    // removeLoading: () => void,
    // signInWithGoogle: (token: string) => void,
    // signInWithFacebook: (token: string) => void,
    // signIn: (user: any) => void,
    storeToken: (token: string, redirect?: string) => void,
    loading: boolean,
    // showMessage: boolean,
    message: string,
    settings: ISettings
}

interface IStateAuthority {
    visible: boolean,
    enabled: boolean
}

interface IState {
    loading: boolean,
    showAccountInfo: boolean,
    showErrorMessage: boolean,
    showPasswordReset: boolean,
    google: IStateAuthority,
    facebook: IStateAuthority,
    additionalAuthorization?: IAdditionalAuthorizationLogin
}

class LoginForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            showAccountInfo: false,
            showErrorMessage: false,
            showPasswordReset: false,
            google: {
                visible: true,
                enabled: true
            },
            facebook: {
                visible: true,
                enabled: true
            }
        }
    }

    showLoading = (loading: boolean) => {
        this.setState(_state => ({loading}))
    }

    showErrorMessage = () => {
        this.setState({showErrorMessage: true})
    }

    onLogin = (values: any) => {
        this.showLoading(true)
        this.handleLoginAttempt(TokenService.retrieve(values['username'], values['password']))
    };


    onLdapLogin = (values: any) => {
        this.showLoading(true)
        this.handleLoginAttempt(TokenService.ldap(values['username'], values['password']))
    };

    isGoogleLoginEnabled() {
        return SettingsService.isEnabled('security', 'googleLoginEnabled', this.props.settings, false)
    }

    isFacebookLoginEnabled() {
        return SettingsService.isEnabled('security', 'facebookLoginEnabled', this.props.settings, false)
    }

    isLdapLoginEnabled() {
        return SettingsService.isEnabled('security', 'ldapLoginEnabled', this.props.settings, false)
    }

    isUsernameLoginEnabled() {
        return SettingsService.isEnabled('security', 'usernameLoginEnabled', this.props.settings, true)
    }

    canEmployeeCreateProfile() {
        return SettingsService.isEnabled('security', 'employeeCreateProfileEnabled', this.props.settings, false)
    }

    onGoogleLogin = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        this.showLoading(true)
        const accessToken = "accessToken" in response ? response.accessToken : null
        if (accessToken) {
            this.handleLoginAttempt(TokenService.google(accessToken))
        } else {
            // @ts-ignore
            if (response.error === 'idpiframe_initialization_failed') {
                this.setState(_state => {
                    return {google: {..._state.google, enabled: false}}
                })
            } else {
                this.showErrorMessage()
            }
            this.showLoading(false)
        }
    }

    onFacebookLogin = (response: any) => {
        this.showLoading(true)
        const accessToken = "accessToken" in response ? response.accessToken : null
        if (accessToken) {
            this.handleLoginAttempt(TokenService.facebook(accessToken))
        } else {
            this.showErrorMessage()
            this.showLoading(false)
        }
    }

    // onFacebookLogin = () => {
    //     this.props.showLoading()
    //     this.props.signInWithFacebook()
    // }


    // if (token !== null && allowRedirect) {
    // 	history.push(redirect)
    // }

// useEffect(() => {
// 	console.log('# 4 USE EFFECT')
// }, []);

    componentDidMount() {
        // hideAuthMessage();
    }

    protected handleLoginAttempt(promise: Promise<ITokenResponse>) {
        const {storeToken, history} = this.props
        promise.then(response => {
            if (!response.token) {
                return Promise.reject(response)
            }
            storeToken(response.token, history.location.state?.from?.pathname)
        }).catch((error) => {
            const response: ITokenResponse = error
            switch (response.code) {
                case("account_missing"):
                    this.showAccountMissingInfo(true);
                    break;
                case "additional_authorization":
                    if (response.additionalAuthorization) {
                        this.additionalAuthorization(response.additionalAuthorization)
                    }
                    break;
                default:
                case "invalid_password":
                    this.showErrorMessage();
            }
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    showAccountMissingInfo = (showAccountInfo: boolean) => {
        this.setState({showAccountInfo})
    }

    showPasswordReset = (showPasswordReset: boolean = false) => {
        this.setState({showPasswordReset})
    }

    additionalAuthorization = (additionalAuthorization?: IAdditionalAuthorizationLogin) => {
        this.setState({additionalAuthorization})
    }

    additionalAuthorizationLogin = (token: string) => {
        const {storeToken, history} = this.props

        storeToken(token, history.location.state?.from?.pathname)
        this.additionalAuthorization()
    }

    render = () => {
        const initialCredential = {
            email: '',
            password: ''
        }
        const allowPasswordReset = this.props.allowPasswordReset || true
        const allowCreateAccount = this.props.allowCreateAccount || true
        const forgotPasswordWidth = 160
        // const {showMessage, message, loading} = this.props
        const {
            loading,
            showErrorMessage,
            showAccountInfo,
            showPasswordReset,
            additionalAuthorization
        } = this.state
        return (
            <>
                <Modal visible={showAccountInfo} footer={false} title={'Registrace'}
                       onCancel={() => this.showAccountMissingInfo(false)}
                >
                    {allowCreateAccount ? (
                        <>
                            <SignUpForm/>
                        </>
                    ) : (
                        <>
                            <p>
                                Abyste se mohli přihlásit do aplikace je nutné mít založený uživatelský účet. Tento účet
                                vám přidělí administrátor nebo pověřený pracovník.
                            </p>

                            <p>
                                Účet má zadané uživatelské jméno ve tvaru e-mailu, které zároveň slouží jako kontaktní
                                e-mailová adresa.
                            </p>

                            <p>
                                Pokud se přihlašujete pomocí autority, je nutné zkontrolovat, že e-mailová adresa
                                autority přesně odpovídá uživatelskému jménu v této aplikaci.
                            </p>
                        </>
                    )}
                </Modal>
                <Modal visible={showPasswordReset} footer={false} onCancel={() => this.showPasswordReset()}
                       title={"Obnova zapomenutého hesla"}>
                    <PasswordResetRequest/>
                </Modal>

                {additionalAuthorization &&
                    <Modal visible={true} footer={false} onCancel={() => this.additionalAuthorization()}>
                        <AdditionalAuthorizationForm additionalAuthorization={additionalAuthorization}
                                                     onCancel={() => this.additionalAuthorization()}
                                                     onSuccess={this.additionalAuthorizationLogin}/>
                    </Modal>}

                {this.isUsernameLoginEnabled() && (
                    <Form
                        layout="vertical"
                        name="login-form"
                        initialValues={initialCredential}
                        onFinish={this.onLogin}
                    >
                        <Divider className={"text-muted font-weight-normal mt-4 mb-4 font-size-sm"}>Přihlášení pomocí
                            e-mailu a hesla</Divider>

                        <Motion
                            defaultStyle={{
                                opacity: 0,
                                marginBottom: 0,
                                maxHeight: 200
                            }}
                            // initial={}
                            style={{
                                opacity: showErrorMessage ? 1 : 0,
                                marginBottom: showErrorMessage ? 10 : 0,
                                maxHeight: showErrorMessage ? 200 : 0
                            }}
                        >
                            {interpolatingStyle => (<Alert type="error" showIcon message={"Přihlášení se nezdařilo"}
                                                           style={interpolatingStyle}/>)}
                        </Motion>

                        <Row gutter={16}>
                            <Col xs={24}>
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vložte uživatelské jméno ve tvaru e-mailu',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Vložte uživatelské jméno ve tvaru e-mailu'
                                        }
                                    ]}>
                                    <Input placeholder={"Uživatelské jméno"}
                                           prefix={<MailOutlined className="text-primary"/>}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col
                                style={allowPasswordReset ? {width: 'calc(100% - ' + (forgotPasswordWidth + 16) + 'px)'} : {}}
                                xs={!allowPasswordReset ? 24 : undefined}>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vložte heslo',
                                        }
                                    ]}
                                >
                                    <Input.Password placeholder={"Heslo"}
                                                    prefix={<LockOutlined className="text-primary"/>}/>
                                </Form.Item>
                            </Col>
                            {allowPasswordReset && (
                                <Col style={{width: forgotPasswordWidth}}>
                                    <Button type={"default"} className={"ant-btn-info"}
                                            onClick={() => this.showPasswordReset(true)}>Zapomenuté heslo</Button>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col className={"text-center"} xs={24}>
                                <Button value={"username"} type="primary" htmlType="submit" block loading={loading}>
                                    Přihlásit se
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                )}


                {this.isLdapLoginEnabled() && (
                    <Form
                        layout="vertical"
                        name="login-form"
                        initialValues={initialCredential}
                        onFinish={this.onLdapLogin}
                    >
                        <Divider className={"text-muted font-weight-normal mt-4 mb-4 font-size-sm"}>Přihlášení pomocí
                            LDAP</Divider>

                        <Motion
                            defaultStyle={{
                                opacity: 0,
                                marginBottom: 0,
                                maxHeight: 200
                            }}
                            // initial={}
                            style={{
                                opacity: showErrorMessage ? 1 : 0,
                                marginBottom: showErrorMessage ? 10 : 0,
                                maxHeight: showErrorMessage ? 200 : 0
                            }}
                        >
                            {interpolatingStyle => (<Alert type="error" showIcon message={"Přihlášení se nezdařilo"}
                                                           style={interpolatingStyle}/>)}
                        </Motion>

                        <Row gutter={16}>
                            <Col xs={24}>
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vložte uživatelské jméno',
                                        }
                                    ]}>
                                    <Input placeholder={"Uživatelské jméno"}
                                           prefix={<UserOutlined className="text-primary"/>}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vložte heslo',
                                        }
                                    ]}
                                >
                                    <Input.Password placeholder={"Heslo"}
                                                    prefix={<LockOutlined className="text-primary"/>}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={"text-center"} xs={24}>
                                <Button value={"username"} type="primary" htmlType="submit" block loading={loading}>
                                    Přihlásit se
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                )}

                {(this.isGoogleLoginEnabled() || this.isFacebookLoginEnabled()) && (
                    <>
                        <Divider className={"text-muted font-weight-normal mt-5 mb-4 font-size-sm"}>Přihlášení pomocí
                            autority</Divider>

                        <div>
                            {/*<Divider>*/}
                            {/*	<span className="text-muted font-size-base font-weight-normal">or connect with</span>*/}
                            {/*</Divider>*/}
                            <div className="d-flex justify-content-center">
                                {this.isGoogleLoginEnabled() && (
                                    <>
                                        {this.isGoogleLoginEnabled() ? (
                                            <GoogleLogin
                                                clientId={process.env.REACT_APP_GOOGLE_ID || ''}
                                                // buttonText="Login"
                                                onSuccess={this.onGoogleLogin}
                                                onFailure={this.onGoogleLogin}
                                                cookiePolicy={'single_host_origin'}
                                                autoLoad={false}
                                                // disabledStyle={{}}
                                                // style={{}}
                                                render={renderProps => (
                                                    <Button
                                                        onClick={renderProps.onClick}
                                                        disabled={renderProps.disabled}
                                                        // onClick={() => onGoogleLogin()}
                                                        className="mr-2"
                                                        // disabled={loading}
                                                        icon={<Icon component={GoogleSVG}/>}
                                                    >
                                                        Google
                                                    </Button>
                                                )}
                                            />
                                        ) : (
                                            <Tooltip title={"Přihlášení pomocí Google není v tuto chvíli k dispozici"}>
                                                <Button disabled className={"mr-2"}
                                                        icon={<Icon component={GoogleSVG}/>}>Google</Button>
                                            </Tooltip>
                                        )}
                                    </>
                                )}


                                {this.isFacebookLoginEnabled() && (
                                    <>
                                        {this.isFacebookLoginEnabled() ? (
                                            <FacebookLogin
                                                appId={process.env.REACT_APP_FACEBOOK_ID || ''}
                                                autoLoad={false}
                                                onSuccess={this.onFacebookLogin}
                                                render={(renderProps: any) => (
                                                    <Button
                                                        onClick={renderProps.onClick}
                                                        className={""}
                                                        icon={<Icon component={FacebookSVG}/>}
                                                    >
                                                        Facebook
                                                    </Button>
                                                )}
                                            />
                                        ) : (
                                            <Tooltip
                                                title={"Přihlášení pomocí Facebook není v tuto chvíli k dispozici"}>
                                                <Button disabled
                                                        icon={<Icon component={FacebookSVG}/>}>Facebook</Button>
                                            </Tooltip>
                                        )}
                                    </>
                                )}

                                {/*<Button*/}
                                {/*	onClick={() => onFacebookLogin()}*/}
                                {/*	icon={<CustomIcon svg={FacebookSVG}/>}*/}
                                {/*	disabled={loading}*/}
                                {/*>*/}
                                {/*	Facebook*/}
                                {/*</Button>*/}
                            </div>
                        </div>
                    </>
                )}

                {this.canEmployeeCreateProfile() && (
                    <div className={"text-center mt-4"}>
                        <span className={"text-primary cursor-pointer"}
                              onClick={() => this.showAccountMissingInfo(true)}>Nemáte účet a měli byste ho mít?</span>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {loading, message, showMessage, token, redirect} = state.auth;
    return {loading, message, showMessage, token, redirect}
}

const mapDispatchToProps = {
    storeToken
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
