import React from "react";
import {Button, Tabs, Modal as AntdModal} from "antd";
import ICard from "../../../../../model/interface/dataStorage/ICard";
import CardProperties from "./CardProperties";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import CardEditor from "./CardEditor";
import CardSummary from "./CardSummary";
import {BarsOutlined, FileTextOutlined, FormOutlined, OrderedListOutlined} from "@ant-design/icons";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import Utils from "../../../../../utils";
import CardEvaluation from "./CardEvaluation";
import Modal from "../../../../shared/modal/Modal";

interface IState {
    saving: boolean
    showAll: boolean
    card: ICard
    step: string
    changedCards: ICard[]
}

interface IProps extends IBaseProps {
    onFinish?: (card?: ICard, cards?: ICard[]) => void
    card?: ICard,
    contentType: IContentType,
}

export interface ICardStepProps extends IBaseProps{
    onChange: (card: ICard, stepBack?: boolean, cards?: ICard[]) => void
    card: ICard,
}

class CardModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            saving: false,
            showAll: false,
            card: {
                ...props.card ? props.card : {name: '', widgets: [], route: undefined, uuid: Utils.uuid(), routePriority: Math.max(...this.props.contentType.cards.map(c => c.routePriority || 0))},
                contentType: props.contentType.uuid
            },
            changedCards: [],
            step: '1'
        }
    }

    onChange = (card: ICard, stepBack: boolean = false, cards?: ICard[]) => {
        this.setState(state => ({card, changedCards: cards || state.changedCards}), () => this.setStep('', stepBack))
    }

    setStep = (step: string, stepBack: boolean = false) => {
        this.setState(state => ({step: step ? step : (Number(state.step) + (stepBack ? -1 : 1)).toString()}))
    }

    onCancel = () => {
        this.state.card && JSON.stringify(this.props.card) !== JSON.stringify(this.state.card) ? AntdModal.confirm({
            title: 'Pozor, pokud budete pokračovat, všechny změny budou ztraceny!',
            onOk: () => {
                AntdModal.destroyAll();
                this.updateParent()
            },
            onCancel() {
            },
            type: 'warning'
        }) : this.updateParent()
    }

    onFinish = () => {
        this.setState({saving: true}, () => {
            const {card, changedCards} = this.state
            if (card) {
                this.updateParent(card, changedCards);
            }
        })
    }

    updateParent(card?: ICard, cards?: ICard[]) {
        this.props.onFinish && this.props.onFinish(card, cards)
    }

    render() {
        const {saving, card, step} = this.state
        const {history, match, contentType} = this.props

        const sharedProps = {match, history, card, onChange: this.onChange}

        const tabs = [
            {
                label: 'Vlastnosti',
                icon: <BarsOutlined/>,
                widget: <CardProperties contentType={contentType} {...sharedProps}/>
            },
            {
                label: 'Vyhodnocení',
                icon: <OrderedListOutlined/>,
                widget: <CardEvaluation contentType={contentType} {...sharedProps}/>
            },
            {
                label: 'Editor',
                icon: <FormOutlined/>,
                widget: <CardEditor {...sharedProps}/>
            }, {
                label: 'Souhrn',
                icon: <FileTextOutlined/>,
                widget: <CardSummary {...sharedProps}/>
            }
        ]

        return (
            <Modal
                fullScreenOption={true}
                title={card.id ? `Uprava karty [${card.name}]` : 'Nová karta'}
                visible={true}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                onCancel={this.onCancel}
                width={800}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" disabled={Number(step) < tabs.length} loading={saving}
                            onClick={() => this.onFinish()}>
                        Submit
                    </Button>
                ]}
            >
                <Tabs defaultActiveKey="1" activeKey={step} onChange={this.setStep}>
                    {tabs.map((tab, index) => (
                        <Tabs.TabPane tab={<div>{tab.icon} {tab.label}</div>} key={index + 1}
                                      disabled={+step < tabs.length}>
                            {tab.widget}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Modal>
        )
    }
}

export default CardModal