import IViewAction, {ViewActionType} from "../../../../../model/interface/dataStorage/view/IViewAction";
import {Button, Dropdown, Form, FormInstance, Input, InputNumber, Menu, Modal, Table} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import React, {RefObject} from "react";
import ViewAction from "../../../view/ViewAction";
import Utils from "../../../../../utils";
import IconPicker from "../../../../shared/IconPicker";
import ButtonTypePicker from "../../../../shared/button/ButtonTypePicker";
import {ButtonTypes} from "../../../../shared/button/Button";
import IBaseProps from "../../../../../model/interface/IBaseProps";

interface IProps extends IBaseProps {
    actions: IAction[],
    viewActions: IViewAction[],
    onChange: (actions: IViewAction[]) => void
    type: ViewActionType
}

interface IState {
    counter: number,
    selectedAction?: IViewAction,
    refAction: RefObject<FormInstance>,
}

class ViewUnitActionsType extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            counter: 0,
            refAction: React.createRef() as RefObject<FormInstance>
        }
    }

    onChange(viewActions: IViewAction[]) {
        this.props.onChange(viewActions)
    }

    editAction(selectedAction?: IViewAction) {
        this.setState({
            selectedAction: selectedAction && {
                ...selectedAction,
                type: selectedAction.buttonAppearance ? selectedAction.buttonAppearance.type : "primary"
            } as IViewAction
        }, this.state.refAction.current?.resetFields)
    }

    deleteAction(viewAction: IViewAction) {
        let viewActions = [...this.props.viewActions]
        const index = Utils.findIndex(viewActions, {action: viewAction.action})
        if (index < 0) {
            throw new Error("Action index not found")
        }
        viewActions.splice(index, 1)
        this.onChange(viewActions)
        this.forceUpdate()
    }

    saveAction() {
        const {viewActions, actions} = this.props
        const {selectedAction, refAction} = this.state
        if (selectedAction) {
            refAction.current?.validateFields().then(values => {
                const index = Utils.findIndex(viewActions, {id: selectedAction.id})
                const action = actions.find(a => a.uuid === selectedAction.action)
                if (!action) {
                    throw new Error(`Action ${selectedAction.action} does not exist!`)
                }
                const buttonAppearance = {
                    type: values.type
                }
                let counter = this.state.counter
                const actionData = {
                    id: index >= 0 ? viewActions[index].id : -(counter++),
                    action: selectedAction.action,
                    contentType: action.contentType,
                    label: values.label,
                    tooltip: values.tooltip,
                    weight: values.weight ?? 1,
                    icon: values.icon,
                    buttonAppearance
                }
                if (index >= 0) {
                    viewActions[index] = actionData
                } else {
                    viewActions.push(actionData)
                }
                this.onChange(viewActions)
                this.editAction()
                this.setState({counter})
            })
        }
    }

    createAction(action: IAction) {
        let counter = this.state.counter
        const {viewActions} = this.props
        let icon = action.name === "create" ? "PlusOutlined" : "EditOutlined"
        let type: ButtonTypes = "primary"
        switch (action.type) {
            case("delete"):
                icon = "DeleteOutlined"
                type = "danger"
                break;
        }
        this.editAction({
            id: -(counter++),
            action: action.uuid,
            label: "",
            tooltip: "",
            buttonAppearance: {type},
            icon,
            weight: (viewActions.length ? Math.max(...viewActions.map(v => v.weight)) : 0) + 1,
            contentType: ''
        })
        this.setState({
            counter
        })
    }

    render() {
        const {viewActions, actions, type, history, match} = this.props
        const {selectedAction, refAction} = this.state
        const entryActionColumns = [
            {
                title: 'Název',
                key: 'name',
                dataIndex: 'name',
                render: (_: any, viewAction: IViewAction) => (
                    <>
                        {actions.find(action => action.uuid === viewAction.action)?.label}
                    </>
                )
            },
            {
                title: 'Náhled',
                key: 'preview',
                render: (_: any, viewAction: IViewAction) => (
                    <ViewAction history={history} match={match} action={viewAction} onFinish={() => Promise.resolve()}/>
                )
            },
            {
                key: 'preview',
            },
            {
                title: 'Váha',
                key: 'weight',
                dataIndex: 'weight'
            },
            {
                title: 'Akce',
                key: 'action',
                render: (_: any, viewAction: IViewAction) => (
                    <>
                        <Button onClick={() => this.editAction(viewAction)} type="link" className="mr-2 ant-btn-info"
                                icon={<EditOutlined/>} size="small"/>
                        <Button onClick={() => Modal.confirm({onOk: () => this.deleteAction(viewAction)})} danger
                                type="link" className="mr-2" icon={<DeleteOutlined/>} size="small"/>
                    </>
                )
            }
        ]
        const selectedNames = viewActions ? viewActions.map((viewAction: IViewAction) => {
            return viewAction.action
        }) : []
        const availableActions = actions.filter((action: IAction) => {
            return selectedNames.indexOf(action.uuid) < 0 || type === 'collection'
        })
        return (
            <div className={"mb-3"}>
                <Modal visible={!!selectedAction} onOk={() => this.saveAction()} onCancel={() => this.editAction()}>
                    <Form initialValues={selectedAction || {}} ref={refAction}>
                        <Form.Item
                            label={"Ikona"}
                            name={"icon"}
                        >
                            <IconPicker/>
                        </Form.Item>
                        <Form.Item
                            label={"Titulek"}
                            name={"label"}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Nápověda"}
                            name={"tooltip"}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Typ"}
                            name={"type"}
                        >
                            <ButtonTypePicker/>
                        </Form.Item>
                        <Form.Item
                            label={"Váha"}
                            name={"weight"}
                        >
                            <InputNumber/>
                        </Form.Item>
                    </Form>
                </Modal>
                {availableActions.length > 0 ? (
                    <Dropdown trigger={["click"]} overlay={(
                        <Menu>
                            {availableActions.map((action: IAction) => {
                                return (
                                    <Menu.Item key={action.id}
                                               onClick={() => this.createAction(action)}>{action.name}</Menu.Item>
                                )
                            })}
                        </Menu>
                    )}>
                        <Button>Přidat akci</Button>
                    </Dropdown>
                ) : (
                    <Button disabled>Přidat akci</Button>
                )}
                <Table
                    // onMove={this.onSort}
                    pagination={false}
                    rowKey={"id"}
                    columns={entryActionColumns}
                    dataSource={viewActions ? [...viewActions.sort((a, b) => a.weight > b.weight ? 1 : -1)] : []}/>
            </div>
        )
    }
}

export default ViewUnitActionsType