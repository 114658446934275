import React from "react";
import IFile from "../../../../../model/interface/file/IFile";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import IReportWidgetView from "../../../../../model/interface/report/IReportWidgetView";
import WidgetTool from "../../widget/WidgetTool";
import View from "../../../view/View";

class ViewWidget extends React.Component<IReportWidgetProps<IReportWidgetView>> {
    render() {
        const {view} = this.props.options

        return (
            <div>
                <WidgetTool {...this.props} edit={true}/>
                <View uuid={view} match={this.props.match} history={this.props.history}/>
            </div>
        );
    }

    static buildUrl(file: IFile | null, width: any, height: any) {
        if (file) {
            const fileName = `${file.id}-${file.currentVersion.id}-${width}x${height}.${file.currentVersion.extension}`
            return process.env.REACT_APP_BASE_URL + `/thumbnails/` + fileName
        }
        throw new Error('Image Widget file option is undefined')
    }
}

export default ViewWidget