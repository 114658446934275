class WidgetType {
    static CONTAINER = 'container'
    static COLUMNS = 'columns'
    static COLUMN = 'column'
    static TABS = 'tabs'
    static TAB = 'tab'
    static TEXT = 'text'
    static ACTION = 'action'
    static BACK_BUTTON = 'back-button'
    static PRINT = 'print'
    static WORKFLOW_TRANSACTION_CONDITIONS = 'workflow-transaction-conditions'

    static COMMENT = 'comment'

    static GROUP_CONSTRUCT = 'construct'
    static GROUP_STATIC = 'static'
    static GROUP_DYNAMIC = 'dynamic'
}

export default WidgetType