import React from 'react'
import {Card} from 'antd';
import IReport from "../../../model/interface/dataStorage/IReport";
import IReportWidget from "../../../model/interface/report/IReportWidget";
import ReportWidgetList from "../configuration/report/widget/ReportWidgetList";
import ReportWidgetType from "../configuration/report/widget/ReportWidgetType";
import ReportEditor from "../configuration/report/ReportEditor";
import ReportWidget from "../configuration/report/widget/ReportWidget";
import IBaseProps from "../../../model/interface/IBaseProps";
import IWidgetPropsFunctions from "../../../model/interface/widget/IWidgetPropsFunctions";
import TreeStructure from "../../../utils/TreeStructure";
import ReportEditButton from "../configuration/report/ReportEditButton";

interface IState {
    structure: { [id: string]: IReportWidget }
}

interface IProps extends IBaseProps {
    report: IReport
}

class Report extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            structure: {
                1: {...ReportWidgetList.getByType(ReportWidgetType.CONTAINER), id: 1, label: 'Container'}
            }
        };
    }

    componentDidMount() {
        let structure = ReportEditor.widgetsToStructure(this.props.report.widgets)
        structure = this.setValues(structure)
        this.props.report.widgets.length > 0 && this.setState({structure})
    }

    setValues(structure: { [p: string]: IReportWidget }) {
        Object.keys(structure).forEach(key => {
            if (structure[key].type === ReportWidgetType.COMMENT){
                structure[key].value = this.props.report.thread
            }
        })
        return structure
    }

    getSortedChildren(id: string, structure: { [id: string]: IReportWidget }) {
        return TreeStructure.sortChildren(id, structure, 'weight') as IReportWidget[]
    }

    render() {
        const {structure} = this.state
        const {report, history, match} = this.props

        return (
            <>
                <Card>
                    <ReportEditButton resource={report} history={history} match={match}/>
                    {
                        Object.entries(structure).map(([, widget]) => {
                            if (widget && !widget.parent) {
                                let functions = {
                                    getNode: (id: string) => structure[id],
                                    getSortedChildren: (id: string) => this.getSortedChildren(id, structure),
                                } as unknown as IWidgetPropsFunctions
                                return (
                                    <ReportWidget
                                        {...widget}
                                        key={widget.uuid}
                                        preview={true}
                                        functions={functions}
                                        match={this.props.match}
                                        history={this.props.history}
                                    />
                                )
                            }
                            return null
                        })
                    }
                </Card>
            </>
        );
    }
}

export default Report