import IRestServiceFilter from "./IRestServiceFilter";
import IRestServiceFilterNested from "./IRestServiceFilterNested";

type FiltersType = {
    [index in (number | string)]: IRestServiceFilter|IRestServiceFilterNested
}

export const isFilterNested = (filter: IRestServiceFilter|IRestServiceFilterNested): filter is IRestServiceFilterNested => {
    return 'children' in filter;
}

export default interface IRestServiceFilters extends FiltersType {

}