import {Card, Collapse, Form, FormInstance} from 'antd';
import React, {RefObject} from "react";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import IField, {
    COMPOSITE_FIELD_TYPE,
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_RELATION,
    FIELD_MODE_SCALAR, FIELD_TYPE,
    IFieldOptions
} from "../../../../../model/interface/dataStorage/IField";
import FieldDateOptionsEditor from "./optionEditors/FieldDateOptionsEditor";
import FieldRateOptionsEditor from "./optionEditors/FieldRateOptionsEditor";
import EmployeesService from "../../../../../model/service/company/EmployeesService";
import FieldEmployeeOptionsEditor from "./optionEditors/FieldEmployeeOptionsEditor";
import Utils from "../../../../../utils";
import LikesService from "../../../../../model/service/dataStorage/extension/LikesService";
import FieldLikeOptionsEditor from "./optionEditors/FieldLikeOptionsEditor";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import DataStorageService from "../../../../../model/service/dataStorage/DataStorageService";
import FieldContentTypeOptionsEditor from "./optionEditors/FieldContentTypeOptionsEditor";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import UnitsService from "../../../../../model/service/company/UnitsService";
import JobsService from "../../../../../model/service/company/JobsService";
import FieldUnitOptionEditor from "./optionEditors/FieldUnitOptionsEditor";
import FieldJobOptionsEditor from "./optionEditors/FieldJobOptionsEditor";
import CompaniesService from "../../../../../model/service/company/CompaniesService";
import FieldCompanyOptionsEditor from "./optionEditors/FieldCompanyOptionsEditor";
import FieldStructureOptionEditor from "./optionEditors/FieldStructureOptionsEditor";
import FilesService from "../../../../../model/service/file/FilesService";
import FieldTextOptionsEditor from "./optionEditors/FieldTextOptionsEditor";
import WorkflowStatesService from "../../../../../model/service/file/WorkflowStatesService";
import CompositeFieldApprovalOptionsEditor from "./optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import FieldPhoneNumberOptionsEditor from "./optionEditors/FieldPhoneNumberOptionsEditor";
import FieldFileOptionsEditor from "./optionEditors/FieldFileOptionsEditor";
import FieldCodeOptionsEditor from "./optionEditors/FieldCodeOptionsEditor";
import FieldBooleanOptionsEditor from "./optionEditors/FieldBooleanOptionsEditor";

interface IProps extends IBaseProps {
    onChange: (options: IFieldOptions) => void
    options: IFieldOptions
    formRef?: RefObject<FormInstance>
    field: IField
    contentType: IContentType
    findContentTypeByClassName: (className: string) => IContentType,
    collapsable?: boolean,

    title?: string
}

export interface IFieldOptionsEditorProps {
    options: IFieldOptions
}

interface IState {

}

class FieldOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    static defaultProps = {
        title: 'Vlastnosti'
    }

    onValueChange = (value: any) => {
        const {options, onChange} = this.props
        console.log(options)
        console.log(Utils.deepMerge(options, value))
        onChange(Utils.deepMerge(options, value))
    }

    componentDidMount() {
        this.onValueChange(this.getOptions(this.props.field))
    }

    getFieldEditor = (field: IField, defaultOptions = false) => {
        const {history, match, findContentTypeByClassName, contentType} = this.props
        if (field.mode === FIELD_MODE_SCALAR) {
            switch (field.type) {
                case FIELD_TYPE.DATE_TIME:
                    return defaultOptions ? FieldDateOptionsEditor.getDefaultOptions() :
                        <FieldDateOptionsEditor options={this.getOptions(field)}/>
                case FIELD_TYPE.RATE:
                    return defaultOptions ? FieldRateOptionsEditor.getDefaultOptions() :
                        <FieldRateOptionsEditor options={this.getOptions(field)}/>
                case FIELD_TYPE.HTML:
                    return defaultOptions ? FieldTextOptionsEditor.getDefaultOptions() :
                        <FieldTextOptionsEditor options={this.getOptions(field)}/>
                case FIELD_TYPE.PHONE_NUMBER:
                    return defaultOptions ? FieldPhoneNumberOptionsEditor.getDefaultOptions() :
                        <FieldPhoneNumberOptionsEditor options={this.getOptions(field)}/>
                case FIELD_TYPE.CODE:
                    return defaultOptions ? FieldCodeOptionsEditor.getDefaultOptions() :
                        <FieldCodeOptionsEditor options={this.getOptions(field)}/>
                case FIELD_TYPE.BOOLEAN:
                    return defaultOptions ? FieldBooleanOptionsEditor.getDefaultOptions() :
                        <FieldBooleanOptionsEditor options={this.getOptions(field)}/>
            }
        } else if (field.mode === FIELD_MODE_COMPOSITE) {
            switch (field.type) {
                case COMPOSITE_FIELD_TYPE.APPROVAL:
                    return defaultOptions ? CompositeFieldApprovalOptionsEditor.getDefaultOptions() :
                        <CompositeFieldApprovalOptionsEditor options={this.getOptions(field)} contentType={contentType}/>
            }
        } else if (field.mode === FIELD_MODE_RELATION && field.targetEntity) {
            switch (field.targetEntity) {
                case FilesService.getRecordClassName():
                    return defaultOptions ? FieldFileOptionsEditor.getDefaultOptions() :
                        <FieldFileOptionsEditor options={this.getOptions(field)}/>
                case EmployeesService.getInstance().getRecordClassName():
                    return defaultOptions ? FieldEmployeeOptionsEditor.getDefaultOptions() :
                        <FieldEmployeeOptionsEditor options={this.getOptions(field)} match={match} history={history}
                                                    field={field}/>
                case UnitsService.getInstance().getRecordClassName():
                    return defaultOptions ? FieldUnitOptionEditor.getDefaultOptions() :
                        <FieldStructureOptionEditor options={this.getOptions(field)} match={match} history={history}
                                                    field={field}/>
                case JobsService.getInstance().getRecordClassName():
                    return defaultOptions ? FieldJobOptionsEditor.getDefaultOptions() :
                        <FieldStructureOptionEditor options={this.getOptions(field)} match={match} history={history}
                                                    field={field}/>
                case CompaniesService.getInstance().getRecordClassName():
                    return defaultOptions ? FieldCompanyOptionsEditor.getDefaultOptions() :
                        <FieldStructureOptionEditor options={this.getOptions(field)} match={match} history={history}
                                                    field={field}/>
                case LikesService.getRecordClassName():
                    return defaultOptions ? FieldLikeOptionsEditor.getDefaultOptions() :
                        <FieldLikeOptionsEditor options={this.getOptions(field)}/>
                case WorkflowStatesService.getRecordClassName():
                    return null // not options here yet, maybe limit states in select... etc.
                // return defaultOptions ? FieldWorkflowStateOptionsEditor.getDefaultOptions() :
                //     <FieldWorkflowStateOptionsEditor options={this.getOptions(field)}/>
                case findContentTypeByClassName(field.targetEntity) ? (new DataStorageService(findContentTypeByClassName(field.targetEntity))).getRecordClassName() : '':
                    return defaultOptions ? FieldContentTypeOptionsEditor.getDefaultOptions() :
                        <FieldContentTypeOptionsEditor options={this.getOptions(field)} match={match} history={history}
                                                       targetContentType={findContentTypeByClassName(field.targetEntity)}/>
            }
        }
    }

    getOptions = (field: IField) => {
        const {options} = this.props
        return {...this.getFieldEditor(field, true), ...options}
    }

    render() {
        const {field, formRef, collapsable, title} = this.props
        const editor = this.getFieldEditor(field)
        const form = <Form ref={formRef} initialValues={this.getOptions(field)} onValuesChange={this.onValueChange}>
            {editor}
        </Form>
        return editor ? (collapsable ?
                <Collapse>
                    <Collapse.Panel key={1} header={title}>
                        {form}
                    </Collapse.Panel>
                </Collapse>
                :
                <Card title={title}>
                    {form}
                </Card>
        ) : null
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findContentTypeByClassName: (className: string) => selectors.contentTypes.findOneBy(store, 'fullClassName', className)
    }
}

export default connect(mapStateToProps)(FieldOptionsEditor)