import IReportWidget from "../../../../../model/interface/report/IReportWidget";
import ReportWidgetType from "./ReportWidgetType";
import WidgetList, {IWidgetList, IWidgetListItem} from "../../widget/WidgetList";
import {antIcons} from "utils/Icons/AntIcons";

const widgets = [
    ...WidgetList.getAll(),
    {
        label: 'Obrázek',
        type: ReportWidgetType.IMAGE,
        icon: antIcons.PictureOutlined,
        group: ReportWidgetType.GROUP_STATIC
    },
    {
        label: 'Pohled',
        type: ReportWidgetType.VIEW,
        icon: antIcons.FileOutlined,
        group: ReportWidgetType.GROUP_DYNAMIC
    },
    {
        label: 'Dohledání zaměstnance',
        type: ReportWidgetType.EMPLOYEE_LOOKUP,
        icon: antIcons.UserAddOutlined,
        group: ReportWidgetType.GROUP_DYNAMIC
    },
    {
        label: 'Notifikace',
        type: ReportWidgetType.NOTIFICATIONS,
        icon: antIcons.BellOutlined,
        group: ReportWidgetType.GROUP_DYNAMIC
    },
    {
        label: 'Vyhledávání podle štítku',
        type: ReportWidgetType.RESOURCE_FINDER_BY_CODE,
        icon: antIcons.ScanOutlined,
        group: ReportWidgetType.GROUP_DYNAMIC
    }
] as IWidgetListItem[]

const ReportWidgetList: IWidgetList = {
    ...WidgetList,
    getGrouped: () => {
        let groups = {
            [ReportWidgetType.GROUP_CONSTRUCT]: {label: 'Modifikace uživatelské rozhraní', widgets: []},
            [ReportWidgetType.GROUP_STATIC]: {label: 'Statická data', widgets: []},
            [ReportWidgetType.GROUP_DYNAMIC]: {label: 'Dynamické struktury', widgets: []}
        } as { [group: string]: { label: string, widgets: IWidgetListItem[] } }
        widgets.filter(widget => !widget.hidden).forEach(widget => {
            groups[widget.group].widgets.push(widget)
        })
        return groups
    },
    getByType: (type: string) => {
        const item = widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item) {
            const {label, type} = item
            return {label, type, weight: 1, children: [], id: '', options: {}, parent: null, uuid: ''} as IReportWidget
        }
        throw new Error(`Report widget type[${type}] does not exist!`)
    }
}

export default ReportWidgetList