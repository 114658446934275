import React from "react";
import {Button, Modal as AntdModal, Tabs} from "antd";
import FormProperties from "./FormProperties";
import FormEditor from "./FormEditor";
import IForm from "../../../../model/interface/form/IForm";
import IBaseProps from "../../../../model/interface/IBaseProps";
import {BarsOutlined, FormOutlined, OrderedListOutlined} from "@ant-design/icons";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import FormEvaluation from "./FormEvaluation";
import Modal from "../../../shared/modal/Modal";

interface IState {
    saving: boolean
    showAll: boolean
    form: IForm
    step: string,
    changedForms: IForm[],
}

interface IProps extends IBaseProps {
    update?: (form: IForm, forms?: IForm[]) => void
    cancel: () => void
    form: IForm
    contentType: IContentType
}

export interface IFormStepProps extends IBaseProps {
    onChange: (form: IForm, changedForms?: IForm[], confirm?: boolean, stepBack?: boolean) => void
    form: IForm,
    contentType: IContentType
}

class FormModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            saving: false,
            showAll: false,
            form: {...props.form},
            step: '1',
            changedForms: []
        }
    }

    onChange = (form: IForm, changedForms?: IForm[], confirm: boolean = false, stepBack: boolean = false) => {
        this.setState(state => ({
            form: {
                ...state.form,
                ...form,
            },
            changedForms: changedForms || state.changedForms
        }), () => confirm && this.setStep('', stepBack))
    }

    setStep = (step: string, stepBack: boolean = false) => {
        this.setState(state => ({step: step ? step : (Number(state.step) + (stepBack ? -1 : 1)).toString()}))
    }

    onCancel = () => {
        this.state.form && JSON.stringify(this.props.form) !== JSON.stringify(this.state.form) ? AntdModal.confirm({
            title: 'Pozor, pokud budete pokračovat, všechny změny budou ztraceny!',
            onOk: () => {
                AntdModal.destroyAll();
                this.props.cancel()
            },
            onCancel() {
            },
            type: 'warning'
        }) : this.props.cancel()
    }

    update = () => {
        const {form, changedForms} = this.state
        if (form && form.name && form.label) {
            console.log(form)
            this.setState({saving: true})
            this.updateParent(form, changedForms);
        }
    }

    updateParent(form: IForm, changedForms?: IForm[]) {
        this.props.update && this.props.update(form, changedForms)
    }

    render() {
        const {saving, form, step} = this.state
        const {history, match, contentType} = this.props

        const stepProps = {form, onChange: this.onChange, history, match, contentType}

        const tabs = [
            {
                label: 'Vlastnosti',
                icon: <BarsOutlined/>,
                widget: <FormProperties {...stepProps} />
            },
            {
                label: 'Vyhodnocení',
                icon: <OrderedListOutlined/>,
                widget: <FormEvaluation {...stepProps} />
            },
            {
                label: 'Editor',
                icon: <FormOutlined/>,
                widget: <FormEditor {...stepProps}/>
            }
        ]

        return (
            <Modal
                fullScreenOption={true}
                title={form.id ? `Uprava formuláře [${form.name}]` : 'Nový formulář'}
                visible={true}
                onCancel={this.onCancel}
                width={800}
                destroyOnClose={true}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" loading={saving} disabled={Number(step) < tabs.length}
                            onClick={() => this.update()}>
                        Submit
                    </Button>
                ]}
            >
                <Tabs defaultActiveKey="1" activeKey={step} onChange={this.setStep}>
                    {tabs.map((tab, index) => (
                        <Tabs.TabPane tab={<div>{tab.icon} {tab.label}</div>}
                                      key={index + 1} disabled={+step < tabs.length}>
                            {tab.widget}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Modal>
        )
    }
}

export default FormModal