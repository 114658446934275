import React, {RefObject} from "react";
import {Button, Form, FormInstance, Modal, Slider, Switch} from "antd";
import IWidgetOptions from "../../../../../../model/interface/widget/IWidgetOptions";
import IFile from "../../../../../../model/interface/file/IFile";
import IReportWidgetNotifications from "../../../../../../model/interface/report/IReportWidgetNotifications";

interface IProps {
    options: IReportWidgetNotifications
    onFinish: (values?: IWidgetOptions) => void
}

class NotificationsWidgetEditor extends React.Component<IProps> {

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then(values => {
            this.props.onFinish({...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    setFile = (files?: IFile[]) => {
        this.setState(() => {
                const file = files ? files[0] : null
                return {file}
            },
            this.formRef.current?.resetFields
        )
    }

    valueChanged = (value: any) => {
        this.setState({...value})
    }

    render() {
        const initialValues: IReportWidgetNotifications = {
            label: 'Najít zaměstnance',
            showLast: true, showTotal: true, showUnread: true, lastLimit: 5, ...this.props.options
        }

        return (
            <Modal
                className={'dir-ltr'}
                title={'Upravit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>
                ]}
            >
                <Form initialValues={initialValues} ref={this.formRef}
                      onValuesChange={this.valueChanged}>
                    <Form.Item name={'showUnread'} label={'Zobrazit počet nepřečtených'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'showTotal'} label={'Zobrazit celkový počet'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'showLast'} label={'Zobrazit poslední'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'lastLimit'} label={'Počet posledních k zobrazení'} rules={[{required: true}]}>
                        <Slider min={3} max={20}/>
                    </Form.Item>
                </Form>
            </Modal>

        )
    }
}

export default NotificationsWidgetEditor