import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IFile from "../../interface/file/IFile";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import fetch from 'model/auth/ETagCachedFetchInterceptor'
import Utils from "../../../utils";
import IPresenter from "../../interface/dataStorage/IPresenter";
import IRepositoryService from "../../interface/IRepositoryService";
import IField from "../../interface/dataStorage/IField";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";
import React from "react";
import {Image} from "antd";
import {EyeOutlined} from "@ant-design/icons";

interface IRestFilesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IFile>
}

interface IRestFilesService extends IRepositoryService {
    collectionList(options?: IRestServiceOptions): Promise<IRestFilesServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IFile[]>,

    resourceRetrieve(id: number | string, options?: IRestServiceOptions): Promise<IFile>,

    resourceUpdate(id: number | string, data: any, options?: IRestServiceOptions): Promise<IFile>,

    resourceDelete(id: number | string): Promise<void>,

    resourceDownload(ids: any[]): Promise<{ blob: Blob, fileName?: string }>,

    resourceDeleteAll(ids: any[]): Promise<void>,

    collectionSearch(pattern: string, options?: IRestServiceOptions): Promise<IRestFilesServiceCollectionResponse>,

    collectionByExtension(extension: string, options?: IRestServiceOptions): Promise<IRestFilesServiceCollectionResponse>

    collectionImage(options?: IRestServiceOptions): Promise<IRestFilesServiceCollectionResponse>

    collectionLatest(options?: IRestServiceOptions): Promise<IRestFilesServiceCollectionResponse>,

    getPresenter(name: string): IPresenter,

    getDefaultPresenter(): IPresenter,

    getStringValue(file: IFile): string,
}

const FilesService_COLLECTION = 'files'
const FilesService_CHOICES = 'files-choices/'
const FilesService_COLLECTION_ACTIONS = 'file-actions'
const FilesService_COLLECTION_ACTION_DOWNLOAD = FilesService_COLLECTION_ACTIONS + '/download'
const FilesService_COLLECTION_ACTION_DELETE = FilesService_COLLECTION_ACTIONS + '/delete'
const FilesService_COLLECTION_SEARCH = 'file-search'
const FilesService_COLLECTION_SEARCH_PATTERN = FilesService_COLLECTION_SEARCH + '/pattern'
const FilesService_COLLECTION_SEARCH_EXTENSION = FilesService_COLLECTION_SEARCH + '/extension'
const FilesService_COLLECTION_SEARCH_IMAGE = FilesService_COLLECTION_SEARCH + '/image'

const filePresenters = [
    {
        type: 'callback',
        name: 'label',
        label: 'Odkaz',
        options: {
            callback: (object: IFile) => {
                if (object) {
                    return (
                        <a className={'d-inline-block'} href={object.publicUrl} target={'_blank'}
                           rel="noreferrer">{object.fullName}</a>
                    )
                }
                return <span className={"text-muted"}>Žádný soubor</span>
            }
        }
    },
    {
        type: 'callback',
        name: 'with_icon',
        label: 'Odkaz s ikonou a názvem',
        options: {
            callback: (object: IFile) => {
                if (object) {
                    return (
                        <a href={object.publicUrl} target={'_blank'} className={'d-inline-block'} rel="noreferrer">
                            {object.currentVersion.mime.slice(0, 5) === 'image'
                                ? <Image className={"file-icon file-icon-thumbnail"} preview={false}
                                         fallback={Utils.imageFallback()} src={object.thumbnailUrl}/>
                                : <span className={'file-icon file-icon-type'}>{object.currentVersion.extension}</span>}
                            <span className={'pl-2'}>{object.fullName}</span>
                        </a>
                    )
                }
                return <span className={"text-muted"}>Žádný soubor</span>
            }
        }
    },
    {
        type: 'callback',
        name: 'image',
        label: 'Obrázek',
        options: {
            callback: (object: IFile) => {
                if (object) {
                    return (
                        <a href={object.publicUrl} target={'_blank'} rel="noreferrer" className={'d-inline-block'}>
                            {object.currentVersion.mime.slice(0, 5) === 'image'
                                ? <img src={object.thumbnailUrl} alt={object.fullName}/>
                                : <>Neplatný obrázek</>}
                        </a>
                    )
                }
                return <span className={"text-muted"}>Žádný soubor</span>
            }
        }
    },
    {
        type: 'callback',
        name: 'icon',
        label: 'Ikona',
        options: {
            callback: (object: IFile) => {
                if (object) {
                    return (
                        object.currentVersion.mime.slice(0, 5) === 'image'
                            ? <Image className={"file-icon file-icon-thumbnail"} preview={false}
                                     fallback={Utils.imageFallback()} src={object.thumbnailUrl}/>
                            : <span className={'file-icon file-icon-type'}>{object.currentVersion.extension}</span>
                    )
                }
                return <span className={"text-muted"}>Žádný soubor</span>
            }
        }
    },
    {
        type: 'callback',
        name: 'icon_link',
        label: 'Ikona s odkazem',
        options: {
            callback: (object: IFile) => {
                if (object) {
                    return (
                        <a href={object.publicUrl} target={'_blank'} rel="noreferrer" className={'d-inline-block'}>
                            {object.currentVersion.mime.slice(0, 5) === 'image'
                                ? <Image className={"file-icon file-icon-thumbnail"} preview={false}
                                         fallback={Utils.imageFallback()} src={object.thumbnailUrl}/>
                                : <span className={'file-icon file-icon-type'}>{object.currentVersion.extension}</span>}
                        </a>
                    )
                }
                return <span className={"text-muted"}>Žádný soubor</span>
            }
        }
    },
    {
        type: 'callback',
        name: 'icon_with_preview',
        label: 'Ikona s náhledem',
        options: {
            callback: (object: IFile) => {
                if (object) {
                    return (
                        object.currentVersion.mime.slice(0, 5) === 'image'
                            ? <Image className={"file-icon file-icon-thumbnail"} fallback={Utils.imageFallback()}
                                     src={object.thumbnailUrl}
                                     preview={{mask: <EyeOutlined/>, src: object.publicUrl}}/>
                            : <span className={'file-icon file-icon-type'}>{object.currentVersion.extension}</span>
                    )
                }
                return <span className={"text-muted"}>Žádný soubor</span>
            }
        }
    },
] as IPresenter[]

const FilesService: IRestFilesService = {
    getFields(): IField[] {
        // TODO
        return [
            {
                uuid: '',
                name: 'name',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            }
        ];
    },
    getRecordClassName() {
        return 'App\\File\\Entity\\File'
    },
    getTitle(): string {
        return "Soubor";
    },
    collectionList: function (options?) {
        return RestService.collectionList(FilesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestFilesServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(FilesService_COLLECTION, data, {fileUpload: true}) as Promise<IFile[]>
    },
    resourceRetrieve: function (id, options) {
        return RestService.resourceRetrieve(FilesService_COLLECTION, id, options) as Promise<IFile>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(FilesService_COLLECTION, id)
    },
    resourceUpdate: function (id, data, options) {
        return RestService.resourceUpdate(FilesService_COLLECTION, id, data, options) as Promise<IFile>
    },
    resourceDownload(ids: []): Promise<{ blob: Blob, fileName?: string }> {
        // @ts-ignore
        return fetch({
            responseType: "blob",
            cacheKey: FilesService_COLLECTION_ACTION_DOWNLOAD,
            url: '/' + FilesService_COLLECTION_ACTION_DOWNLOAD,
            method: 'post',
            data: Utils.convertArrayToFormData({ids: ids}),
        }).then((response: { data: Blob, fileName: string } | any) => ({
            blob: response.data,
            fileName: response.fileName
        }))
    },
    resourceDeleteAll: function (ids): Promise<void> {
        // @ts-ignore
        return fetch({
            cacheKey: FilesService_COLLECTION_ACTION_DELETE,
            url: '/' + FilesService_COLLECTION_ACTION_DELETE,
            method: 'post',
            data: Utils.convertArrayToFormData({ids: ids}),
        })
    },
    collectionSearch: function (pattern, options) {
        return RestService.collectionList(FilesService_COLLECTION_SEARCH_PATTERN + '/'
            + pattern, options) as Promise<IRestFilesServiceCollectionResponse>
    },
    collectionByExtension: function (extension, options) {
        return RestService.collectionList(FilesService_COLLECTION_SEARCH_EXTENSION
            + '/' + extension, options) as Promise<IRestFilesServiceCollectionResponse>
    },
    collectionImage: function (options) {
        return RestService.collectionList(FilesService_COLLECTION_SEARCH_IMAGE, options) as Promise<IRestFilesServiceCollectionResponse>
    },
    collectionLatest: function (options = {order: {1: {field: 'id', direction: "DESC"}}, limit: 100}) {
        return RestService.collectionList(FilesService_COLLECTION, options) as Promise<IRestFilesServiceCollectionResponse>
    },
    getPresenter(name: string): IPresenter {
        return filePresenters.find(presenter => presenter.name === name)!
    },
    getDefaultPresenter(): IPresenter {
        return filePresenters[0];
    },
    getStringValue(file) {
        return file.name;
    },
    getPresenterList() {
        return filePresenters.map(presenter => {
            return {value: presenter.name, label: presenter.label}
        })
    },
    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(FilesService_CHOICES + (presenterName === 'label' ? 'fullName' : presenterName), options) as unknown as Promise<IRestServiceChoiceListResponse> //TODO presenters defined here dont work with choice list
    }
}

export default FilesService