import {DatePicker, Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase, {IFilterBaseValue} from "./IFilterBase";
import FilterDropDown from "./FilterDropDown";
import {API_FILTER_TYPE} from "../../model/constants/ApiConstant";
import LocaleProvider from "../../i18n/LocaleProvider";
import moment, {Moment} from "moment";
import {PickerMode} from 'rc-picker/lib/interface';

const {RangePicker} = DatePicker;

const DateFilter: IFilterBase = {
    handleSearch(setSelectedKeys, selectedKeys, confirm) {
        confirm();
    },

    handleReset(clearFilters, setValue) {
        setValue([])
        clearFilters();
    },

    build(item, field, state, setValue): IFilterFunctions {
        const dataIndex = field.name

        const findFrom = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.GREATER_OR_EQUAL)
        }
        const findTo = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.LESSER_OR_EQUAL)
        }


        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                const defaultDates = [findFrom(selectedKeys)?.value, findTo(selectedKeys)?.value]
                const picker = (((item.options && item.options.picker) || field.options.datePicker) || "date") as PickerMode
                const formatValue = (value: Moment, picker: PickerMode, start: boolean): string => {
                    let v = value.clone()
                    let unitOfTime: moment.unitOfTime.StartOf|undefined
                    switch(picker) {
                        case("date"): unitOfTime = "day"; break;
                        case 'time': unitOfTime = undefined; break;
                        default: unitOfTime = picker
                    }
                    if(unitOfTime) {
                        if(start) {
                            v.startOf(unitOfTime).hour(0).minute(0).second(0)
                        } else {
                            v.endOf(unitOfTime).hour(23).minute(59).second(59)
                        }
                    }
                    return v.format('YYYY-MM-DD HH:mm:ss')
                }
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>

                        <RangePicker
                            picker={picker}
                            locale={LocaleProvider.getPicker()}
                            format={(item.options && item.options.dateFormat) || field.options.dateFormat}
                            className={'d-block'}
                            allowEmpty={[true, true]}
                            placeholder={['Od', 'Do']}
                            value={defaultDates.map(date => date ? moment(date.toString()) : undefined) as any}
                            onChange={dates => {
                                setSelectedKeys(dates ? ([
                                    ...(dates[0] ? [{
                                        type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                                        value: formatValue(dates[0], picker, true)
                                    }] : [])
                                    ,
                                    ...(dates[1] ? [{
                                        type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                                        value: formatValue(dates[1], picker, false)
                                    }] : [])
                                ]) : [])
                            }}
                        />
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered: boolean): JSX.Element => {
                const from = findFrom(state)?.value
                const to = findTo(state)?.value
                return (
                    <Tooltip title={filtered ? "Filtrováno: " + (from || '') + ' -> ' + (to || '') : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                const recordDate = moment(record[dataIndex])

                return value.type === API_FILTER_TYPE.LESSER_OR_EQUAL ?
                    (!value.value || moment(value.value).isSameOrBefore(recordDate)) :
                    (!value.value || moment(value.value).isSameOrAfter(recordDate))
            }
        }
    }
}

export default DateFilter