import {Button, Col, Divider, Modal, ModalFuncProps, Row, Typography} from "antd";
import Title from "antd/es/typography/Title";
import {PlusOutlined} from "@ant-design/icons";
import {IWidgetList, IWidgetListItem} from "./WidgetList";


interface Select {
    (type: string, ...args: any[]): void
}

interface GroupItem {
    label: string
    widgets: IWidgetListItem[]
}

class WidgetGallery {

    protected onSelect
    protected list

    protected modal?: {
        destroy: () => void;
        update: (configUpdate: ModalFuncProps) => void;
    }

    constructor(onSelect: Select, list: IWidgetList) {
        this.onSelect = onSelect
        this.list = list
    }

    public show(group?: string, title: string = 'Widgety') {
        this.modal = Modal.info({
            width: 860,
            bodyStyle: {maxHeight: '60vh', overflow: "hidden auto"},
            content: (
                <div className={"d-block dir-ltr"}>
                    <Title level={3}>{title ? title : 'Widgety'}</Title>
                    {Object.entries(this.list.getGrouped())
                        .filter(value => group ? value[0] === group : true)
                        .map(group =>
                            <div className={'p-1'} key={group[0]}>
                                <Title level={4}>{group[1].label}</Title>
                                <Row gutter={16}>{this.getGroup(group)}
                                </Row>
                            </div>
                        )}
                </div>
            )
        })
    }

    protected getGroup(group: [string, GroupItem]) {
        return <>
            {group[1].widgets.map((widget, index) =>
                this.getNodeOptionTag(widget, index, () => this.onSelectItem(widget.type)))}
        </>;
    }

    protected onSelectItem(type: string, ...args: any[]) {
        this.onSelect(type, ...args);
        this.modal?.destroy()
    }

    protected getNodeOptionTag(
        widget: IWidgetListItem,
        key: number | string,
        onSelect?: () => void,
        disabled: boolean = false
    ) {
        return (
            <Col key={key + widget.type} sm={8} className={"d-block dir-ltr"}>
                <Row align={"middle"} justify={"space-between"} className={'border mb-1 shadow-sm pl-2'}>
                    <div>
                        {widget.icon}
                        <Divider type={"vertical"}/>
                        <Typography.Text strong>{widget.label}</Typography.Text>
                    </div>
                    <Button disabled={disabled} className={"float-right"} type={"link"} size={"small"}
                            icon={<PlusOutlined/>}
                            onClick={() => onSelect ? onSelect() : this.onSelectItem(widget.type)}/>
                </Row>
            </Col>
        );
    }
}

export default WidgetGallery