import {Card} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IBaseProps from "../../../../../../model/interface/IBaseProps";
import FieldStructureOptionsEditor, {IFieldStructureOptions} from "./FieldStructureOptionsEditor";

export interface IFieldUnitOptions extends IFieldStructureOptions{

}

interface IProps extends IFieldOptionsEditorProps, IBaseProps {

}

interface IState {

}

class FieldUnitOptionEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    static getDefaultOptions(): IFieldUnitOptions {
        return {
            ...FieldStructureOptionsEditor.getDefaultOptions(),
            companyStructureAccepts: 'unit'
        }
    }

    render() {
        return (
            <Card>

            </Card>
        )
    }
}

export default FieldUnitOptionEditor