import React from "react";
import WidgetTool from "../../../widget/WidgetTool";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import IconBuilder from "../../../../../../utils/IconBuilder";
import {CheckOutlined, ExclamationOutlined, HeartFilled, HeartOutlined, StopOutlined} from "@ant-design/icons";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import IUser from "../../../../../../model/interface/security/IUser";
import {ISetupState} from "../../../../../../redux/reducers/Setup";
import {Card, Col, Empty, List, Row} from "antd";
import ICardWidgetWorkflowTransactionConditions
    from "../../../../../../model/interface/dataStorage/card/ICardWidgetWorkflowTransactionConditions";
import IWorkflow from "../../../../../../model/interface/dataStorage/workflow/IWorkflow";
import WorkflowsService from "../../../../../../model/service/dataStorage/WorkflowsService";

interface IProps extends ICardWidgetProps<ICardWidgetWorkflowTransactionConditions> {
    findService: (contentType: IContentType) => IRepositoryService,
    user: IUser

    findWorkflowByContentType: (contentType: IContentType) => IWorkflow
}

interface IState {
    saving: boolean,
    loading: boolean,
    users: IUser[]

}

class CardWidgetWorkflowTransactionConditions extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            saving: false,
            loading: false,
            users: []
        }
    }

    static getIcon(liked: boolean, icon?: string) {
        return liked ? (
            icon ? IconBuilder(icon
                .replace('Outlined', 'Filled')
                .replace('Outline', '')) : <HeartFilled/>
        ) : (
            icon ? IconBuilder(icon) : <HeartOutlined/>
        );
    }

    render() {
        const {options, resource, functions, findWorkflowByContentType} = this.props
        const {
            notMetOnly,
            showIcon,
            showColor,
            background
        } = {...options}

        const contentType = functions.getContentType()
        const workflow = findWorkflowByContentType(contentType)
        const version = workflow.activeVersion
        if (!version) {
            return new Error('Workflow does not have an active version!')
        }

        const outgoingTransitions = resource && WorkflowsService.getOutgoingTransitions(version, resource, contentType)

        return (
            <div>
                <WidgetTool {...this.props} edit={true}/>
                <Card size={"small"} className={background} title={'Podmínky pro přechod do jiného stavu'}>
                    {resource && outgoingTransitions ? (outgoingTransitions.length ? outgoingTransitions.map(transaction => (
                            <List
                                header={outgoingTransitions.length > 1 ? <b>{transaction.title}</b> : false}
                                size={'small'}
                                itemLayout="horizontal">
                                {resource._failedWorkflowTransitionConditions?.filter(c => c.transition === transaction.uuid)
                                    .map(condition => (
                                        <List.Item className={showColor ? 'text-danger' : ''} key={condition.uuid}>
                                            <Row justify={"space-between"} className={'w-100'}>
                                                <Col>
                                                    {showIcon && (
                                                        <div className={'pr-2 d-inline-block'}>
                                                            {IconBuilder(condition.icon || '', <ExclamationOutlined/>)}
                                                        </div>
                                                    )}
                                                    {condition.failText ?? 'Nejsou splněny požadované podmínky!'}
                                                </Col>
                                                <Col>
                                                    <StopOutlined/>
                                                </Col>
                                            </Row>
                                        </List.Item>))
                                }
                                {!notMetOnly && resource._metWorkflowTransitionConditions?.filter(c => c.transition === transaction.uuid)
                                    .map(condition => (
                                        <List.Item className={showColor ? 'text-success' : ''} key={condition.uuid}>
                                            <Row justify={"space-between"} className={'w-100'}>
                                                <Col>
                                                    {showIcon && (
                                                        <div className={'pr-2 d-inline-block'}>
                                                            {IconBuilder(condition.icon || '', <CheckOutlined/>)}
                                                        </div>
                                                    )}
                                                    {condition.failText ?? 'Splněny požadované podmínky!'}
                                                </Col>
                                                <Col>
                                                    <CheckOutlined/>
                                                </Col>
                                            </Row>
                                        </List.Item>))
                                }
                                {(!resource._metWorkflowTransitionConditions?.filter(c => c.transition === transaction.uuid).length || notMetOnly)
                                    && !resource._failedWorkflowTransitionConditions?.filter(c => c.transition === transaction.uuid).length && (
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'nejsou nastaveny žádné podmínky'}/>
                                        )}
                            </List>
                        )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'žádné další změny stavu'}/>)
                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'není definován žádný stav'}/>
                    }
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState

    return {
        findWorkflowByContentType: (contentType: IContentType) => selectors.workflow.findOneByContentType(state, contentType),
        findService: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        user
    }
}

export default connect(mapStateToProps)(CardWidgetWorkflowTransactionConditions)
