import React from "react";
import ContainerWidget from "./ContainerWidget";
import {Col} from "antd";
import WidgetType from "./WidgetType";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import ColumnsWidget from "./ColumnsWidget";
import ColumnWidget from "./ColumnWidget";
import TabsWidget from "./TabsWidget";
import TabWidget from "./TabWidget";
import IWidget from "../../../../model/interface/widget/IWidget";
import ITabOptions from "../../../../model/interface/widget/option/ITabOptions";
import IColumnOptions from "../../../../model/interface/widget/option/IColumnOptions";
import TextWidget from "./TextWidget";
import TextWidgetEditor from "./optionEditor/TextWidgetEditor";
import ColumnWidgetEditor from "./optionEditor/ColumnWidgetEditor";
import TabWidgetEditor from "./optionEditor/TabWidgetEditor";
import IWidgetOptions from "../../../../model/interface/widget/IWidgetOptions";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import ITextOptions from "../../../../model/interface/widget/option/ITextOptions";
import CommentEditor from "./optionEditor/CommentEditor";
import ICommentOptions from "../../../../model/interface/widget/option/ICommentOptions";
import CommentWidget from "./CommentWidget";
import WidgetTool from "./WidgetTool";
import BackButtonWidget from "./BackButtonWidget";
import IContainerOptions from "../../../../model/interface/widget/option/IContainerOptions";
import ContainerEditor from "./optionEditor/ContainerEditor";
import TabsWidgetEditor from "./optionEditor/TabsWidgetEditor";
import ITabsOptions from "../../../../model/interface/widget/option/ITabsOptions";
import BackButtonWidgetEditor from "./optionEditor/BackButtonWidgetEditor";
import IBackButtonOptions from "../../../../model/interface/widget/option/IBackButtonOptions";

class Widget extends React.Component<IWidgetProps> {

    static getWidget(type: string, props: IWidgetProps) {
        switch (type) {
            case(WidgetType.CONTAINER):
                return <ContainerWidget {...props} />
            case(WidgetType.COLUMNS):
                return <ColumnsWidget {...props as IWidgetProps<IWidgetPropsFunctions<IWidget<IColumnOptions>>, IColumnOptions>}/>
            case(WidgetType.COLUMN):
                return <ColumnWidget {...props}/>
            case(WidgetType.TABS):
                return <TabsWidget {...props as IWidgetProps<IWidgetPropsFunctions<IWidget<ITabsOptions>>, ITabsOptions>}/>
            case(WidgetType.TAB):
                return <TabWidget {...props}/>
            case(WidgetType.TEXT):
                return <TextWidget {...props as IWidgetProps<IWidgetPropsFunctions, ITextOptions>}/>
            case(WidgetType.COMMENT):
                return <CommentWidget {...props as IWidgetProps<IWidgetPropsFunctions, ITextOptions>}/>
            case(WidgetType.BACK_BUTTON):
                return <BackButtonWidget {...props}/>
            default: return (
                <div>
                    Not implemented
                    <WidgetTool {...props}/>
                </div>
            )
        }
    }

    static getOptionEditor(type: string, options: IWidgetOptions, onFinish: (values?: IWidgetOptions) => void) {
        switch (type) {
            case WidgetType.TEXT:
                return <TextWidgetEditor options={options as ITextOptions} onFinish={onFinish}/>
            case WidgetType.COLUMN:
                return <ColumnWidgetEditor options={options as IColumnOptions} onFinish={onFinish}/>
            case WidgetType.TAB:
                return <TabWidgetEditor options={options as ITabOptions} onFinish={onFinish}/>
            case WidgetType.TABS:
                return <TabsWidgetEditor options={options as ITabsOptions} onFinish={onFinish}/>
            case(WidgetType.COMMENT):
                return <CommentEditor options={options as ICommentOptions} onFinish={onFinish}/>
            case(WidgetType.CONTAINER):
                return <ContainerEditor options={options as IContainerOptions} onFinish={onFinish}/>
            case(WidgetType.BACK_BUTTON):
                return <BackButtonWidgetEditor options={options as IBackButtonOptions} onFinish={onFinish}/>
            default:
                return null
        }
    }

    static renderWidget(props: IWidgetProps, getWidget: (type: string, props: IWidgetProps<any>) => JSX.Element) {
        const {type, preview} = props;
        return <Col className={(preview ? '' : ' border shadow-sm p-1')}>
            {getWidget(type, props)}
        </Col>
    }

    render() {
        return Widget.renderWidget(this.props, Widget.getWidget)
    }
}

export default Widget