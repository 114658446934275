import WidgetList, {IWidgetListItem} from "../../../widget/WidgetList";
import CardWidgetType from "./CardWidgetType";
import ICardWidget from "../../../../../../model/interface/dataStorage/card/ICardWidget";
import {antIcons} from "../../../../../../utils/Icons/AntIcons";


const widgets = [
    ...WidgetList.getAll(),
    {
        label: 'Vazba',
        type: CardWidgetType.RELATION_FIELD,
        icon: antIcons.PartitionOutlined,
        group: CardWidgetType.GROUP_RELATION,
        hidden: true
    }, {
        label: 'Vazba',
        type: CardWidgetType.SCALAR_FIELD,
        icon: antIcons.DatabaseOutlined,
        group: CardWidgetType.GROUP_SCALAR,
        hidden: true
    }, {
        label: 'Akce',
        type: CardWidgetType.ACTION,
        icon: antIcons.ThunderboltOutlined,
        group: CardWidgetType.ACTION,
        hidden: true
    },{
        label: 'Dokumenty',
        type: CardWidgetType.GENERATED_DOCUMENTS,
        icon: antIcons.FileTextOutlined,
        group: CardWidgetType.GROUP_RELATION,
        hidden: true
    },
    {
        label: 'Líbí se mi',
        type: CardWidgetType.LIKE,
        icon: antIcons.LikeOutlined,
        group: CardWidgetType.GROUP_RELATION
    },
    {
        label: 'Workflow podmínky',
        type: CardWidgetType.WORKFLOW_TRANSACTION_CONDITIONS,
        icon: antIcons.BranchesOutlined,
        group: CardWidgetType.GROUP_RELATION
    },
    {
        label: 'Schválení',
        type: CardWidgetType.SCALAR_FIELD,
        icon: antIcons.AppstoreAddOutlined,
        group: CardWidgetType.GROUP_SCALAR
    }
] as IWidgetListItem[]

const CardWidgetList = {
    ...WidgetList,
    getGrouped: () => {
        let groups = {
            [CardWidgetType.GROUP_CONSTRUCT]: {label: 'Modifikace uživatelské rozhraní', widgets: []},
            [CardWidgetType.GROUP_STATIC]: {label: 'Statická data', widgets: []},
            [CardWidgetType.GROUP_SCALAR]: {label: 'Scalárani a vztahová pole [N:1]', widgets: []},
            [CardWidgetType.GROUP_RELATION]: {label: 'Vztahová pole [1:N]', widgets: []},
            [CardWidgetType.GROUP_ACTION]: {label: 'Akce', widgets: []}
        } as { [group: string]: { label: string, widgets: IWidgetListItem[] } }
        widgets.filter(widget => !widget.hidden).forEach(widget => {
            groups[widget.group].widgets.push(widget)
        })
        return groups
    },
    getByType: (type: string) => {
        const item = widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item) {
            const {label, type} = item
            return {label, type, weight: 1, children: [], id: '', options: {}, parent: null, uuid: ''} as ICardWidget
        }
        throw new Error(`Report widget type[${type}] does not exist!`)
    },
    getItemByType: (type: string) => {
        const item = widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item) {
            return item
        }
        throw new Error(`Widget type[${type}] does not exist!`)
    }
}

export default CardWidgetList
