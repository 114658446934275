import React from "react";
import IBookmark from "../../../model/interface/ui/IBookmark";
import IconBuilder from "../../../utils/IconBuilder";
import BookmarkModal from "./BookmarkModal";
import {Dropdown, Menu, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, ExportOutlined, StarFilled, StarOutlined} from "@ant-design/icons";
import BookmarksService from "../../../model/service/ui/BookmarksService";
import IBaseProps from "../../../model/interface/IBaseProps";
import Utils from "../../../utils";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IRoute from "../../../model/interface/dataStorage/IRoute";
import IView from "../../../model/interface/dataStorage/IView";
import {bookmarksUpdate} from "../../../redux/actions/Setup";
import IMenu from "../../../model/interface/ui/IMenu";

interface IState {
    edit: boolean
}

export const BOOKMARK_TYPE_PLAIN = 'BOOKMARK_TYPE_PLAIN';
export const BOOKMARK_TYPE_TAG = 'BOOKMARK_TYPE_TAG';

interface IProps extends IBaseProps {
    bookmark?: IBookmark
    bookmarks: IBookmark[]
    findOneByUrl: (path: string) => IRoute | null
    findViewByUuid: (uuid: string) => IView
    bookmarksUpdate: (bookmarks: IBookmark[]) => any
    mode?: 'BOOKMARK_TYPE_PLAIN' | 'BOOKMARK_TYPE_TAG'
    newMode?: 'icon' | 'item'
    style?: React.CSSProperties
    menu: IMenu[]
}

class Bookmark extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            edit: false
        }
    }

    edit = (bookmark?: IBookmark) => {
        this.setState(state => ({edit: !state.edit}))
        bookmark && this.update(bookmark)
    }

    update = (bookmark: IBookmark, remove?: boolean) => {
        const {bookmarksUpdate, bookmarks} = this.props
        const updated = [...bookmarks]
        const found = updated.findIndex(item => item.uuid === bookmark.uuid)
        if (found > -1) {
            updated[found] = bookmark
            if (remove) {
                updated.splice(found, 1)
            }
        } else {
            updated.push(bookmark)
        }

        bookmarksUpdate(updated)
    }

    remove = () => {
        const {bookmark} = this.props
        bookmark?.id && BookmarksService.resourceDelete(bookmark.id)
        bookmark && this.update(bookmark, true)
    }

    getLabel() {
        const {history, findOneByUrl, findViewByUuid, menu} = this.props
        let label
        const route = findOneByUrl(history.location.pathname)
        if (route && route.items) {
            menu.forEach(menuGroup => {
                const item = menuGroup.items.find(item => item.uuid === route.items?.[0])
                if (item) {
                    label = item.title
                }
            })
        }
        if (route && route.view && !label) {
            const view = findViewByUuid(route.view)
            label = view.label
        }
        return label;
    }

    render() {
        const {bookmark, history, mode, newMode, style} = this.props
        const {edit} = this.state

        const {bookmarks} = this.props
        const current = bookmarks.find(b => b.url === history.location.pathname)

        const newLabel = current ? 'Zobrazit nastavení této záložky' : 'Přidat tuto stránku do záložek'
        const newIcon = current ? <StarFilled style={style} className={(newMode || 'icon') === "icon" ? 'm-0' : ''}/> :
            <StarOutlined style={style} className={((newMode || 'icon') === "icon") ? 'm-0' : ''}/>
        const icon = bookmark?.icon && IconBuilder(bookmark.icon)

        return (
            <div className={'w-100'}>
                {edit && (
                    <BookmarkModal bookmark={bookmark || current || {
                        uuid: Utils.uuid(),
                        title: this.getLabel(),
                        url: history.location.pathname,
                        weight: bookmarks.length
                    }} onFinish={this.edit}/>
                )}
                {bookmark ?
                    <div>
                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item key="1" icon={<EditOutlined/>}
                                           onClick={() => this.edit()}>Upravit</Menu.Item>
                                <Menu.Item key="2" icon={<DeleteOutlined/>} onClick={this.remove}>Smazat</Menu.Item>
                                <Menu.Item key="3" icon={<ExportOutlined/>}
                                           onClick={() => window.open(bookmark.url, "_blank")}>
                                    Otevřít na nové kartě
                                </Menu.Item>
                            </Menu>
                        } trigger={['contextMenu']}>
                            <Tooltip title={bookmark.tooltip}>
                                {{
                                    BOOKMARK_TYPE_TAG: <div className={'d-flex font-size-base font-weight-semibold cursor-pointer ml-4'} onClick={() => history.push(bookmark.url)}>
                                        {bookmark.icon && (<span className={bookmark.style + ' cursor-pointer font-size-lg mr-1'}>{IconBuilder(bookmark.icon)}</span>)}

                                        {bookmark.title}
                                    </div>,
                                    BOOKMARK_TYPE_PLAIN:
                                        <Menu className={'w-100'}>
                                            <Menu.Item icon={icon &&
                                                React.cloneElement(icon, {className: bookmark.style})}
                                                       onClick={() => history.push(bookmark.url)}>
                                                {bookmark.title}
                                            </Menu.Item>
                                        </Menu>
                                }[mode || BOOKMARK_TYPE_TAG]}
                            </Tooltip>
                        </Dropdown>
                    </div> :
                    <div>
                        {{
                            icon: (
                                <Tooltip placement={"bottom"} title={newLabel}>
                                    <div onClick={() => this.edit()}
                                         className={'text-warning cursor-pointer'}>
                                        {newIcon}
                                    </div>
                                </Tooltip>
                            ),
                            item: (
                                <Menu className={'text-warning w-100'}>
                                    <Menu.Item onClick={() => this.edit()} icon={newIcon}>
                                        {newLabel}
                                    </Menu.Item>
                                </Menu>
                            )
                        }[newMode || 'icon']}
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findOneByUrl: (path: string) => selectors.routes.findOneByUrlWithIdentifier(state, path),
        findViewByUuid: (uuid: string) => selectors.views.findOneBy(state, 'uuid', uuid),
        menu: state.setup.menu,
        bookmarks: state.setup.bookmarks
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        bookmarksUpdate: (bookmarks: IBookmark[]) => dispatch(bookmarksUpdate(bookmarks))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookmark)