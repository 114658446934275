import Label from "./presenter/Label";
import IPresenter from "../../model/interface/dataStorage/IPresenter";
import AvatartIconWithLabel from "./presenter/AvatartIconWithLabel";
import _ from "underscore"
import selectors from "../../redux/selectors";
import store from "../../redux/store";
import React from "react";
import {Tag, Tooltip} from "antd";
import moment from "moment";
import IRestResource from "../../model/interface/api/IRestResource";
import IAction from "../../model/interface/dataStorage/IAction";
import Price from "./presenter/Price";
import IconBuilder from "../../utils/IconBuilder";
import IPresenterOptions from "../../model/interface/dataStorage/IPresenterOptions";
import ActionButton from "../../components/app/action/ActionButton";

export interface IPresenterTypeOption {
    name: string,
    label: string,
    type: string,
    default?: any
}

export interface IPresenterType {
    type: string,
    label: string,
    options: IPresenterTypeOption[]
    render: (presenter: IPresenter, object: IRestResource | null, options: IPresenterOptions) => JSX.Element | string
}

const presenterTypes: IPresenterType[] = []
presenterTypes.push({
    type: 'label',
    label: 'Jednoduchý popisek',
    options: [{name: 'label', label: 'Pole', type: 'mentions'}, {
        name: 'unit',
        label: 'Jednotka',
        type: 'string'
    }, {name: 'separator', label: 'Oddělovač', type: 'string', default: " "}],
    render: (presenter, object, options) => (
        <Label
            label={PresenterBuilder.getMappedValue('label', presenter, object, options)}
            unit={PresenterBuilder.getMappedValue('unit', presenter, object, options)}/>
    )
})
presenterTypes.push({
    type: 'avatar_with_label',
    label: 'Avatar s popiskem',
    options: [{name: 'label', label: 'Pole', type: 'mentions'}, {
        name: 'unit',
        label: 'Jednotka',
        type: 'string'
    }, {name: 'avatar', label: 'Avatar', type: 'field'}],
    render: (presenter, object, options) => (
        <AvatartIconWithLabel
            label={PresenterBuilder.getMappedValue('label', presenter, object, options)}
            unit={PresenterBuilder.getMappedValue('unit', presenter, object, options)}
            avatar={PresenterBuilder.getMappedValue('avatar', presenter, object, options)}/>
    )
})
presenterTypes.push({
    type: 'price',
    label: 'Cena',
    options: [{name: 'value', label: 'Pole', type: 'field'}, {name: 'unit', label: 'Jednotka', type: 'string'}],
    render: (presenter, object, options) => (
        <Price
            value={PresenterBuilder.getMappedValue('value', presenter, object, options)}
            unit={PresenterBuilder.getMappedValue('unit', presenter, object, options)}
            options={options}
        />
    )
})
presenterTypes.push({
    type: 'tag',
    label: 'Tag',
    options: [{name: 'value', label: 'Hodnota', type: 'field'}, {name: 'scheme', label: 'Schema', type: 'string'}],
    render: (presenter, object, options) => {
        // const value = PresenterBuilder.getMappedValue('value', presenter, object, options)

        const name = options.hasOwnProperty('value') ? options['value'] : presenter.options['value']
        // @ts-ignore
        const value = object && object.hasOwnProperty(name) ? object[name] : ''
        let color: undefined | string = undefined
        let label: string | JSX.Element;
        let toolTip: undefined | string = undefined;
        switch (options.scheme) {
            case('boolean'):
                
                color = value === null ? 'default' : !value ? 'error' : 'success'
                label = value === null ? options.nullLabel || 'není vyplněno' :
                    !value ? options.falseLabel || 'Zakázáno' : options.trueLabel || 'Povoleno'
                break;
            case 'state':
                color = value
                if (!object) {
                    return ''
                }
                label = <span>
                    {object['tagIcon'] && <span className={'mr-2'}>{IconBuilder(object['tagIcon'])}</span>}
                    {object['name']}
                </span>
                toolTip = object['tagTooltip']
                break;
            default:
                label = value + ""
        }
        return (
            <Tooltip title={toolTip}>
                <Tag color={color}>{label}</Tag>
            </Tooltip>
        )
    }
})
presenterTypes.push({
    type: 'callback',
    label: 'Callback',
    options: [],
    render: (presenter, object, options) => {
        return options.callback ? options.callback(object) : <></>;
    }
})

const PresenterBuilder = {
    build: (presenter: IPresenter, object: IRestResource | null, options: IPresenterOptions) => {
        const presenterType: IPresenterType = _.findWhere(presenterTypes, {type: presenter.type})!
        if (!presenterType) {
            throw new Error("Invalid presenter type: " + presenter.type)
        }
        let action: IAction | undefined = undefined
        if (object && object._class && presenter.action) {
            const contentType = selectors.contentTypes.findOneBy(store.getState(), 'fullClassName', object._class)
            if (contentType) {
                action = contentType.actions.find(a => a.uuid === presenter.action)
            }
        }

        let value: JSX.Element | string
        if (object?._presenters?.hasOwnProperty(presenter.name) && ['label', 'price'].includes(presenterType.type)) {
            value = <>{object._presenters[presenter.name]}</>
        } else {
            value = presenterType.render(presenter, object, {...options, ...presenter.options})
        }

        return (
            <>
                {action && object && (!object._permissions || object._permissions[action.name]) ? (
                    <Tooltip title={action.label}>
                        <ActionButton action={action} resources={[object]} options={{label: value, link: true}}/>
                    </Tooltip>
                ) : value}
            </>
        )
    },
    getMappedValue: (name: string, presenter: IPresenter, object: any, options: any): any => {
        const option = PresenterBuilder.getOption(presenter.type, name)
        const separator = options.hasOwnProperty("separator") ? options["separator"] : " "
        if (object && (option.type === 'field' || option.type === 'fields')) {
            let propertyNames = options.hasOwnProperty(name) ? options[name] : presenter.options[name]
            if (typeof propertyNames !== "object") {
                propertyNames = [propertyNames]
            }
            const text = propertyNames.map((propertyName: string) => {
                return object && object.hasOwnProperty(propertyName) ? object[propertyName] : ''
            }).join(separator)
            return text !== undefined ? <span key={name}>{text}</span> : null
        }
        if (object && option.type === 'mentions') {
            let propertyValue = options.hasOwnProperty(name) ? options[name] : ''
            const children: JSX.Element[] = []
            const regex = /(#(?<field>[a-zA-Z_]+)((\|(?<presenterName>[a-z_]+))|@(?<aggregator>[a-z_]+|)|)|(?<text>[^#]+))/g
            let match: any = regex.exec(propertyValue)
            while (match) {
                const groups = match.groups
                const {text, field, aggregator, presenterName} = groups
                if (text !== undefined && text !== null && text.toString().trim()) {
                    children.push(<span key={field}>{text}</span>)
                } else if (field) {
                    let value = object && object.hasOwnProperty(field) ? object[field] : null
                    if (field === 'administrators') {
                        console.log('XXX', text, field, aggregator, presenterName, object, value);
                    }
                    if (aggregator) {
                        switch (aggregator) {
                            case('length'):
                                return typeof value === 'object' ? Object.keys(value).length : 0
                            default:
                                throw new Error('Invalid aggregate function: ' + aggregator)
                        }
                    } else if (presenterName) {
                        if (typeof value === 'object' && value !== null) {
                            console.log('PPP', value)
                            const service = selectors.services.findOneByFullClassName(store.getState(), value._class)
                            if (!service) {
                                throw new Error('Service for class "' + value._class + '" was not found')
                            }
                            const presenter = service.getPresenter(presenterName);
                            if (!presenter) {
                                throw new Error('Presenter "' + presenterName + '" was not found')
                            }
                            const presenterValue = PresenterBuilder.build(presenter, value, presenter.options)
                            if (presenterValue) {
                                children.push(presenterValue)
                            }
                        } else {
                            children.push(<>ERROR: {JSON.stringify(object)}, {name}, field: {field}</>)
                        }
                    } else if (value !== undefined && value !== null) {
                        if (value && typeof value === 'object') {
                            if (value.hasOwnProperty('_class') && value._class === 'DateTime') {
                                console.log('presenter format date', value, options)
                                value = moment(value.raw).format(value.format || 'LLL') as string
                            } else {
                                console.log('Value is object, check presenter', value)
                                throw new Error('Value is object, check presenter')
                            }
                        }
                        children.push(<span key={field}>{value}</span>)
                    } else {
                    }
                }
                match = regex.exec(propertyValue)
            }
            return children.length > 0 ? <>{children}</> : null
        }
        if (option.type === 'string') {
            return options.hasOwnProperty(name) ? options[name] : ''
        }
    },
    getType: (type: string) => {
        return _.findWhere(PresenterBuilder.getTypes(), {type})
    },
    getOption: (type: string, name: string): IPresenterTypeOption => {
        const presenterType = PresenterBuilder.getType(type)
        if (!presenterType) {
            throw new Error('Presenter with type "' + type + '" was not found')
        }
        const option = _.findWhere(presenterType.options, {name})
        if (!option) {
            throw new Error('Option "' + name + '" in type "' + type + '" was not found')
        }
        return option
    },
    getTypes: () => presenterTypes,
    buildFromServer: (presenter: string, resource: IRestResource) => {
        if (resource?._presenters?.hasOwnProperty(presenter)) {
            return resource._presenters[presenter] as string
        }
        return ''
    }
}

export default PresenterBuilder