import {Form} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IBaseProps from "../../../../../../model/interface/IBaseProps";
import IRestServiceFilters from "../../../../../../model/interface/api/IRestServiceFilters";
import FilterTreeBuilder from "../../../../view/settings/customFilters/FilterTreeBuilder";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";

export interface IFieldContentTypeOptions {
    contentTypeFilters?: IRestServiceFilters
}

interface IProps extends IFieldOptionsEditorProps, IBaseProps {
    targetContentType: IContentType
}

interface IState {

}

class FieldContentTypeOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    static getDefaultOptions(): IFieldContentTypeOptions {
        return {}
    }

    render() {
        const {history, match, targetContentType} = this.props
        return (
            <div>
                <Form.Item name={'contentTypeFilters'} label={'Filter'}>
                    <FilterTreeBuilder history={history} match={match}
                                       contentTypeFullClassName={targetContentType.fullClassName}/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldContentTypeOptionsEditor