import React from 'react';
import {Badge, Dropdown, Tooltip} from 'antd';
import {BellOutlined, LoadingOutlined} from '@ant-design/icons';
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../redux/selectors";
import actions from "../../redux/actions";
import ScrollContainer from "../shared/scrollContainer/ScrollContainer";
import NotificationList from "../app/message/notification/NotificationList";

interface IState {
    visible: boolean
}

interface IProps {
    loaded: boolean,
    loading: boolean,
    countUnread: () => number,
    load: () => void
}

class NavNotification extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            visible: false
        }
    }

    handleVisibleChange = (visible: boolean) => {
        this.setState({
            visible
        });
    }

    render() {
        const {loaded, loading, countUnread} = this.props
        const count = countUnread()
        const {visible} = this.state
        return (
            <Dropdown
                placement="bottomRight"
                overlay={<div className="nav-dropdown nav-notification nav-notification-wide overflow-hidden">
                    <div className="nav-notification-header d-flex justify-content-between align-items-center">
                        <h4 className="mb-0">Upozornění</h4>
                    </div>
                    <ScrollContainer className="nav-notification-body" visibility={"visible"}>
                        <NotificationList/>
                    </ScrollContainer>
                </div>}
                onVisibleChange={this.handleVisibleChange}
                visible={visible}
                trigger={['click']}
            >
                <div onClick={() => this.handleVisibleChange(!visible)}>
                    {(loading || !loaded) ? (
                        <Badge count={<LoadingOutlined/>}>
                            <BellOutlined className="nav-icon mx-auto" style={{fontSize: 20}} />
                        </Badge>
                    ) : (
                        <>
                            {count > 0 ? (
                                <Tooltip title={"Máte nová upozornění"} placement={'bottom'}>
                                    <div>
                                        <Badge count={count}>
                                            <BellOutlined className="nav-icon mx-auto" style={{fontSize: 20}} />
                                        </Badge>
                                    </div>

                                </Tooltip>
                            ) : (
                                <Tooltip title={"Žádná nová upozornění"} placement={'bottom'}>
                                    <div>
                                        <Badge count={0} showZero style={{backgroundColor: '#52c41a'}}>
                                            <BellOutlined className="nav-icon mx-auto" style={{fontSize: 20}} />
                                        </Badge>
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
            </Dropdown>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {loaded, loading} = state.notifications
    return {
        countUnread: (): number => selectors.notifications.countUnread(state),
        loaded,
        loading
    }
}

const mapDispatchToProps = {
    load: actions.notifications.load
}

export default connect(mapStateToProps, mapDispatchToProps)(NavNotification);
