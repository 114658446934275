import React from "react";
import {Form} from "antd";
import WidgetTool from "../../widget/WidgetTool";
import IWidgetProps from "../../../../../model/interface/widget/IWidgetProps";
import IFormElementFunctions from "../../../../../model/interface/form/IFormElementFunctions";
import IActionOptions from "../../../../../model/interface/widget/option/IActionOptions";
import ActionButton from "../../../action/ActionButton";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import {IActionResult} from "../../../../../model/service/dataStorage/ActionsService";
import DataStorageHelper from "../../../../../utils/DataStorageHelper";

interface IProps extends IWidgetProps<IFormElementFunctions, IActionOptions> {
    extractRouteParametersFromUrl: (url: string) => null | { id: number, parameters: { [name: string]: any } },
}

class FormElementAction extends React.Component<IProps> {

    renderAction = (options: IActionOptions) => {
        const {extractRouteParametersFromUrl, history, functions, id} = this.props
        const action = functions.getNode(id).action
        if (!action) {
            throw new Error('Undefined action')
        }
        const resource = functions.getResource()
        const formRef = functions.getFormRef()

        const formValues: any = DataStorageHelper.buildFormData({
            ...formRef.current?.getFieldsValue(),
            uuid: resource.uuid
        }, functions.getContentType())

        return (
            <ActionButton
                resources={[resource]}
                onClick={(executeAction: () => Promise<void>) => this.execute(executeAction)}
                action={action}
                options={options}
                payload={{
                    data: formValues,
                    route: extractRouteParametersFromUrl(history.location.pathname)
            }}
                onFinish={this.onFinish}
            />
        )
    }

    onFinish = (result?: IActionResult): Promise<void> => {
        return (result && this.props.functions.onActionFinish?.(result)) || Promise.resolve()
    }

    execute(executeAction: () => Promise<void>): Promise<void> {
        const {functions} = this.props
        return functions.onActionStart?.().then(() => {
            return executeAction().catch(() => {
                this.props.functions.onActionFinish?.()
                return Promise.reject()
            })
        }) || new Promise(resolve => resolve())
    }

    render() {
        const {options} = this.props

        return (
            <div>
                <WidgetTool {...this.props} edit={true}/>
                <Form.Item>
                    {this.renderAction(options)}
                </Form.Item>
            </div>
        );
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        extractRouteParametersFromUrl: (url: string) => selectors.routes.extractRouteParametersFromUrl(store, url)
    }
}

export default connect(mapStateToProps)(FormElementAction)