import {
    SortableContainer as sortableContainer,
    SortableElement as sortableElement,
    SortableHandle as sortableHandle
} from 'react-sortable-hoc';
import React from "react";

export type DragSortListItem<T = any> = {
    render: (item: T, index: number, handle: JSX.Element) => JSX.Element
    className?: string,
    style?: React.CSSProperties
}

export type DragSortListHandle<T = any> = {
    render: (item: T, index: number) => JSX.Element
    className?: string,
    style?: React.CSSProperties
}

interface ISharedProps {
    handle?: DragSortListHandle
}

interface IProps extends ISharedProps {
    children: any[],
    item: DragSortListItem
}

const DragSortList = sortableContainer(({children, item, handle}: IProps) => {
    return (
        <div className={"position-relative"}>
            {children.map((value, index) => {
                return (
                    <SortableItem key={index} index={index} data={value} item={item} handle={handle}/>
                )
            })}
        </div>
    )
})

interface IElementProps extends ISharedProps {
    data: any,
    index: number,
    item: DragSortListItem
}


const SortableItem = sortableElement(({item, data, index, handle}: IElementProps) => {
    return (
        <div className={item.className || ''}
             style={{backgroundColor: 'white', cursor: 'move', ...item?.style}}>
            {item.render(data, index, <DragHandle handle={handle} data={data} index={index}/>)}
        </div>
    )
})

interface IHandleProps extends ISharedProps {
    data: any,
    index: number
}

const DragHandle = sortableHandle(({handle, data, index}: IHandleProps) => {
    return (
        <div className={handle?.className || ''}
             style={{backgroundColor: 'white', cursor: 'move', ...handle?.style}}>
            {handle?.render(data, index)}
        </div>
    )
})

export default DragSortList


