import React from 'react'
import {connect, RootStateOrAny} from "react-redux";
import ISettings from "../../model/interface/ui/ISettings";
import {Divider} from "antd";

interface IProps {
	settings: ISettings,
}
interface IState {}

class Footer extends React.Component<IProps, IState> {
	render() {
		const {ui, version} = this.props.settings
		const {supportPhone, supportMail, footerText} = ui
		return (
			<footer className="footer">
				<span>
					{footerText && (<>{footerText} <Divider type={"vertical"} /></>)}
					<span className="font-weight-semibold">ProCES</span> verze {version}
				</span>
				{(supportMail || supportPhone) && (
					<div>
						<span className={"mr-2"}>Kontakt na podporu:</span>
						{supportPhone && (
							<a className="text-gray" href={"tel:"+supportPhone.replace(/\s/g,'')}>{supportPhone}</a>
						)}
						{supportPhone && supportMail && <Divider type={"vertical"} />}
						{supportMail && (
							<a className="text-gray" href={"mailto:"+supportMail}>{supportMail}</a>
						)}
						{/*<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Term & Conditions</a>*/}
						{/*<span className="mx-2 text-muted"> | </span>*/}
						{/*<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Privacy & Policy</a>*/}
					</div>
				)}
			</footer>
		);
	}
}

const mapStateToProps = (state: RootStateOrAny) => {
	return {
	}
}


const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)