import axios from 'axios'
import {env} from 'configs/EnvironmentConfig'
import RestService from "./dataStorage/RestService";
import ISettings from "../interface/ui/ISettings";
import store from "../../redux/store";
import IFile from "../interface/file/IFile";
import IRoute from "../interface/dataStorage/IRoute";

type Module = 'ui' | 'timeTracker' | 'ui-theme' | 'company' | 'security-additional-auth' | string

const PUBLIC_COLLECTION = 'settings';
const PRIVATE_COLLECTION = 'private-settings';

const settingsService = {
    getPublic: (latest = true): Promise<ISettings> => {
        const cachedSettingsRaw = localStorage.getItem('settings')
        if (cachedSettingsRaw && !latest) {
            const cachedSettings = JSON.parse(cachedSettingsRaw) as ISettings
            if (cachedSettings && cachedSettings.expire! > Date.now()) {
                return new Promise(resolve => {
                    return resolve(cachedSettings)
                })
            }
        }
        return axios.get(env.API_ENDPOINT_URL + '/' + PUBLIC_COLLECTION).then(response => {
            const settings = response.data as ISettings
            settings.expire = Date.now() + 24 * 3600 * 1000 // add one day
            try {
                localStorage.setItem('settings', JSON.stringify(settings))
            } catch (e) {
                console.error('Unable to write to local storage', e)
            }
            return settings
        })
    },
    getPrivate: (): Promise<ISettings> => {
        return RestService.collectionList(PRIVATE_COLLECTION).then(response => {
            return response as ISettings
        })
    },
    update: (data: ISettings) => {
        return RestService.resourceUpdate(PRIVATE_COLLECTION, 1, data).then(settings => {
            settings.expire = Date.now() + 24 * 3600 * 1000 // add one day
            try {
                localStorage.setItem('settings', JSON.stringify(settings))
            } catch (e) {
                console.error('Unable to write to local storage', e)
            }
            return settings
        })
    },
    isEnabled(module: Module, name: string, customSettings?: ISettings, defaultValue = false): boolean {
        const settings = customSettings || store.getState().setup.settings
        if(typeof settings[module] !== 'undefined') {
            if(settings[module].hasOwnProperty(name)) {
                const value = settings[module][name]
                if(value === true || value === 'true') {
                    return true
                }
                if(value === false || value === 'false') {
                    return false
                }
            }
        }
        return defaultValue || false
    },
    getValue(module: Module, name: string, customSettings?: ISettings): string|IFile|IRoute|undefined|any {
        const settings = customSettings || store.getState().setup.settings
        if(typeof settings[module] !== 'undefined') {
            if(settings[module].hasOwnProperty(name)) {
                const value = settings[module][name]
                if(typeof value === 'undefined') {
                    return value
                }
                return value || ''
            }
        }
        return undefined
    },
    getThemeColor(): string|undefined {
        return settingsService.getValue('ui-theme', 'theme')
    }
}


export default settingsService