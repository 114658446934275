import React, {RefObject} from "react"
import _ from "underscore"
import {Button, Form, FormInstance, Input, Switch, Typography} from "antd";
import {
    VIEW_TYPE_CALENDAR,
    VIEW_TYPE_GRAPH,
    VIEW_TYPE_TABLE,
    VIEW_TYPE_TIMELINE
} from "../../../../../model/service/dataStorage/ViewTypesService";
import ViewTimelineProperties from "../properties/ViewTimelineProperties";
import ViewGraphProperties from "../properties/ViewGraphProperties";
import {IViewStepProps} from "./ViewUnitForm";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../../redux/reducers/Setup";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import ViewTableProperties from "../properties/ViewTableProperties";
import IViewUnit from "../../../../../model/interface/dataStorage/IViewUnit";
import {
    DOWNLOAD_REQUEST_FORMAT
} from "../../../../../model/service/export/DownloadRequestsService";
import IViewOptions from "../../../../../model/interface/dataStorage/view/IViewOptions";
import ViewCalendarProperties from "../properties/ViewCalendarProperties";
import AggregationProperties from "../properties/AggregationProperties";


export const getNestedFieldOptions = (field: string) => ['options', field]

interface IState {
    formRef: RefObject<FormInstance>,
    values: IViewUnit
}

interface IProps extends IViewStepProps {
    contentTypes: IContentType[]

}

class ViewUnitProperties extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            values: _.extend(props.resource, {})
        }
    }

    setValue = (value: any, values: any) => {
        this.setState(state => ({values: {...state.values, ...values}}), () => this.props.onChange(values))
    }

    getViewOptionsConfigurationComponent(type: string, values: IViewUnit) {
        const {contentTypes, resource} = this.props
        const {formRef} = this.state
        const contentType = contentTypes.find(type => type.uuid === resource.contentTypes[0])
        switch (type) {
            case VIEW_TYPE_TABLE:
                return contentType &&
                    <ViewTableProperties formRef={formRef} contentType={contentType} values={values.options}/>
            case VIEW_TYPE_GRAPH:
                return contentType && <ViewGraphProperties contentType={contentType} values={values.options}/>
            case VIEW_TYPE_TIMELINE:
                return contentType && <ViewTimelineProperties contentType={contentType}/>
            case VIEW_TYPE_CALENDAR:
                return contentType && <ViewCalendarProperties resource={resource} contentTypes={contentTypes}/>
            default:
                return null
        }
    }

    confirm(back?: boolean) {
        this.state.formRef.current?.validateFields().then(values => {
            this.props.onChange(values)
            this.props.setStep(back)
        })
    }

    render() {
        const {resource, contentTypes} = this.props
        const {formRef, values} = this.state

        const formValues = {
            ...values,
            options: {
                exportInterval: 3,
                allowExport: true,
                allowSettings: true,
                exportIncludeHeader: true,
                saveInlineEditingGloballyWarningText: 'Pozor, máte neuložené změny!',
                saveInlineEditingGloballyWarning: true,
                exportFormats: [DOWNLOAD_REQUEST_FORMAT.XLSX],
                timelineCards: values.options.timelineCards || (values.options.timelineCard ? [values.options.timelineCard] : []),
                ...values.options
            } as IViewOptions,
        }

        const optionsConfigurationComponent = this.getViewOptionsConfigurationComponent(resource.type, formValues)
        const contentType = contentTypes.find(type => type.uuid === resource.contentTypes[0])

        return (
            <>
                <Form ref={formRef} initialValues={formValues} onValuesChange={this.setValue}>
                    <Typography.Title level={3}>Obecné</Typography.Title>
                    <Form.Item label={"Titulek"} name={"label"} rules={[{required: true, message: 'Vyplňte titulek'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        valuePropName={'checked'}
                        label={"Povolit uživatelské nastavení"}
                        name={getNestedFieldOptions('allowSettings')}
                    >
                        <Switch/>
                    </Form.Item>
                    {optionsConfigurationComponent}
                    {contentType && <AggregationProperties contentType={contentType} values={formValues.options}
                                                           formRef={formRef}/>}
                </Form>
                <Button type={"primary"} onClick={() => this.confirm()}>
                    Následující krok
                </Button>
                <Button onClick={() => this.confirm(true)}>Předchozí krok</Button>
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {contentTypes} = state.setup as ISetupState

    return {
        contentTypes
    }
}

export default connect(mapStateToProps)(ViewUnitProperties)