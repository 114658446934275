import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IOrganigrammeNode from "../../interface/company/IOrganigrammeNode";
import _ from "underscore"
import {API_FILTER_TYPE} from "../../constants/ApiConstant";
import IRestServiceChoiceListResponse, {
    IRestServiceChoiceList
} from "../../interface/api/IRestServiceChoiceListResponse";

interface IRestCompanyStructureServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IOrganigrammeNode>
}

export type IRestCompanyStructureAccept = "company" | "employee" | "unit" | "job" | "node"

interface ICompanyStructureService {
    collectionList(options?: IRestServiceOptions): Promise<IRestCompanyStructureServiceCollectionResponse>,

    loadTree(options?: IRestServiceOptions): Promise<IRestCompanyStructureServiceCollectionResponse>,

    loadByPath(path: any): Promise<IOrganigrammeNode>,

    getData(collection: string, options?: IRestServiceOptions): Promise<IRestCompanyStructureServiceCollectionResponse>,

    loadById(id: number): Promise<IOrganigrammeNode | undefined>,

    loadByParentId(id: number | string | number[] | string[]): Promise<Array<IOrganigrammeNode>>,

    loadByChildId(id: number | string): Promise<Array<IOrganigrammeNode>>,

    getAllChildren(parent: IOrganigrammeNode): Promise<Array<IOrganigrammeNode>>,

    walkTree(items: Array<IOrganigrammeNode>, callback: Function): void,

    collectionCreate(data: any): Promise<IOrganigrammeNode>,

    resourceUpdate(id: number | string, data: any): Promise<IOrganigrammeNode>,

    resourceDelete(id: number | string): Promise<void>,

    loadAllChildrenById(id?: number | string | number[] | string[]): Promise<Array<IOrganigrammeNode>>,

    loadAllStructureChoicesById(id?: number | string | number[] | string[], accept?: IRestCompanyStructureAccept, leafId?: boolean, options?: IRestServiceOptions): Promise<IRestServiceChoiceList>,
}

const CompanyStructureService: ICompanyStructureService = {
    loadTree: function (options) {
        const params = {
            limit: 0,
            filters: {
                0: {
                    field: 'lvl',
                    type: API_FILTER_TYPE.EQUAL,
                    value: '0'
                },
            },
            ...options
        }
        return CompanyStructureService.getData('organigramme', params);
    },
    loadByPath: function (path: Array<number>) {
        return CompanyStructureService.getData('organigramme').then(data => {
            return new Promise((resolve, reject) => {
                let items = data.results
                let item: IOrganigrammeNode | undefined;
                path.forEach(id => {
                    if (items) {
                        item = _.findWhere(items, {id: id})
                        if (item) {
                            items = item.children
                        } else {
                            items = []
                        }
                    }
                })
                if (item) {
                    resolve(item)
                } else {
                    reject(item)
                }
            });
        })
    },
    getData: function (collection: string, options?: any) {
        if (!options) {
            options = {}
        }
        options.cache = false
        return RestService.collectionList(collection, options) as Promise<IRestCompanyStructureServiceCollectionResponse>
    },
    loadById: function (id: number) {
        const params = {
            limit: 0,
            filters: {
                0: {
                    field: 'id',
                    type: API_FILTER_TYPE.EQUAL,
                    value: id.toString()
                },
            },
        }
        return CompanyStructureService.getData('organigramme', params).then(data => {
            let result: IOrganigrammeNode | undefined;
            if (data.results !== undefined) {
                CompanyStructureService.walkTree(data.results, (item: IOrganigrammeNode) => {
                    if (item.id === id) {
                        result = item
                        return false
                    }
                })
                return result
            }
        })
    },
    loadByParentId: function (parentId: number | string | number[] | string[]) {
        const params = {
            limit: 0,
            filters: {
                0: {
                    field: 'parent',
                    type: typeof parentId === 'object' ? API_FILTER_TYPE.IN : API_FILTER_TYPE.EQUAL,
                    value: parentId
                },
            },
        }
        return CompanyStructureService.getData('organigramme', params).then(data => {
            if (data.results !== undefined) {
                return data.results;
            } else {
                return [];
            }
        })
    },
    loadByChildId: function (childId: number) {
        return CompanyStructureService.getData('organigramme-action/get-parent-tree/' + childId).then(data => {
            if (data.results !== undefined) {
                return data.results;
            } else {
                return [];
            }
        })
    },
    getAllChildren: function (parent: IOrganigrammeNode) {
        return new Promise(resolve => {
            let output: Array<IOrganigrammeNode> = []
            CompanyStructureService.walkTree(parent.children, (node: IOrganigrammeNode) => {
                output.push(node)
            })
            resolve(output)
        })
    },
    loadAllChildrenById: function (id?: number | string | number[] | string[]) {
        if (!id) {
            return CompanyStructureService.collectionList({limit: 0}).then(data => {
                return data.results || []
            })
        }
        const ids = typeof id === 'object' ? id : [id]
        let promises = [] as Promise<Array<IOrganigrammeNode>>[]
        ids.forEach(_id => {
            promises.push(CompanyStructureService.getData('organigramme-action/get-all-children/' + _id).then(data => {
                return data.results || []
            }))
        })
        return Promise.all(promises).then(results => {
            let output = [] as Array<IOrganigrammeNode>
            results.forEach(result => {
                output = output.concat(result)
            })
            return output
        })
    },
    loadAllStructureChoicesById: function (id, accept?: IRestCompanyStructureAccept, leafId = false,options?: IRestServiceOptions) {
        if (!id) {
            return RestService.collectionList('organigramme-action/get-all-structure-choices' + (id ? '/' + id : ''), {
                ...options,
                accept: accept === 'node' ? '' : accept, ...(leafId ? {leafId} : {})
            })
                .then(response => {
                    return response as unknown as IRestServiceChoiceListResponse
                })
                .then(data => {
                    return data.results
                })
        }
        const ids = typeof id === 'object' ? id : [id]
        let promises = [] as Promise<IRestServiceChoiceList>[]
        ids.forEach(_id => {
            promises.push(RestService.collectionList('organigramme-action/get-all-structure-choices/' + _id, {
                accept,
                ...options?.contentTypePresenter,
                ...(leafId ? {leafId} : {})
            })
                .then(response => {
                    return response as unknown as IRestServiceChoiceListResponse
                })
                .then(data => {
                    return data.results || {}
                }))
        })
        return Promise.all(promises).then(results => {
            let output: IRestServiceChoiceList = {}
            results.forEach(result => {
                output = {...output, ...result}
            })
            return output
        })
    },
    walkTree: function (items: Array<IOrganigrammeNode>, callback) {
        if (items.filter((node: IOrganigrammeNode) => {
            return node.id !== undefined;
        }).length === 0) {
            return [];
        }
        items.forEach(item => {
            const result = callback(item)
            if (result === false) {
                return false
            }
            if (item.children !== undefined) {
                CompanyStructureService.walkTree(item.children, callback)
            }
        })
    },
    collectionCreate: function (data: any) {
        if (data.parent === undefined) {
            data.parent = null
        }
        return RestService.collectionCreate('organigramme-action/create-root', data) as Promise<IOrganigrammeNode>
    },
    resourceUpdate: function (id: number | string, data: any) {
        if (data.parent === undefined) {
            data.parent = null
        }
        return RestService.resourceUpdate('organigramme', id, data) as Promise<IOrganigrammeNode>
    },
    resourceDelete: function (id: number | string) {
        return RestService.resourceDelete('organigramme', id) as Promise<void>
    },
    collectionList(options?: IRestServiceOptions): Promise<IRestCompanyStructureServiceCollectionResponse> {
        return RestService.collectionList('organigramme', options as unknown as IRestServiceOptions) as Promise<IRestCompanyStructureServiceCollectionResponse>
    }
}

export default CompanyStructureService
