import React, {RefObject} from "react";
import {Card, Col, Divider, Dropdown, FormInstance, Menu, Row, Spin, Tooltip, Typography} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined, ShareAltOutlined, UserOutlined} from "@ant-design/icons";
import Button from "../../shared/button/Button";
import IInput from "../../../model/interface/symfony-console/IInput";
import IResult from "../../../model/interface/symfony-console/IResult";
import SavedCommandService from "../../../model/service/symfony-console/SavedCommandService";
import ISavedCommand from "../../../model/interface/symfony-console/ISavedCommand";
import SavedCommandForm from "./SavedCommandForm";
import IconBuilder from "../../../utils/IconBuilder";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../redux/reducers/Setup";
import ICommand from "../../../model/interface/symfony-console/ICommand";
import IUser from "../../../model/interface/security/IUser";
import Utils from "../../../utils";

interface IProps {
    run: (command: string, optionsInput?: IInput, argumentsInput?: IInput) => Promise<IResult | void>
    command?: string,
    optionsInput?: IInput,
    argumentsInput?: IInput,
    commands: ICommand[],
    user?: IUser
}

interface IState {
    loading: boolean
    running?: string,
    savedCommands?: ISavedCommand[],
    saveCommand?: ISavedCommand | any
}

class SavedCommands extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loading: true
        }
    }

    load() {
        const {user} = this.props
        if (!user){
            return this.setState({loading: false})
        }
        SavedCommandService.collectionList({
            filters: {
                load: {
                    type: API_FILTER_TYPE.OR,
                    children: {
                        personal: {field: 'user', type: API_FILTER_TYPE.EQUAL, value: user.uuid},
                        global: {field: 'user', type: API_FILTER_TYPE.IS_NULL}
                    }
                }
            }
        }).then(({results}) => {
            this.setState({savedCommands: results, loading: false})
        })
    }

    componentDidMount() {
        this.load()
    }

    setSaveCommand = (savedCommand?: ISavedCommand) => {
        const {command, optionsInput, argumentsInput} = this.props
        this.setState(state => ({
            saveCommand: state.saveCommand ? undefined : (savedCommand || {
                command: command,
                options: optionsInput,
                arguments: argumentsInput,
                label: command
            })
        }))
    }

    run = (savedCommand: ISavedCommand) => {
        const {run} = this.props
        this.setState({running: savedCommand.uuid})
        run(savedCommand.command, savedCommand.options, savedCommand.arguments).then(() => {
            this.setState({running: undefined})
        })
    }

    deleteSavedCommand = (command: ISavedCommand) => {
        command.uuid && SavedCommandService.resourceDelete(command.uuid)
        this.setState(state => ({
            savedCommands: state.savedCommands?.filter(c => c.uuid !== command.uuid)
        }))
    }

    addSavedCommand = (command: ISavedCommand) => {
        this.setState(state => ({
            savedCommands: Utils.arrayAddOrUpdateWhere(state.savedCommands, {uuid: command.uuid}, command),
            saveCommand: undefined
        }))
    }

    render() {
        const {savedCommands, saveCommand, loading} = this.state
        const {commands, user} = this.props

        const personalCommands = savedCommands?.filter(c => c.personal) || []
        const sharedCommands = savedCommands?.filter(c => !c.personal) || []

        return user && (
            <Card size={"small"} className={'bg-gray-lightest'} bodyStyle={{padding: 0}}>
                <Row gutter={[12, 12]} align={"middle"} justify={"space-between"} className={'py-1 pl-3 pr-1'}>
                    <Col>
                        {personalCommands.length + sharedCommands.length === 0 && (
                            loading ? <Spin size={"small"}/> :
                                <Typography.Text disabled>žádné rychlé příkazy</Typography.Text>
                        )}
                        <Row gutter={[12, 12]} align={"middle"}>
                            {sharedCommands?.length > 0 && (
                                <Tooltip title={'Sdílené rychlé příkazy'}>
                                    <Col>
                                        <ShareAltOutlined/>
                                        <Divider type={'vertical'}/>
                                    </Col>
                                </Tooltip>
                            )}
                            {savedCommands?.filter(c => !c.personal).map(savedCommand => this.buildSavedCommand(savedCommand))}
                            {personalCommands?.length > 0 && (
                                <Tooltip title={'Vaše rychlé příkazy'}>
                                    <Col>
                                        <UserOutlined className={sharedCommands?.length > 0 ? 'ml-2' : ''}/>
                                        <Divider type={'vertical'}/>
                                    </Col>
                                </Tooltip>
                            )}
                            {personalCommands?.filter(c => c.personal).map(savedCommand => this.buildSavedCommand(savedCommand))}
                            {saveCommand &&
                                <SavedCommandForm onClose={this.setSaveCommand} onSave={this.addSavedCommand}
                                                  modal={true}
                                                  commands={commands} resource={saveCommand}/>
                            }
                        </Row>
                    </Col>
                    <Col>
                        <Tooltip title={'Přidat rychlý příkaz'}>
                            <Button className={'ml-2'} onClick={() => this.setSaveCommand()} icon={<PlusOutlined/>}
                                    type={"default"} size={"small"}/>
                        </Tooltip>
                    </Col>
                </Row>
            </Card>
        )
    }

    buildSavedCommand(savedCommand: ISavedCommand) {
        const {running} = this.state
        return <Col>
            <Dropdown overlay={
                <Menu>
                    <Menu.Item key="1" icon={<EditOutlined/>} onClick={() => this.setSaveCommand(savedCommand)}>
                        Upravit
                    </Menu.Item>
                    <Menu.Item className={'text-danger'} key="2" icon={<DeleteOutlined/>}
                               onClick={() => this.deleteSavedCommand(savedCommand)}>
                        Smazat
                    </Menu.Item>
                </Menu>
            } trigger={['contextMenu']}>
                <Tooltip title={savedCommand.finalInput}>
                    <Button
                        size={"small"}
                        onClick={() => this.run(savedCommand)}
                        icon={savedCommand.icon && IconBuilder(savedCommand.icon)}
                        loading={running === savedCommand.uuid}
                        type={savedCommand.buttonType}>
                        {savedCommand.label}
                    </Button>
                </Tooltip>
            </Dropdown>
        </Col>
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        user
    }
}

export default connect(mapStateToProps)(SavedCommands)