import React from "react";
import {Button, Col, Empty, Row} from "antd";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IColumnOptions from "../../../../model/interface/widget/option/IColumnOptions";
import WidgetType from "./WidgetType";
import Widget from "./Widget";
import IWidget from "../../../../model/interface/widget/IWidget";
import WidgetTool from "./WidgetTool";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";

class ColumnsWidget extends React.Component<IWidgetProps<IWidgetPropsFunctions<IWidget<IColumnOptions>>, IColumnOptions>> {
    render() {
        const {id, functions, preview} = this.props
        const children = functions.getSortedChildren(id)

        return (
            <div>
                <WidgetTool {...this.props} functions={{
                    ...functions, appendNodeExecute: (id) => functions.appendNodeExecute(id, WidgetType.COLUMN),
                }}  execute={true}/>
                {children.length > 0 ? (
                    <Row gutter={[8, 8]}>
                        {functions.getSortedChildren(id).map(child => {
                            return (
                                <Col key={child.id} sm={child.options.width ? Number(child.options.width) : 24}>
                                    <Widget
                                        {...this.props}
                                        {...child}
                                        id={child.id}
                                        key={child.id}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                ) : !preview && (
                    <Empty description={
                        <Button type={"link"} onClick={() => functions.appendNodeExecute(id, WidgetType.COLUMN)}>
                            Přidat první sloupec
                        </Button>
                    }/>
                )}
            </div>
        );
    }
}

export default ColumnsWidget