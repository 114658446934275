import {Card, Form, Slider} from 'antd';
import React from "react";
import IconPicker from "../../../../../../shared/IconPicker";
import IFieldOptions from "../../../../../../../model/interface/form/elementOptions/IFieldOptions";

export interface IRateEditorOptions {
    rateIcon?: string
    rateLength?: number
}

export interface IProps {
    options: IFieldOptions
}

class FormFieldRateEditor extends React.Component<IProps> {

    render() {
        return (
            <Card>
                <Form.Item name={'rateLength'} label={'Zvolte počet bodů'} rules={[{required: true}]}>
                   <Slider min={1} max={10}/>
                </Form.Item>
                <Form.Item name={'rateIcon'} label={'Zvolte iconu'}>
                    <IconPicker/>
                </Form.Item>
            </Card>
        )
    }
}

export default FormFieldRateEditor