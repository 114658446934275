import {Table, TableProps} from 'antd';
import {DndProvider, DragSource, DropTarget, DropTargetMonitor} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import React, {HTMLAttributes} from "react";
import update from "immutability-helper";

let dragingIndex = -1;

class BodyRow extends React.Component<any> {
    render() {
        const {isOver, connectDragSource, connectDropTarget, moveRow, ...restProps} = this.props;
        const style = {...restProps.style, cursor: 'move'};

        let {className} = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style}/>),
        );
    }
}

const rowSource = {
    beginDrag(props: any) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props: any, monitor: DropTargetMonitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        if (dragIndex === hoverIndex) {
            return;
        }

        props.moveRow(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);


interface DragSortingTableProps extends TableProps<any> {
    onMove: (data: Array<any>) => void,
    indexColumn?: string
}


interface IStateDragSortingTable {
    indexColumn?: string
}

class DragSortingTable extends React.Component<DragSortingTableProps, IStateDragSortingTable> {

    constructor(props: DragSortingTableProps) {
        super(props);
        this.state = {
            indexColumn: this.props.indexColumn
        }
    }

    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    moveRow = (dragIndex: number, hoverIndex: number) => {
        const data = [...(this.props.dataSource || [])]
        const indexColumn = this.state.indexColumn;
        let sortedData = update(data, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, data[dragIndex]]
            ]
        })
        if (indexColumn && sortedData[dragIndex][indexColumn]) {
            sortedData.forEach((value, index) => {
                sortedData[index][indexColumn] = sortedData.length - index
            })
        }
        this.props.onMove(sortedData)
    };

    render() {
        const {dataSource} = this.props

        return (
            <DndProvider backend={HTML5Backend}>
                <Table
                    {...this.props}
                    columns={this.props.columns}
                    dataSource={[...(dataSource || [])]}
                    components={this.components}
                    onRow={(record, index) => ({
                        index,
                        moveRow: this.moveRow,
                    } as HTMLAttributes<Element>)}
                />
            </DndProvider>
        );
    }
}

export default DragSortingTable