import IWidget from "./IWidget";
import IRestResource from "../api/IRestResource";
import IRestServiceFilters from "../api/IRestServiceFilters";

export const FORM_RESERVED_NAMES = {
    EMPLOYEE_FORM: '#employee-form',
    TIME_TRACKER: '#time-tracker'
}
export default interface IForm extends IRestResource {
    contentType: string,
    name: string,
    label: string
    widgets: IWidget[],
    actions?: string[]
    priority?: number
    conditions?: IRestServiceFilters
}