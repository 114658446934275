import {Card} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IBaseProps from "../../../../../../model/interface/IBaseProps";
import {IFieldStructureOptions} from "./FieldStructureOptionsEditor";

export interface IFieldJobOptions extends IFieldStructureOptions{

}

interface IProps extends IFieldOptionsEditorProps, IBaseProps {

}

interface IState {

}

class FieldJobOptionEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    static getDefaultOptions(): IFieldJobOptions {
        return {
            companyStructureAccepts: 'job'
        }
    }

    render() {
        return (
            <Card>

            </Card>
        )
    }
}

export default FieldJobOptionEditor