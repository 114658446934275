import React, {RefObject} from "react";
import {Button, Form, FormInstance, Modal, Select, Typography} from "antd";
import IReportWidgetImage from "../../../../../../model/interface/report/IReportWidgetImage";
import IWidgetOptions from "../../../../../../model/interface/widget/IWidgetOptions";
import IReportWidgetView from "../../../../../../model/interface/report/IReportWidgetView";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../../../redux/reducers/Setup";
import IView from "../../../../../../model/interface/dataStorage/IView";
import viewTypesService from "../../../../../../model/service/dataStorage/ViewTypesService";
import IViewType from "../../../../../../model/interface/dataStorage/view/IViewType";
import selectors from "../../../../../../redux/selectors";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import Utils from "../../../../../../utils";

interface IProps {
    options: IReportWidgetView
    onFinish: (values?: IWidgetOptions) => void
    views: IView[]
    findContentType: (uuid: string) => IContentType
}

interface IState extends IReportWidgetView {
    viewTypes: IViewType[],
}

class ViewWidgetEditor extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options,
            view: props.options.view ? +props.options.view : undefined as any,
            viewTypes: [],
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    componentDidMount() {
        viewTypesService.collectionList().then(types => this.setState({viewTypes: types}))
    }

    onOk = () => {
        this.formRef.current?.validateFields().then((values: IReportWidgetImage) => {
            this.props.onFinish({...values})
        })
    }

    onTypeChange = (type: string) => {
        this.setState({viewType: type},
            () => this.formRef.current?.setFieldsValue({viewType: type, view: undefined})
        )
    }

    onCancel = () => {
        this.props.onFinish()
    }

    render() {
        const {views, findContentType} = this.props
        const {viewTypes, viewType} = this.state

        return (
            <Modal
                className={'dir-ltr'}
                title={'Upravit pohled'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form initialValues={this.state} ref={this.formRef}>
                    {/*<Form.Item label={'Typ pohledu'} name={'viewType'}*/}
                    {/*           rules={[{required: true, message: 'Typ pohledu je vyžadován'}]}>*/}
                    {/*    <Select onChange={this.onTypeChange}>*/}
                    {/*        {viewTypes.map(type => (*/}
                    {/*            <Select.Option value={type.name}*/}
                    {/*                           key={type.name}>{type.icon} {type.label}</Select.Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}
                    {/*{viewType && (*/}
                    {/*    <Form.Item label={'Pohled'} name={'view'}*/}
                    {/*               rules={[{required: true, message: 'Pohled je vyžadován'}]}*/}
                    {/*               help={views.filter(view => view.type === viewType).length > 0 ? 'Vyberte pohled' : (*/}
                    {/*                   <Typography.Text type={"danger"}>*/}
                    {/*                       {'Neexistuje žádný pohled s typem [' + viewType + ']'}*/}
                    {/*                   </Typography.Text>)}>*/}
                    {/*        <Select>*/}
                    {/*            {views.filter(view => view.type === viewType).map(view => (*/}
                    {/*                <Select.Option*/}
                    {/*                    value={view.uuid}>{view.label} -*/}
                    {/*                    [{view.contentTypes.map(contentType => findContentType(contentType).label).join(', ')}]*/}
                    {/*                </Select.Option>*/}
                    {/*            ))}*/}
                    {/*        </Select>*/}
                    {/*    </Form.Item>*/}
                    {/*)}*/}
                    <Form.Item label={'Pohled'} name={'view'} rules={[{required: true, message: 'Pohled je vyžadován'}]}>
                        <Select showSearch  filterOption={(input, option) =>
                            Utils.stringContains(option?.props.children.join(), input)
                        }>
                            {views.map(view => (
                                <Select.Option
                                    value={view.uuid}>{view.label} -
                                    [{view.contentTypes.map(contentType => findContentType(contentType).label).join(', ')}]
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {views} = state.setup as ISetupState

    return {
        views,
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
    }
}

export default connect(mapStateToProps)(ViewWidgetEditor)
