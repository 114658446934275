import {Divider, Dropdown, Modal, Slider, Tooltip, Typography} from "antd";
import {
    TableOutlined,
    FontSizeOutlined,
    LineHeightOutlined,
    ColumnHeightOutlined
} from "@ant-design/icons";
import React from "react";
import {connect, RootStateOrAny} from "react-redux";
import IUser from "../../model/interface/security/IUser";
import Button from "../shared/button/Button";
import {onFontSizeChange, onMarginChange, onTableSizeChange} from "../../redux/actions/Theme";
import UsersService from "../../model/service/security/UsersService";
import {Spin} from "antd/es";
import {ITheme} from "../../model/interface/security/ITheme";

interface IProps {
    user: IUser
    margin: string
    fontSize: string
    tableSize: string
    onMarginChange: (margin: string) => void
    onFontSizeChange: (fontSize: string) => void
    onTableSizeChange: (fontSize: string) => void
}

interface IState {
    loading: boolean
    visible: boolean
    oldValues: ITheme | null
}

class NavConfiguration extends React.Component<IProps, IState> {
    state = {
        loading: false,
        visible: false,
        oldValues: null as null | ITheme
    }

    changeMargin(margin: string) {
        this.props.onMarginChange(margin)
    }

    changeFontSize(fontSize: string) {
        this.props.onFontSizeChange(fontSize)
    }

    changeTableSize(tableSize: string) {
        this.props.onTableSizeChange(tableSize)
    }

    save(): Promise<void> {
        const {fontSize, margin, tableSize, user} = this.props
        return Promise.resolve()
            .then(() => new Promise<void>(resolve => {
                this.setState({loading: true}, () => resolve())
            }))
            .then(() => UsersService.resourceUpdate(user.id!, {theme: {fontSize, margin, tableSize}}))
            .then(() => new Promise<void>(resolve => {
                this.setState({loading: false, visible: false, oldValues: null}, () => resolve())
            }))
    }

    open() {
        this.setState({visible: true})
    }

    close() {
        const {oldValues} = this.state
        const {margin, fontSize, tableSize} = this.props
        if (oldValues && (
            oldValues!.margin !== margin
            || oldValues!.fontSize !== fontSize
            || oldValues!.tableSize !== tableSize)) {
            Modal.confirm({
                content: 'Uložit změny?',
                onOk: () => this.save().then(() => {
                    this.setState({visible: false})
                }),
                onCancel: () => {
                    this.props.onMarginChange(oldValues.margin)
                    this.props.onFontSizeChange(oldValues.fontSize)
                    this.props.onTableSizeChange(oldValues.tableSize)
                    this.setState({visible: false})
                }
            })
        } else {
            this.setState({visible: false})
        }
    }

    onOpen() {
        const {margin, fontSize, tableSize} = this.props
        this.setState({oldValues: {margin, fontSize, tableSize}})
    }

    onClose() {

    }

    numberToSize(value: number): string {
        switch (value) {
            case(1):
                return 'xs';
            case(2):
                return 'sm';
            default:
            case(3):
                return 'md';
            case(4):
                return 'lg';
            case(5):
                return 'xl';
        }
    }

    sizeToNumber(size: string): number {
        switch (size) {
            case('xs'):
                return 1;
            case('sm'):
                return 2;
            default:
            case('md'):
                return 3;
            case('lg'):
                return 4;
            case('xl'):
                return 5;
        }
    }

    render() {
        const {margin, fontSize, tableSize} = this.props
        const {loading, visible} = this.state
        return (
            <Dropdown visible={visible}
                      placement="bottomCenter"
                      onVisibleChange={(visible: boolean) => visible ? this.onOpen() : this.onClose()}
                      overlay={(
                          <div className="nav-profile nav-dropdown p-2">
                              <Spin spinning={loading}>
                                  <Typography.Title level={3} className={"text-center"}>Přizpůsobit
                                      vzhled</Typography.Title>
                                  <div>
                                      <Divider><TableOutlined/> Rozložení tabulky</Divider>

                                      <Slider
                                          min={1}
                                          max={4}
                                          step={1}
                                          defaultValue={this.sizeToNumber(tableSize)}
                                          onChange={(value: number) => this.changeTableSize(this.numberToSize(value))}
                                      />
                                  </div>

                                  <div>
                                      <Divider><ColumnHeightOutlined/> Mezery</Divider>

                                      <Slider
                                          min={1}
                                          max={4}
                                          step={1}
                                          defaultValue={this.sizeToNumber(margin)}
                                          onChange={(value: number) => this.changeMargin(this.numberToSize(value))}
                                      />
                                  </div>

                                  <div>
                                      <Divider><LineHeightOutlined/> Velikost písma</Divider>

                                      <Slider
                                          step={1}
                                          min={1}
                                          max={5}
                                          defaultValue={this.sizeToNumber(fontSize)}
                                          onChange={(value: number) => this.changeFontSize(this.numberToSize(value))}
                                      />
                                  </div>

                                  <Button type={"primary"} onClick={() => this.save()}>Uložit</Button>
                                  <Button type={"default"} onClick={() => this.close()}>Zavřít</Button>
                              </Spin>
                          </div>
                      )} trigger={["click"]}>
                <Tooltip title={"Přizpůsobit vzhled"} placement={"bottom"}>
                    <div>
                        <FontSizeOutlined onClick={() => visible ? this.close() : this.open()}
                                          className="nav-icon mx-auto"
                                          style={{fontSize: 20}}/>
                    </div>
                </Tooltip>
            </Dropdown>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {margin, fontSize, tableSize} = state.theme
    const {user} = state.setup
    return {
        margin, fontSize, tableSize, user
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        onMarginChange: (margin: string) => dispatch(onMarginChange(margin)),
        onFontSizeChange: (fontSize: string) => dispatch(onFontSizeChange(fontSize)),
        onTableSizeChange: (tableSize: string) => dispatch(onTableSizeChange(tableSize)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavConfiguration)
