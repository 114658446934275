import WidgetGallery from "../../widget/WidgetGallery";
import {IWidgetList, IWidgetListItem} from "../../widget/WidgetList";
import IReport from "../../../../../model/interface/dataStorage/IReport";
import ReportWidgetType from "./ReportWidgetType";
import ReportWidgetList from "./ReportWidgetList";
import WidgetType from "../../widget/WidgetType";


interface Select {
    (type: string): void
}

interface Exists {
    (type: string): boolean
}

interface GroupItem {
    label: string
    widgets: IWidgetListItem[]
}

class ReportWidgetGallery extends WidgetGallery {

    protected report: IReport
    protected exists: Exists

    constructor(onSelect: Select, list: IWidgetList, report: IReport, exists: Exists) {
        super(onSelect, list);
        this.report = report
        this.exists = exists
    }

    protected getGroup(group: [string, GroupItem]) {
        return <>
            {group[1].widgets.map((widget, index) =>
                this.getNodeOptionTag(widget, index))}
            {group[0] === ReportWidgetType.GROUP_DYNAMIC && this.getCommentOption()}
        </>;
    }

    protected getCommentOption() {
        if (this.report.thread) {
            const widget = ReportWidgetList.getItemByType(WidgetType.COMMENT)
            return this.getNodeOptionTag(widget, 'comment-widget',
                () => this.onSelectItem(widget.type),
                this.exists(WidgetType.COMMENT)
            )
        }
        return ''
    }
}

export default ReportWidgetGallery