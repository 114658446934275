import React from "react"
import {Button, Col, DatePicker, Row, Select, Spin, Tooltip} from "antd";
import IRestResource from "model/interface/api/IRestResource";
import IRepositoryService from "model/interface/IRepositoryService";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import moment, {Moment} from "moment";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import DataStorageService from "../../../model/service/dataStorage/DataStorageService";
import _ from "underscore";
import IViewItem from "../../../model/interface/dataStorage/view/IViewItem";
import {IViewGraphSettingsInterval} from "../../../model/interface/dataStorage/view/graph/IViewGraphSettings";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import ViewCustomFilters, {DefaultCustomFilters} from "./ViewCustomFilters";
import IRestServiceFilters from "../../../model/interface/api/IRestServiceFilters";
import IUser from "../../../model/interface/security/IUser";
import {IBaseViewProps} from "./ViewUnit";
import ViewAction from "./ViewAction";
import DataStorageHelper from "../../../utils/DataStorageHelper";
import IField from "../../../model/interface/dataStorage/IField";
import IPresenter from "../../../model/interface/dataStorage/IPresenter";
import ReactDOMServer from "react-dom/server";
import ViewEditButton from "./ViewEditButton";
import ViewGraphService from "../../../model/service/dataStorage/view/ViewGraphService";
import IRestServiceOptions from "../../../model/interface/api/IRestServiceOptions";
import {API_AGGREGATION_TYPE, API_FILTER_TYPE, API_GROUP_BY_FORMAT} from "../../../model/constants/ApiConstant";
import {DATE_FORMAT_YYYY_MM_DD} from "../../../model/constants/DateConstant";
import {MomentBuilder} from "../../../utils/MomentBuilder";
import {StopOutlined} from "@ant-design/icons";
import PieChart from "./graph/PieChart";
import XYChart from "./graph/XYChart";
import {RefreshOutline} from "react-ionicons";
import ViewPersonalEditButton from "./ViewPersonalEditButton";
import {GraphXAxis} from "../../../model/interface/dataStorage/view/graph/IViewGraphOptions";

const {RangePicker} = DatePicker;

interface IChartCategory {
    label: string
    min?: any
    max?: any,
    resource: IRestResource,
    fieldName: string
}

export interface IChartDataSet {
    name: string,
    data: any
}

interface IState {
    results: Array<IRestResource>,
    total: number,
    loading: boolean,
    filters: IRestServiceFilters
    interval: IViewGraphSettingsInterval
    intervalMin?: Moment,
    intervalMax?: Moment
    intervalLength: number
    customFilters?: IRestServiceFilters
}

interface IProps extends IBaseViewProps {
    findOneByFullClassName: (fullClassName: string) => IRepositoryService
    findOneByContentType: (contentType: IContentType) => IRepositoryService,
    findContentTypeByUuid: (uuid: string) => IContentType
    user: IUser
}

class ViewGraph extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            results: [] as Array<IRestResource>,
            filters: {},
            loading: false,
            total: 0,
            customization: {
                items: null
            },
            customizationDraft: null,
            activeContentTypes: [] as number[],
            interval: "month",
            intervalLength: 12,
            customFilters: ViewCustomFilters.getDefault(props.settings.customFilters)[this.getContentType().fullClassName],
            ...this.presetState()
        }
    }

    componentDidMount() {
        this.load().then()
    }

    load = (): Promise<void> => {
        const {filters, viewUnit} = this.props
        const {customFilters, intervalMin, intervalMax, interval} = this.state
        const {graphXAxis, graphType, graphGroup, graphAggregation, graphXAxisField} = viewUnit.options
        const field = this.getField(graphXAxisField)
        this.setState({loading: true})
        const params: IRestServiceOptions = {
            filters: {...filters, ...customFilters},
            groupBy: {
                x: {
                    field: field.name, format: graphXAxis === ViewGraphService.X_AXIS_INTERVAL ? {
                        day: API_GROUP_BY_FORMAT.DATE_DAY,
                        week: API_GROUP_BY_FORMAT.DATE_WEEK,
                        month: API_GROUP_BY_FORMAT.DATE_MONTH,
                        quarter: API_GROUP_BY_FORMAT.DATE_QUARTER,
                        year: API_GROUP_BY_FORMAT.DATE_YEAR,
                    }[interval] : ''
                }
            },
            cache: false,
            limit: 0,
            view: viewUnit.view
        }

        switch (graphType) {
            case ViewGraphService.TYPE_PIE:
            case ViewGraphService.TYPE_DONUT:
                params.aggregations = {
                    ...params.aggregations,
                    pieCount: {field: 'id', type: API_AGGREGATION_TYPE.COUNT, groupBy: true}
                }
                break
            case ViewGraphService.TYPE_BAR:
            case ViewGraphService.TYPE_LINE:
                viewUnit.items.forEach(item => {
                    params.aggregations = {
                        ...params.aggregations,
                        aggregation: {
                            field: this.getField(item.field).name,
                            type: graphAggregation,
                            groupBy: true
                        }
                    }
                })
                if (graphGroup && this.getField(graphGroup)) {
                    params.groupBy = {
                        ...params.groupBy,
                        group: {
                            field: this.getField(graphGroup).name
                        }
                    }
                }
        }

        if (field && graphXAxis === ViewGraphService.X_AXIS_INTERVAL && intervalMax && intervalMin) {
            params.filters = {
                ...params.filters,
                from: {
                    field: field.name,
                    type: API_FILTER_TYPE.GREATER,
                    value: intervalMin.add(-1, 'day').format(DATE_FORMAT_YYYY_MM_DD)
                },
                to: {
                    field: field.name,
                    type: API_FILTER_TYPE.LESSER,
                    value: intervalMax.clone().add(1, 'day').format(DATE_FORMAT_YYYY_MM_DD)
                }
            }
        }

        return this.getService().collectionList(params).then(({count, results}: any) => {
            return new Promise(resolve => {
                this.setState({
                    results,
                    total: count,
                    loading: false
                }, resolve)
            })
        })
    }

    getService = (): DataStorageService => {
        return this.props.findOneByContentType(this.getContentType()) as DataStorageService
    }

    getContentType() {
        const {findContentTypeByUuid, viewUnit} = this.props
        return findContentTypeByUuid(viewUnit.contentTypes[0])
    }

    presetState() {
        const {settings} = this.props
        let previousState: any | IState = {}
        if (settings.graphIntervalLength) {
            previousState.intervalLength = settings.graphIntervalLength
        }
        if (settings.graphIntervals) {
            previousState.interval = settings.graphIntervals[0]
            previousState.intervalMax = moment().endOf(previousState.interval)
            previousState.intervalMin = moment().subtract(previousState.intervalLength - 1, previousState.interval)
                .startOf(previousState.interval)
        }
        return previousState
    }

    saveState() {
        const {saveState, state} = this.props
        let newState = {...state}
        saveState({...newState}).then()
    }

    doAction = (result?: IActionResult) => {
        if (result) {
            return this.refresh()
        }
        return Promise.resolve()
    }

    refresh = (): Promise<void> => {
        return new Promise(resolve => {
            this.setState({results: []}, () => resolve(this.load()))
        })
    }

    buildCategories = (): IChartCategory[] => {
        const categories: IChartCategory[] = []
        const {settings, viewUnit} = this.props
        const {interval, intervalLength, intervalMin, intervalMax, results} = this.state
        const {graphType, graphXAxis, graphXAxisInterval, graphXAxisField} = viewUnit.options

        switch (graphType) {
            case(ViewGraphService.TYPE_BAR):
            case(ViewGraphService.TYPE_LINE):
            case(ViewGraphService.TYPE_PIE):
            case(ViewGraphService.TYPE_DONUT):
                switch (graphXAxis) {
                    case(ViewGraphService.X_AXIS_INTERVAL):
                        const intervalList = ViewGraphService.intervalFormats()
                        const {delta, format} = intervalList[interval || graphXAxisInterval]

                        let min = intervalMin?.clone() || (intervalMax || moment())?.clone()
                            .add(1 - (settings.graphIntervalLength || intervalLength), delta)
                        let max = intervalMax?.clone() || intervalMin?.clone()
                            .add((settings.graphIntervalLength || intervalLength) - 1, delta) || moment()
                        if (!min || !max) {
                            break;
                        }
                        if (delta === "week" && (intervalMin || intervalMax) && min !== max) { // TODO Refactor maybe ?? tricky !!!...
                            max = max.endOf(delta).add(1, "d")
                            min = min.endOf(delta).add(1, "d")
                        }
                        let current = min.clone().startOf(delta)
                        while (current < max) {
                            categories.push({
                                label: current.format(format),
                                min: current.clone(),
                                max: current.clone().add(1, delta),
                                resource: current,
                                fieldName: this.getField(graphXAxisField).name
                            });
                            current.add(1, delta)
                        }
                        break
                    case(ViewGraphService.X_AXIS_FIELD):
                        const field = this.getField(graphXAxisField)
                        results.filter((value, index, self) => self.indexOf(value) === index)
                            .forEach(result => {
                                categories.push({
                                    label: ReactDOMServer.renderToString(<>{this.buildFieldValue(field, result)}</>),
                                    resource: result,
                                    fieldName: field.name
                                })
                            })
                        break
                    default:
                        throw new Error('Unsupported graphXAxis: ' + graphXAxis)
                }
                break;
            default:
                throw new Error('Unsupported graph type: ' + graphType)
        }

        return categories
    }

    getField(uuid: string) {
        const contentType = this.getService().getContentType()
        const field = _.findWhere(contentType.fields, {uuid: uuid})
        if (!field) {
            throw new Error('Field not found by id: ' + uuid)
        }
        return field
    }

    getPresenter(field: IField, item?: IViewItem) {
        const {viewUnit} = this.props
        const {graphGroupPresenter} = viewUnit.options
        let presenter: null | IPresenter = null
        if (field.mode === 'relation') {
            const service = this.props.findOneByFullClassName(field.targetEntity!)
            if (graphGroupPresenter) {
                presenter = service.getPresenter(graphGroupPresenter) || service.getDefaultPresenter()
            }
        }
        if (!presenter) {
            presenter = {
                type: 'label',
                name: 'default',
                label: 'Výchozí',
                options: {
                    label: "#" + field.name
                }
            } as IPresenter
        }
        presenter.options = {...item?.options?.fieldOptions, ...item?.options, ...presenter.options, ...field.options}
        return presenter
    }

    buildSeries = () => {
        let series: IChartDataSet[] = [] as IChartDataSet[]
        const {viewUnit} = this.props
        const {results, interval} = this.state
        const {graphGroup, graphType, graphXAxisField, graphXAxis} = viewUnit.options

        const contentType = this.getService().getContentType()
        const field = this.getField(graphXAxisField)
        const item = viewUnit.items.find(i => i.field === graphXAxisField)!
        switch (graphType) {
            case(ViewGraphService.TYPE_BAR):
            case(ViewGraphService.TYPE_LINE):
                viewUnit.items.filter(i => i.field !== graphGroup).forEach(item => {
                    const itemField = _.findWhere(contentType.fields, {uuid: item.field})!
                    if (graphGroup) {
                        const groupField = _.findWhere(contentType.fields, {uuid: graphGroup})!
                        const groupItem = viewUnit.items.find(i => i.field === graphGroup)!
                        results
                            .map(result => result[groupField.name])
                            .filter((value, index, self) => self.indexOf(value) === index)
                            .sort((a, b) => (a > b ? 1 : -1))
                            .forEach(graphGroupValue => {
                                series.push({
                                    data: this.buildBarData(field, itemField, {[groupField.name]: graphGroupValue}),
                                    name: ReactDOMServer.renderToString(<>
                                            {(itemField.label || itemField.name) + ' - '}
                                            {this.buildFieldValue(groupField, {[groupField.name]: graphGroupValue}, groupItem)}
                                        </>
                                    )
                                })
                            })
                    } else {
                        series.push({
                            data: this.buildBarData(field, itemField),
                            name: (field.label || field.name)
                        })
                    }
                })
                break;
            case(ViewGraphService.TYPE_PIE):
            case(ViewGraphService.TYPE_DONUT):
                series = this.buildPieSeries(graphXAxis, results, field, interval, item)
                break
            default:
                throw new Error('Unsupported graph type: ' + graphType)
        }
        return series.sort((a: IChartDataSet, b: IChartDataSet) => {
            return a.name > b.name ? -1 : 1
        })
    }

    buildPieSeries(graphXAxis: GraphXAxis, results: IRestResource[], field: IField, interval: IViewGraphSettingsInterval, item: IViewItem) {
        const series: IChartDataSet[] = [] as IChartDataSet[]
        this.buildCategories().forEach(category => {
            let result
            switch (graphXAxis) {
                case ViewGraphService.X_AXIS_FIELD:
                    result = category.resource
                    break
                case ViewGraphService.X_AXIS_INTERVAL:
                    result = results.find(r => {
                        const intervalValue = MomentBuilder.build(r[field.name])
                        return intervalValue && intervalValue.isBetween(category.min, category.max, interval, '[)')
                    })
                    break
            }
            series.push({
                data: result?.['id0'] || 0,
                name: ReactDOMServer.renderToString(<>{this.buildFieldValue(field, category.resource, item)}</>)
            })
        })
        return series
    }

    buildFieldValue(field: IField, value: any, item?: IViewItem) {
        const {history} = this.props
        return value[field.name] !== null && value[field.name] !== undefined ? DataStorageHelper.buildFieldValue(field, value, history, this.getPresenter(field, item)) :
            <StopOutlined/>
    }

    buildBarData = (xField: IField, field?: IField, conditions?: any): {} => {
        const {viewUnit} = this.props
        const {results, interval} = this.state
        const {
            graphXAxis
        } = viewUnit.options

        return this.buildCategories().map(category => {
            const values: any = results
                .filter(result => {
                    return !conditions || (conditions && _.isMatch(result, conditions))
                })
                .filter(result => {
                    switch (graphXAxis) {
                        case ViewGraphService.X_AXIS_FIELD:
                            return result[xField.name] === category.resource[xField.name]
                        case ViewGraphService.X_AXIS_INTERVAL:
                            const intervalValue = MomentBuilder.build(result[xField.name])
                            return intervalValue && intervalValue.isBetween(category.min, category.max, interval, '[)')
                    }
                    return true
                })
                .map(result => field && result[field.name + '0'])
            return values[0] || 0
        })
    }

    setIntervalMinMax = (intervalMinMax: [min: Moment, max: Moment] | any) => {
        if (intervalMinMax) {
            const [min, max] = intervalMinMax
            this.setState({
                intervalMin: max && min > max ? null : min,
                intervalMax: min && max < min ? null : max,
            }, this.load)
        } else {
            this.setState({intervalMin: undefined, intervalMax: undefined}, this.load)
        }
    }

    disableMaxLengthInterval = (current: Moment) => {
        const {interval, intervalMin, intervalMax, intervalLength} = this.state
        const maxLength = (this.props.settings.graphIntervalLength || intervalLength) - 1
        if (intervalMin && !intervalMax) {
            return current > intervalMin.clone().add(maxLength, interval) || intervalMin > current
        }
        if (!intervalMin && intervalMax) {
            return intervalMax.clone().add(-maxLength, interval) > current || intervalMax < current
        }
        if (intervalMin && intervalMax) {
            return (current < intervalMin && current < intervalMax.clone().add(-maxLength, interval))
                || (current > intervalMax && current > intervalMin.clone().add(maxLength, interval))
        }
        return false
    }

    setInterval = (interval: IViewGraphSettingsInterval) => {
        this.setState({interval, intervalMin: undefined, intervalMax: undefined}, this.load)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.settings.graphIntervals?.length === 1
            && this.state.interval !== this.props.settings.graphIntervals[0]) {
            setInterval(this.props.settings.graphIntervals[0])
        }
        if (prevProps.reload !== this.props.reload && this.props.reload) {
            this.refresh().then()
        }
    }

    onCustomFilterChange = (filters?: DefaultCustomFilters) => {
        this.setState({customFilters: filters?.[this.getContentType().fullClassName]}, this.load)
    }

    buildGraph = () => {
        const {viewUnit} = this.props
        const {graphType} = viewUnit.options
        const categories = this.buildCategories().map(category => category.label)
        const series = this.buildSeries()
        const colors = ViewGraphService.buildColors(viewUnit.options.colorScheme)
        switch (graphType) {
            case (ViewGraphService.TYPE_BAR):
            case (ViewGraphService.TYPE_LINE):
                return <XYChart viewUnit={viewUnit} colors={colors} series={series} categories={categories}
                                type={graphType}/>
            case (ViewGraphService.TYPE_PIE):
            case (ViewGraphService.TYPE_DONUT):
                return <PieChart viewUnit={viewUnit} colors={colors} series={series}
                                 labels={categories} type={graphType}/>
        }
    }

    render() {
        const {settings, viewUnit, history, match} = this.props
        const {loading, interval, intervalMin, intervalMax} = this.state;

        return (
            <div>
                <div>
                    <Row gutter={[8, 8]} className={'mb-2'}>
                        {settings.graphIntervals && settings.graphIntervals.length > 1 && (
                            <Col>
                                <Select value={interval} placeholder={'Zvolte interval'} onChange={this.setInterval}
                                        defaultActiveFirstOption={true}>
                                    {ViewGraphService.buildIntervals()
                                        .filter(interval => settings.graphIntervals?.includes(interval.value))
                                        .map(interval => (
                                            <Select.Option key={interval.value} value={interval.value}>
                                                {interval.label}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Col>
                        )}
                        {settings.graphIntervalRange && interval && (
                            <Col>
                                <RangePicker placeholder={['od', 'do']} disabledDate={this.disableMaxLengthInterval}
                                             picker={interval === 'day' ? 'date' : interval}
                                             allowEmpty={[true, true]}
                                             value={[intervalMin || null, intervalMax || null]}
                                             onCalendarChange={this.setIntervalMinMax}/>
                            </Col>
                        )}
                        <Col className={'flex-grow-1 d-flex flex-row justify-items-end'}>
                            <Row className={"noPrint w-100"} justify={"end"} gutter={[4, 6]}>
                                {viewUnit.schemaActions.length > 0 && (
                                    <Col>
                                        <Row gutter={[6, 6]}>
                                            {viewUnit.schemaActions
                                                .filter(schemaAction => {
                                                    const contentType = this.getContentType()
                                                    const action = _.findWhere(contentType.actions, {uuid: schemaAction.action})
                                                    return action && this.props.permissions[contentType.fullClassName] && this.props.permissions[contentType.fullClassName][action.name]
                                                })
                                                .map(action => (
                                                    <Col key={action.uuid}>
                                                        <ViewAction history={history} match={match}
                                                                    onFinish={this.doAction} action={action}/>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Col>
                                )}
                                <Col>
                                    <Tooltip title={"Znovu načíst data"}>
                                        <Button onClick={this.refresh} style={{verticalAlign: "middle"}}
                                                icon={<RefreshOutline/>} size={"small"}
                                        />
                                    </Tooltip>
                                </Col>
                                <ViewPersonalEditButton {...this.props} />
                                <ViewEditButton {...this.props} />
                            </Row>
                        </Col>
                    </Row>
                    {settings.customFilters && (
                        <ViewCustomFilters filters={settings.customFilters} onChange={this.onCustomFilterChange}/>
                    )}
                </div>

                <Row hidden={!loading} className={'position-absolute w-100 h-100'}
                     style={{background: "rgba(255,255,255, 0.7)", zIndex: 10}}
                     justify={"center"} align={"middle"}>
                    <Spin size={"large"}/>
                </Row>
                {this.buildGraph()}
            </div>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findOneByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findOneByFullClassName: (fullClassName: string) => selectors.services.findOneByFullClassName(state, fullClassName),
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        user: state.setup.user
    }
}

export default connect(mapStateToProps)(ViewGraph)