import React from "react";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import IField, {FIELD_MODE_ICONS, RELATION_FIELD_TYPE} from "../../../../../model/interface/dataStorage/IField";
import FormElementList from "./FormElementList";
import FormElementType from "./FormElementType";
import {TagOutlined, ThunderboltOutlined} from "@ant-design/icons";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import {IWidgetList, IWidgetListItem} from "../../widget/WidgetList";
import IExtension from "../../../../../model/interface/dataStorage/IExtension";
import WidgetGallery from "../../widget/WidgetGallery";
import Utils from "../../../../../utils";

interface Select {
    (type: string, action?: IAction, field?: IField): void
}

interface Exists {
    (group: string, id: number, type?: string): boolean
}

interface GroupItem {
    label: string
    widgets: IWidgetListItem[]
}

interface INodeOptionTagProps {
    widget: IWidgetListItem,
    onSelect?: () => void,
    disabled?: boolean,
    index: number
}

class FormElementGallery extends WidgetGallery {

    protected contentType: IContentType
    protected exists: Exists

    constructor(onSelect: Select, list: IWidgetList, contentType: IContentType, exists: Exists) {
        super(onSelect, list);
        this.contentType = contentType
        this.exists = exists
    }

    protected getGroup(group: [string, GroupItem]) {
        const {actions, fields} = this.contentType
        return <>
            {
                {
                    [FormElementType.GROUP_FIELD]:
                        this.getFieldOptions(fields, group, this.contentType.extensions),
                    [FormElementType.GROUP_ACTION]:
                        this.getActionOptions(actions, group),
                }[group[0]] || group[1].widgets.map((widget, index) =>
                    this.getNodeOptionTag(widget, index)
                )
            }
        </>;
    }

    protected getActionOptions(actions: IAction[], group: [string, GroupItem]) {
        return actions.map((action, index) => {
            return this.getNodeOptionTag({
                    type: FormElementType.ACTION,
                    label: action.label,
                    group: FormElementType.GROUP_ACTION,
                    icon: <ThunderboltOutlined/>,
                }, index,
                () => this.onSelectItem(FormElementType.ACTION, action),
                this.exists(group[0], action.id as number)
            )
        });
    }

    protected onSelectItem(type: string, action?: IAction, field?: IField) {
        super.onSelectItem(type, action, field);
    }

    protected getFieldOptions(fields: IField[], group: [string, GroupItem], extensions: IExtension[]) {
        return [
            ...fields
                // .filter(field => field.mode === "scalar" || field.type === RELATION_FIELD_TYPE.MANY_TO_ONE)
                .map((field, index) => ({
                    widget: {
                        type: FormElementType.FIELD,
                        label: field.label || field.name,
                        group: FormElementType.GROUP_FIELD,
                        icon: field.mode ? FIELD_MODE_ICONS[field.mode] : <TagOutlined/>
                    },
                    index,
                    onSelect: () => this.onSelectItem(FormElementType.FIELD, undefined, field),
                    //disabled: this.exists(group[0], field.id as number)  TODO this was commented in order to configure different settings for same field based some conditions
                } as INodeOptionTagProps)),
            ...extensions.map((extension, index) => {
                switch (extension.type) {
                    case 'App\\DataStorage\\Extension\\Comments':
                        const widget = FormElementList.getItemByType(FormElementType.COMMENT)
                        return {
                            widget,
                            index: index + fields.length,
                            onSelect: () => this.onSelectItem(FormElementType.COMMENT),
                            disabled: this.exists(group[0], -1, widget.type)
                        } as INodeOptionTagProps
                    default:
                        return null
                }
            }),
            ...this.getEntityTableOption(fields).map((widget, index) => ({widget, index} as INodeOptionTagProps))
        ]
            .filter(item => item !== null)
            // @ts-ignore
            .sort((a: INodeOptionTagProps, b: INodeOptionTagProps) => a.widget.label > b.widget.label ? 1 : -1)
            // @ts-ignore
            .map(({widget, index, onSelect, disabled}) => this.getNodeOptionTag(widget, index, onSelect, disabled));
    }

    protected getEntityTableOption(fields: IField[]) {
        const field = fields.find(field => Utils.isTargetEntityContentType(field.targetEntity) && [
            RELATION_FIELD_TYPE.ONE_TO_MANY,
            RELATION_FIELD_TYPE.MANY_TO_MANY
            ].includes(field.type)
        )
        return field ? [FormElementList.getItemByType(FormElementType.ENTITY_TABLE)] : []
    }
}

export default FormElementGallery