import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import {DatePicker} from "antd";
const { RangePicker } = DatePicker;

class FormFieldDateRange {
    public static render = (options: IFieldOptions) => {
        const props = {
            style: options.style,
            allowClear: options.showClear,
            picker: options.datePicker as any,
            disabled: options.disabled,
            value: options.value
        };
        return  <RangePicker {...props}/>
    }
}

export default FormFieldDateRange