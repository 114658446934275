import UsersService from "../service/security/UsersService";
import EmployeesService from "../service/company/EmployeesService";
import UnitsService from "../service/company/UnitsService";
import CompaniesService from "../service/company/CompaniesService";
import DataStorageService from "../service/dataStorage/DataStorageService";
import JobsService from "../service/company/JobsService";
import {FIELD_TYPE} from "../interface/dataStorage/IField";

export const API_FILTER_TYPE = {
    OR: 'or',
    AND: 'and',
    LIKE: 'like',
    IN: 'in',
    NOT_IN: 'notIn',
    EQUAL: 'equal',
    NOT_EQUAL: 'notEqual',
    CONTAINS: 'contains',
    CONTAINS_NOT: 'containsNot',
    LESSER: 'lesser',
    LESSER_OR_EQUAL: 'lesserOrEqual',
    GREATER: 'greater',
    GREATER_OR_EQUAL: 'greaterOrEqual',
    IS_NULL: 'isNull',
    IS_NOT_NULL: 'isNotNull',
    LIKE_BEGINNING: 'likeBeginning',
}

export const API_AGGREGATION_TYPE = {
    SUM: 'sum',
    AVG: 'avg',
    MIN: 'min',
    MAX: 'max',
    COUNT: 'count',
    DATE_INTERVAL: 'date_interval',
    LIST: 'list'
}

export const API_AGGREGATION_TYPE_LIST = [
    {label: 'Počet', value: API_AGGREGATION_TYPE.COUNT},
    {label: 'Součet', value: API_AGGREGATION_TYPE.SUM},
    {label: 'Průměr', value: API_AGGREGATION_TYPE.AVG},
    {label: 'Minimum', value: API_AGGREGATION_TYPE.MIN},
    {label: 'Maximum', value: API_AGGREGATION_TYPE.MAX},
    {label: 'Datum od do', value: API_AGGREGATION_TYPE.DATE_INTERVAL},
    {label: 'Seznam', value: API_AGGREGATION_TYPE.LIST},
]

export const API_AGGREGATION_TYPE_NUMBER = [
    API_AGGREGATION_TYPE.AVG,
    API_AGGREGATION_TYPE.MAX,
    API_AGGREGATION_TYPE.MIN,
    API_AGGREGATION_TYPE.SUM,
    API_AGGREGATION_TYPE.LIST,
]

export const API_AGGREGATION_TYPE_DATE = [
    API_AGGREGATION_TYPE.MAX,
    API_AGGREGATION_TYPE.MIN,
    API_AGGREGATION_TYPE.DATE_INTERVAL,
    API_AGGREGATION_TYPE.LIST,
]

export const API_AGGREGATION_GROUP_COUNT = '_group_count'

export const API_GROUP_BY_FORMAT = {
    DATE_DAY: 'date_day',
    DATE_WEEK: 'date_week',
    DATE_MONTH: 'date_month',
    DATE_QUARTER: 'date_quarter',
    DATE_YEAR: 'date_year'
}

export const API_TOKEN = {
    USER: '#user',
    EMPLOYEE: '#employee',
    NOW: '#now',
    UNIT: '#unit',
    COMPANY: '#company',
    JOB: '#job',
    ROUTE_PARAM: '#route-param',
}

export const API_TOKEN_ENTITY = {
    [FIELD_TYPE.DATE_TIME]: API_TOKEN.NOW,
    [UsersService.getRecordClassName()]: API_TOKEN.USER,
    [EmployeesService.getInstance().getRecordClassName()]: API_TOKEN.EMPLOYEE,
    [UnitsService.getInstance().getRecordClassName()]: API_TOKEN.UNIT,
    [JobsService.getInstance().getRecordClassName()]: API_TOKEN.JOB,
    [CompaniesService.getInstance().getRecordClassName()]: API_TOKEN.COMPANY,
    [DataStorageService.VIRTUAL_ENTITY]: API_TOKEN.ROUTE_PARAM,
}

export const API_TOKEN_LIST = [
    {value: API_TOKEN.USER, label: 'Uživatel'},
    {value: API_TOKEN.EMPLOYEE, label: 'Zaměstnanec'},
    {value: API_TOKEN.NOW, label: 'Čas'},
    {value: API_TOKEN.UNIT, label: 'Oddělení'},
    {value: API_TOKEN.COMPANY, label: 'Společnost'},
    {value: API_TOKEN.JOB, label: 'Pozice'},
    {value: API_TOKEN.ROUTE_PARAM, label: 'Hodnota z url'}
]

export const API_FILTER_TYPE_LIST = [
    {label: '[OR] Jeden z podřízených filtrů musí být pravdivý', value: API_FILTER_TYPE.OR},
    {label: '[AND] Všechny podřízené filtry musí být pravdivé', value: API_FILTER_TYPE.AND},
    {label: '[LIKE %x%] Uvnitř', value: API_FILTER_TYPE.LIKE},
    {label: '[LIKE %x] Začátek', value: API_FILTER_TYPE.LIKE_BEGINNING},
    {label: 'Obsahuje', value: API_FILTER_TYPE.CONTAINS},
    {label: 'Neobsahuje', value: API_FILTER_TYPE.CONTAINS_NOT},
    {label: '[=] Presna hodnota', value: API_FILTER_TYPE.EQUAL},
    {label: '[!=] Nerovná se hodnotě', value: API_FILTER_TYPE.NOT_EQUAL},
    {label: '[IS NULL] Je prázdný', value: API_FILTER_TYPE.IS_NULL},
    {label: '[IS NOT NULL] Není prázdná hodnota', value: API_FILTER_TYPE.IS_NOT_NULL},
    {label: '[<] Menší', value: API_FILTER_TYPE.LESSER},
    {label: '[<=] Menší nebo rovné ', value: API_FILTER_TYPE.LESSER_OR_EQUAL},
    {label: '[>] Větší', value: API_FILTER_TYPE.GREATER},
    {label: '[>=] Větší nebo rovné', value: API_FILTER_TYPE.GREATER_OR_EQUAL},
    {label: '[IN] Je v poli hondot', value: API_FILTER_TYPE.IN},
    {label: '[NOT IN] Není v poli hodnot', value: API_FILTER_TYPE.NOT_IN}
]

export const API_FILTER_TYPE_LOGICAL_OPERATORS = [
    API_FILTER_TYPE.OR, API_FILTER_TYPE.AND
]

export const API_FILTER_TYPE_COMPARISON_LIST = [
    ...API_FILTER_TYPE_LIST.filter(filter => !API_FILTER_TYPE_LOGICAL_OPERATORS.includes(filter.value))
]

export const API_FILTER_TYPE_VALUELESS = [
    API_FILTER_TYPE.IS_NULL, API_FILTER_TYPE.IS_NOT_NULL
]

export const API_FILTER_TYPE_COLLECTION = [
    ...API_FILTER_TYPE_VALUELESS,
    API_FILTER_TYPE.CONTAINS,
    API_FILTER_TYPE.CONTAINS_NOT
]

export const API_FILTER_TYPE_MULTIPLE = [
    API_FILTER_TYPE.IN,
    API_FILTER_TYPE.NOT_IN,
    API_FILTER_TYPE.CONTAINS,
    API_FILTER_TYPE.CONTAINS_NOT
]
