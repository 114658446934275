import {connect} from "react-redux";
import React from "react";
import {Button, Card, Modal, Table} from "antd";
import MessagesService from "../../../../../model/service/ui/MessagesService";
import {FilterValue, SorterResult, TablePaginationConfig} from "antd/lib/table/interface";
import IMessage from "../../../../../model/interface/ui/IMessage";
import Utils from "../../../../../utils";
import IRestServiceOptions from "../../../../../model/interface/api/IRestServiceOptions";
import IUser from "../../../../../model/interface/security/IUser";
import UserLabel from "../../../security/UserLabel";
import {EyeOutlined, MessageOutlined} from "@ant-design/icons";
import MessageModal from "../../../message/MessageModal";
import IDateTime from "../../../../../model/interface/api/IDateTime";
import moment from "moment";
import MessageShow from "./MessageShow";
import {ColumnsType} from "antd/es/table";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

interface IProps {
    user: IUser
}

interface IState {
    pagination: TablePaginationConfig
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IMessage>[],
    loading: boolean
    results: IMessage[]
    writeMessage: null | { sender: IUser }
    readMessage: null | IMessage
}

class MessageList extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 5
            },
            filters: {} as Record<string, FilterValue | null>,
            sorter: [] as SorterResult<IMessage>[],
            results: [],
            writeMessage: null,
            readMessage: null
        }
    }


    componentDidMount() {
        this.load()
    }

    load() {
        this.setState({loading: true}, () => {
            const {pagination, sorter, filters} = this.state
            let options = Utils.buildRestOptions(pagination, sorter, filters) as IRestServiceOptions
            if (!options.filters) {
                options.filters = {}
            }
            options.filters["user"] = {type: "contains", field: "recipients", value: this.props.user.id}
            MessagesService.collectionList(options).then(response => {
                const {results, count} = response
                this.setState({
                        results,
                        pagination: {
                            ...this.state.pagination,
                            total: count
                        },
                        loading: false
                    }
                )
            })
        })
    }

    buildColumns(): ColumnsType<IMessage> {
        return [
            {
                title: "Odesílatel",
                dataIndex: "sender",
                render: (sender: IUser) => ((<>{sender ? <UserLabel user={sender}/> : ''}</>))
            },
            {title: "Předmět", dataIndex: "subject", render: (subject: string) => ((<>{subject}</>))},
            {
                title: "Datum",
                dataIndex: "createdAt",
                render: (date: IDateTime) => ((<>{date ? moment(date.raw).format('LLL') : ''}</>))
            },
            {
                title: "Akce", render: (message: IMessage) => ((
                    <>
                        <Button icon={<EyeOutlined/>} type={"default"} onClick={() => this.readMessage(message)}/>
                    </>
                ))
            },
        ]
    }

    buildData() {
        return [...this.state.results]
    }

    onChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<IMessage>[]) {
        this.setState({pagination, filters, sorter}, () => this.load())
    }

    writeMessage() {
        this.setState({
            writeMessage: {sender: this.props.user}
        })
    }

    closeMessageWrite() {
        this.setState({writeMessage: null})
    }


    readMessage(readMessage: IMessage) {
        console.log(readMessage)
        this.setState({
            readMessage
        })
    }

    closeMessageRead() {
        this.setState({readMessage: null})
    }

    render() {
        const {readMessage, writeMessage, loading, pagination} = this.state
        return (
            <Card title={"Moje zprávy"}
                  extra={<Button size={"small"} type={"primary"} onClick={() => this.writeMessage()}
                                 icon={<MessageOutlined/>}>Nová zpráva</Button>}>
                {writeMessage && (<MessageModal {...writeMessage} onAfterSend={() => this.closeMessageWrite()}
                                                onCancel={() => this.closeMessageWrite()}/>)}
                {readMessage &&
                    <Modal visible={true} footer={false} onCancel={() => this.closeMessageRead()}>
                        <MessageShow resource={readMessage!}/>
                    </Modal>
                }
                <Table
                    onRow={(record) => {
                        return {
                            onClick: () => this.readMessage(record),
                        };
                    }}
                    loading={loading}
                    pagination={pagination}
                    onChange={(pagination, filters, sorter: any) => this.onChange(pagination, filters, sorter)}
                    columns={this.buildColumns()}
                    rowKey={row => row.id || generateUniqueID()}
                    dataSource={this.buildData()}
                />
            </Card>
        )
    }
}

const mapStateToProps = () => {
    return {}
}

export default connect(mapStateToProps)(MessageList)