import {Switch, Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase from "./IFilterBase";
import FilterDropDown from "./FilterDropDown";
import {API_FILTER_TYPE} from "../../model/constants/ApiConstant";
import Utils from "../index";

const BooleanFilter: IFilterBase = {
    handleSearch(setSelectedKeys, selectedKeys, confirm) {
        confirm();
    },

    handleReset(clearFilters, setValue) {
        clearFilters();
        setValue([])
    },

    build(item, field, state, setValue): IFilterFunctions {
        const dataIndex = field.name

        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                        <Switch
                            checkedChildren={item.options?.fieldOptions?.trueLabel || field.options?.trueLabel || 'Povoleno'}
                            unCheckedChildren={item.options?.fieldOptions?.falseLabel || field.options?.falseLabel || 'Zakázáno'}
                            checked={!!selectedKeys[0]?.value}
                            onChange={(checked) => {
                                setSelectedKeys([{
                                    type: API_FILTER_TYPE.EQUAL,
                                    value: checked
                                }])
                            }}/>
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered: boolean): JSX.Element => {
                return (
                    <Tooltip title={filtered ? "Filtrováno: " + (state[0]?.value ? 'Povoleno' : 'Zakázáno') : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                const recordValue = record[dataIndex]
                return Utils.toBoolean(recordValue)
            }
        }
    }
}

export default BooleanFilter