import React from "react";
import {Button, Divider, Tag, Tooltip} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";

interface IProps extends IWidgetProps{
    edit?: boolean,
    add?: boolean,
    execute?: boolean
}

class WidgetTool extends React.Component<IProps> {
    render() {
        const {id, functions, preview, label, edit, add, execute} = this.props
        if(preview) {
            return null;
        }
        const parent = functions.getNode(id).parent

        return !preview && (
            <Tag color={"processing"} className={"mb-1 p-1 d-block w-100 overflow-auto"}>
                {label}
                {add && (
                    <>
                        <Divider type={"vertical"}/>
                        <Tooltip title={"Přidat podřízený prvek"}>
                            <Button size={"small"} onClick={() => functions.appendNode(id)}>
                                <PlusOutlined/>
                            </Button>
                        </Tooltip>
                    </>
                )}
                {edit && (
                    <>
                        <Divider type={"vertical"}/>
                        <Tooltip title={"Nastavení"}>
                            <Button onClick={() => functions.editOptions(id)} size={"small"}>
                                <EditOutlined/>
                            </Button>
                        </Tooltip></>
                )}
                {execute && (
                    <>
                        <Divider type={"vertical"}/>
                        <Tooltip title={"Přidat"}>
                            <Button size={"small"} onClick={() => functions.appendNodeExecute(id, '')}>
                                <PlusOutlined/>
                            </Button>
                        </Tooltip>
                    </>
                )}
                {parent && (
                    <>
                        <Divider type={"vertical"}/>
                        <Tooltip title={"Posunout nahoru"}>
                            <Button size={"small"} onClick={() => functions.moveUp(id)}>
                                <ArrowUpOutlined/>
                            </Button>
                        </Tooltip>
                        <Divider type={"vertical"}/>
                        <Tooltip title={"Posunout nahoru"}>
                            <Button size={"small"} onClick={() => functions.moveDown(id)}>
                                <ArrowDownOutlined/>
                            </Button>
                        </Tooltip>
                        <Divider type={"vertical"}/>
                        <Tooltip title={"Odstranit"}>
                            <Button size={"small"} onClick={() => functions.delete(id)}>
                                <DeleteOutlined/>
                            </Button>
                        </Tooltip>
                    </>
                )}
            </Tag>

        );
    }
}

export default WidgetTool