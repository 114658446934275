import React from "react";
import {Badge, Col, Row, Statistic} from "antd";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import EmployeesService from "../../../../../model/service/company/EmployeesService";
import IEmployee from "../../../../../model/interface/company/IEmployee";
import WidgetTool from "../../widget/WidgetTool";
import IReportWidgetNotifications from "../../../../../model/interface/report/IReportWidgetNotifications";
import {BellOutlined} from "@ant-design/icons";
import {connect, RootStateOrAny} from "react-redux";
import INotification, {STATES} from "../../../../../model/interface/ui/INotification";
import selectors from "../../../../../redux/selectors";
import {changeState, load} from "../../../../../redux/actions/Notifications";
import NotificationList from "../../../message/notification/NotificationList";

interface IProps extends IReportWidgetProps<IReportWidgetNotifications> {
    findAll: () => INotification[]
}

interface IState {
    selected?: IEmployee,
    loading?: boolean
}

class NotificationsWidget extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {}
    }

    onSelect = (uuid?: string) => {
        this.setState({loading: true})
        uuid ? EmployeesService.getInstance().resourceRetrieve(uuid).then(resource => {
            this.setState({loading: false, selected: resource})
        }) : this.setState({selected: undefined, loading: false})
    }

    render() {
        const {options, findAll} = this.props
        const {showLast, showTotal, showUnread, lastLimit} = options
        const notifications = findAll()


        return (
            <div>
                <WidgetTool {...this.props} edit={true}/>
                <Row gutter={[16, 16]}>
                    {showUnread && (
                        <Col className={'p-3'}>
                            <Statistic title="Nepřečtené" valueRender={
                                () => <Badge count={notifications.filter(n => n.state === STATES.STATE_NEW).length}
                                             showZero>
                                    <BellOutlined className="nav-icon mx-auto" style={{fontSize: 20}}/>
                                </Badge>}/>
                        </Col>
                    )}
                    {showTotal && (
                        <Col className={'p-3'}>
                            <Statistic title="Celkem" valueRender={
                                () => <Badge count={notifications.filter(n => n.state !== STATES.STATE_DELETED).length}
                                             style={{backgroundColor: '#72849a'}} showZero>
                                    <BellOutlined className="nav-icon mx-auto" style={{fontSize: 20}}/>
                                </Badge>}/>
                        </Col>
                    )}
                </Row>
                {showLast && (
                    <NotificationList limit={lastLimit}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findAll: (): INotification[] => selectors.notifications.findAll(state)
    }
}

const mapDispatchToProps =
    {
        load,
        changeState
    }

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsWidget);