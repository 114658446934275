import {Card} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IBaseProps from "../../../../../../model/interface/IBaseProps";
import {IFieldStructureOptions} from "./FieldStructureOptionsEditor";

export interface IFieldCompanyOptions extends IFieldStructureOptions{

}

interface IProps extends IFieldOptionsEditorProps, IBaseProps {

}

interface IState {

}

class FieldCompanyOptionEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    static getDefaultOptions(): IFieldCompanyOptions {
        return {
            companyStructureAccepts: 'company'
        }
    }

    render() {
        const {history, match, options} = this.props
        return (
            <Card>

            </Card>
        )
    }
}

export default FieldCompanyOptionEditor