import ICustomArguments from "./ICustomArguments";
import {
    IFieldDateOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldDateOptionsEditor";
import {
    IFieldRatingOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldRateOptionsEditor";
import {
    IFieldEmployeeOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldEmployeeOptionsEditor";
import {
    IFieldLikeOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldLikeOptionsEditor";
import {
    IFieldContentTypeOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldContentTypeOptionsEditor";
import {
    IFieldStructureOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldStructureOptionsEditor";
import {
    IFieldTextOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldTextOptionsEditor";
import {
    IFieldWorkflowStateOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldWorkflowStateOptionsEditor";
import {
    ICompositeFieldApprovalOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import {AppstoreAddOutlined, CalculatorOutlined, PartitionOutlined, TagOutlined} from "@ant-design/icons";
import React from "react";
import {
    IFieldPhoneNumberOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldPhoneNumberOptionsEditor";
import {
    IFieldFileOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldFileOptionsEditor";
import {
    IFieldCodeOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldCodeOptionsEditor";
import {
    IFieldBooleanOptions
} from "../../../components/app/configuration/content-type/field/optionEditors/FieldBooleanOptionsEditor";

export const FIELD_MODE_SCALAR = 'scalar';
export const FIELD_MODE_RELATION = 'relation';
export const FIELD_MODE_COMPUTED = 'computed';
export const FIELD_MODE_COMPOSITE = 'composite';

export type FieldModeType = 'scalar' | 'relation' | 'computed' | 'composite'

export interface IFieldOptions extends IFieldDateOptions, IFieldRatingOptions, IFieldEmployeeOptions, IFieldLikeOptions, IFieldContentTypeOptions, IFieldStructureOptions, IFieldTextOptions, IFieldWorkflowStateOptions, ICompositeFieldApprovalOptions, IFieldPhoneNumberOptions, IFieldFileOptions, IFieldCodeOptions, IFieldBooleanOptions {
    presenterOptions?: { [x: string]: string },
    allowSelectAll?: boolean
    autoFocus?: boolean

    [x: string]: any,
}

export const FIELD_TYPE = {
    TEXT: "App\\DataStorage\\FieldType\\TextType",
    DATE_TIME: "App\\DataStorage\\FieldType\\DateTimeType",
    RATE: "App\\DataStorage\\FieldType\\Specialized\\RateType",
    HTML: "App\\DataStorage\\FieldType\\Specialized\\HtmlType",
    STRING: "App\\DataStorage\\FieldType\\StringType",
    INTEGER: "App\\DataStorage\\FieldType\\IntegerType",
    COUNT: "App\\DataStorage\\FieldType\\Specialized\\CountType",
    FLOAT: "App\\DataStorage\\FieldType\\FloatType",
    BOOLEAN: "App\\DataStorage\\FieldType\\BooleanType",
    PRICE: "App\\DataStorage\\FieldType\\Specialized\\PriceType",
    BASE64: "App\\DataStorage\\FieldType\\Specialized\\Base64Type",
    SPECIALIZED_COLOR: 'App\\DataStorage\\FieldType\\Specialized\\ColorType',
    COLOR: 'App\\DataStorage\\FieldType\\ColorType',
    ICON: 'App\\DataStorage\\FieldType\\IconType',
    ARRAY: 'App\\DataStorage\\FieldType\\ArrayType',
    EMAIL: 'App\\DataStorage\\FieldType\\Specialized\\EmailType',
    PHONE_NUMBER: 'App\\DataStorage\\FieldType\\Specialized\\PhoneNumberType',
    BANK_ACCOUNT_NUMBER: 'App\\DataStorage\\FieldType\\Specialized\\BankAccountNumberType',
    ICO: 'App\\DataStorage\\FieldType\\Specialized\\IcoType',
    CODE: 'App\\DataStorage\\FieldType\\Specialized\\CodeType'

}

export const FIELD_NUMBER_TYPES = [
    FIELD_TYPE.INTEGER,
    FIELD_TYPE.COUNT,
    FIELD_TYPE.FLOAT,
    FIELD_TYPE.PRICE
]

export const FIELD_FLOAT_TYPES = [
    FIELD_TYPE.FLOAT,
    FIELD_TYPE.PRICE
]

export const RELATION_FIELD_TYPE = {
    ONE_TO_MANY: 'one_to_many',
    MANY_TO_ONE: 'many_to_one',
    MANY_TO_MANY: 'many_to_many',
    ONE_TO_ONE: 'one_to_one',
}

export const RELATION_FIELD_TYPES = [
    RELATION_FIELD_TYPE.ONE_TO_MANY,
    RELATION_FIELD_TYPE.MANY_TO_ONE,
    RELATION_FIELD_TYPE.MANY_TO_MANY,
    RELATION_FIELD_TYPE.ONE_TO_ONE
]

export const FIELD_MODE_ICONS = {
    [FIELD_MODE_SCALAR]: <TagOutlined/>,
    [FIELD_MODE_RELATION]: <PartitionOutlined rotate={180}/>,
    [FIELD_MODE_COMPOSITE]: <AppstoreAddOutlined/>,
    [FIELD_MODE_COMPUTED]: <CalculatorOutlined/>,
    default: <TagOutlined/>
}

export const COMPOSITE_FIELD_TYPE = {
    APPROVAL: "App\\DataStorage\\FieldType\\Composite\\ApprovalType"
}

export default interface IField {
    id?: number,
    uuid: string,
    name: string,
    label?: string,
    mode: null | FieldModeType,
    type: string,
    targetEntity: string | null,
    options: IFieldOptions,
    unit?: string | null,
    weight: number,
    contentTypeId: string | null,
    contentTypeName: boolean,
    locked: boolean,
    script?: string
    loadInCollection?: boolean
    loadInResource?: boolean
    defaultValue?: any,
    arguments?: ICustomArguments,
    deleteRelated?: boolean,
    masterField?: boolean
    saveTrend?: boolean
}