import React from 'react'
import {Card, Col, Row} from "antd";
import logo from '../../assets/images/proces_logo-sm.png';
import IBaseProps from "../../model/interface/IBaseProps";
import PasswordResetForm from "./PasswordResetForm";

interface IProps extends IBaseProps {
    settings: any
}

interface IState {
}

class PasswordReset extends React.Component<IProps, IState> {

    render() {
        const {match} = this.props
        return (
            <div className="h-100" style={this.props.settings.background}>
                <div className="container d-flex flex-column justify-content-center h-100">
                    <Row justify="center">
                        <Col style={{maxWidth: 420}}>
                            <Card className={"p-2"}>
                                <div className="text-center mb-4">
                                    <img className="img-fluid" src={logo} alt=""/>
                                </div>
                                <PasswordResetForm token={'hash' in match.params ? match.params['hash'] : ''}/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default PasswordReset