import {Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase from "./IFilterBase";
import FilterDropDown from "./FilterDropDown";
import {API_FILTER_TYPE} from "../../model/constants/ApiConstant";
import moment from "moment";
import FormFieldCode from "../../components/app/configuration/form/FormElement/formField/FormFieldCode";

const DateFilter: IFilterBase = {
    handleSearch(setSelectedKeys, selectedKeys, confirm) {
        confirm();
    },

    handleReset(clearFilters, setValue) {
        setValue([])
        clearFilters();
    },

    build(item, field, state, setValue): IFilterFunctions {
        const dataIndex = field.name
        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                const value = selectedKeys[0] ? selectedKeys[0].value : undefined
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>

                        <FormFieldCode value={value as any} onChange={result => result && setSelectedKeys([{
                            value: result,
                            type: API_FILTER_TYPE.LIKE
                        }])} options={{label: '', type: '', ...field.options, ...item.options?.fieldOptions}}/>
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered: boolean): JSX.Element => {
                return (
                    <Tooltip title={filtered ? "Filtrováno: " + (state[0] ? state[0].value : '') : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                const recordDate = moment(record[dataIndex])

                return value.type === API_FILTER_TYPE.LESSER_OR_EQUAL ?
                    (!value.value || moment(value.value).isSameOrBefore(recordDate)) :
                    (!value.value || moment(value.value).isSameOrAfter(recordDate))
            }
        }
    }
}

export default DateFilter