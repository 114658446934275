import Routes from './Routes';
import Views from './Views';
import ContentTypes from './ContentTypes';
import Services from './Services';
import Notifications from './Notifications';
import Menu from './Menu';
import Forms from './Forms';
import Scripts from './Scripts';
import Workflow from './Workflow';

const selectors = {
    routes: Routes,
    views: Views,
    contentTypes: ContentTypes,
    services: Services,
    notifications: Notifications,
    workflow: Workflow,
    forms: Forms,
    scripts: Scripts,
    menu: Menu
}

export default selectors;