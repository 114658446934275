import React from 'react';
import {connect, RootStateOrAny} from 'react-redux';
import SideNav from 'components/layout/SideNav';
// import TopNav from 'components/layout/TopNav';
// import Loading from 'components/shared/Loading';
import MobileNav from 'components/layout/MobileNav'
import HeaderNav from 'components/layout/HeaderNav';
import Footer from 'components/layout/Footer';
import AppRouter from 'components/app/router/AppRouter';
import {Button, Layout, Spin,} from "antd";

import navigationConfig from "configs/NavigationConfig";
import {SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH,} from 'model/constants/ThemeConstant';
import utils from 'utils';
import actions from "redux/actions";
import IModule from "../../model/interface/dataStorage/IModule";
import IBaseProps from "../../model/interface/IBaseProps";
import IRoute from "../../model/interface/dataStorage/IRoute";
import {ReloadOutlined} from "@ant-design/icons";
import IScreen from "../../model/interface/ui/IScreen";
import ISettings from "../../model/interface/ui/ISettings";
import Utils from "../../utils";
import {load} from "../../redux/actions/Setup";
import PushNotificationsService from "../../model/service/push-notifications/PushNotificationsService";

const {Content} = Layout;

interface IProps extends IBaseProps {
    navCollapsed: boolean,
    margin: string,
    fontSize: string,
    tableSize: string,
    navType: string,
    locale: 'cs',
    loaded: boolean,
    loading: boolean,
    modules: IModule[],
    // screen: IScreen,
    // location: H.Location,
    load: () => { type: string; },
    loadNotifications: () => void
    reloadNotifications: () => void
    settings: ISettings
}

interface IState {
    interval: NodeJS.Timeout | null
    reloadAvailable: boolean
    screen: IScreen
    screenTimeout: null | NodeJS.Timeout
    reloadButtonTimeout: null | NodeJS.Timeout
    initialized: boolean
}

class AppLayout extends React.Component<IProps, IState> {
    // const dispatch = useDispatch()


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            interval: null,
            reloadAvailable: true,
            initialized: false,
            screen: Utils.getScreenInfo(),
            screenTimeout: null,
            reloadButtonTimeout: null
        }
    }

    headerElement: HTMLDivElement | null = null

    componentDidMount() {
        if (!this.props.loaded && !this.props.loading) {
            // dispatch(load());
            this.props.load();
        }

        setInterval(() => {
            if (this.props.loaded && !this.state.initialized) {
                this.props.loadNotifications()
                if (this.state.interval) {
                    clearInterval(this.state.interval!)
                }
                PushNotificationsService.askPermission().then()
                this.setState({
                    interval: setInterval(() => this.props.reloadNotifications(), 30000),
                    initialized: true
                })
            }
        }, 500)

        Utils.registerScreenUpdate(
            (screenTimeout: NodeJS.Timeout) => this.setState({screenTimeout}),
            (screen: IScreen) => this.setState({screen}),
        )
    }

    //
    // componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    //     // alert('A:'+  prevProps.loading)
    //     this.setState({reloadButtonTimeout: setTimeout(() => {
    //         alert('X')
    //         // this.setState({reloadAvailable: true, reloadButtonTimeout: null})
    //     }, 3000)});
    //     if(prevProps.loading && !prevState.reloadButtonTimeout) {
    //     }
    // }

    reload() {
        localStorage.clear()
        window.location.reload()
    }

    render() {
        const {loaded, match, history, settings, margin, fontSize, tableSize} = this.props
        const {reloadAvailable, screen} = this.state
        const {isMobile} = screen
        const currentRouteInfo = utils.getRouteInfo(navigationConfig, window.location.pathname) as IRoute
        // const isNavSide = true
        // const isNavTop = false
        // const screens = utils.getBreakPoint(useBreakpoint());
        // const isMobile = !screens.includes('lg')
        // const isMobile = screen.isMobile
        let layoutGutter = this.props.navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH

        if (isMobile) {
            layoutGutter = 0;
        }
        // const isNavSide = navType === NAV_TYPE_SIDE
        // const isNavTop = navType === NAV_TYPE_TOP

        return (
            <div style={{"height": "100vh"}}>
                <Spin wrapperClassName={"h-100"} spinning={this.props.loading} style={{"height": "100vh"}} tip={(
                    <>
                        <div>
                            Načítám osobní nastavení ...
                        </div>
                        {reloadAvailable && (
                            <div className={"mt-2"}>
                                <Button onClick={() => this.reload()} type={"ghost"} size={"small"}
                                        style={{fontSize: 12}}><ReloadOutlined/> Načíst znovu</Button>
                            </div>
                        )}
                    </>
                ) as unknown as string}>
                    {loaded && (
                        <Layout>
                            <HeaderNav headerRef={ref => this.headerElement = ref} isMobile={isMobile} history={history}
                                       match={match} settings={settings}/>
                            {/*{(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null}*/}
                            <Layout
                                className={"app-container app-container-margin-" + margin + " app-container-font-size-" + fontSize + " app-container-table-" + tableSize}>
                                {!isMobile ?
                                    <SideNav settings={settings} headerElement={this.headerElement} match={match}
                                             history={history}
                                             routeInfo={currentRouteInfo}/> : null}
                                <Layout className="app-layout" style={{paddingLeft: layoutGutter}}>
                                    <div className={'app-content'} style={{
                                        marginTop: this.headerElement?.clientHeight + 'px',
                                        minHeight: 'calc(100vh - ' + ((this.headerElement?.clientHeight || 70) + 60) + 'px)'
                                    }}>
                                        {/*<PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title}/>*/}
                                        <Content className={'h-100'}>
                                            <AppRouter match={match} history={history}/>
                                        </Content>
                                    </div>
                                    <Footer settings={settings}/>
                                </Layout>
                            </Layout>
                            {isMobile && (<MobileNav history={history} match={match} projectSettings={settings}/>)}
                        </Layout>
                    )}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = ({theme, setup}: RootStateOrAny) => {
    const {loaded, loading, modules} = setup
    const {navCollapsed, navType, locale, margin, fontSize, tableSize} = theme;
    return {navCollapsed, navType, locale, loaded, loading, modules, margin, fontSize, tableSize}
};

const mapDispatchToProps = {
    load: () => load(),
    loadNotifications: actions.notifications.load,
    reloadNotifications: actions.notifications.reload
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppLayout));
