import React, {RefObject} from "react";
import {Button, Col, Collapse, Form, FormInstance, Modal, Row, Switch, Typography} from "antd";
import IActionOptions from "../../../../../model/interface/widget/option/IActionOptions";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import IWidgetOptions from "../../../../../model/interface/widget/IWidgetOptions";
import ButtonProperties from "./ButtonProperties";

interface IProps {
    options: IActionOptions
    onFinish: (values?: IWidgetOptions) => void
    action: IAction
}

const {Text} = Typography;
const {Panel} = Collapse;

class ActionWidgetEditor extends React.Component<IProps, IActionOptions> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options,
            type: props.options.type ? props.options.type : "primary",
            label: props.options.label ? props.options.label : this.props.action.label
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then((values: IActionOptions) => {
            this.props.onFinish({...this.state, ...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    render() {
        const {action} = this.props

        return (
            <Modal
                title={'Upravit akci'}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Collapse className={'mb-3'}>
                    <Panel header={`Akce [${action.name}]`} key="1">
                        <Row gutter={10}>
                            <Col span={8}><Text type="secondary">Nazev: </Text></Col>
                            <Col span={16}><Text type="secondary">{action.name}</Text></Col>
                            <Col span={8}><Text type="secondary">Titulek: </Text></Col>
                            <Col span={16}><Text type="secondary">{action.label}</Text></Col>
                            <Col span={8}><Text type="secondary">Typ: </Text></Col>
                            <Col span={16}><Text type="secondary">{action.type}</Text></Col>
                            <Col span={8}><Text type="secondary">ID: </Text></Col>
                            <Col span={16}><Text type="secondary">{action.id}</Text></Col>
                        </Row>
                    </Panel>
                </Collapse>

                <Form initialValues={this.state || {}} ref={this.formRef}>
                    <ButtonProperties options={this.state}/>
                    <Form.Item name={'disabledOnInvalid'} label={'Neaktivne ak je formular nevalidni'}
                               valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default ActionWidgetEditor