import React from "react";
import {Button, Col, Divider, Empty, Row, Spin, Timeline, Typography} from "antd";
import IRestResource from "model/interface/api/IRestResource";
import IRepositoryService from "model/interface/IRepositoryService";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IRestServiceOptions from "../../../model/interface/api/IRestServiceOptions";
import ViewAction from "./ViewAction";
import {DownloadOutlined, VerticalAlignTopOutlined} from "@ant-design/icons";
import {ISetupState} from "../../../redux/reducers/Setup";
import IUser from "../../../model/interface/security/IUser";
import Card from "../configuration/content-type/card/Card";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import IRestServiceFilters from "../../../model/interface/api/IRestServiceFilters";
import ViewCustomFilters, {DefaultCustomFilters} from "./ViewCustomFilters";
import {IBaseViewProps} from "./ViewUnit";
import FiltersService from "../../../model/service/dataStorage/FIltersService";
import IViewOptions from "../../../model/interface/dataStorage/view/IViewOptions";
import {ActionType} from "../../../model/interface/dataStorage/IAction";
import settingsService from "../../../model/service/SettingsService";
import ViewEditButton from "./ViewEditButton";

interface IState {
    results: Array<IRestResource>,
    loading: boolean,
    count: number,
    totalCount: number,
    page: number
    customFilters?: IRestServiceFilters
}

interface IProps extends IBaseViewProps {
    findServiceByContentType: (contentType: IContentType) => IRepositoryService,
    findContentType: (uuid: string) => IContentType,
    user: IUser
}

class ViewTimeLine extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            results: [],
            loading: false,
            count: 0,
            totalCount: 0,
            page: 1,
            customFilters: ViewCustomFilters.getDefault(props.settings.customFilters)[this.getContentType().fullClassName]
        }
    }

    componentDidMount() {
        this.load(false).then()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.reload !== this.props.reload && this.props.reload) {
            this.load().then()
        }
    }

    load = (append = true, force = false): Promise<void> => {
        const {page, results, customFilters, totalCount} = this.state
        const {findServiceByContentType, filters} = this.props
        const contentType = this.getContentType()
        const service = findServiceByContentType(contentType)
        const {timelineField, timelineLength} = this.getViewOptions()
        if (totalCount && totalCount <= results.length && !force) {
            return Promise.resolve()
        }
        this.setState({
            loading: true
        })
        const orderField = this.getContentType().fields.find(field => field.uuid === timelineField)?.name
        let fixedFilters = FiltersService.makeFixed({...filters, ...customFilters})
        const options: IRestServiceOptions = {
            limit: timelineLength,
            page: force ? 1 : page,
            order: orderField ? {'main': {field: orderField, direction: "DESC"}, 'help': {field: 'id'}} : {},
            filters: {
                ...fixedFilters,
            },
            view: this.props.viewUnit.view,
            cache: !force
        }
        return service.collectionList(options).then(({results, count, totalCount}) => {
            return new Promise<void>(resolve => {
                this.setState(state => ({
                    results: append ? [...state.results, ...results] : results,
                    totalCount,
                    count,
                    loading: false
                }), () => resolve())
            })
        })
    }

    doAction = (result?: IActionResult) => {
        if (result) {
            return new Promise<void>(() => {
                if (this.props.onFinishAction) {
                    return this.props.onFinishAction(result)
                } else {
                    return this.load(false, true)
                }
            })
        }
        return Promise.resolve()
    }

    onCardActionFinished = (result?: IActionResult): Promise<void> => {
        const reloadTypes: ActionType[] = ['delete', 'import']
        return reloadTypes.includes(result?.action.type || '') ? this.load(false, true) : Promise.resolve()
    }

    getContentType() {
        return this.props.findContentType(this.props.viewUnit.contentTypes[0])
    }

    getViewOptions() {
        return this.props.viewUnit.options as IViewOptions
    }

    getCards() {
        return this.getContentType().cards.filter(card => this.getViewOptions().timelineCards?.includes(card.uuid) || this.getViewOptions().timelineCard === card.uuid)
    }

    incrementPage(reset: boolean = false) {
        this.setState(state => ({page: reset ? 1 : state.page + 1}), this.load)
    }

    onCustomFilterChange = (filters?: DefaultCustomFilters) => {
        this.setState({customFilters: filters?.[this.getContentType().fullClassName]},
            () => this.load(false, true))
    }

    render() {
        const {viewUnit, title, titleLevel, match, history, settings, view} = this.props
        const {results, loading, totalCount, page} = this.state;
        const cards = this.getCards()
        const {timelineLength} = this.getViewOptions()

        return (
            <div className={'position-relative'}>
                <div className={"d-flex justify-content-between align-content-center mb-2"}>
                    <div className={"d-flex align-content-center align-self-center align-items-center"}>
                        <Typography.Title
                            style={{color: settingsService.getThemeColor()}}
                            level={titleLevel || 3}
                            className={"mb-0"}
                        >{title || view.label}</Typography.Title>

                        <Typography className={"font-size-base font-weight-normal text-muted"}>
                            <Divider type={"vertical"}/>
                            načteno {Math.min(results.length, page * timelineLength)} záznamů z {totalCount}
                        </Typography>
                    </div>
                    <Row gutter={[4, 6]}>
                        {viewUnit.schemaActions.length > 0 && (
                            <Col>
                                <Row gutter={[6, 6]}>
                                    {viewUnit.schemaActions.map(action => {
                                        return (
                                            <Col key={action.uuid}>
                                                <ViewAction
                                                    history={history}
                                                    match={match}
                                                    key={action.uuid} action={action}
                                                    onFinish={this.doAction}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>
                        )}
                        <ViewEditButton {...this.props}/>
                    </Row>
                </div>
                {settings.customFilters && (
                    <ViewCustomFilters filters={settings.customFilters} onChange={this.onCustomFilterChange}/>
                )}
                <div className={'mt-3 p-2'}>
                    <Spin spinning={loading && page === 1}>
                        <Timeline className={'pl-2'}
                                  pending={(totalCount > page * timelineLength || (loading)) && results.length > 0 && (
                                      <Button loading={page > 1 && loading} onClick={() => this.incrementPage()}
                                              icon={<DownloadOutlined/>}>
                                          Načíst další
                                      </Button>)}>
                            {results.slice(0, page * timelineLength).map(event => (
                                <Timeline.Item className={'pb-2'} key={event.id}>
                                    {cards.length && (
                                        <Card card={cards[0]} cards={cards} resource={event} match={match}
                                              history={history} onActionFinish={this.onCardActionFinished}/>
                                    )}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        {page > 1 && (
                            <Button onClick={() => this.incrementPage(true)} icon={<VerticalAlignTopOutlined/>}>
                                Skrýt
                            </Button>
                        )}
                        {results.length === 0 && !loading && (
                            <Empty description={'Žádná data'}/>
                        )}
                    </Spin>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        findServiceByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        user
    }
}

export default connect(mapStateToProps)(ViewTimeLine)