import React, {RefObject} from "react";
import {Button, Form, FormInstance, Modal} from "antd";
import IFormOptions from "../../../../../../model/interface/form/IFormOptions";
import ISubmitOptions from "../../../../../../model/interface/form/elementOptions/ISubmitOptions";
import ButtonProperties from "../../../widget/optionEditor/ButtonProperties";

interface IProps {
    options: ISubmitOptions
    onFinish: (values?: IFormOptions) => void
}

class SubmitEditor extends React.Component<IProps, ISubmitOptions> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options,
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then((values: ISubmitOptions) => {
            this.props.onFinish({...this.state, ...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    render() {
        return (
            <Modal
                title={'Upravit tlačítko'}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>
                ]}
            >
                <Form initialValues={this.state || {}} ref={this.formRef}>
                    <ButtonProperties options={{...this.state, label: this.state.label || this.state.text}}/>
                </Form>
            </Modal>

        )
    }
}

export default SubmitEditor