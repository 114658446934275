import React from "react";
import WidgetTool from "./WidgetTool";
import WidgetChildren from "./WidgetChildren";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";


class ColumnWidget extends React.Component<IWidgetProps> {
    render() {
        return (
           <>
               <WidgetTool {...this.props} add={true} edit={true}/>
               <WidgetChildren {...this.props}/>
           </>
        );
    }
}

export default ColumnWidget