import {Input, Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase from "./IFilterBase";
import {API_FILTER_TYPE} from "../../model/constants/ApiConstant";
import FilterDropDown from "./FilterDropDown";

const SearchFilter: IFilterBase = {
    handleSearch(setSelectedKeys, selectedKeys, confirm) {
        confirm();
    },

    handleReset(clearFilters, setValue) {
        setValue([])
        clearFilters()
    },

    build(item, field, state, setValue): IFilterFunctions {
        const dataIndex = field.name
        let searchInput: any

        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                const search = selectedKeys[0] ? selectedKeys[0].value : undefined
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                        <Input
                            ref={node => {
                                searchInput = node;
                            }}
                            placeholder={'Vyhledat'}
                            value={search as string | undefined}
                            onChange={e => setSelectedKeys([{value: e.target.value, type: API_FILTER_TYPE.LIKE}])}
                            onPressEnter={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}
                            style={{marginBottom: 8, display: 'block'}}
                        />
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered) => {
                return (
                    <Tooltip title={filtered && state[0] ? "Filtrováno: " + state[0].value : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                return !value.search ? true : record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.search.toLowerCase());
            },
            onFilterDropdownVisibleChange: (visible) => {
                if (visible && searchInput) {
                    setTimeout(() => searchInput.select());
                }
            }
        }
    }
}

export default SearchFilter