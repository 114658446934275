import React from "react";
import ITextOptions from "../../../../model/interface/form/elementOptions/ITextOptions";
import {Typography} from "antd";
import Utils from "../../../../utils";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import WidgetTool from "./WidgetTool";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import settingsService from "../../../../model/service/SettingsService";


class TextWidget extends React.Component<IWidgetProps<IWidgetPropsFunctions, ITextOptions>> {

    renderText = (options: ITextOptions) => {
        const styles = options.style && !Utils.isNull(options.style) ?
            options.style.reduce((a, v) => ({...a, [v]: true}), {}) : {}
        const params = {
            ...styles, style: {color: options.color},
            ellipsis: Utils.toBoolean(options.ellipsis),
            level: Number(options.titleLevel ? options.titleLevel : 1) as ITextOptions["titleLevel"]
        }

        return (
            <div>
                {Utils.toBoolean(options.wysiwyg) ? (
                        <div className={"ant-typography"} dangerouslySetInnerHTML={{__html: (options.text)}}/>)
                    :
                    Utils.toBoolean(options.isTitle) ? (
                        <Typography.Title {...params} style={{color: settingsService.getThemeColor()}}>
                            {options.text}
                        </Typography.Title>
                    ) : Utils.toBoolean(options.copyable) ? (
                        <Typography.Paragraph copyable={true} {...params}>{options.text}</Typography.Paragraph>
                    ) : (
                        <Typography.Text {...params}>{options.text}</Typography.Text>
                    )
                }
            </div>
        )
    }

    render() {
        const {options} = this.props

        return (
            <>
                <WidgetTool {...this.props} edit={true}/>
                {this.renderText(options)}
            </>
        );
    }
}

export default TextWidget