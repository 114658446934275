type ITreeNode = {
    children: (number | string)[],
    parent: number | string |null,
    id: number | string,
    [name: string]: any
}

type Structure = {
    [index in (number | string)]: any
}

export interface ITreeStructure extends Structure {}

class TreeStructure {
    static build(nodeList: ITreeStructure) {
        let tree: ITreeNode[] = []

        Object.keys(nodeList).forEach(key => {
            let node = nodeList[key]
            !node.parent && nodeList[node.id] && tree.push(this.buildNode(node.id, nodeList))
        })

        return tree
    }

    private static buildNode(id: number | string, nodeList: ITreeStructure) {
        const node = {...nodeList[id]}
        let children: any[] = []
        Object.entries(nodeList).forEach(node => {
            if (node[1].parent === id){
                children.push(this.buildNode(node[1].id, nodeList))
            }
        })
        if (!children.length) {
            return node
        }

        return {...node, children: children}
    }

    static buildJson(nodeList: ITreeStructure, replacer?: (string | number)[] | null | undefined, space?: number) {
        return JSON.stringify(this.build(nodeList), replacer, space)
    }

    static destruct(tree: [ITreeNode | any]) {
        let structure = {}
        return this.destructNode(structure, tree[0])
    }

    private static destructNode(nodeList: ITreeStructure, node: ITreeNode, parent?: ITreeNode) {
        nodeList[node.id] = {
            ...node,
            parent: parent ? parent.id : null,
            children: []
        }
        if (parent) {
            nodeList[parent.id].children.push(node.id)
        }
        if (node.children) {
            node.children.forEach((child: any) => {
                nodeList = this.destructNode(nodeList, child, node)
            })
        }
        return nodeList
    }

    static sortChildren(id: number | string, nodeList: ITreeStructure, sortProperty: string, desc: boolean = false) {
        const node = this.getNode(id, nodeList)
        let childrenNodes: ITreeNode[] = []
        node.children.forEach((childId) => {
            nodeList[childId] && childrenNodes.push(nodeList[childId])
        })

        return childrenNodes.sort((a, b) =>
            (a[sortProperty] > b[sortProperty]) ? (desc ? -1 : 1) :
                ((b[sortProperty] > a[sortProperty]) ? (desc ? 1 : -1) : 0))
    }

    static updateChildPosition(id: number | string, nodeList: ITreeStructure, up = true, sortProperty: string) {
        const node = this.getNode(id, nodeList)
        const direction = up ? -1 : 1
        if (node.parent) {
            const children = this.sortChildren(node.parent, nodeList, sortProperty)
                .map(value => value.id)
            const pos = children.indexOf(id)
            if ((up && pos > 0) || (!up && pos < children.length - 1)) {
                nodeList[children[pos + direction]].weight = pos
                nodeList[id].weight = pos + direction
            }
        }

        return nodeList
    }

    private static getNode(id: number | string, nodeList: ITreeStructure): ITreeNode {
        if (!nodeList[id]) {
            throw new Error('Tree structure received an invalid id index!')
        }
        return nodeList[id]
    }
}

export default TreeStructure




