import React, {RefObject} from "react";
import {Button, Form, FormInstance, Mentions} from "antd";
import RoutePicker from "../../../../shared/pickers/RoutePicker";
import {ICardStepProps} from "./CardModal";
import {RightOutlined} from "@ant-design/icons";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import ICard, {CARD_RESERVED_NAMES} from "../../../../../model/interface/dataStorage/ICard";
import ActionPicker from "../../../../shared/pickers/ActionPicker";

interface IState {
    formRef: RefObject<FormInstance>,
    formValues: ICard,
    changedCards: ICard[]
}

interface IProps extends ICardStepProps {
    contentType: IContentType
}

export default class CardProperties extends React.Component<IProps, IState> {
    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            formValues: {...props.card},
            changedCards: props.contentType.cards.filter(c => c.uuid !== props.card.uuid)
        }
    }

    confirm = (back: boolean = false) => {
        const {formRef, formValues, changedCards} = this.state
        formRef.current?.validateFields().then(values => {
            this.props.onChange({...this.props.card, ...values, ...formValues}, back, changedCards)
        })
    }

    onValuesChange = (formValues: ICard) => {
        this.setState(state => ({formValues: {...state.formValues, ...formValues}}))
    }

    render() {
        const {card, contentType} = this.props
        const {formRef} = this.state

        return (
            <>
                <Form
                    onValuesChange={this.onValuesChange}
                    initialValues={card ? card : {}}
                    ref={formRef}
                >
                    <Form.Item
                        label={"Název"}
                        name={"name"}
                        rules={[{required: true}]}
                    >
                        <Mentions
                            split={''}
                            placeholder={"název nebo # pro vyhrazený název"}
                            prefix={'#'}
                        >
                            {Object.entries(CARD_RESERVED_NAMES).map(([label, value]) => (
                                <Mentions.Option key={value} value={value.slice(1)}>
                                    {label}
                                </Mentions.Option>
                            ))}
                        </Mentions>
                    </Form.Item>
                    <Form.Item
                        label={"Cesta"}
                        name={"route"}
                        rules={[{required: true}]}
                    >
                        <RoutePicker identifier={contentType.name}/>
                    </Form.Item>
                    <Form.Item
                        label={"Akce"}
                        name={"action"}
                    >
                        <ActionPicker contentTypeUuid={contentType.uuid}/>
                    </Form.Item>
                    <Button icon={<RightOutlined/>} onClick={() => this.confirm()} type={"primary"}>
                        Následující krok
                    </Button>
                </Form>
            </>
        )
    }
}