import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import FieldWysiwygOptionsEditor, {IFieldWYSIWYGOptions} from "./FieldWysiwygOptionsEditor";

export interface IFieldTextOptions extends IFieldWYSIWYGOptions {

}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {

}

class FieldCompanyOptionEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    static getDefaultOptions(): IFieldWYSIWYGOptions {
        return {
            ...FieldWysiwygOptionsEditor.getDefaultOptions()
        }
    }

    render() {
        const {options} = this.props
        return (
            <div>
                <FieldWysiwygOptionsEditor options={options}/>
            </div>
        )
    }
}

export default FieldCompanyOptionEditor