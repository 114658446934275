import React from "react";
import {Button, Empty, Form} from "antd";
import FormElementType from "./FormElementType";
import WidgetTool from "../../widget/WidgetTool";
import IWidgetProps from "../../../../../model/interface/widget/IWidgetProps";
import IFormElementFunctions from "../../../../../model/interface/form/IFormElementFunctions";
import IEntityTableOptions from "../../../../../model/interface/form/elementOptions/IEntityTableOptions";
import FormElementEntityTable from "./FormElementEntityTable";
import IField from "../../../../../model/interface/dataStorage/IField";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import {withRouter} from "react-router-dom";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";


interface IProps extends IWidgetProps<IFormElementFunctions, IEntityTableOptions> {
    getServiceByClass: (className: string) => IRepositoryService
}

class FormElementEntityTableWrapper extends React.Component<IProps> {

    render() {
        const {id, functions, preview} = this.props
        const children = functions.getSortedChildren(id)

        const field = functions.getNode(id).field as IField

        return (
            <div>
                <WidgetTool {...this.props} functions={{
                    ...functions,
                    appendNode: (id) => functions.appendNode(id, FormElementType.GROUP_FIELD, field)
                }} add={true} edit={true}/>
                {children.length > 0 && field ? (
                    <Form.Item name={field.name} label={field.label}>
                        <FormElementEntityTable {...this.props} className={field.targetEntity!} />
                    </Form.Item>
                ) : !preview && (
                    <Empty description={
                        <Button type={"link"}
                                onClick={() => functions.appendNode(id, FormElementType.GROUP_FIELD, field)}>
                            Přidat sloupec
                        </Button>
                    }/>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        getServiceByClass: (className: string) => selectors.services.findOneByFullClassName(state, className)
    }
}

export default connect(mapStateToProps)(FormElementEntityTableWrapper)