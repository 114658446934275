import {Form, Slider} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IconPicker from "../../../../../shared/IconPicker";

export interface IFieldRatingOptions {
    rateIcon?: string
    rateLength?: number
}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {
    format?: string
    type?: string
}

class FieldRateOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            format: props.options.dateFormat
        };
    }

    static getDefaultOptions(): IFieldRatingOptions {
        return {rateLength: 5}
    }

    render() {
        return (
            <div>
                <Form.Item name={'rateLength'} label={'Zvolte počet bodů'} rules={[{required: true}]}>
                    <Slider min={1} max={10}/>
                </Form.Item>
                <Form.Item name={'rateIcon'} label={'Zvolte iconu'}>
                    <IconPicker/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldRateOptionsEditor