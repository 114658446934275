import React from "react"
import {Col, Form, Input, Row, Switch, Typography} from "antd";
import {IViewSettingsProps} from "../ViewSettings";
import IViewCalendarSettings from "../../../../model/interface/dataStorage/view/calendar/IViewCalendarSettings";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IContentType from "../../../../model/interface/dataStorage/IContentType";

interface IProps extends IViewSettingsProps {
    findContentTypeByUuid: (uuid: string) => IContentType
}

class ViewCalendarSettings extends React.Component<IProps, IViewCalendarSettings> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            ...props.settings
        }
    }

    defineColor = (color: string, contentType: string) => {
        this.setState(state => ({
            calendarColors: {...state.calendarColors || {}, [contentType]: color}
        }), this.onChange)
    }

    onChange = () => {
        this.props.onChange({...this.state})
    }

    onChangeForm = (values: any): void => {
        this.setState(state => ({...state, ...values}), this.onChange)
    }

    render() {
        const {view, findContentTypeByUuid} = this.props
        const {calendarColors} = this.state

        return (
            <>
                <Form onValuesChange={this.onChangeForm} initialValues={this.state}>
                    <Row gutter={12}>
                        <Col sm={12}>
                            <Form.Item name={'calendarSaveMonth'} label={'Zapamatovat si vybraný měsíc'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'calendarSelectedContentTypes'} label={'Zapamatovat si vybrané typy obsahu'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'calendarSaveMode'} label={'Zapamatovat si vybraný režim zobrazení'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Typography.Text strong className={'mb-2 d-block'}>Barvy</Typography.Text>
                {view.contentTypes.map(uuid => {
                    const contentType = findContentTypeByUuid(uuid)
                    return (
                            <div>
                                <Form.Item label={contentType.label}>
                                    <Input value={calendarColors?.[contentType.fullClassName]} type={"color"}
                                           className={'w-100'}
                                           onChange={(e) =>
                                               this.defineColor(e.target.value, contentType.fullClassName)}/>
                                </Form.Item>
                            </div>
                        )
                    }
                )}
            </>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ViewCalendarSettings)