import React from "react";
import IEmployee from "../../../../model/interface/company/IEmployee";
import {Avatar} from "antd";
import AvatarStatus from "../../../shared/AvatarStatus";

interface IProps {
    employee: IEmployee|null,
    mode?: "default"|"simple"
}

class EmployeeLabel extends React.Component<IProps> {
    render() {
        const { employee } = this.props
        if(!employee) {
            return (<span className={"text-muted"}>Neplatný zaměnstnanec</span>)
        }
        switch(this.props.mode) {
            case('simple'):
                // @ts-ignore
                return (<AvatarStatus src={employee.photo ? employee.photo.thumbnailUrl : null} name={employee.name + ' ' + employee.surname} size={24} />)
            default:
                // @ts-ignore
                return (<AvatarStatus src={employee.photo ? employee.photo.thumbnailUrl : null} name={employee.name + ' ' + employee.surname} subTitle={employee.email}/>)
        }

    }
}

export default EmployeeLabel