import React, {RefObject} from "react";
import {Button, Divider, Form, FormInstance, Input, Modal, Select, Switch, Table, Typography} from "antd";
import selectors from "../../../../../../../redux/selectors";
import {connect, RootStateOrAny} from "react-redux";
import ICardWidgetRelationField from "../../../../../../../model/interface/dataStorage/card/ICardWidgetRelationField";
import ScalarFieldEditor from "./ScalarFieldEditor";
import IContentType from "../../../../../../../model/interface/dataStorage/IContentType";
import {ISetupState} from "../../../../../../../redux/reducers/Setup";
import _ from "underscore";
import Title from "antd/es/typography/Title";
import FormElementField from "../../../../form/FormElement/FormElementField";
import IFieldOptions from "../../../../../../../model/interface/form/elementOptions/IFieldOptions";
import {DeleteOutlined, PlusOutlined, SwapOutlined} from "@ant-design/icons";
import FormFieldType from "../../../../form/FormElement/formField/FormFieldType";
import IRestServiceFilter from "../../../../../../../model/interface/api/IRestServiceFilter";
import IField, {
    FIELD_MODE_RELATION,
    RELATION_FIELD_TYPE
} from "../../../../../../../model/interface/dataStorage/IField";
import FieldEditor from "../../../../form/FormElement/optionEditor/FieldEditor";
import {
    API_FILTER_TYPE,
    API_FILTER_TYPE_COMPARISON_LIST,
    API_FILTER_TYPE_LIST
} from "../../../../../../../model/constants/ApiConstant";
import ScalarFieldWidget from "../ScalarFieldWidget";
import IRepositoryService from "../../../../../../../model/interface/IRepositoryService";
import IView from "../../../../../../../model/interface/dataStorage/IView";
import FieldCollapseInfo from "../../../field/FieldCollapseInfo";


interface IProps {
    options: ICardWidgetRelationField
    onFinish: (values?: ICardWidgetRelationField) => void
    findViewsByContentType: (uuid: string) => IView[]
    findContentTypeByClassName: (name: string) => IContentType,
    findServiceByClassName: (className: string) => IRepositoryService,
    field: IField
}

interface IState extends ICardWidgetRelationField {
    currentFilterField?: IField,
    currentFilterFieldNames?: string,
    isDefaultRelationValue?: boolean,
    relationFieldSearchBy?: string,
    // type: string|null
}

class RelationFieldEditor extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options,
            view: props.options.view,
            filters: props.options.filters ? props.options.filters : [],
            // type: null
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>
    filterFormRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        const {field, filters, relationField} = this.state
        this.formRef.current?.validateFields().then((values: ICardWidgetRelationField) => {
            this.props.onFinish({...values, filters, relationField, field})
        })
    }

    valueUpdated = (changes: any) => {
        const values = {...this.state, ...changes}
        if (!this.state.view && values.view) {
            const view = _.findWhere(this.getViews(), {id: values.view})
            if (view && !values.title) {
                values.title = view!.label
            }
            if (view && !values.allowSettings) {
                values.allowSettings = view!.allowSettings
            }
            if (view && !values.allowExport) {
                values.allowExport = view!.allowExport
            }
            setTimeout(() => {
                this.formRef.current?.resetFields(['title', 'allowSettings', 'allowExport'])
            }, 1)
        }
        this.setState(values)
    }

    onCancel = () => {
        this.props.onFinish()
    }

    createFilter = () => {
        this.filterFormRef.current?.validateFields().then(values => {
            let {filters} = this.state;
            if (filters && filters.findIndex(filter => JSON.stringify(filter) === JSON.stringify(values)) === -1) {
                delete values['relationField']
                delete values['relationFieldSearchBy']
                values['value'] = this.formatValue(values);
                this.setState(state => ({filters: [...(state.filters ? state.filters : []), {...values}]}));
            }
            this.filterFormRef.current?.resetFields()
            this.setState({currentFilterField: undefined, currentFilterFieldNames: undefined})
        })
    };

    formatValue(values: any) {
        if (this.state.currentFilterField && values['value']) {
            return FormFieldType.formatFromForm(FieldEditor.detectType(this.state.currentFilterField), values['value'])
        }
        return ''
    }

    transformNameArrayToFields(names: string[]): IField[] {
        const {findContentTypeByClassName} = this.props
        const originalField = this.props.field as undefined | IField
        let currentName = undefined as undefined | string
        let fields = [] as IField[]
        if (originalField!.targetEntity) {
            let contentType = findContentTypeByClassName(originalField!.targetEntity) as IContentType
            let field = undefined
            while ((currentName = names.shift())) {
                field = this.findField(contentType, currentName);
                fields.push(field!)
                console.log('debug A', field, currentName, names)
                if (field && field.mode !== FIELD_MODE_RELATION) {
                    if (names.length !== 0) {
                        throw new Error('There are nested field names but current field is not a relation');
                    }
                    contentType = findContentTypeByClassName(field!.targetEntity!)
                }
            }
        }
        return fields
    }

    findField(contentType: IContentType, currentName: string | undefined) {
        return contentType.fields.find(field => field.name === currentName)
    }

    filterFieldChange = (field: string) => {
        const fieldObjects = this.transformNameArrayToFields(field.split('.'))
        const lastField = fieldObjects[fieldObjects.length - 1]
        if (lastField) {
            this.setState({currentFilterField: lastField, currentFilterFieldNames: field})
            this.filterFormRef.current?.resetFields()
            const type = [RELATION_FIELD_TYPE.MANY_TO_MANY, RELATION_FIELD_TYPE.ONE_TO_MANY].indexOf(lastField!.type) >= 0 ? API_FILTER_TYPE.CONTAINS : API_FILTER_TYPE.EQUAL
            this.filterFormRef.current?.setFieldsValue({field, type})
            // this.filterFormRef.current?.setFieldsValue({field: field?.name, type})
        }
        // if (contentType) {

        //
        // const field = contentType.fields.find(value => value.name === fieldName)
        // if(field) {
        //     this.setState({currentFilterField: field})
        //     this.filterFormRef.current?.resetFields()
        //     const type = ['many_to_many','one_to_many'].indexOf(field!.type) >= 0 ? API_FILTER_TYPE.CONTAINS : API_FILTER_TYPE.EQUAL
        //     this.filterFormRef.current?.setFieldsValue({field: field?.name, type})
        // }
        // }
    }

    relationFieldChange = (checked: boolean) => {
        this.setState({relationField: checked ? this.state.currentFilterFieldNames : undefined})
    }

    handleDelete = (removeFilter: IRestServiceFilter) => {
        const filters = this.state.filters && this.state.filters
            .filter(filter => JSON.stringify(removeFilter) !== JSON.stringify(filter));
        this.setState({filters});
    };

    onFilterFormValuesChange = (values: IRestServiceFilter | any) => {
        this.setState(state => ({
            isDefaultRelationValue: values['relationField'] ? true : values['value'] ? false : undefined,
            relationFieldSearchBy: values['relationFieldSearchBy'] || state.relationFieldSearchBy
        }))
    }

    getViews = () => {
        const {findViewsByContentType, findContentTypeByClassName} = this.props
        const {field} = this.props
        const contentType = field.targetEntity && findContentTypeByClassName(field.targetEntity)
        return contentType ? findViewsByContentType(contentType.uuid) : []
    }

    render() {
        const {findContentTypeByClassName} = this.props
        const {
            view,
            filters,
            currentFilterField,
            relationField,
            isDefaultRelationValue,
            relationFieldSearchBy,
            // type
        } = this.state
        const {field} = this.props
        const contentType = field.targetEntity && findContentTypeByClassName(field.targetEntity)
        let autoCompleteCollection = ''
        if (currentFilterField && currentFilterField.targetEntity) {
            const baseContentType = findContentTypeByClassName(currentFilterField.targetEntity)
            autoCompleteCollection = baseContentType ? baseContentType.collection : ''
        }

        const views = this.getViews()
        const fieldType = currentFilterField ? FieldEditor.detectType(currentFilterField) : null;
        const initialValues = {
            titleLevel: 2,
            ...this.state
        }
        console.log('initialValues', initialValues)
        return (
            <Modal
                title={'Upravit vlasnost'}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                width={700}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <FieldCollapseInfo field={field}/>
                <Form initialValues={initialValues} ref={this.formRef} onValuesChange={this.valueUpdated}>
                    {/*<Form.Item*/}
                    {/*    name={'type'}*/}
                    {/*    label={'Zobrazení'}*/}
                    {/*>*/}
                    {/*    <Radio.Group>*/}
                    {/*        <Radio value={'view'}>Pohled</Radio>*/}
                    {/*        <Radio value={'list'}>Seznam</Radio>*/}
                    {/*    </Radio.Group>*/}
                    {/*</Form.Item>*/}
                    {/*{type === 'list' && (*/}
                    {/*    <Form.Item name={'presenter'} label={'Zobrazení'} rules={[{required: true}]}>*/}
                    {/*        <Select allowClear={true}>*/}
                    {/*            {presenters.map(presenter => (*/}
                    {/*                <Select.Option key={presenter.value} value={presenter.value}>{presenter.label}</Select.Option>*/}
                    {/*            ))}*/}
                    {/*        </Select>*/}
                    {/*    </Form.Item>*/}
                    {/*)}*/}
                    {/*{type === 'view' && (*/}
                    <>
                        <Form.Item name={'view'} label={'Pohled'} rules={[{required: true}]}>
                            <Select allowClear={true} disabled={views.length < 1}>
                                {views.map(view => (
                                    <Select.Option key={view.uuid}
                                                   value={view.uuid}>{view.label} [{view.name}]</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name={'title'} label={'Titulek'}>
                            <Input disabled={!view}/>
                        </Form.Item>
                        <Form.Item name={'titleLevel'} label={'Velikost titulku'}>
                            <Select disabled={!view} options={[{value: 1, label: 'Hlavní nadpis'}, {
                                value: 2,
                                label: 'Velký nadpis'
                            }, {value: 3, label: 'Střední nadpis'}, {value: 4, label: 'Malý nadpis'}, {
                                value: 5,
                                label: 'Nejmenší nadpis'
                            }]}/>
                        </Form.Item>
                        <Form.Item name={'allowSettings'} label={'Povolit nastavení'} valuePropName={'checked'}>
                            <Switch disabled={!view}/>
                        </Form.Item>
                        {views.length < 1 &&
                            <Typography.Text type={"danger"}>
                                Pro navázaný typ obsahu neexistuje odpovídající pohled
                            </Typography.Text>}
                    </>
                    {/*)}*/}
                </Form>
                {view && contentType && (
                    <div className={'border p-3'}>
                        <Title level={4}>Filtry</Title>
                        <div style={{marginBottom: 16}}>
                            <Table
                                pagination={false}
                                dataSource={filters}
                                columns={[
                                    {
                                        title: 'Název pole',
                                        dataIndex: 'field',
                                        render: (_, filter) => {
                                            return (
                                                <>
                                                    {this.transformNameArrayToFields(filter.field.split('.')).map((field, index) => (
                                                        <>
                                                            {index > 0 && <Divider type={"vertical"}/>}
                                                            {field.label}
                                                        </>
                                                    ))}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        title: 'Hodnota', dataIndex: 'value', render: (_, filter) => {
                                            const field = contentType.fields.find(field => field.name === filter.field)
                                            return field ? ScalarFieldWidget
                                                    .renderPreview(ScalarFieldEditor.detectType(field), filter.value) :
                                                _.toString()
                                        }
                                    },
                                    {
                                        title: 'Typ filtru',
                                        dataIndex: 'type',
                                        render: (type) => API_FILTER_TYPE_LIST
                                            .find(filterType => filterType.value === type)?.label
                                    },
                                    {
                                        render: (_, filter) => <Button size={"small"} type={'link'} danger
                                                                       icon={<DeleteOutlined/>}
                                                                       onClick={() => this.handleDelete(filter)}/>
                                    },
                                ]}/>
                        </div>
                        <Form ref={this.filterFormRef} initialValues={{type: API_FILTER_TYPE.EQUAL}}
                              onValuesChange={this.onFilterFormValuesChange}>
                            <Form.Item name={'field'} label={'Vlastnost nebo vazba'} rules={[{required: true}]}>
                                <Select onChange={this.filterFieldChange}>
                                    {contentType.fields.map(field => {
                                        let targetContentType = undefined as IContentType | undefined
                                        if (field.mode === FIELD_MODE_RELATION) {
                                            targetContentType = this.props.findContentTypeByClassName(field.targetEntity!)
                                        }
                                        if (targetContentType) {
                                            return (
                                                <Select.OptGroup key={field.label}>
                                                    <Select.Option key={field.id} value={field.name}>
                                                        {field.label} [{field.name}]
                                                    </Select.Option>
                                                    {targetContentType.fields.map(targetField =>
                                                        (
                                                            <Select.Option key={targetField.id}
                                                                           value={field.name + '.' + targetField.name}>
                                                                {field.label} - {targetField.label} [{targetField.name}]
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select.OptGroup>
                                            )
                                        } else {
                                            return (
                                                <Select.Option key={field.id} value={field.name}>
                                                    {field.label} [{field.name}]
                                                </Select.Option>
                                            )
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                            {currentFilterField && (
                                <div>
                                    {currentFilterField.mode === 'relation' && !isDefaultRelationValue && (
                                        <Form.Item name={'relationFieldSearchBy'}
                                                   label={'Hledat dle pole'}
                                                   rules={[{required: true}]}
                                                   help={'Vyberte pole, podle kterého chcete vyhledat vazebnu hodnotu'}>
                                            <Select>
                                                {contentType.fields.filter(field => field.type.includes('String'))
                                                    .map(field => {
                                                        return (
                                                            <Select.Option key={field.id} value={field.name}>
                                                                {field.label} [{field.name}]
                                                            </Select.Option>
                                                        )
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    )}
                                    <Form.Item name={'value'} label={'Ocekavana hodnota'}
                                               valuePropName={fieldType === FormFieldType.FIELD_BOOLEAN ?
                                                   'checked' : 'value'}
                                               rules={[{required: currentFilterField?.mode !== 'relation'}]}>
                                        {((!isDefaultRelationValue && relationFieldSearchBy)
                                                || !(currentFilterField?.mode === 'relation'))
                                            && FormElementField.renderField({
                                                type: fieldType,
                                                datePicker: 'date',
                                                showClear: true,
                                                autocompleteField: relationFieldSearchBy,
                                                autocompleteMode: API_FILTER_TYPE.LIKE,
                                                autocompleteMin: 2,
                                                autocompleteCollection: autoCompleteCollection
                                            } as IFieldOptions, field)}
                                    </Form.Item>
                                    {currentFilterField.mode === 'relation'
                                        && field.targetEntity === contentType?.fullClassName && (
                                            <div>
                                                <div className={'text-center'}>
                                                    <div>OR</div>
                                                    <SwapOutlined/>
                                                </div>
                                                <Form.Item name={'relationField'} label={'Použít vazebné pole'}
                                                           valuePropName={'checked'}>
                                                    <Switch
                                                        onChange={(checked) => this.relationFieldChange(checked)}
                                                        disabled={(!!relationField && currentFilterField.name !== relationField)
                                                            || isDefaultRelationValue === false}/>
                                                </Form.Item>
                                            </div>
                                        )}
                                    <Form.Item label={"Typ filtru"} name={"type"} rules={[{required: true}]}>
                                        <Select disabled={currentFilterField?.mode === 'relation'}>
                                            {API_FILTER_TYPE_COMPARISON_LIST.map(filterType =>
                                                <Select.Option key={filterType.value} value={filterType.value}>
                                                    {filterType.label}
                                                </Select.Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </div>
                            )}
                            <Button onClick={this.createFilter} icon={<PlusOutlined/>}>
                                Add
                            </Button>
                        </Form>
                    </div>
                )}
            </Modal>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {views} = state.setup as ISetupState

    return {
        findViewsByContentType: (uuid: string) => views.filter(view => view.contentTypes.includes(uuid)),
        findContentTypeByClassName: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name),
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(state, className)
    }
}

export default connect(mapStateToProps)(RelationFieldEditor)
