import React, {RefObject} from "react"
import {Button, Col, Empty, Form, FormInstance, Row, Select, Typography} from "antd";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    DeleteOutlined,
    PlusOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined
} from "@ant-design/icons";
import ISortSettings from "../../../../model/interface/dataStorage/view/settings/ISortSettings";
import {TweenOneGroup} from "rc-tween-one";
import IRestServiceOrder from "../../../../model/interface/api/IRestServiceOrder";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import {IViewSettingsProps} from "../ViewSettings";
import {
    SortableContainer as sortableContainer,
    SortableElement as sortableElement,
    SortableHandle as sortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IField from "../../../../model/interface/dataStorage/IField";
import ViewTableSettings from "../table/ViewTableSettings";

interface IState {
    sort: ISortSettings
    formRef: RefObject<FormInstance>,
    currentContentType: string
}

interface IProps extends IViewSettingsProps {
    findContentTypeByClassName: (name: string) => IContentType
    findContentTypeByUuid: (uuid: string) => IContentType
}

class SortSettings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            sort: props.settings?.sort ? {...props.settings.sort} : {},
            formRef: React.createRef(),
            currentContentType: props.findContentTypeByUuid(props.view.contentTypes[0]).fullClassName
        }
    }

    handleClose = (removeSortItem: IRestServiceOrder) => {
        const {currentContentType} = this.state
        const sortItems = this.state.sort[currentContentType]
            .filter(sortItem => sortItem.field !== removeSortItem.field)
        this.setState(state => ({sort: {...state.sort, [currentContentType]: sortItems}}), this.onChange)
    }

    onValuesChange(values: any) {
        this.setState(state => ({sort: {...values, ...state.sort}}))
        this.onChange()
    }

    onChange() {
        this.props.onChange({sort: this.state.sort}).then();
    }

    getContentType() {
        const {findContentTypeByClassName} = this.props
        const {currentContentType} = this.state
        return findContentTypeByClassName(currentContentType)
    }

    onAdd = () => {
        this.state.formRef.current?.validateFields().then(values => {
            let {sort, currentContentType} = this.state;
            if (!sort[currentContentType] || sort[currentContentType].findIndex(sortItem => sortItem.field === values.field) === -1) {
                this.setState(state => ({
                    sort: {
                        ...state.sort,
                        [currentContentType]: [...(state.sort[currentContentType] || []), values]
                    }
                }), this.onChange)
            } else {
                const index = sort[currentContentType].findIndex(sortItem => sortItem.field === values.field)
                sort[currentContentType][index] = values
                this.setState(state => ({
                    sort: {...state.sort, [currentContentType]: [...sort[currentContentType]]}
                }), this.onChange)
            }
            this.state.formRef.current?.resetFields()
        })
    }

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }): void => {
        const {currentContentType} = this.state
        const sortList = arrayMove(this.state.sort[currentContentType], oldIndex, newIndex)
        this.setState(state => ({sort: {...state.sort, [currentContentType]: sortList}}), this.onChange);
    }

    onContentTypeChange = (name: string) => {
        this.setState({currentContentType: name}, this.state.formRef.current?.resetFields)
    }

    getField = (value: string, property: 'uuid' | 'name' = 'name') => {
        const contentType = this.getContentType()
        const field = contentType.fields.find(f => f[property] === value);
        if (!field) {
            throw new Error(`Field with identifier [${property}: ${value}] does not exist`)
        }
        return field
    }

    render() {
        const {sort, formRef, currentContentType} = this.state;
        const {view, findContentTypeByUuid} = this.props
        const contentType = this.getContentType()

        return (
            <>
                <Typography.Text strong className={'mb-2 d-block'}>Řazení</Typography.Text>
                {view.contentTypes.length > 1 && (
                    <Form.Item label={'Zvolte typ obsahu'}>
                        <Select value={currentContentType} onChange={this.onContentTypeChange}>
                            {view.contentTypes.map(uuid => {
                                const listContentType = findContentTypeByUuid(uuid)
                                return currentContentType && (
                                    <Select.Option value={listContentType.fullClassName} key={uuid}>
                                        {listContentType.label}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                )}
                <div>
                    <Row gutter={12}>
                        <Col sm={12}>
                            <Form layout={"vertical"} ref={formRef}
                                  onValuesChange={(values) => this.onValuesChange(values)}>
                                <Row gutter={12}>
                                    <Col sm={12}>
                                        <Form.Item name={'field'} label={'Pole'} rules={[{required: true}]}>
                                            <Select>
                                                {view.items
                                                    .filter(item => !!this.getField(item.field, 'uuid'))
                                                    .map(item => {
                                                        const field = this.getField(item.field, 'uuid')
                                                        return (
                                                            <Select.Option
                                                                disabled={ViewTableSettings.isSortableDisabled(this.getField(item.field, 'uuid'))}
                                                                key={item.id} value={field.name}>
                                                                {field.label}
                                                            </Select.Option>
                                                        )
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Item name={'direction'} label={'Směr'} rules={[{required: true}]}>
                                            <Select>
                                                <Select.Option key={'asc'} value={"ASC"}>
                                                    <Row justify={"space-between"} align={"middle"}>
                                                        ASC<SortAscendingOutlined style={{fontSize: 20}}/>
                                                    </Row>
                                                </Select.Option>
                                                <Select.Option key={'desc'} value={"DESC"}>
                                                    <Row justify={"space-between"} align={"middle"}>
                                                        DESC<SortDescendingOutlined style={{fontSize: 20}}/>
                                                    </Row>
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="dashed" onClick={() => this.onAdd()}
                                            icon={<PlusOutlined/>}>
                                        Přidat řazení
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col sm={12}>
                            {sort[contentType.fullClassName] && (
                                <SortableContainer
                                    lockAxis={"y"}
                                    onSortEnd={(sort) =>
                                        this.onSortEnd(sort)}
                                    useDragHandle
                                    children={sort[currentContentType].filter(item => !!this.getField(item.field))}
                                    onClose={this.handleClose}
                                    getField={this.getField}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByClassName: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name),
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(SortSettings)


const DragHandle = sortableHandle(() => {
    return (
        <div className={'d-inline-block'}>
            <div className={"d-flex flex-column pl-2 pr-2"} style={{cursor: "move"}}>
                <CaretUpOutlined/>
                <CaretDownOutlined/>
            </div>
        </div>
    )
})

const SortableItem = sortableElement(({
                                          sort,
                                          onClose,
                                          getField
                                      }: {
    sort: IRestServiceOrder
    onClose: (sortItem: IRestServiceOrder) => void,
    getField: (uuid: string) => IField
}) => {

    return (
        <Row justify={"space-between"} align={"middle"} className={'mb-2 border p-1 shadow-sm'}
             style={{zIndex: 1001}} key={sort.field}>
            <Row align={"middle"}>
                <DragHandle/> {getField(sort.field).label || getField(sort.field).name}
            </Row>
            <Row align={"middle"}>
                {`[${sort.direction}]`}
                {sort.direction && {
                    'ASC': <SortAscendingOutlined style={{fontSize: 20}}/>,
                    'DESC': <SortDescendingOutlined style={{fontSize: 20}}/>
                }[sort.direction]}
                <Button size={"small"} type={"link"} className={'ml-3'} danger icon={
                    <DeleteOutlined/>} onClick={() => onClose(sort)}/>
            </Row>

        </Row>
    )
})

const SortableContainer = sortableContainer(({
                                                 children,
                                                 onClose,
                                                 getField
                                             }: {
    children: IRestServiceOrder[]
    onClose: (sortItem: IRestServiceOrder) => void,
    getField: (uuid: string) => IField,
}) => {
    return (
        <div className={'position-relative'}>
            <TweenOneGroup
                className={'position-relative'}
                enter={{scale: 0.8, opacity: 0, type: 'from', duration: 100}}
                leave={{y: -20, opacity: 0, duration: 300}}
                appear={false}
            >
                {children.map((value, index) => {
                    return (
                        <SortableItem key={`item-${value.field}`} index={index} sort={value} getField={getField}
                                      onClose={onClose}/>
                    )
                })}
                {children.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'žádné položky'}/>}
            </TweenOneGroup>
        </div>
    )
});