import React from "react";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../redux/reducers/Setup";
import IUser from "../../../model/interface/security/IUser";
import {IBaseViewProps} from "./ViewUnit";
import {Button, Col, Dropdown, Menu} from "antd";
import {Link} from "react-router-dom";
import {SettingOutlined} from "@ant-design/icons";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import selectors from "../../../redux/selectors";

interface IState {

}

interface IProps extends IBaseViewProps {
    user: IUser,
    findContentTypeByUuid: (uuid: string) => IContentType,
}

class ViewEditButton extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {
            view,
            showBaseSettings,
            findContentTypeByUuid
        } = this.props

        return this.props.user.credentials.indexOf('configuration') >= 0 && !!view.id ? (
            <Col>
                <Dropdown trigger={["click"]} overlay={(
                    <Menu>
                        <Menu.Item key="1" onClick={showBaseSettings}>
                            Změnit výchozí nastavení
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Link to={"/app/configuration/views/" + view.id}>
                                Upravit pohled
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="3">
                            <Link
                                to={"/app/configuration/content-types/" + findContentTypeByUuid(view.contentType).id}>
                                Upravit typ obsahu
                            </Link>
                        </Menu.Item>
                    </Menu>
                )}>
                    <Button
                        style={{verticalAlign: "middle"}}
                        icon={<SettingOutlined/>}
                        size={"small"}
                    />
                </Dropdown>
            </Col>
        ) : <></>
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        findContentTypeByUuid: (uuid: string): IContentType => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        user
    }
}

export default connect(mapStateToProps)(ViewEditButton)