import IFilterFunctions from "./Filter/IFilterFunctions";
import SearchFilter from "./Filter/SearchFilter";
import RangeFilter from "./Filter/RangeFilter";
import ChooseFilter from "./Filter/ChooseFilter";
import {IFilterBaseBuild} from "./Filter/IFilterBase";
import BooleanFilter from "./Filter/BooleanFilter";
import DateFilter from "./Filter/DateFilter";
import ViewTableSettings from "../components/app/view/table/ViewTableSettings";
import {COMPOSITE_FIELD_TYPE, FIELD_TYPE, RELATION_FIELD_TYPE} from "../model/interface/dataStorage/IField";
import CompositeApprovalFilter from "./Filter/composite/CompositeApprovalFilter";
import CodeFilter from "./Filter/CodeFilter";

const FilterBuilder: IFilterBaseBuild = {
    build(item, field, state, setValue, values, remote = true, allFilters): IFilterFunctions {
        if (!item.filterable || ViewTableSettings.isFilterableDisabled(field)) {
            return {}
        }
        let filter: IFilterFunctions

        switch (field.type) {
            case(RELATION_FIELD_TYPE.MANY_TO_MANY):
            case(RELATION_FIELD_TYPE.ONE_TO_MANY):
            case(RELATION_FIELD_TYPE.MANY_TO_ONE):
            case(RELATION_FIELD_TYPE.ONE_TO_ONE):
                filter = ChooseFilter.build(item, field, state, setValue, values, remote, allFilters)
                break;
            case(FIELD_TYPE.INTEGER):
            case(FIELD_TYPE.FLOAT):
            case(FIELD_TYPE.PRICE):
            case(FIELD_TYPE.COUNT):
                filter = RangeFilter.build(item, field, state, setValue, values)
                break;
            case(FIELD_TYPE.TEXT):
            case(FIELD_TYPE.STRING):
            case(FIELD_TYPE.EMAIL):
            case(FIELD_TYPE.PHONE_NUMBER):
            case(FIELD_TYPE.HTML):
                filter = SearchFilter.build(item, field, state, setValue, values)
                break;
            case(FIELD_TYPE.BOOLEAN):
                filter = BooleanFilter.build(item, field, state, setValue, values)
                break;
            case(FIELD_TYPE.DATE_TIME):
                filter = DateFilter.build(item, field, state, setValue, values)
                break;
            case(FIELD_TYPE.CODE):
                filter = CodeFilter.build(item, field, state, setValue, values)
                break;
            case(COMPOSITE_FIELD_TYPE.APPROVAL):
                filter = CompositeApprovalFilter.build(item, field, state, setValue, values)
                break;
            default:
                throw new Error('Invalid field type: ' + field.type)
        }

        if (remote) {
            delete filter['onFilter'];
        }

        if (state) {
            filter.defaultFilteredValue = state as any
        }

        return filter
    }
}

export default FilterBuilder