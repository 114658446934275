import React, {RefObject} from "react";
import {Button, Form, FormInstance, Modal, Slider} from "antd";
import IFormOptions from "../../../../../model/interface/form/IFormOptions";
import ITextOptions from "../../../../../model/interface/form/elementOptions/ITextOptions";
import IColumnOptions from "../../../../../model/interface/form/elementOptions/IColumnOptions";

interface IProps {
    options: IColumnOptions
    onFinish: (values?: IFormOptions) => void
}

class ColumnWidgetEditor extends React.Component<IProps, IColumnOptions> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            width: 24,
            ...props.options,
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then((values: ITextOptions) => {
            this.props.onFinish({...this.state, ...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    render() {
        return (
            <Modal
                title={'Upravit sloupec'}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form initialValues={this.state || {}} ref={this.formRef}>
                    <Form.Item name={'width'} label={'Zvolte velikost nadpisu'}>
                        <Slider marks={{ 4: '4/24', 24: '24/24'}} min={4} max={24} tooltipVisible/>
                    </Form.Item>
                </Form>
            </Modal>

        )
    }
}

export default ColumnWidgetEditor