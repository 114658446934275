import React from "react";
import FieldWysiwygOptionsEditor from "../../../../content-type/field/optionEditors/FieldWysiwygOptionsEditor";

export interface IWYSIWYGEditorOptions {
    wysiwygPackage?: 'basic' | 'medium' | 'full',
    wysiwygInlineFile?: boolean
}

interface IProps {
    options: IWYSIWYGEditorOptions
}

class FormFieldWYSIWYGEditor extends React.Component<IProps> {
    render() {
        const {options} = this.props
        return (
            <FieldWysiwygOptionsEditor options={options}/>
        )
    }
}

export default FormFieldWYSIWYGEditor