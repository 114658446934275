import React, {RefObject} from "react";
import {Button, Form, FormInstance, Modal, Select, Slider, Switch} from "antd";
import IContainerOptions, {ContainerDisplay} from "../../../../../model/interface/widget/option/IContainerOptions";

interface IProps {
    options: IContainerOptions
    onFinish: (values?: IContainerOptions) => void,
    editableLabel?: boolean
}

const displayTypes: { value: ContainerDisplay, label: string }[] = [
    {value: 'd-block', label: 'V řádcích'},
    {value: 'd-flex', label: 'Za sebou'}
]

class CommentEditor extends React.Component<IProps, IContainerOptions> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            display: 'd-block',
            padding: 1,
            ...props.options,
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then((values: IContainerOptions) => {
            this.props.onFinish({...this.state, ...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    valueUpdated = (changes: any) => {
        this.setState({...this.state, ...changes})
    }

    render() {

        const {children} = this.props
        return (
            <Modal
                title={'Upravit kontejner'}
                okText={'Uložit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form initialValues={this.state || {}} ref={this.formRef} onValuesChange={this.valueUpdated}>
                    <Form.Item name={'display'} label={'Zobrazení'}>
                        <Select>
                            {displayTypes.map(({value, label}) => (
                                <Select.Option value={value} key={value}>{label}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={'bordered'} label={'Ohraničení'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'padding'} label={'Odsazení'}>
                        <Slider min={0} max={4}/>
                    </Form.Item>
                    {children}
                </Form>
            </Modal>
        )
    }
}

export default CommentEditor