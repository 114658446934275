import React from "react";
import {Col, Empty, Modal, Row, Spin, Timeline, Tooltip, Typography} from "antd";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import {EyeOutlined, WarningOutlined} from "@ant-design/icons";
import ContentTypeConfiguration from "../ContentTypeConfiguration";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import Button from "../../../../shared/button/Button";
import FilePicker from "../../../../shared/pickers/file/FilePicker";
import ContentTypesService from "../../../../../model/service/dataStorage/ContentTypesService";
import IFile from "../../../../../model/interface/file/IFile";
import MaintenanceImportChange, {ContentTypeChange} from "./import/MaintenaceImportChange";

interface IState {
    loading: boolean,
    importing: boolean
    data?: IContentType,
    showImported: boolean,
    changes: ContentTypeChange[]
    chosenChanges: string[]
    file?: IFile
}

interface IProps extends IBaseProps {
    resource: IContentType,
    onImport: (contentType: IContentType) => void
}

class MaintenanceImport extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            importing: false,
            showImported: false,
            changes: [],
            chosenChanges: []
        }
    }

    onImportFile = (file: IFile | IFile[] | undefined) => {
        const {resource} = this.props
        file = Array.isArray(file) ? file[0] : file
        if (file) {
            this.setState({loading: true, file})
            ContentTypesService.compare(resource.uuid, file.uuid).then(({contentType, changes}) => {
                this.setState({changes, data: contentType, loading: false, chosenChanges: []})
            })
        } else {
            this.setState({data: undefined, changes: [], file: undefined})
        }
    }

    showImportedPreview = () => {
        this.setState(state => ({showImported: !state.showImported}))
    }

    onImport = () => {
        const {resource, onImport} = this.props
        const {file, chosenChanges} = this.state
        this.setState({importing: true})
        file && ContentTypesService.import(resource.id, file.uuid, chosenChanges).then(result => {
            this.setState({importing: false})
            onImport(result)
        }).catch(() => this.setState({importing: false}))
    }

    onChooseChange = (path: string[]) => {
        const pathKey = path.join('-')
        this.setState(state => ({
            chosenChanges: state.chosenChanges.includes(pathKey) ?
                state.chosenChanges.filter(change => change !== pathKey) : [...state.chosenChanges, pathKey]
        }))
    }

    render() {
        const {loading, data, showImported, changes, importing, file, chosenChanges} = this.state

        return (
            <>
                <Typography.Title level={3}>Import</Typography.Title>
                <FilePicker placeholder={'Vyberte soubor JSON s typem obsahu, který chcete importovat'}
                            extensions={['.zip']} widget={"extended"} onChange={this.onImportFile}/>
                <Modal width={'800px'} bodyStyle={{height: '60vh', overflow: 'auto'}} visible={showImported}
                       title={'Náhled'} onCancel={this.showImportedPreview}>
                    <ContentTypeConfiguration {...this.props} resource={data} standAlone={true} preview={true}/>
                </Modal>
                <Spin spinning={loading}>
                    {changes.length > 0 ? (
                        <Row className={'mt-2'} gutter={[20, 10]}>
                            <Col>
                                <Typography.Title level={4} className={'d-inline-block mr-3'}>Změny</Typography.Title>
                                {data && (
                                    <Tooltip title={''}>
                                        <Button type={'info'} size={"small"} icon={<EyeOutlined/>}
                                                onClick={this.showImportedPreview}>
                                            Náhled importovaného typu obsahu
                                        </Button>
                                    </Tooltip>
                                )}
                            </Col>
                            <Col span={24}>
                                <Typography.Text type={"warning"} className={'d-block'}>
                                    <WarningOutlined/> Pozor, všechny změny provedené v tomto typu obsahu, které nejsou
                                    předem uloženy, nejsou zahrnuty ve srovnání a budou ztraceny po provedení importu!
                                </Typography.Text>
                                <Timeline className={'mt-3'} pending={true}
                                          pendingDot={
                                              <Button loading={importing} disabled={chosenChanges.length < 1}
                                                      onClick={this.onImport} size={"small"}
                                                      type={"primary"}
                                                      style={{left: '45%'}}>
                                                  Importovat
                                              </Button>}
                                          mode={'left'}>
                                    {changes.map(value => (
                                        <Timeline.Item color={{
                                            delete: 'red',
                                            add: 'green',
                                            update: 'blue'
                                        }[value.state]}>
                                            <MaintenanceImportChange
                                                checked={chosenChanges.includes(value.path.join('-'))} change={value}
                                                onChoose={this.onChooseChange}/>
                                        </Timeline.Item>
                                    ))}
                                </Timeline>
                            </Col>
                        </Row>
                    ) : file && !loading && (
                        <Empty description={'Importovaný Typ obsahu je shodný s otevřeným, vyberte jiný soubor'}/>
                    )}
                </Spin>
            </>
        )
    }
}

export default MaintenanceImport