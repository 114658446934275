import React from "react";
import CardWidgetType from "./CardWidgetType";
import Widget from "../../../widget/Widget";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import ScalarFieldWidget from "./ScalarFieldWidget";
import ICardWidgetScalarField from "../../../../../../model/interface/dataStorage/card/ICardWidgetScalarField";
import RelationFieldWidget from "./RelationFieldWidget";
import ICardWidgetRelationField from "../../../../../../model/interface/dataStorage/card/ICardWidgetRelationField";
import CommentCardWidget from "./editor/CommentCardWidget";
import CardWidgetAction from "./CardWidgetAction";
import IActionOptions from "../../../../../../model/interface/widget/option/IActionOptions";
import ICardWidgetContainer from "../../../../../../model/interface/dataStorage/card/ICardWidgetContainer";
import ContainerWidget from "./ContainerWidget";
import CardWidgetGeneratedDocuments from "./CardWidgetGeneratedDocuments";
import CardWidgetLike from "./CardWidgetLike";
import CardWidgetPrint from "./CardWidgetPrint";
import ICardWidgetWorkflowTransactionConditions
    from "../../../../../../model/interface/dataStorage/card/ICardWidgetWorkflowTransactionConditions";
import CardWidgetWorkflowTransactionConditions from "./CardWidgetWorkflowTransactionConditions";
import CompositeFieldApproval, {ICardWidgetCompositeApprovalOptions} from "../../field/presenters/CompositeFieldApproval";

class ICardWidgetLikeOptions {
}


interface ICardWidgetPrintOptions {
}

class CardWidget extends React.Component<ICardWidgetProps> {

    static getWidget(type: string, props: ICardWidgetProps) {
        switch (type) {
            case(CardWidgetType.SCALAR_FIELD):
                return <ScalarFieldWidget {...props as ICardWidgetProps<ICardWidgetScalarField>}/>
            case(CardWidgetType.RELATION_FIELD):
                return <RelationFieldWidget {...props as ICardWidgetProps<ICardWidgetRelationField>}/>
            case(CardWidgetType.COMMENT):
                return <CommentCardWidget {...props as ICardWidgetProps<ICardWidgetRelationField>}/>
            case(CardWidgetType.ACTION):
                return <CardWidgetAction {...props as ICardWidgetProps<IActionOptions>}/>
            case(CardWidgetType.CONTAINER):
                return <ContainerWidget {...props as ICardWidgetProps<ICardWidgetContainer>}/>
            case(CardWidgetType.GENERATED_DOCUMENTS):
                return <CardWidgetGeneratedDocuments {...props as ICardWidgetProps}/>
            case(CardWidgetType.LIKE):
                return <CardWidgetLike {...props as ICardWidgetProps<ICardWidgetLikeOptions>}/>
            case(CardWidgetType.PRINT):
                return <CardWidgetPrint {...props as ICardWidgetProps<ICardWidgetPrintOptions>}/>
            case(CardWidgetType.WORKFLOW_TRANSACTION_CONDITIONS):
                return <CardWidgetWorkflowTransactionConditions {...props as ICardWidgetProps<ICardWidgetWorkflowTransactionConditions>}/>
            default:
                return Widget.getWidget(type, props)
        }
    }

    render(): JSX.Element {
        return Widget.renderWidget({...this.props, customWidget: CardWidget}, CardWidget.getWidget)
    }
}

export default CardWidget