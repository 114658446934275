import React, {RefObject} from "react";
import {Button, Card, Form, FormInstance, Input, Modal, Select, Switch} from "antd";
import IFormOptions from "../../../../../../model/interface/form/IFormOptions";
import IFormFieldSelectOption from "../../../../../../model/interface/form/formField/IFormFieldSelectOption";
import IField, {
    COMPOSITE_FIELD_TYPE,
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_RELATION,
    FIELD_MODE_SCALAR, FIELD_NUMBER_TYPES, FIELD_TYPE,
    RELATION_FIELD_TYPE
} from "../../../../../../model/interface/dataStorage/IField";
import FormFieldType from "../formField/FormFieldType";
import FormFieldSelectOptionEditor from "../formField/editor/FormFieldSelectOptionEditor";
import Utils from "../../../../../../utils";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import FormElementField from "../FormElementField";
import FormFieldAutocompleteEditor from "../formField/editor/FormFieldAutocompleteEditor";
import {API_FILTER_TYPE} from "../../../../../../model/constants/ApiConstant";
import FormFieldWYSIWYGEditor from "../formField/editor/FormFieldWYSIWYGEditor";
import FormFieldFilePickerEditor from "../formField/editor/FormFieldFilePickerEditor";
import FormFieldContentTypeEditor from "../formField/editor/FormFieldContentTypeEditor";
import ConditionEditor, {ICondition} from "./ConditionEditor";
import IFormStructureNode from "../../../../../../model/interface/form/IFormStructureNode";
import Title from "antd/es/typography/Title";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import FormFieldRateEditor from "../formField/editor/FormFieldRateEditor";
import FormFieldContentTypeCascadeEditor from "../formField/editor/FormFieldContentTypeCascadeEditor";
import FormFieldCompanyStructureEditor from "../formField/editor/FormFieldCompanyStructureEditor";
import FormFieldEmployeeEditor from "../formField/editor/FormFieldEmployeeEditor";
import IBaseProps from "../../../../../../model/interface/IBaseProps";
import EmployeesService from "../../../../../../model/service/company/EmployeesService";
import WorkflowStatesService from "../../../../../../model/service/file/WorkflowStatesService";
import FilesService from "../../../../../../model/service/file/FilesService";
import FieldCollapseInfo from "../../../content-type/field/FieldCollapseInfo";
import CompositeFieldApprovalOptionsEditor
    from "../../../content-type/field/optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import FieldPhoneNumberOptionsEditor from "../../../content-type/field/optionEditors/FieldPhoneNumberOptionsEditor";
import ActionPicker from "../../../../../shared/pickers/ActionPicker";
import FieldCodeOptionsEditor from "../../../content-type/field/optionEditors/FieldCodeOptionsEditor";

interface IProps extends IBaseProps {
    options: IFieldOptions
    onFinish: (values?: IFormOptions) => void
    field: IField
    fields: IFormStructureNode[]
    contentType: IContentType
}

class FieldEditor extends React.Component<IProps, IFieldOptions> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            sliderMin: 1,
            sliderMax: 100,
            datePicker: FormFieldType.FIELD_DATE_DATE_PICKER as 'date',
            requiredText: 'Tato položka je povinná',
            autocompleteCollection: '',
            autocompleteField: '',
            fileWidget: 'simple',
            rateLength: 5,
            autocompleteMode: API_FILTER_TYPE.LIKE as 'like',
            autocompleteMin: 2,
            contentTypeAutocompleteMin: 2,
            contentTypeMode: 'auto',
            contentTypeFullClassName: props.field.targetEntity || undefined,
            wysiwygPackage: 'basic',
            contentTypeCreationButtonType: 'default',
            contentTypeCreationButtonIcon: 'ant.PlusOutlined',
            ...props.field.options,
            ...props.options,
            initialValue: props.options.type ? FormFieldType.formatToForm(props.options.type, props.options.initialValue) : undefined,
            showClear: Utils.toBoolean(props.options.showClear),
            required: Utils.toBoolean(props.options.required),
            sliderTooltipVisible: Utils.toBoolean(props.options.sliderTooltipVisible),
            label: (props.options.label ? props.options.label : this.props.field.label) || '',
            type: props.options.type ? props.options.type : FieldEditor.detectType(props.field),
            fileCanChoose: Utils.toBoolean(props.options.fileCanChoose),
            fileCanDownload: Utils.toBoolean(props.options.fileCanDownload),
            fileCanUpload: props.options.fileCanUpload !== undefined ? Utils.toBoolean(props.options.fileCanUpload) : true,
            fileCanRemove: Utils.toBoolean(props.options.fileCanRemove),
            fileDirectory: props.options.fileDirectory ? +props.options.fileDirectory : props.options.fileDirectory,
            multiple: Utils.toBoolean(props.options.multiple),
            disabledConditions: props.options.disabledConditions ? props.options.disabledConditions : [],
            companyStructureAccepts: props.options.companyStructureAccepts || "employee",
            companyStructureMultiple: this.props.field.type === RELATION_FIELD_TYPE.MANY_TO_MANY,
            employeeMultiple: this.props.field.type === RELATION_FIELD_TYPE.MANY_TO_MANY,
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    static detectType(field: IField) {

        switch (field.mode) {
            case FIELD_MODE_SCALAR:
                const type = field.type.toLowerCase();
                if (FIELD_NUMBER_TYPES.includes(field.type)) {
                    return FormFieldType.FIELD_NUMBER
                } else if (type.includes('icon')) {
                    return FormFieldType.FIELD_ICON
                } else if (type.includes('string')) {
                    return FormFieldType.FIELD_TEXT
                } else if (type.includes('text')) {
                    return FormFieldType.FIELD_WYSIWYG
                } else if (type.includes('date')) {
                    return FormFieldType.FIELD_DATE
                } else if (type.includes('bool')) {
                    return FormFieldType.FIELD_BOOLEAN
                } else if (field.type === FIELD_TYPE.PHONE_NUMBER) {
                    return FormFieldType.FIELD_PHONE_NUMBER
                } else if (field.type === FIELD_TYPE.BANK_ACCOUNT_NUMBER) {
                    return FormFieldType.FIELD_BANK_ACCOUNT_NUMBER
                } else if (field.type === FIELD_TYPE.ICO) {
                    return FormFieldType.FIELD_ICO
                } else if (type.includes('count')) {
                    return FormFieldType.FIELD_COUNT
                } else if (type.includes('rate')) {
                    return FormFieldType.FIELD_RATE
                } else if (type.includes('currency')) {
                    return FormFieldType.FIELD_CURRENCY
                } else if (type.includes('url')) {
                    return FormFieldType.FIELD_URL
                } else if (type.includes('email')) {
                    return FormFieldType.FIELD_EMAIL
                } else if (type.includes('base64')) {
                    return FormFieldType.FIELD_SIGNATURE
                } else if (type.includes('color')) {
                    return FormFieldType.FIELD_COLOR
                } else if (field.type === FIELD_TYPE.CODE) {
                    return FormFieldType.FIELD_CODE
                }
                return FormFieldType.FIELD_TEXT
            case FIELD_MODE_RELATION:
                const target = field.targetEntity;
                if (target) {
                    if (target.includes(FilesService.getRecordClassName())) {
                        return FormFieldType.FIELD_FILE
                    } else if (target.includes(EmployeesService.getInstance().getRecordClassName())) {
                        return FormFieldType.FIELD_EMPLOYEE
                    } else if (target.includes('Company')) {
                        return FormFieldType.FIELD_COMPANY_STRUCTURE
                    } else if (target.includes(WorkflowStatesService.getRecordClassName())) {
                        return FormFieldType.FIELD_WORKFLOW_STATE
                    }
                }
                return FormFieldType.FIELD_CONTENT_TYPE
            case FIELD_MODE_COMPOSITE:
                if (field.type === COMPOSITE_FIELD_TYPE.APPROVAL) {
                    return FormFieldType.COMPOSITE_FIELD_APPROVAL
                }
                return ''
            default:
                return ''
        }
    }

    onValuesChange = (values: any) => {
        this.setState({...values})
    }

    updateSelectOptions = (selectOptions: IFormFieldSelectOption[]) => {
        this.setState({selectOptions})
    }

    onFieldSettingsChange = (options: IFieldOptions, afterCallback?: () => void) => {
        console.log('onFieldSettingsChange', options)
        this.setState({...options}, afterCallback)
    }

    onOk = () => {
        this.formRef.current?.validateFields().then((values: IFieldOptions) => {
            const initialValue = values.type ?
                FormFieldType.formatFromForm(values.type, values.initialValue) : ''
            this.props.onFinish({...this.state, ...values, initialValue})
        })
    }

    valueUpdated = (changes: any) => {
        const forceCleanList = [
            'type', 'datePicker', 'wysiwygPackage', 'employeeOnlyDirectChildren', 'employeeStructureRoot'
        ]
        if (!!forceCleanList.find(key => key in changes)) {
            if (this.state.initialValue) {
                this.formRef.current?.setFieldsValue({'initialValue': undefined})
            }
            this.setState({...Utils.deepMerge(this.state, changes), initialValue: undefined})
        } else {
            this.setState({...Utils.deepMerge(this.state, changes)})
        }
    }

    onCancel = () => {
        this.props.onFinish()
    }

    addCondition = (disabledConditions: ICondition[]) => {
        this.setState({disabledConditions})
    }

    render() {
        const {type, selectOptions, required, disabledConditions, multiple} = this.state
        const {field, fields, contentType, match, history} = this.props

        const initialValues = this.state || {}

        return (
            <Modal
                title={'Upravit vlasnosti'}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                width={700}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <FieldCollapseInfo field={field}/>
                <Form initialValues={initialValues} ref={this.formRef} onValuesChange={this.valueUpdated}>
                    <Form.Item name={'label'} label={'Zvolte nazev'} rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'required'} label={'Pole je povinne'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    {required && (
                        <Form.Item name={'requiredText'} label={'Hlaska pro povinne pole'} rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                    )}
                    <Form.Item name={'disabled'} label={'Zakázat'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'placeholder'} label={'Nápověda'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'showClear'} label={'Možnost smazat hodnotu'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'type'} label={'Zvolte typ vstupu'} rules={[{required: true}]}>
                        <Select>
                            {FormFieldType.FIELD_TYPES.sort((a, b) => a.label > b.label ? 1 : -1).map(type => (
                                <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={'editRightsAction'} label={'Zakázat na základě oprávnění k akci'}>
                        <ActionPicker contentTypeUuid={contentType.uuid}/>
                    </Form.Item>
                    <Form.Item name={'readRightsAction'} label={'Skrýt na základě oprávnění k akci'}>
                        <ActionPicker contentTypeUuid={contentType.uuid}/>
                    </Form.Item>
                    {type === FormFieldType.FIELD_SLIDER && (
                        <Card>
                            <Form.Item label={"Minimalna hodnota"} name={"sliderMin"} rules={[{required: true}]}>
                                <Input type={"number"}/>
                            </Form.Item>
                            <Form.Item label={"Maximalna hodnota"} name={"sliderMax"} rules={[{required: true}]}>
                                <Input type={"number"}/>
                            </Form.Item>
                            <Form.Item label={"Viditelna hodnota"} name={"sliderTooltipVisible"}
                                       valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </Card>
                    )}
                    {[FormFieldType.FIELD_DATE, FormFieldType.FIELD_DATE_RANGE].includes(type) && (
                        <Card>
                            <Form.Item name={'datePicker'} label={'Zvolte typ datumu/casu'} rules={[{required: true}]}>
                                <Select>
                                    {FormFieldType.FIELD_DATE_PICKER_TYPES.map(type => (
                                        <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Card>
                    )}
                    {[FormFieldType.FIELD_PHONE_NUMBER].includes(type) && (
                        <Card>
                            <FieldPhoneNumberOptionsEditor options={this.state}/>
                        </Card>
                    )}
                    {type === FormFieldType.FIELD_AUTOCOMPLETE && (
                        <FormFieldAutocompleteEditor
                            onChange={this.onFieldSettingsChange}
                            options={this.state}
                            formRef={this.formRef}
                        />
                    )}
                    {type === FormFieldType.FIELD_CONTENT_TYPE && (
                        <FormFieldContentTypeEditor
                            onChange={this.onFieldSettingsChange}
                            options={this.state}
                            history={history}
                            match={match}
                            formRef={this.formRef}
                            field={field}
                        />
                    )}
                    {type === FormFieldType.FIELD_CONTENT_TYPE_CASCADE && (
                        <FormFieldContentTypeCascadeEditor
                            onChange={this.onFieldSettingsChange}
                            options={this.state}
                            formRef={this.formRef}
                            field={field}
                        />
                    )}
                    {type === FormFieldType.FIELD_WYSIWYG && (
                        <FormFieldWYSIWYGEditor options={this.state}/>
                    )}
                    {type === FormFieldType.FIELD_FILE && (
                        <FormFieldFilePickerEditor field={field}/>
                    )}
                    {type === FormFieldType.FIELD_RATE && (
                        <FormFieldRateEditor options={this.state}/>
                    )}
                    {type === FormFieldType.FIELD_EMPLOYEE && (
                        <FormFieldEmployeeEditor
                            options={this.state}
                            formRef={this.formRef}
                            contentType={this.props.contentType}
                            history={history}
                            match={match}
                            field={field}
                        />
                    )}
                    {type === FormFieldType.FIELD_COMPANY_STRUCTURE && (
                        <FormFieldCompanyStructureEditor
                            onChange={this.onFieldSettingsChange}
                            options={this.state}
                            formRef={this.formRef}
                            history={history}
                            match={match}
                            field={field}
                        />
                    )}
                    {type === FormFieldType.COMPOSITE_FIELD_APPROVAL && (
                        <Card>
                            <CompositeFieldApprovalOptionsEditor options={this.state} contentType={contentType}/>
                        </Card>
                    )}
                    {type === FormFieldType.FIELD_CODE && (
                        <Card>
                            <FieldCodeOptionsEditor options={this.state}/>
                        </Card>
                    )}
                    {type && (
                        <FormElementField
                            field={field}
                            functions={{
                                ...{
                                    getNode: () => ({field}),
                                    getContentType: () => contentType,
                                } as any
                            }}
                            type={'field'}
                            label={'Zvolte pocatecni hodnotu'}
                            id={'field-default-value'}
                            options={{...this.state, disabled: false, showClear: true, required: false}}
                            preview={true}
                            customFieldName={'initialValue'}
                        />
                    )}
                </Form>
                {type === FormFieldType.FIELD_SELECT && (
                    <FormFieldSelectOptionEditor
                        options={selectOptions}
                        multiple={multiple || false}
                        onValuesChange={(values) => this.onValuesChange(values)}
                        onChange={this.updateSelectOptions}
                    />
                )}
                <div>
                    <Title level={4}>Deaktivovat pole na základě podmínek</Title>
                    <ConditionEditor value={disabledConditions} onChange={this.addCondition}
                                     fields={contentType.fields} fieldNodes={fields}/>
                </div>
            </Modal>

        )
    }
}

export default FieldEditor