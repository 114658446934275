import moment, {Moment} from "moment";
import {Button, Col, Radio, Row, Select, Tooltip} from "antd";
import * as Icon from "@ant-design/icons";
import {ClockCircleOutlined, FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";
import React from "react";
import {CalendarMode} from "./Calendar";

interface IProps {
    date: Moment
    mode: CalendarMode
    onChange: (day: Moment, mode?: CalendarMode) => void
    modes?: CalendarMode[]
    customItems?: JSX.Element[],
    fullScreenChange?: () => void
    fullScreen?: boolean
}

interface IState {
    mode: CalendarMode
}

const modesList: { value: CalendarMode, label: string }[] = [
    {value: 'year', label: 'Rok'},
    {value: 'month', label: 'Měsíc'},
    {value: 'week', label: 'Týden'},
    {value: 'day', label: 'Den'}
]

class Header extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context)
        this.state = {
            mode: props.mode || 'month'
        }
    }

    onModeChange = (mode: CalendarMode) => {
        const {date, onChange} = this.props
        this.setState({mode}, () => onChange(date, mode))
    }

    render() {
        const {date, onChange, customItems, modes, fullScreenChange, fullScreen} = this.props
        const {mode} = this.state
        const monthOptions = [];
        const current = date.clone();
        const localeData = date.localeData();
        for (let i = 0; i < 12; i++) {
            monthOptions.push(
                <Select.Option className="month-item" key={`${i}`} value={String(i)}>
                    {localeData.monthsShort(current.month(i))}
                </Select.Option>,
            );
        }

        const year = date.year();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>,
            );
        }
        return (
            <div className={'p-1 pt-2 mb-3'}>
                <Row justify="end" gutter={[10, 10]}>
                    {customItems?.map(item => item)}
                    <Col>
                        <Button onClick={() => onChange(moment())} icon={<ClockCircleOutlined/>}>Dnes</Button>
                    </Col>
                    <Col>
                        <Button.Group>
                            <Radio.Button onClick={() => onChange(date.clone().add(-1, mode))}>
                                <Icon.LeftOutlined/>
                            </Radio.Button>
                            <Radio.Button onClick={() => onChange(date.clone().add(1, mode))}>
                                <Icon.RightOutlined/>
                            </Radio.Button>
                        </Button.Group>
                    </Col>
                    <Col>
                        <Select value={String(date.month())}
                                onChange={selectedMonth => onChange(date.clone().month(parseInt(selectedMonth, 10)))}
                        >
                            {monthOptions}
                        </Select>
                    </Col>
                    <Col>
                        <Select className="my-year-select" value={String(year)}
                                onChange={newYear => onChange(date.clone().year(Number(newYear)))}
                        >
                            {options}
                        </Select>
                    </Col>
                    <Col>
                        <Radio.Group onChange={e => this.onModeChange(e.target.value)} value={mode}>
                            {modesList.filter(m => !modes || modes?.includes(m.value)).map(item => (
                                <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                            ))}
                        </Radio.Group>
                    </Col>
                    <Col>
                        <Tooltip title={'Zapněte režim celé obrazovky'}>
                            <Button type={"link"} icon={fullScreen ? <FullscreenExitOutlined/> : <FullscreenOutlined/>}
                                    onClick={fullScreenChange}/>
                        </Tooltip>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Header