import React, {Component} from 'react';
import {Row, Select} from "antd";
import Button, {ButtonTypes} from "./Button";

interface IProps {
    value?: string
    onChange?: (key: string) => void
    className?: string
}

interface IState {
    value?: string
}

const BUTTON_TYPES: { value: ButtonTypes, label: string }[] = [
    {value: 'default', label: 'Výchozí'},
    {value: 'primary', label: 'Zvýrazněné'},
    {value: 'link', label: 'Odkaz'},
    {value: 'info', label: 'Info'},
    {value: 'success', label: 'Úspěch'},
    {value: 'warning', label: 'Varování'},
    {value: 'danger', label: 'Kritické'},
    {value: 'special', label: 'Speciální'},
    {value: 'dashed', label: 'Čárkovaný'}
]

class ButtonTypePicker extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onPickerChoose = (type: string) => {
        this.setState({
            value: type
        })
        if (this.props.onChange) {
            this.props.onChange(type)
        }
    }

    render() {
        const {value} = this.state
        const {className} = this.props

        return (
            <div className="type-picker">
                <Select
                    allowClear
                    className={className}
                    showSearch
                    value={value}
                    placeholder="Vyberte typ"
                    optionFilterProp="children"
                    onChange={this.onPickerChoose}
                    filterOption={(input, option) =>
                        option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {BUTTON_TYPES.map(type => (
                        <Select.Option key={type.value} value={type.value}>
                            <Row justify={"space-between"} align={"middle"}>
                                {type.label} <Button
                                type={type.value}
                                size={"small"}>Ukazka</Button>
                            </Row>
                        </Select.Option>
                    ))}
                </Select>
            </div>
        )
    }
}

export default ButtonTypePicker;
