import {Button, Card, Form, FormInstance, Input, Select, Switch, Tag} from 'antd';
import {TweenOneGroup} from 'rc-tween-one';
import {PlusOutlined} from '@ant-design/icons';
import React, {RefObject} from "react";
import IFormFieldSelectOption from "../../../../../../../model/interface/form/formField/IFormFieldSelectOption";
import Title from "antd/es/typography/Title";
import {IFilePickerWidgetType} from "../../../../../../shared/pickers/file/FilePicker";


export interface ISelectEditorOptions {
    multiple?: boolean
}


interface IProps {
    multiple: boolean
    options?: IFormFieldSelectOption[]
    onChange: (options: IFormFieldSelectOption[]) => void
    onValuesChange: (values: any) => void
}

interface IState {
    options: IFormFieldSelectOption[],
    formRef: RefObject<FormInstance>,
}

class FormFieldSelectOptionEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            options: props.options ? [...props.options] : [],
            formRef: React.createRef(),
        }
    }

    handleClose = (removeOption: IFormFieldSelectOption) => {
        const options = this.state.options.filter(option => option.value !== removeOption.value);
        this.setState({options});
        this.props.onChange(options)
    };

    confirm = () => {
        this.state.formRef.current?.validateFields().then(values => {
            let {options} = this.state;
            if (options.findIndex(option => option.value === values.value) === -1) {
                options = [...options, {...values}];
                this.setState({options});
                this.props.onChange(options)
            }
            this.state.formRef.current?.resetFields()
        })
    };

    forMap = (option: IFormFieldSelectOption) => {
        const tagElem = (
            <Tag
                key={option.value}
                closable
                onClose={e => {
                    e.preventDefault();
                    this.handleClose(option);
                }}
            >
                {option.title + ` [${option.value}]`}
            </Tag>
        );

        return (
            <span key={option.value} style={{display: 'inline-block'}}>
                {tagElem}
            </span>
        );
    };

    render() {
        const {options, formRef} = this.state;
        const tagChild = options.map(this.forMap);

        return (
            <Card>
                <Form.Item name={'multiple'} label={'Více položek'} valuePropName={'checked'}>
                    <Switch
                        onChange={(multiple) => {this.props.onValuesChange({multiple})}}
                        defaultChecked={this.props.multiple}
                    />
                </Form.Item>

                <Title level={4}>Select Options</Title>
                <div style={{marginBottom: 16}}>
                    <TweenOneGroup
                        enter={{
                            scale: 0.8,
                            opacity: 0,
                            type: 'from',
                            duration: 100
                        }}
                        leave={{opacity: 0, width: 0, scale: 0, duration: 200}}
                        appear={false}
                    >
                        {tagChild}
                    </TweenOneGroup>
                </div>
                <Form ref={formRef}>
                    <Form.Item name={'value'} label={'Hodnota'} rules={[{'required' : true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'title'} label={'Nazev'} rules={[{'required' : true}]}>
                        <Input/>
                    </Form.Item>
                    <Button onClick={this.confirm} icon={<PlusOutlined/>}>
                         Add
                    </Button>
                </Form>
            </Card>
        );
    }
}

export default FormFieldSelectOptionEditor