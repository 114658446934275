import React, {RefObject} from "react";
import {Button, Divider, Form, FormInstance, Input, Modal, Row, Select, Switch, Typography} from "antd";
import IField, {
    COMPOSITE_FIELD_TYPE, FIELD_MODE_COMPOSITE,
    FIELD_MODE_RELATION,
    FIELD_MODE_SCALAR,
    FIELD_TYPE, IFieldOptions
} from "../../../../../../../model/interface/dataStorage/IField";
import Utils from "../../../../../../../utils";
import moment from "moment";
import ScalarFieldWidget from "../ScalarFieldWidget";
import IRepositoryService from "../../../../../../../model/interface/IRepositoryService";
import selectors from "../../../../../../../redux/selectors";
import {connect, RootStateOrAny} from "react-redux";
import ICardWidgetScalarField from "../../../../../../../model/interface/dataStorage/card/ICardWidgetScalarField";
import IContentType from "../../../../../../../model/interface/dataStorage/IContentType";
import FormFieldType from "../../../../form/FormElement/formField/FormFieldType";
import FieldEditor from "../../../../form/FormElement/optionEditor/FieldEditor";
import ConditionEditor from "../../../../form/FormElement/optionEditor/ConditionEditor";
import MomentJsDateFormat from "../../../../../../shared/input/MomentJsDateFormat";
import FieldCollapseInfo from "../../../field/FieldCollapseInfo";
import CompositeFieldApprovalOptionsEditor
    from "../../../field/optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import FieldPhoneNumberOptionsEditor from "../../../field/optionEditors/FieldPhoneNumberOptionsEditor";
import FieldOptionsEditor from "../../../field/FieldOptionsEditor";
import IBaseProps from "../../../../../../../model/interface/IBaseProps";


interface IProps extends IBaseProps {
    options: ICardWidgetScalarField
    onFinish: (values?: ICardWidgetScalarField) => void
    findServiceByClassName: (name: string) => IRepositoryService,
    findContentTypeBy: (property: string, value: any) => IContentType,
    field: IField
    fields: IField[]
}

class ScalarFieldEditor extends React.Component<IProps, ICardWidgetScalarField> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        const type = props.options.type ? props.options.type : ScalarFieldEditor.detectType(props.field)
        this.state = {
            label: props.field.label,
            emptyText: 'není vyplněno',
            dateFormat: 'LLL',
            booleanTrue: 'Povoleno',
            booleanFalse: 'Zakázáno',
            ...(type === ScalarFieldWidget.RELATION ? {relationPresenter: this.getRelationFieldPresenters()[0]?.value} : {}),
            inPlaceType: FieldEditor.detectType(props.field),
            ...this.props.field.options,
            ...props.options,
            type,
            showLabel: props.options.showLabel !== undefined ? Utils.toBoolean(props.options.showLabel) : true,
            titleLevel: props.options.titleLevel ? +props.options.titleLevel as any : 0
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    static detectType(field: IField) {
        switch (field.mode) {
            case FIELD_MODE_SCALAR:
                return {
                    [FIELD_TYPE.STRING]: ScalarFieldWidget.TEXT,
                    [FIELD_TYPE.TEXT]: ScalarFieldWidget.TEXT,
                    [FIELD_TYPE.INTEGER]: ScalarFieldWidget.NUMBER,
                    [FIELD_TYPE.PRICE]: ScalarFieldWidget.NUMBER,
                    [FIELD_TYPE.FLOAT]: ScalarFieldWidget.NUMBER,
                    [FIELD_TYPE.BASE64]: ScalarFieldWidget.BASE_64,
                    [FIELD_TYPE.BOOLEAN]: ScalarFieldWidget.BOOLEAN,
                    [FIELD_TYPE.DATE_TIME]: ScalarFieldWidget.DATE,
                    [FIELD_TYPE.PHONE_NUMBER]: ScalarFieldWidget.PHONE_NUMBER
                }[field.type] || ScalarFieldWidget.TEXT
            case FIELD_MODE_COMPOSITE:
                return {
                    [COMPOSITE_FIELD_TYPE.APPROVAL]: ScalarFieldWidget.COMPOSITE_APPROVAL
                }[field.type] || ScalarFieldWidget.TEXT
            case FIELD_MODE_RELATION:
                return ScalarFieldWidget.RELATION
            default:
                return ''
        }
    }

    onOk = () => {
        this.formRef.current?.validateFields().then((values: ICardWidgetScalarField) => {
            this.props.onFinish({...this.state, ...values})
        })
    }

    valueUpdated = (changes: any) => {
        console.log('valueUpdated', changes)
        this.setState({...this.state, ...changes})
    }

    onCancel = () => {
        this.props.onFinish()
    }

    getRelationFieldPresenters() {
        const {field} = this.props
        if (!field.targetEntity) {
            throw new Error(`Target entity not defined for contentType[${field.contentTypeId}] field[${field.name}]`)
        }

        let service: IRepositoryService = this.props.findServiceByClassName(field.targetEntity)

        if (!service) {
            throw new Error('Service for "' + field.targetEntity + '" is missing')
        }

        console.log('getRelationFieldPresenters', service, service.getPresenterList())

        return service.getPresenterList()
    }

    buildActionOptions(type?: string) {
        const contentType = this.props.findContentTypeBy('uuid', this.props.field.contentTypeId)
        return contentType.actions.filter(item => {
            if (type !== undefined) {
                return item.type === 'form'
            } else {
                return item;
            }
        }).map(action => ({
            value: action.uuid,
            label: action.label
        }))
    }

    onOptionsChange = (options: IFieldOptions) => {
        this.setState(state => ({inPlaceFieldOptions: {...state.inPlaceFieldOptions, options}}))
    }

    render() {
        const {type, showLabel, inPlaceFieldOptions} = this.state
        const {field, fields, findContentTypeBy, history, match} = this.props

        const dateFormatRules = [
            {required: true, message: 'Prosím vyplňte format'},
            () => ({
                validator(_: any, value: string) {
                    //TODO : is valid doest work when localized format is applied. Maybe add better validation ??
                    if (/*moment(moment().format(value)).isValid() && */value !== moment().format(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Formát se zdá být neplatný'));
                }
            })]

        return (
            <Modal
                title={'Upravit vlasnost'}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                width={700}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <FieldCollapseInfo field={field}/>
                <Form initialValues={{...this.state}} ref={this.formRef} onValuesChange={this.valueUpdated}>
                    <Form.Item name={'showLabel'} label={'Zobrazit popisek'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    {showLabel && (
                        <Form.Item name={'label'} label={'Popisek'} rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                    )}
                    <Form.Item name={'titleLevel'} label={'Formátovat jako nadpis'} rules={[{required: true}]}>
                        <Select>
                            {['Vypnuto', 'Velký nadpis', 'Střední nadpis', 'Malý nadpis']
                                .map((size, index) => (
                                    <Select.Option key={index} value={index}>{size}</Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={'emptyText'} label={'Text pro prázdnou hotnotu'} rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'type'} label={'Zvolte typ vstupu'} rules={[{required: true}]}>
                        <Select>
                            {ScalarFieldWidget.TYPES.map(type => (
                                <Select.Option key={type.value} value={type.value}>
                                    <Row justify={"space-between"}>
                                        <div>{type.label}</div>
                                        <div>{ScalarFieldWidget.renderPreview(type.value)}</div>
                                    </Row>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {type === ScalarFieldWidget.DATE && (
                        <div>
                            <Form.Item label={'Formát'} name={'dateFormat'} rules={dateFormatRules}>
                                <MomentJsDateFormat/>
                            </Form.Item>
                        </div>

                    )}
                    {type === ScalarFieldWidget.COMPOSITE_APPROVAL && (
                        <div>
                            <CompositeFieldApprovalOptionsEditor contentType={findContentTypeBy('uuid', field.contentTypeId)} options={{...this.state}}/>
                        </div>
                    )}
                    {type === ScalarFieldWidget.PHONE_NUMBER && (
                        <div>
                            <FieldPhoneNumberOptionsEditor options={{...this.state}}/>
                        </div>
                    )}
                    {type === ScalarFieldWidget.RELATION && (
                        <Form.Item name={'relationPresenter'} label={'Presenter'} rules={[{required: true}]}>
                            <Select>
                                {this.getRelationFieldPresenters().map(type => (
                                    <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item name={'prefix'} label={'Prefix'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'suffix'} label={'Suffix'}>
                        <Input/>
                    </Form.Item>
                    <div>
                        <Divider>Editace na kartě</Divider>

                        <Form.Item name={'inPlaceEdit'} label={'Povolená editace'}>
                            <Switch checked={this.state.inPlaceEdit} onChange={inPlaceEdit => {
                                this.valueUpdated({inPlaceEdit})
                            }}/>
                        </Form.Item>
                        <Form.Item name={'inPlaceAction'} label={'Akce '}>
                            <Select options={this.buildActionOptions()}/>
                        </Form.Item>
                        <Form.Item name={'inPlaceDataAction'} label={'Akce pro data'}>
                            <Select options={this.buildActionOptions('form')}/>
                        </Form.Item>
                        <Form.Item name={'inPlaceType'} label={'Zvolte typ vstupu'}>
                            <Select>
                                {FormFieldType.FIELD_TYPES.map(type => (
                                    <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Typography.Text>Deaktivovat pole na základě podmínek</Typography.Text>
                        <Form.Item name={'inPlaceConditions'} noStyle={true}>
                            <ConditionEditor fields={fields}/>
                        </Form.Item>
                    </div>
                </Form>
                <FieldOptionsEditor field={field} contentType={findContentTypeBy('uuid', field.contentTypeId)}
                                    onChange={this.onOptionsChange} options={inPlaceFieldOptions || field.options} match={match}
                                    history={history} collapsable={true} title={'Vlastnosti pole'}/>
            </Modal>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
        findContentTypeBy: (property: string, value: any) => selectors.contentTypes.findOneBy(state, property, value),
    }
}

export default connect(mapStateToProps)(ScalarFieldEditor)
