import React from 'react'
import {Link} from "react-router-dom";
import {Button, Col, Descriptions, Modal, Result, Row, Tooltip, Typography} from "antd";
import {default as RouteCard} from "../../../configuration/content-type/card/Card";
import {EditOutlined, MailOutlined, RollbackOutlined, UserOutlined, UserSwitchOutlined} from "@ant-design/icons";
import IEmployee from "../../../../../model/interface/company/IEmployee";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import IconBuilder from "../../../../../utils/IconBuilder";
import MessageList from "../partials/MessageList";
import AvatarWithDefault from "../../../security/AvatarWithDefault";
import IUser from "../../../../../model/interface/security/IUser";
import {connect, RootStateOrAny} from "react-redux";
import {masquerade} from "../../../../../redux/actions/Auth";
import EmployeesService from "../../../../../model/service/company/EmployeesService";
import {CARD_RESERVED_NAMES} from "../../../../../model/interface/dataStorage/ICard";

interface IProps extends IBaseProps {
    resource: IEmployee
    standalone: boolean
    currentUser: IUser
    masquerade: (user: IUser) => void
}

interface IState {
}

class EmployeeProfile extends React.Component<IProps, IState> {
    isCurrentUser() {
        const {resource, currentUser} = this.props
        return resource.user && currentUser.id === resource.user.id
    }

    masquerade(user: IUser) {
        Modal.confirm({
            title: "Opravdu provést?", onOk: () => {
                this.props.masquerade(user)
            }
        })
    }

    getProperties = () => {
        const {resource} = this.props
        return [
            {label: 'Pracovní pozice', value: resource.job?.name},
            {label: 'Jednotka', value: resource.unit?.name},
            {label: 'Společnost', value: resource.company?.name},
            {label: 'Pracovní úvazek', value: resource.obligation?.title},
            {label: 'E-mail', value: resource.email}
        ]
    }

    render() {
        const {resource, standalone, currentUser, history} = this.props

        return (
            <>
                {this.isCurrentUser()
                || currentUser.credentials.indexOf('employeeCard') >= 0
                || (currentUser.credentials.indexOf('employeeCardSupervisor') >= 0 && (currentUser.employees.some(employee => employee.uuid === resource.supervisor?.uuid) || resource.isSubordinate)) ?
                    <div>
                        <div
                            className={"d-flex flex-row justify-content-start align-items-end p-3 rounded-top position-relative"}
                            style={{backgroundColor: 'lightgray'}}>
                            <div style={{position: "absolute", top: 20, right: 20}}>
                                <Link to={"/app/company/employees/" + resource.id + "/edit"}>
                                    <Button type={"primary"} size={"small"} className={"mr-2"} icon={<EditOutlined/>}>Upravit
                                        zaměstnance</Button>
                                </Link>
                                {currentUser.credentials.indexOf('masquerade') >= 0 && resource.user && resource.user.id !== currentUser.id && (
                                    <Tooltip title={"Přihlášením se za uživatele získáte dočasně jeho práva"}>
                                        <Button size={"small"} onClick={() => this.masquerade(resource.user!)}
                                                icon={<UserSwitchOutlined/>}>
                                            Přihlásit se za
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                            <AvatarWithDefault src={resource.photo ? resource.photo.thumbnailUrl : undefined} size={128}
                                               className={"mr-3"}/>
                            <div className={"mb-3"}>
                                <Typography.Title
                                    className={"font-size-lg"}>{resource.name} {resource.surname}</Typography.Title>
                                <Row gutter={[8, 8]}>
                                    {resource.user?.username && (
                                        <Col>
                                            <UserOutlined/> {resource.user?.username}
                                        </Col>
                                    )}
                                    {resource.email && (
                                        <Col>
                                            <MailOutlined/> {resource.email}
                                        </Col>
                                    )}
                                    {resource.contacts.map(contact => (
                                        <Tooltip title={contact.type ? contact.type.name : 'Kontakt'}>
                                            <Col>
                                                {IconBuilder(contact.type ? contact.type.icon : 'QuestionCircleOutlined')} {contact.value}
                                            </Col>
                                        </Tooltip>
                                    ))}
                                </Row>
                            </div>
                        </div>
                        <div className={"border-left border-right border-bottom rounded-bottom p-4"}
                             style={{background: 'white'}}>
                            <Descriptions colon={false}>
                                {this.getProperties().map(property => (
                                    <Descriptions.Item label={<Typography
                                        className={"font-weight-bold"}>{property.label}</Typography>}>
                                        <Typography.Text className={resource.unit ? 'text-muted' : ''}>
                                            {property.value || 'není vyplněno'}
                                        </Typography.Text>
                                    </Descriptions.Item>
                                ))}
                            </Descriptions>
                            {this.buildCard()}
                        </div>

                        {standalone && resource.user && this.isCurrentUser() && (
                            <div className={"mt-3"}>
                                <MessageList user={resource.user}/>
                            </div>
                        )}
                    </div>
                    :
                    <Result status="404" title="404" subTitle="Nemáte oprávnění prohlížet tento obsah." extra={
                        <Button icon={<RollbackOutlined/>} onClick={() => history.go(-1)} type="primary">
                            Zpět
                        </Button>
                    }/>
                }
            </>
        )
    }

    buildCard() {
        const {history, match, resource} = this.props
        const card = EmployeesService.getInstance().getSlaveContentTypeCard(CARD_RESERVED_NAMES.EMPLOYEE_CARD)
        // TODO select card by permission

        return card ?
            <RouteCard card={card} match={match} history={history} standAlone={false}
                       resource={{...resource, id: resource.virtualEntityId, uuid: resource.virtualEntityUuid}}/> : null
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        currentUser: state.setup.user
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        masquerade: (user: IUser) => dispatch(masquerade(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile)