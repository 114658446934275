import React from "react";
import IActionOptions from "../../../model/interface/widget/option/IActionOptions";
import IconBuilder from "../../../utils/IconBuilder";
import {Spin, Tooltip} from "antd";
import Utils from "../../../utils";
import IAction from "../../../model/interface/dataStorage/IAction";
import WorkflowActionButton from "./WorkflowActionButton";
import IRestResource from "../../../model/interface/api/IRestResource";
import Action from "./Action";
import {ACTION_REJECT_REASON, IActionResult} from "../../../model/service/dataStorage/ActionsService";
import RoutesRouter from "../router/RoutesRouter";
import Button from "../../shared/button/Button";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Modal from "../../shared/modal/Modal";

export type ActionButtonExecuteType = (customPayload?: any, customAction?: IAction, customResources?: IRestResource[]) => Promise<void>

interface IProps extends RouteComponentProps {
    onFinish?: (result?: IActionResult) => Promise<void>,
    disabled?: boolean
    action: IAction
    options: IActionOptions
    payload?: any
    onClick?: (execute: (customPayload?: any, customAction?: IAction, customResources?: IRestResource[]) => Promise<void>) => Promise<void>
    resources?: IRestResource[],
    className?: string
    wrapperClassName?: string,
    triggerClickRender?: boolean
}

interface IState {
    loading: boolean
    result?: IActionResult
}

class ActionButton extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        const {triggerClickRender} = this.props
        if (triggerClickRender) {
            this.onClick().then()
        }

    }

    render() {
        const {action, wrapperClassName} = this.props
        const {result} = this.state
        return <div className={"noPrint " + (wrapperClassName || '')}>
            {this.getButtonByActionType(action)}
            {action.modal && result && result.redirect && (
                <Modal onCancel={() => this.hideModal()} visible={true} width={'75vw'} title={action.label}
                       fullScreenOption={true} okButtonProps={{hidden: true}} cancelText={'zavřít'}>
                    <RoutesRouter path={result.redirect} onActionFinish={this.onModalActionFinish} isModal={true}/>
                </Modal>
            )}
        </div>
    }

    onModalActionFinish = (result?: IActionResult) => {
        if (this.props.onFinish) {
            this.props.onFinish(result).then()
            this.setState({result: undefined})
        }
    }

    hideModal = () => {
        this.setState({result: undefined})
        if (this.props.onFinish) {
            this.props.onFinish().then()
        }
    }

    getButtonByActionType(action: IAction) {
        switch (action.type) {
            case('workflow_state'):
                return this.renderWorkflowStateChange();
            default:
                return this.renderDefault()
        }
    }

    runAction = (customPayload?: any, customAction?: IAction, customResources?: IRestResource[]): Promise<void> => {
        const {onFinish, action, payload, history, resources} = this.props
        console.log('runAction', customPayload, payload, customResources || resources)
        this.setState({loading: true})
        return Action.doAction(
            customAction || action,
            customResources || resources || null,
            history,
            {...payload, ...customPayload}
        ).then(result => {
            Action.setLastActionFormData(result.action, {...payload, ...customPayload})
            this.setState({result, loading: false})
            let promise = Promise.resolve()
            if (!action.modal) {
                if (result.redirect) {
                    promise.then(() => history.push(result.redirect!))
                }
                if (onFinish) {
                    return promise.then(() => {
                        return (!result.redirect || result.redirect === history.location.pathname) ? onFinish(result) : Promise.reject()
                    }).catch(() => Promise.reject())
                }
            }
            return promise
        }).catch((reason: string) => {
            if ([ACTION_REJECT_REASON.CANCELLED, ACTION_REJECT_REASON.TERMINATED].includes(reason)) {
                this.setState({loading: false})
                return Promise.reject()
            } else {
                return Promise.reject(reason)
            }
        })

    }

    renderWorkflowStateChange() {
        const {resources} = this.props
        return resources && resources.length > 0 && <WorkflowActionButton
            {...this.props}
            onExecute={(payload, action, resources) => this.onExecute(payload, action, resources) as Promise<void>}
        />
    }

    onExecute(payload: any, action: IAction, resources?: IRestResource[]) {
        if (this.props.onClick) {
            return this.props.onClick(() => this.runAction(payload, action, resources))
        } else {
            return this.runAction(payload, action, resources)
        }
    }

    onClick() {
        if (this.props.onClick) {
            return this.props.onClick(this.runAction)
        }
        return this.runAction()
    }

    renderDefault() {
        const {options, disabled, action, className} = this.props
        const {loading} = this.state
        const icon = options.icon ? IconBuilder(options.icon) : undefined
        let button = (
            <Button
                className={(className || '')}
                disabled={disabled}
                loading={loading}
                type={options.type}
                danger={Utils.toBoolean(options.danger)}
                onClick={() => this.onClick()}
                style={{background: options.color, color: options.textColor}}
                icon={icon}
                size={options.size || "small"}
            >
                {options.label}
            </Button>
        )
        if (options.link) {
            button = (
                <Spin spinning={loading} wrapperClassName={'d-inline-block'}>
                    <div className={'d-inline-block text-link ' + (className || '')} onClick={() => this.onClick()}>
                        {options.label}
                    </div>
                </Spin>
            )
        }
        if (options.tooltip) {
            return (
                <Tooltip key={action.uuid!} title={options.tooltip}>
                    {button}
                </Tooltip>
            )
        }
        return button
    }
}

export default (withRouter(ActionButton))