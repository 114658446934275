import {Form, Input, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IconPicker from "../../../../../shared/IconPicker";
import TextColorPicker from "../../../../../shared/textColorPicker/TextColorPicker";

export interface IFieldLikeOptions {
    icon?: string,
    text?: string,
    color?: string,
    textTooltip?: boolean
    showUsers?: boolean
    showCount?: boolean,
    hideCountIfEmpty?: boolean
}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {
    format?: string
    type?: string
}

class FieldLikeOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            format: props.options.dateFormat
        };
    }

    static getDefaultOptions(): IFieldLikeOptions {
        return {
            icon: 'ant.LikeOutlined',
            text: 'líbí se mi',
            showCount: true,
            showUsers: true,
            hideCountIfEmpty: true,
            color: 'text-primary'
        }
    }

    render() {
        return (
            <div>
                <Form.Item name={'icon'} label={'Zvolte iconu'}>
                    <IconPicker/>
                </Form.Item>
                <Form.Item name={'text'} label={'Zvolte popisek'}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'color'} label={'Zvolte barvu'}>
                    <TextColorPicker/>
                </Form.Item>
                <Form.Item name={'textTooltip'} label={'Popisek jen jako nápověda'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'showUsers'} label={'zobrazit uživatele'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'showCount'} label={'Zobrazit počet'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'hideCountIfEmpty'} label={'Skrýt počet, pokud je prázdný'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldLikeOptionsEditor