import React, {RefObject} from "react"
import {Button, Checkbox, Col, Empty, Form, FormInstance, Input, InputNumber, Row, Select, Tooltip} from "antd";
import {CaretDownOutlined, CaretUpOutlined, DeleteOutlined, EditOutlined, SaveOutlined,} from "@ant-design/icons";
import {TweenOneGroup} from "rc-tween-one";
import {IViewSettingsProps} from "../ViewSettings";
import {
    SortableContainer as sortableContainer,
    SortableElement as sortableElement,
    SortableHandle as sortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";
import ICustomFiltersSettings, {
    ICustomFilterItemSettings
} from "../../../../model/interface/dataStorage/view/settings/ICustomFiltersSettings";
import FilterTreeBuilder from "./customFilters/FilterTreeBuilder";
import IconPicker from "../../../shared/IconPicker";
import IconBuilder from "../../../../utils/IconBuilder";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";

type CustomFiltersManageType = 'delete' | 'edit' | 'default'
type CustomFiltersManageFunction = (filterItem: ICustomFilterItemSettings, index: number, type: CustomFiltersManageType) => void

interface IState {
    filters: ICustomFiltersSettings
    formRef: RefObject<FormInstance>,
    filter?: ICustomFilterItemSettings
    currentIndex?: number,
    currentContentType: string
}

interface IProps extends IViewSettingsProps {
    findContentTypeByClassName: (name: string) => IContentType
    findContentTypeByUuid: (uuid: string) => IContentType
}

class CustomFiltersSettings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            filters: props.settings?.customFilters ? {...props.settings.customFilters} : {},
            formRef: React.createRef(),
            currentContentType: props.findContentTypeByUuid(props.view.contentTypes[0]).fullClassName
        }
    }

    manage = (filterItem: ICustomFilterItemSettings, index: number, type: CustomFiltersManageType) => {
        switch (type) {
            case "edit":
                return this.onEdit(filterItem, index)
            case "delete":
                return this.onDelete(filterItem, index)
            case "default":
                return this.onDefault(filterItem, index)
        }
    }

    onDefault = (filterItem: ICustomFilterItemSettings, index: number) => {
        const {currentContentType, filters} = this.state
        const filterItems = [...filters[currentContentType]]
        filterItems[index].default = !filterItem.default
        filterItems.forEach((item, currIndex) => {
            item.default = currIndex === index ? item.default : undefined
        })
        this.setState(state => ({filters: {...state.filters, [currentContentType]: filterItems}}), this.onChange)
    }

    onDelete = (removeFilterItem: ICustomFilterItemSettings, index: number) => {
        const {currentContentType} = this.state
        const filterItems = [...this.state.filters[currentContentType]]
        filterItems.splice(index, 1)
        this.setState(state => ({filters: {...state.filters, [currentContentType]: filterItems}}), this.onChange)
    }

    onChange() {
        this.resetEditing()
        this.props.onChange({customFilters: this.state.filters}).then();
    }

    onEdit = (filterItem: ICustomFilterItemSettings, index: number) => {
        const filterItems = this.state.filters[this.state.currentContentType]
        this.setState({filter: filterItems[index], currentIndex: index}, this.state.formRef.current?.resetFields)
    }

    resetEditing() {
        this.setState({filter: undefined, currentIndex: undefined}, this.state.formRef.current?.resetFields)
    }

    onAdd = () => {
        this.state.formRef.current?.validateFields().then(values => {
            let {filters, currentIndex, currentContentType} = this.state
            if (!filters[currentContentType]) {
                this.setState(state => ({
                    filters: {
                        ...state.filters,
                        [currentContentType]: [{...values}]
                    }
                }), this.onChange)
            } else {
                if (currentIndex !== undefined) {
                    filters[currentContentType][currentIndex] = {...values}
                } else {
                    filters[currentContentType].push(values)
                }
                this.setState(state => ({
                    filters: {...state.filters, [currentContentType]: [...filters[currentContentType]]}
                }), this.onChange)
            }
        })
    };

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }): void => {
        let {currentContentType} = this.state
        const filtersList = arrayMove(this.state.filters[currentContentType], oldIndex, newIndex)
        this.setState(state => ({filters: {...state.filters, [currentContentType]: filtersList}}), this.onChange)
    }

    onContentTypeChange = (name: string) => {
        this.setState({currentContentType: name}, this.resetEditing)
    }

    getContentType() {
        const {findContentTypeByClassName} = this.props
        const {currentContentType} = this.state
        return findContentTypeByClassName(currentContentType)
    }

    render() {
        const {filters, formRef, filter, currentContentType} = this.state
        const {view, history, match, findContentTypeByUuid} = this.props
        return (
            <>
            {/*// <Collapse>*/}
            {/*//     <Collapse.Panel*/}
            {/*//         header={"Vlastní filtry [" + Object.entries(filters)*/}
            {/*//             .map(([, items]) => items.length)*/}
            {/*//             .reduce((a, b) => a + b, 0) + ']'}*/}
            {/*        key="1" className={'mb-3'}>*/}
                    {view.contentTypes.length > 1 && (
                        <Form.Item label={'Zvolte typ obsahu'}>
                            <Select value={currentContentType} onChange={this.onContentTypeChange}>
                                {view.contentTypes.map(uuid => {
                                    const listContentType = findContentTypeByUuid(uuid)
                                    return currentContentType && (
                                        <Select.Option value={listContentType.fullClassName} key={uuid}>
                                            {listContentType.label}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    )}
                    <div>
                        <Row gutter={12}>
                            <Col sm={12}>
                                <Form initialValues={{...filter, filters: filter?.filters || {}}}
                                      layout={"vertical"}
                                      ref={formRef}>
                                    <Row gutter={12}>
                                        <Col>
                                            <Form.Item name={'label'} label={'Titulek'} rules={[{required: true}]}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name={'icon'} label={'Ikona'}>
                                                <IconPicker/>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name={'group'} label={'Skupina'}>
                                                <InputNumber />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item name={'filters'} label={'Filters'} rules={[{required: true}]}>
                                        <FilterTreeBuilder contentTypeFullClassName={currentContentType}
                                                           history={history} match={match}/>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => this.onAdd()}
                                                icon={<SaveOutlined/>}>
                                            Uložit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col sm={12}>
                                {filters[currentContentType] ? (
                                    <SortableContainer lockAxis={"y"}
                                                       onSortEnd={(filters) =>
                                                           this.onSortEnd(filters)}
                                                       useDragHandle
                                                       children={filters[currentContentType]}
                                                       manage={this.manage}/>
                                ) : <Empty description={''}/>}
                            </Col>
                        </Row>
                    </div>
            {/*    </Collapse.Panel>*/}
            {/*</Collapse>*/}
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByClassName: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name),
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(CustomFiltersSettings)

const DragHandle = sortableHandle(() => {
    return (
        <div className={'d-inline-block'}>
            <div className={"d-flex flex-column pl-2 pr-2"} style={{cursor: "move"}}>
                <CaretUpOutlined/>
                <CaretDownOutlined/>
            </div>
        </div>
    )
})

const SortableItem = sortableElement(({
                                          filter,
                                          manage,
                                          filterIndex
                                      }: {
    filter: ICustomFilterItemSettings
    manage: CustomFiltersManageFunction,
    filterIndex: number
}) => {
    return (
        <Row justify={"space-between"} align={"middle"} className={'mb-2 border p-1 shadow-sm'}
             style={{zIndex: 1001}} key={filterIndex}>
            <DragHandle/>
            <Button size={"small"} icon={filter.icon && IconBuilder(filter.icon)}>
                {filter.label}
            </Button>
            <Row align={"middle"} justify={"end"}>
                <Button size={"small"} type={"link"} icon={
                    <EditOutlined/>} onClick={() => manage(filter, filterIndex, "edit")}/>
                <Button size={"small"} type={"link"} danger icon={
                    <DeleteOutlined/>}
                        onClick={() => manage(filter, filterIndex, "delete")}/>
                <Tooltip title={'Výchozí aktivní'}>
                    <Checkbox checked={filter.default} onChange={() => manage(filter, filterIndex, "default")}/>
                </Tooltip>
            </Row>
        </Row>
    )
})

const SortableContainer = sortableContainer(({
                                                 children,
                                                 manage
                                             }: {
    children: ICustomFilterItemSettings[]
    manage: CustomFiltersManageFunction
}) => {
    return (
        <div className={'position-relative'}>
            <TweenOneGroup
                className={'position-relative'}
                enter={{scale: 0.8, opacity: 0, type: 'from', duration: 100}}
                leave={{y: -20, opacity: 0, duration: 300}}
                appear={false}
            >
                {children.map((value, index) => {
                    return (
                        <SortableItem key={`item-${index}`} index={index} filterIndex={index} filter={value}
                                      manage={manage}/>
                    )
                })}
                {children.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'žádné položky'}/>}
            </TweenOneGroup>
        </div>
    )
});