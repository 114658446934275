import IAction from "../../../model/interface/dataStorage/IAction";
import IRestResource from "../../../model/interface/api/IRestResource";
import ActionsService, {ACTION_REJECT_REASON, IActionResult} from "../../../model/service/dataStorage/ActionsService";
import store from "../../../redux/store";
import selectors from "../../../redux/selectors";
import {notification} from "antd";
import Utils from "../../../utils";
import SettingsService from "../../../model/service/SettingsService";
import FilesService from "../../../model/service/file/FilesService";

export const LAST_ACTION_FORM_DATA = '_last_action_form_data'

const Action = {
    doAction(action: IAction, entities: IRestResource[] | null, history: any, payload?: any, confirm: boolean = true): Promise<IActionResult> {
        console.log('doAction execute', action, entities, history, payload, confirm)
        if (!payload) {
            payload = {}
        }
        if (history) {
            payload.route = selectors.routes.extractRouteParametersFromUrl(store.getState(), history.location.pathname)
        }
        const storeState = store.getState()
        const last = selectors.routes.getReferrer(storeState)
        payload.last = last ? selectors.routes.extractRouteParametersFromUrl(storeState, last) : undefined
        const promise = ActionsService.execute(action, entities, payload, confirm)
        return promise.then(response => {
            if (response.messages) {
                response.messages.forEach(message => {
                    const messageConfig = {
                        message: message.title,
                        description: message.content,
                        duration: Number(SettingsService.getValue('ui', 'alertDuration') || 3)
                        // onClick: () => {
                        //     console.log('Notification Clicked!');
                        // },
                    }
                    switch (message.type) {
                        case "alert":
                            notification.warning(messageConfig);
                            break;
                        case "notification":
                            notification.info(messageConfig);
                            break;
                        case "warning":
                            notification.warning(messageConfig);
                            break;
                        case "success":
                            notification.success(messageConfig);
                            break;
                        case "error":
                            notification.error(messageConfig);
                            break;
                    }
                })
            }
            if (response.fileIds?.length) {
                FilesService.resourceDownload(response.fileIds).then(({blob, fileName}) => {
                    Utils.downloadFile(blob, fileName)
                })
                return response
            }
            if (!response.success) {
                response.messages?.length === 0 && notification.error({message: 'Akci se nepodařilo úspěšně dokončit'})
                return Promise.reject(ACTION_REJECT_REASON.TERMINATED)
            }
            return response
        })
    },
    getLastActionFormData: (actionUuid: string): any | null => {
        const result = localStorage.getItem(LAST_ACTION_FORM_DATA)
        localStorage.removeItem(LAST_ACTION_FORM_DATA)
        return result && JSON.parse(result)?.[actionUuid]
    },
    setLastActionFormData: (action: IAction, payload?: any) => {
        if (payload.data && !payload.submit && action.type === 'form'){
            localStorage.setItem(LAST_ACTION_FORM_DATA, JSON.stringify({[action.uuid]: payload.data}))
        }
    }
}

export default Action