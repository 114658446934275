import React, {RefObject} from "react";
import {Form, FormInstance, Switch} from "antd";
import IFormOptions from "../../../../../../model/interface/form/IFormOptions";
import IContainerOptions from "../../../../../../model/interface/form/elementOptions/IContainerOptions";
import Utils from "../../../../../../utils";
import IFormStructureNode from "../../../../../../model/interface/form/IFormStructureNode";
import ConditionEditor from "./ConditionEditor";
import WidgetContainerEditor from "../../../widget/optionEditor/ContainerEditor";
import Title from "antd/es/typography/Title";
import IField from "../../../../../../model/interface/dataStorage/IField";

interface IProps {
    options: IContainerOptions
    onFinish: (values?: IFormOptions) => void
    fieldNodes: IFormStructureNode[]
    fields: IField[]
}

interface IState extends IContainerOptions {
    showMainEditor?: boolean
}

class ContainerEditor extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options,
            conditions: props.options.conditions ? props.options.conditions : [],
            collapsable: Utils.toBoolean(props.options.collapsable)
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>
    formRefCondition = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then((values: IContainerOptions) => {
            this.props.onFinish({...this.state, ...values})
        })
    }

    onCollapsableChange = () => {
        this.setState({collapsable: !this.state.collapsable})
    }

    onCancel = () => {
        this.props.onFinish()
    }

    showMainEditor = () => {
        this.setState(state => ({showMainEditor: !state.showMainEditor}))
    }

    render() {
        const {collapsable} = this.state
        const {fields, fieldNodes} = this.props

        return (
            <WidgetContainerEditor {...this.props}>
                <Form.Item name={'collapsable'} label={'Možnost skrýt'} valuePropName={'checked'}>
                    <Switch onChange={this.onCollapsableChange}/>
                </Form.Item>
                {collapsable && (
                    <div>
                        <Title level={4}>Collapsable conditions</Title>
                        <Form.Item name={'conditions'} noStyle={true}>
                            <ConditionEditor fields={fields} fieldNodes={fieldNodes}/>
                        </Form.Item>
                    </div>

                )}
            </WidgetContainerEditor>
        )
    }
}

export default ContainerEditor