import React, {RefObject} from 'react'
import {Button, Card, Form, FormInstance, Input, message} from 'antd';
import {CodeOutlined, CopyOutlined, EyeOutlined, ToolOutlined} from "@ant-design/icons";
import IFormOptions from "../../../../model/interface/form/IFormOptions";
import {IPropsReportStep} from "./ReportConfiguration";
import IReportWidget from "../../../../model/interface/report/IReportWidget";
import ReportWidgetType from "./widget/ReportWidgetType";
import ReportWidget from "./widget/ReportWidget";
import TreeStructure from "../../../../utils/TreeStructure";
import ReportWidgetList from "./widget/ReportWidgetList";
import IWidgetOptions from "../../../../model/interface/widget/IWidgetOptions";
import Widget from "../widget/Widget";
import ImageWidgetEditor from "./widget/optionEditor/ImageWidgetEditor";
import IReportWidgetImage from "../../../../model/interface/report/IReportWidgetImage";
import IReportWidgetView from "../../../../model/interface/report/IReportWidgetView";
import ViewWidgetEditor from "./widget/optionEditor/ViewWidgetEditor";
import _ from "underscore";
import ReportWidgetGallery from "./widget/ReportWidgetGallery";
import Utils from "../../../../utils";
import EmployeeLookupWidgetEditor from "./widget/optionEditor/EmployeeLookupWidgetEditor";
import IReportWidgetEmployeeLookup from "../../../../model/interface/report/IReportWidgetEmployeeLookup";
import NotificationsWidgetEditor from "./widget/optionEditor/NotificationsWidgetEditor";
import ResourceFinderByCodeWidgetEditor from "./widget/optionEditor/ResourceFinderByCodeWidgetEditor";

interface IState {
    mode: string,
    structure: { [id: string]: IReportWidget }
    optionEditor: JSX.Element | null
    formRefCode: RefObject<FormInstance>
}

class ReportEditor extends React.Component<IPropsReportStep, IState> {

    constructor(props: IPropsReportStep) {
        super(props);
        const containerUuid = Utils.uuid();
        this.state = {
            structure: {
                [containerUuid]: {
                    ...ReportWidgetList.getByType(ReportWidgetType.CONTAINER),
                    id: containerUuid,
                    label: 'Container'
                }
            },
            mode: 'editor',
            optionEditor: null,
            formRefCode: React.createRef()
        };
    }

    componentDidMount() {
        let structure = ReportEditor.widgetsToStructure(this.props.resource.widgets
            .filter(widget => this.props.resource.thread || widget.type !== ReportWidgetType.COMMENT))
        this.props.resource.widgets.length > 0 && this.setState({structure})
    }

    static widgetsToStructure(widgets: IReportWidget[]) {
        let structure = {} as { [id: string]: IReportWidget }
        widgets.forEach(widget => {
            structure[widget.uuid] = {
                ...widget,
                id: widget.uuid,
                children: widgets.filter(value => value.parent === widget.uuid).map(wd => wd.uuid),
                parent: widget.parent
            }
        })
        return structure;
    }

    static isWidget(value: any): value is IReportWidget {
        return value && value.uuid;
    }

    setMode(mode: string) {
        this.setState({
            mode
        })
    }

    moveUp(id: string) {
        this.movePosition(id)
    }

    moveDown(id: string) {
        this.movePosition(id, false);
    }

    movePosition(id: string, up = true) {
        this.setState({
            structure: TreeStructure.updateChildPosition(id, this.state.structure, up, 'weight')
        })
    }

    removeNodeById(id: string) {
        let structure = {...this.state.structure}
        if (structure[id]) {
            structure = this.removeChildren(id, structure)
            const parent = structure[id].parent
            if (parent && this.state.structure[parent]) {
                structure[parent].children = _.without(structure[parent].children, id)
            }
            delete structure[id]
            this.setState({structure})
        }
    }

    removeChildren(id: string, structure: { [id: string]: IReportWidget }) {
        for (const child of this.getSortedChildren(id, structure)) {
            structure = this.removeChildren(child.id, structure)
            delete structure[child.id]
        }
        return structure
    }

    editOptions(widget: IReportWidget) {
        const onFinish = (values?: IFormOptions) => {
            if (values) {
                for (const property in values) {
                    if (values.hasOwnProperty(property) && values[property] === undefined) {
                        values[property] = ''
                    }
                }
                this.saveWidget(widget, values);
            }
            this.setState({optionEditor: null})
        }
        let optionEditor = this.getOptionEditor(widget, onFinish);
        this.setState({optionEditor}, () => !optionEditor && this.saveWidget(widget));
    }

    saveWidget(widget: IReportWidget, values: IWidgetOptions = {}) {
        const id = widget.id
        let structure = this.state.structure;
        widget.options = values
        if (!structure[id] && widget.parent) {
            widget.weight = structure[widget.parent].children.push(id)
        }
        structure[id] = widget
        this.setState({structure})
    }

    getOptionEditor(node: IReportWidget, onFinish: (values?: IFormOptions) => void) {
        switch (node.type) {
            case ReportWidgetType.IMAGE:
                return <ImageWidgetEditor options={node.options as IReportWidgetImage} onFinish={onFinish}/>
            case ReportWidgetType.VIEW:
                return <ViewWidgetEditor options={node.options as IReportWidgetView} onFinish={onFinish}/>
            case ReportWidgetType.EMPLOYEE_LOOKUP:
                return <EmployeeLookupWidgetEditor options={node.options as IReportWidgetEmployeeLookup}
                                                   onFinish={onFinish}/>
            case ReportWidgetType.NOTIFICATIONS:
                return <NotificationsWidgetEditor options={node.options} onFinish={onFinish}/>
            case ReportWidgetType.RESOURCE_FINDER_BY_CODE:
                return <ResourceFinderByCodeWidgetEditor options={node.options} onFinish={onFinish}/>
            default:
                return Widget.getOptionEditor(node.type, node.options, onFinish)
        }
    }

    appendNode(id: string, group?: string) {
        (new ReportWidgetGallery(
            (type: string) => this.appendNodeExecute(id, type),
            ReportWidgetList,
            this.props.resource,
            this.typeExists
        )).show(group)
    }

    typeExists = (type: string) => {
        return !!Object.entries(this.state.structure).find(value => value[1].type === type)
    }

    appendNodeExecute(id: string, type: string) {
        const widget = {...ReportWidgetList.getByType(type), parent: id, id: Utils.uuid(), type}
        this.editOptions(widget)
    }

    getSortedChildren(id: string, structure: { [id: string]: IReportWidget }) {
        return TreeStructure.sortChildren(id, structure, 'weight') as IReportWidget[]
    }

    formatJsonTree() {
        return TreeStructure.buildJson(this.state.structure, null, 2)
    }

    formatStructureFromTree(tree: [IReportWidget]) {
        this.setState({
            structure: TreeStructure.destruct(tree)
        })
    }

    applyCode(values: any) {
        let code = JSON.parse(values["code"])
        if (code) {
            this.formatStructureFromTree(code)
        }
        message.success('Formulář aktualizován').then();
    }

    confirm = (step: number) => {
        let resource = {...this.props.resource}
        resource.widgets = this.mapWidgets()
        this.props.onChange(resource)
        this.props.setStep(step)
    }

    mapWidgets() {
        let widgets: IReportWidget[] = []
        Object.entries(this.state.structure).forEach(([, widget]) => {
            widgets.push({
                ...widget,
                children: undefined as any, // can`t send empty array, will delete all attached widgets
                id: null as any,
                uuid: widget.id
            })
        })
        return widgets;
    }

    copyCode = () => {
        navigator.clipboard.writeText(this.state.formRefCode.current?.getFieldValue('code'))
            .then(() => {
                message.success('Zkopírováno').then()
            })
    }

    render() {
        const {mode, structure, optionEditor, formRefCode} = this.state

        return (
            <>
                {optionEditor && (optionEditor)}
                <Card>
                    <Button.Group>
                        <Button type={mode === 'editor' ? 'primary' : 'default'} onClick={() => this.setMode('editor')}
                                icon={<ToolOutlined/>}> Editor</Button>
                        <Button type={mode === 'preview' ? 'primary' : 'default'}
                                onClick={() => this.setMode('preview')} icon={<EyeOutlined/>}> Náhled</Button>
                        <Button type={mode === 'code' ? 'primary' : 'default'} onClick={() => this.setMode('code')}
                                icon={<CodeOutlined/>}> Kód</Button>
                    </Button.Group>

                    {(mode === 'editor' || mode === 'preview') && Object.entries(structure).map(([, widget]) => {
                            if (widget && !widget.parent) {
                                let functions = {
                                    getNode: (id: string) => structure[id],
                                    editOptions: (id: string) => this.editOptions(structure[id]),
                                    delete: (id: string) => this.removeNodeById(id),
                                    appendNode: (id: string, group?: string) => this.appendNode(id, group),
                                    moveUp: (id: string) => this.moveUp(id),
                                    moveDown: (id: string) => this.moveDown(id),
                                    appendNodeExecute: (id: string, type: string) => this.appendNodeExecute(id, type),
                                    getSortedChildren: (id: string) => this.getSortedChildren(id, structure),
                                }
                                return (
                                    <ReportWidget
                                        {...widget}
                                        key={widget.uuid}
                                        preview={mode === 'preview'}
                                        functions={functions}
                                        match={this.props.match}
                                        history={this.props.history}
                                    />
                                )
                            }
                            return null
                        }
                    )}
                    {mode === 'code' && (
                        <Form onFinish={(values) => this.applyCode(values)} ref={formRefCode}
                              initialValues={{code: this.formatJsonTree()}}>
                            <Form.Item name={"code"}>
                                <Input.TextArea rows={15}/>
                            </Form.Item>
                            <Button htmlType={"submit"} type={"primary"}>Aktualizovat</Button>
                            <Button className={'ml-1'} type={"dashed"}
                                    onClick={this.copyCode} icon={<CopyOutlined/>}/>
                        </Form>
                    )}
                </Card>
                <Button type={"primary"} onClick={() => this.confirm(2)}>Následující krok</Button>
                <Button onClick={() => this.confirm(0)}>Předchozí krok</Button>
            </>
        );
    }
}

export default ReportEditor