import FormElementType from "./FormElementType";
import WidgetList, {IWidgetList, IWidgetListItem} from "../../widget/WidgetList";
import WidgetType from "../../widget/WidgetType";
import IFormStructureNode from "../../../../../model/interface/form/IFormStructureNode";
import {antIcons} from "utils/Icons/AntIcons";

const widgets = [
    {
        type: FormElementType.SUBMIT,
        group: WidgetType.GROUP_STATIC,
        label: "Tlačítko",
        icon: antIcons.SendOutlined
    },
    {
        type: FormElementType.BUTTONS,
        group: WidgetType.GROUP_STATIC,
        label: 'Tlačítka',
        icon: antIcons.AppstoreAddOutlined
    },
    {
        type: FormElementType.COMMENT,
        group: FormElementType.GROUP_FIELD,
        label: 'Komentáře',
        icon: antIcons.CommentOutlined
    },
    {
        type: FormElementType.ENTITY_TABLE,
        group: FormElementType.GROUP_FIELD,
        label: 'Tabulka entit',
        icon: antIcons.TableOutlined,
        hidden: true
    },
    {
        type: FormElementType.ENTITY_TABLE_COLUMN,
        group: FormElementType.GROUP_FIELD,
        label: 'Tabulka entit sloupec',
        icon: antIcons.InsertRowRightOutlined,
        hidden: true
    },
    ...WidgetList.getAll(),
] as IWidgetListItem[]

const FormElementList: IWidgetList = {
    ...WidgetList,
    getGrouped: () => {
        let groups = {
            [FormElementType.GROUP_CONSTRUCT]: {label: 'Modifikace uživatelské rozhraní', widgets: []},
            [FormElementType.GROUP_STATIC]: {label: 'Statická data', widgets: []},
            [FormElementType.GROUP_FIELD]: {label: 'Vlastnosti', widgets: []},
            [FormElementType.GROUP_ACTION]: {label: 'Akce', widgets: []}
        } as { [group: string]: { label: string, widgets: IWidgetListItem[] } }
        widgets.filter(widget => !widget.hidden).forEach(widget => {
            groups[widget.group].widgets.push(widget)
        })
        return groups
    },
    getByType: (type: string) => {
        const itemDefault = {type, label: ''}
        const item = widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item || [FormElementType.GROUP_ACTION, FormElementType.GROUP_FIELD].includes(type)) {
            const {label, type} = (item || itemDefault)
            return {label, type, weight: 1, children: [], id: '', options: {}, parent: null} as IFormStructureNode
        }
        throw new Error(`Report widget type[${type}] does not exist!`)
    },
    getItemByType: (type: string) => {
        const item = widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item) {
            return item
        }
        throw new Error(`Report widget type[${type}] does not exist!`)
    }
}

export default FormElementList
