import {Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterBase from "../IFilterBase";
import IFilterFunctions from "../IFilterFunctions";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import FilterDropDown from "../FilterDropDown";
import FormCompositeFieldApproval
    from "../../../components/app/configuration/form/FormElement/formField/FormCompositeFieldApproval";
import ICompositeFieldData from "../../../model/interface/dataStorage/field/composite/ICompositeFieldData";


const CompositeApprovalFilter: IFilterBase = {
    handleSearch(setSelectedKeys, selectedKeys, confirm) {
        confirm();
    },

    handleReset(clearFilters, setValue) {
        clearFilters();
        setValue([])
    },

    build(item, field, state, setValue): IFilterFunctions {
        const dataIndex = field.name

        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                        <FormCompositeFieldApproval options={{
                            ...field.options,
                            allowChange: true,
                            requireJustification: false,
                            allowJustification: false
                        }} value={selectedKeys[0]?.value} field={field} onChange={(data: ICompositeFieldData) => {
                            setSelectedKeys([{
                                type: data[dataIndex + "_result"] ? API_FILTER_TYPE.EQUAL : API_FILTER_TYPE.IS_NULL,
                                value: data[dataIndex + "_result"],
                            }])
                        }}/>
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered: boolean): JSX.Element => {
                return (
                    <Tooltip title={filtered ? "Filtrováno: " + (state[0]?.value ? 'Povoleno' : 'Zakázáno') : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                return record[dataIndex] === value
            }
        }
    }
}

export default CompositeApprovalFilter