import React from 'react'
import {Button, Dropdown, Menu} from 'antd';
import {EditOutlined, SettingOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import IReport from "../../../../model/interface/dataStorage/IReport";
import IUser from "../../../../model/interface/security/IUser";
import IBaseProps from "../../../../model/interface/IBaseProps";
import {connect, RootStateOrAny} from "react-redux";

interface IState {
    visible: boolean
}

interface IProps extends IBaseProps {
    user: IUser
    resource: IReport
}

class ReportEditButton extends React.Component<IProps, IState> {
    render() {
        const {resource, user} = this.props

        return (
            <div className={"noPrint"}>
                {user.credentials.indexOf('configuration') >= 0 && (
                    <>
                        <Dropdown
                            trigger={["click"]}
                            overlay={(
                                <Menu>
                                    <Menu.Item key="1">
                                        <Link to={"/app/configuration/reports/" + resource.id}>
                                            <EditOutlined/>Upravit report
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            )}
                        >
                            <Button
                                style={{verticalAlign: "middle"}}
                                icon={<SettingOutlined/>}
                                size={"small"}
                            />
                        </Dropdown>
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        user: state.setup.user
    }
}

export default connect(mapStateToProps)(ReportEditButton)