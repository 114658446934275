import ValidationService from "../model/service/validation/ValidationService";

const InputValidator = {
    validateURL: (value: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(value);
    },
    validateEmail: (email: string) => {
        return !email || !!String(email).toLowerCase().match(
            /[^@]+@.+\..{2,5}/
        )
    },
    validatePhoneNumber: (value: string) => {
        return !!value.match(/^(\+\d{1,3}\s)?\(?\d{3,4}\)?[\s.-]?\d{3}[\s.-]?\d{3,4}$/) //TODO ??
    },
    validateICO: (value: string) => {
        return !!value.match(/^[\d]{8}$/) // TODO VALIDATE WITH ARES, ADD METHOD TO VALIDATION SERVICE
    },
    validateBankNumber: (value: string) => {
        return ValidationService.validateBankAccountNumber(value)
    }
}

export default InputValidator