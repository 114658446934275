import React, {CSSProperties} from "react";
import Utils from "../../../utils";

interface IState {
    visible: boolean
}

interface IProps {
    visibility?: 'visible' | 'auto' | 'hidden'
    design?: 'minimalist' | 'classic'
    style?: CSSProperties
    orientation?: 'vertical' | 'horizontal'
    className?: string
    wrapperClassName?: string,
    wrapperStyle?: CSSProperties
    shadowCoverColor?: string
}

class ScrollContainer extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            visible: props.visibility === 'visible'
        }
    }

    static defaultProps = {
        visibility: 'auto',
        design: 'minimalist',
        orientation: 'vertical',
        className: '',
        shadowCoverColor: '#ffffffff'
    }

    setVisible = (visible: boolean = true) => {
        const {visibility} = this.props
        visibility === 'auto' && this.setState({visible})
    }

    render() {
        const {children, visibility, design, style, orientation, className, wrapperClassName, wrapperStyle} = this.props
        const shadowCoverColor = Utils.rgbaToHexNoA(Utils.hexaToRgba(this.props.shadowCoverColor!))

        return (
            <div className={"scroll-container-wrapper " + wrapperClassName} style={wrapperStyle}
                 id='scroll-container-wrapper-id'>
                <div className={'scroll-container ' + visibility + ' ' + design + ' ' + orientation + ' ' + className}
                     id='scroll-container-id'
                     onMouseEnter={() => this.setVisible()}
                     onMouseLeave={() => this.setVisible(false)}
                     style={{
                         ...style,
                         background: orientation === 'vertical' ? `linear-gradient(${shadowCoverColor} 99%, transparent),radial-gradient(farthest-side at top, rgba(0, 0, 0, .2), transparent),linear-gradient(transparent, ${shadowCoverColor} 1%) bottom,radial-gradient(farthest-side at bottom, rgba(0, 0, 0, .2), transparent) bottom` :
                             `linear-gradient(${shadowCoverColor} 99%, transparent) left,radial-gradient(farthest-side at left, rgba(0, 0, 0, .2), transparent) left,linear-gradient(transparent, ${shadowCoverColor} 1%) right,radial-gradient(farthest-side at right, rgba(0, 0, 0, .2), transparent) right`
                     }}>
                    {children}
                </div>
            </div>
        );
    }
}

export default ScrollContainer