import React, {RefObject} from "react";
import {Button, Form, FormInstance} from "antd";
import {ICardStepProps} from "./CardModal";
import {ArrowDownOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import DragSortingTable from "../../../../shared/Table/DragSortingTable";
import ICard from "../../../../../model/interface/dataStorage/ICard";
import {ColumnsType} from "antd/es/table";
import FilterTreeBuilder from "../../../view/settings/customFilters/FilterTreeBuilder";

interface IState {
    formRef: RefObject<FormInstance>,
    formValues: ICard,
    changedCards: ICard[]
}

interface IProps extends ICardStepProps {
    contentType: IContentType
}

export default class CardEvaluation extends React.Component<IProps, IState> {
    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            formValues: {...props.card},
            changedCards: props.contentType.cards.filter(c => c.uuid !== props.card.uuid)
        }
    }

    confirm = (back: boolean = false) => {
        const {formRef, formValues, changedCards} = this.state
        formRef.current?.validateFields().then(values => {
            this.props.onChange({...this.props.card, ...values, ...formValues}, back, changedCards)
        })
    }

    onValuesChange = (formValues: ICard) => {
        this.setState(state => ({formValues: {...state.formValues, ...formValues}}))
    }

    onRouteCardsSort = (data: ICard[]) => {
        const {card} = this.props
        const priority = data.findIndex(c => c.uuid === card.uuid)
        this.setState(state => ({
            changedCards: [...data.map((c, index) => ({...c, routePriority: index}))
                .filter(c => c.uuid !== card.uuid)],
            formValues: {...state.formValues, routePriority: (priority < 0 ? undefined : priority)}
        }))
    }

    render() {
        const {card, contentType, history, match} = this.props
        const {formRef, formValues, changedCards} = this.state

        return (
            <Form onValuesChange={this.onValuesChange}
                  initialValues={card ? card : {}}
                  ref={formRef}
            >
                {formValues?.route && contentType.cards.filter(c => c.route === formValues?.route).length > 1 && (
                    <DragSortingTable className={'mb-3'} onMove={this.onRouteCardsSort}
                                      columns={this.getColumns(card)}
                                      pagination={false}
                                      dataSource={this.getDataSource(changedCards, formValues, card)}
                                      rowKey={card => card.uuid}/>
                )}
                <Form.Item name={'conditions'} label={'Podmínky zobrazení'}>
                    <FilterTreeBuilder contentTypeFullClassName={contentType.fullClassName} fieldPickerMode={"simple"}
                                       history={history} match={match} addNewLabel={'Přidat podmínku'}/>
                </Form.Item>
                <Button icon={<LeftOutlined/>} onClick={() => this.confirm(true)}>Předchozí krok</Button>
                <Button icon={<RightOutlined/>} onClick={() => this.confirm()} type={"primary"}>Následující krok</Button>
            </Form>
        )
    }

    getDataSource(changedCards: ICard[], formValues: ICard, card: ICard) {
        return [...changedCards
            .filter(c => c.route === formValues?.route), {...card, ...formValues}]
            .sort((a, b) => (a.routePriority || 0) - (b.routePriority || 0));
    }

    getColumns(card: ICard): ColumnsType<ICard> {
        return [{
            title: <div>
                <ArrowDownOutlined className={'mr-2'}/>Priorita zobrazení karty na zvolené route
            </div>,
            render: (_: any, row: ICard) => {
                let children: any = row.name
                let className: string = ''
                if (row.uuid === card.uuid) {
                    className = 'ant-btn-info'
                }
                return {props: {className}, children}
            },
        }];
    }
}