import React, {Component, RefObject} from 'react';
import {Button, FormInstance, Input, Select} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import {PlusOutlined} from "@ant-design/icons";
import IAction, {ActionType} from "../../../model/interface/dataStorage/IAction";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import selectors from "../../../redux/selectors";
import ContentTypeActionModal from "../../app/configuration/content-type/action/ContentTypeActionModal";
import ContentTypeActions from "../../app/configuration/content-type/ContentTypeActions";
import Utils from "../../../utils";

interface IProps {
    value?: string | IAction
    onChange?: (action?: string | IAction) => void
    className?: string,
    contentTypeUuid: string,
    findContentType: (value: string) => IContentType
    style?: React.CSSProperties,
    disabled?: boolean
    canAdd?: boolean,
    types?: ActionType[],
    autoSave?: boolean
    output?: 'uuid' | 'object' | 'name' | string,
    defaultType?: ActionType
}

interface IState {
    value?: string
    formRef: RefObject<FormInstance>,
    showModal: boolean
}

class ActionPicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            value: Utils.parseObjectToIdentifier(props.value, 'uuid'),
            formRef: React.createRef(),
            showModal: false
        }
    }

    static defaultProps = {
        canAdd: true,
        autoSave: true,
        output: "uuid"
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {value} = this.props
        if (prevProps.value !== value) {
            this.setState({value: Utils.parseObjectToIdentifier(value, 'uuid')})
        }
    }

    getContentType = () => {
        const {findContentType, contentTypeUuid} = this.props
        return findContentType(contentTypeUuid);
    }

    onPickerChoose = (action: string) => {
        this.setState({value: action})
        const actionObject = this.getContentType().actions.find(a => a.uuid === action)
        actionObject && this.updateParent(actionObject)
    }

    onModalChange = (action?: IAction) => {
        if (action) {
            this.updateParent(action);
        }
        this.setState({showModal: false})
    }

    updateParent(action: IAction) {
        const {onChange, output} = this.props
        if (onChange && action.uuid) {
            switch (output) {
                case "object":
                    return onChange(action)
                case "uuid":
                    return onChange(action.uuid)
                case "name":
                    return onChange(action.name)
            }
        }
    }

    showModal = () => {
        this.setState(state => ({showModal: !state.showModal}))
    }

    getOptions() {
        const {types, value, autoSave} = this.props
        return [
            ...this.getContentType().actions.filter(a => !types || types.includes(a.type)),
            ...(value && typeof value === 'object' && !autoSave && !value.id ? [value] : [])
        ]
    }

    render() {
        const {value, showModal} = this.state
        const {className, contentTypeUuid, style, disabled, canAdd, autoSave, defaultType} = this.props
        const contentType = this.getContentType();

        return (
            <div className="route-picker" style={style}>
                {showModal && (
                    <ContentTypeActionModal contentType={contentType}
                                            resource={{
                                                ...ContentTypeActions.createNewActionObject(contentTypeUuid),
                                                type: defaultType || ''
                                            }}
                                            onCancel={this.showModal}
                                            forms={contentType.forms} onSave={this.onModalChange}
                                            saveSeparately={autoSave}/>
                )}
                <Input.Group compact className={'d-flex w-100'}>
                    <Select
                        allowClear
                        className={className + ' flex-grow-1 min-w-0'}
                        disabled={disabled}
                        value={value}
                        placeholder="Vyberte akci"
                        optionFilterProp="children"
                        onChange={this.onPickerChoose}
                        filterOption={(input, option) =>
                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {this.getOptions().map(action => (
                                <Select.Option key={action.uuid} value={action.uuid as string}>
                                    {action.label + ' [' + action.name + ']'}
                                </Select.Option>
                            )
                        )}
                    </Select>
                    {canAdd && <Button className={'flex-shrink-0'} disabled={disabled} onClick={this.showModal}
                                       icon={<PlusOutlined/>}/>}
                </Input.Group>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentType: (value: string) => selectors.contentTypes.findOneBy(state, 'uuid', value),
    }
}

export default connect(mapStateToProps)(ActionPicker)
