import React from 'react';
import {Button, Col, Row, Tooltip} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import selectors from "../../../redux/selectors";
import IField from "../../../model/interface/dataStorage/IField";
import IRepositoryService from "../../../model/interface/IRepositoryService";
import {API_TOKEN_ENTITY, API_TOKEN_LIST} from "../../../model/constants/ApiConstant";
import FormElementField from "../../app/configuration/form/FormElement/FormElementField";
import Utils from "../../../utils";
import {CheckOutlined, SwapOutlined} from "@ant-design/icons";
import IBaseProps from "../../../model/interface/IBaseProps";
import DataStorageService from "../../../model/service/dataStorage/DataStorageService";

interface IProps extends IBaseProps {
    value?: any
    field: IField
    onChange?: (value: any) => void
    className?: string,
    style?: React.CSSProperties,
    disabled?: boolean
    findContentType: (property: string, value: string) => IContentType
    findServiceByClassName: (className: string) => IRepositoryService
    isVirtual: (className: string) => boolean,
    multiple?: boolean
}

interface IState {
}

class FieldDefaultValuePicker extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    setValue = (value: any) => {
        this.props.onChange?.(Utils.parseObjectToIdentifier(value, 'uuid'))
    }

    render() {
        const {
            className,
            style,
            disabled,
            value,
            isVirtual,
            match,
            history,
            field,
            findContentType,
            multiple
        } = this.props

        const entity = field?.targetEntity ?
            (isVirtual(field.targetEntity) ? DataStorageService.VIRTUAL_ENTITY : field.targetEntity) : field?.type
        const token = entity ? Object.entries(API_TOKEN_LIST)
            .find(([, t]) => t.value === API_TOKEN_ENTITY[entity])?.[1] : undefined

        return (
            <Row gutter={[10, 10]} style={style} className={className}>
                <Col span={field && token ? 10 : 24}>
                    <FormElementField
                        field={field}
                        functions={{
                            ...{
                                getNode: () => ({field}),
                                getContentType: () => findContentType('uuid', field.contentTypeId!),
                            } as any
                        }}
                        type={'field'}
                        label={''}
                        onChange={this.setValue}
                        match={match}
                        value={value === token?.value ? undefined : value}
                        history={history}
                        id={'default-value-id'}
                        options={{
                            showClear: true,
                            disabled: disabled || (!!token && (token.value === value || (token.value === 'content-type' && typeof value === "string" && !Utils.isUuid(value)))),
                            multiple: multiple,
                            companyStructureMultiple: multiple,
                            employeeMultiple: multiple
                        }}
                        noName={true}
                        noStyle={true}
                        preview={true}
                    />
                </Col>
                {token && (
                    <>
                        <Col span={4} className={'text-center'}>
                            <div>OR</div>
                            <SwapOutlined/>
                        </Col>
                        <Col span={10}>
                            <Tooltip
                                title={'vždy aktuální hodnota v závislosti na přihlášeném uživateli'}>
                                <Button
                                    disabled={disabled}
                                    icon={value === token.value &&
                                        <CheckOutlined/>}
                                    onClick={() => this.setValue(value === token.value ? undefined : token?.value)}
                                    type={value === token.value ?
                                        'primary' : 'default'
                                    }>
                                    {token.label}
                                </Button>
                            </Tooltip>
                        </Col>
                    </>
                )}
            </Row>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {

    return {
        findContentType: (property: string, value: string) => selectors.contentTypes.findOneBy(state, property, value),
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(state, className),
        isVirtual: (className: string) => selectors.services.isVirtual(state, className),
    }
}

export default connect(mapStateToProps)(FieldDefaultValuePicker)
