import React from "react";
import {DatePicker} from "antd";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import FormFieldType from "./FormFieldType";
import LocaleProvider from "../../../../../../i18n/LocaleProvider";

class FormFieldDate {
    public static render = (options: IFieldOptions) => {
        const props = {
            style: options.style,
            placeholder: options.placeholder,
            allowClear: options.showClear,
            disabled: options.disabled,
            value: options.value
        };
        switch (options.datePicker) {
            case FormFieldType.FIELD_DATE_DATE_PICKER:
                return <DatePicker locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_DATE_TIME_PICKER:
                return <DatePicker showTime locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_YEAR_PICKER:
                return <DatePicker.YearPicker locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_QUARTER_PICKER:
                return <DatePicker.QuarterPicker locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_MONTH_PICKER:
                return <DatePicker.MonthPicker locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_WEEK_PICKER:
                return <DatePicker.WeekPicker locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_TIME_PICKER:
                return <DatePicker.TimePicker locale={LocaleProvider.getPicker()} {...props} />
            default:
                return <span>{'Invalid datePicker option: ' + options.datePicker}</span>
        }
    }
}

export default FormFieldDate