import React, {RefObject} from "react";
import {connect, RootStateOrAny} from 'react-redux'
import selectors from "../../../redux/selectors";
import IRoute from "../../../model/interface/dataStorage/IRoute";
import IBaseProps from "../../../model/interface/IBaseProps";
import IBookmark from "../../../model/interface/ui/IBookmark";
import Bookmark, {BOOKMARK_TYPE_PLAIN, BOOKMARK_TYPE_TAG} from "../../app/bookmark/Bookmark";
import {Menu, Row} from "antd";
import {SortableContainer as sortableContainer, SortableElement as sortableElement} from "react-sortable-hoc";
import arrayMove from "array-move";
import {bookmarksUpdate} from "../../../redux/actions/Setup";
import ScrollContainer from "../../shared/scrollContainer/ScrollContainer";
import BookmarksService from "../../../model/service/ui/BookmarksService";
import SettingsService from "../../../model/service/SettingsService";

interface IProps extends IBaseProps {
    findOneByUrl: (path: string) => IRoute | null
    bookmarks: IBookmark[]
    bookmarksUpdate: (bookmarks: IBookmark[]) => any
    isMobile: boolean
    menuRef?: RefObject<Menu>
}

interface ISortableItemProps extends IBaseProps {
    isMobile: boolean
}

interface IState {

}

class Bookmarks extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {}
    }

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }): void => {
        if (oldIndex === newIndex) {
            return
        }
        const {bookmarksUpdate, bookmarks} = this.props
        const items = arrayMove(bookmarks, oldIndex, newIndex);
        items.forEach((value, index) => {
            value.weight = index
        })
        bookmarksUpdate(items)
        bookmarks[oldIndex].id && BookmarksService.resourceUpdate(bookmarks[oldIndex].uuid, items[newIndex])
    }

    render() {
        const {history, match, bookmarks, isMobile} = this.props

        return isMobile ? (
            <>
                {/*<SubMenu>*/}
                {bookmarks.map(bookmark => (
                    <Menu.Item key={bookmark.id}>
                        {bookmark.title}
                    </Menu.Item>
                ))}
                {/*</SubMenu>*/}

                {/*<Dropdown trigger={['click']} overlay={*/}
                {/*    <Card onClick={() => console.log('dede')} size={"small"}>*/}
                {/*        <div className={'border-bottom'}>*/}
                {/*            <Bookmark newMode={"item"} history={history} match={match}/>*/}
                {/*        </div>*/}
                {/*    </Card>*/}
                {/*}>*/}
                {/*    <div className={'text-warning font-size-xl cursor-pointer'}>*/}
                {/*        <StarFilled className={'m-0'} style={{fontSize: 20}}/>*/}
                {/*    </div>*/}
                {/*</Dropdown>*/}


                {/*<SortableContainer*/}
                {/*    axis={'y'}*/}
                {/*    distance={1}*/}
                {/*    lockAxis={'y'}*/}
                {/*    onSortEnd={this.onSortEnd}*/}
                {/*    children={[...bookmarks*/}
                {/*        .sort((a, b) => a.weight > b.weight ? 1 : -1)]}*/}
                {/*    baseProps={{history, match, isMobile}}*/}
                {/*/>*/}
            </>
        ) : (
            <ScrollContainer style={{whiteSpace: "nowrap", height: '100%'}} orientation={"horizontal"}
                             shadowCoverColor={SettingsService.getValue('ui-theme', 'header')}>
                <SortableContainer
                    axis={'x'}
                    lockAxis={'x'}
                    onSortEnd={this.onSortEnd}
                    transitionDuration={300}
                    pressThreshold={300}
                    pressDelay={300}
                    children={[...bookmarks
                        .sort((a, b) => a.weight > b.weight ? 1 : -1)]}
                    baseProps={{history, match, isMobile}}
                />
            </ScrollContainer>

        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        bookmarksUpdate: (bookmarks: IBookmark[]) => dispatch(bookmarksUpdate(bookmarks))
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findOneByUrl: (path: string) => selectors.routes.findOneByUrlWithIdentifier(state, path),
        bookmarks: state.setup.bookmarks
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookmarks)


const SortableItem = sortableElement(({
                                          bookmark,
                                          baseProps
                                      }: {
    bookmark: IBookmark
    baseProps: ISortableItemProps
}) => {
    const {history, match, isMobile} = baseProps
    return (
        <Row align={'middle'} className={isMobile ? 'd-flex' : 'd-inline-flex'} style={{zIndex: 9999}}>
            <Bookmark mode={isMobile ? BOOKMARK_TYPE_PLAIN : BOOKMARK_TYPE_TAG} bookmark={bookmark} history={history}
                      match={match}/>
        </Row>
    )
});

const SortableContainer = sortableContainer(({children, baseProps}: {
    children: IBookmark[], baseProps: ISortableItemProps
}) => {
    return (
        <div className={'position-relative'}>
            {children.map((value, index) =>
                <SortableItem key={`item-${index}`} index={index} bookmark={value} baseProps={baseProps}/>
            )}
        </div>
    )
})
