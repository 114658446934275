import React from "react";
import WidgetTool from "../../widget/WidgetTool";
import IReportWidgetImage from "../../../../../model/interface/report/IReportWidgetImage";
import {Image, Row} from "antd";
import IFile from "../../../../../model/interface/file/IFile";
import Utils from "../../../../../utils";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";

class ImageWidget extends React.Component<IReportWidgetProps<IReportWidgetImage>> {
    render() {
        const {width, height, file, preview} = this.props.options
        let imageWidth = width ? Number(width) : undefined
        let imageHeight = height ? Number(height) : undefined
        const url = ImageWidget.buildUrl(file,
            width ? width : file?.currentVersion.width,
            height ? height : file?.currentVersion.height)

        return (
            <Row>
                <WidgetTool {...this.props} edit={true}/>
                <Image src={url} width={imageWidth} height={imageHeight} preview={Utils.toBoolean(preview)}
                       fallback={Utils.imageFallback()}/>
            </Row>
        );
    }

    static buildUrl(file: IFile | null, width: any, height: any) {
        if (file) {
            const fileName = `${file.id}-${file.currentVersion.id}-${width}x${height}.${file.currentVersion.extension}`
            return process.env.REACT_APP_BASE_URL + `/thumbnails/` + fileName
        }
        throw new Error('Image Widget file option is undefined')
    }
}

export default ImageWidget