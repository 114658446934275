import React from "react";
import {Button, Form, Input, Switch} from "antd";
import IField, {RELATION_FIELD_TYPE} from "../../../../../model/interface/dataStorage/IField";
import FormFieldText from "./formField/FormFieldText";
import FormFieldSelect from "./formField/FormFieldSelect";
import FormFieldNumber from "./formField/FormFieldNumber";
import FormFieldType from "./formField/FormFieldType";
import Utils from "../../../../../utils";
import {Rule} from "antd/es/form";
import FormFieldSlider from "./formField/FormFieldSlider";
import IFieldOptions from "../../../../../model/interface/form/elementOptions/IFieldOptions";
import FormFieldDate from "./formField/FormFieldDate";
import FormFieldAutocomplete from "./formField/FormFieldAutocomplete";
import FormFieldDateRange from "./formField/FormFieldDateRange";
import FormFieldWYSIWYG from "./formField/FormFieldWYSIWYG";
import WidgetTool from "../../widget/WidgetTool";
import IWidgetProps from "../../../../../model/interface/widget/IWidgetProps";
import IFormElementFunctions from "../../../../../model/interface/form/IFormElementFunctions";
import FormFieldFilePicker from "./formField/FormFieldFilePicker";
import FormFieldContentType from "./formField/FormFieldContentType";
import FormFieldCurrency from "./formField/FormFieldCurrency";
import FormFieldPhoneNumber from "./formField/FormFieldPhoneNumber";
import {BankOutlined, GlobalOutlined, IdcardOutlined, MailOutlined} from "@ant-design/icons";
import InputValidator from "../../../../../utils/InputValidator";
import _ from "underscore";
import FormFieldSignature from "./formField/FormFieldSignature";
import FormFieldWorkflowState from "./formField/FormFieldWorkflowState";
import FieldEditor from "./optionEditor/FieldEditor";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import {API_FILTER_TYPE, API_TOKEN} from "../../../../../model/constants/ApiConstant";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../../redux/reducers/Setup";
import selectors from "../../../../../redux/selectors";
import IUser from "../../../../../model/interface/security/IUser";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import FormFieldRate from "./formField/FormFieldRate";
import FormFieldContentTypeCascade from "./formField/FormFieldContentTypeCascade";
import FormFieldEmployee from "./formField/FormFieldEmployee";
import FormFieldCompanyStructure from "./formField/FormFieldCompanyStructure";
import UsersService from "../../../../../model/service/security/UsersService";
import EmployeesService from "../../../../../model/service/company/EmployeesService";
import UnitsService from "../../../../../model/service/company/UnitsService";
import CompaniesService from "../../../../../model/service/company/CompaniesService";
import JobsService from "../../../../../model/service/company/JobsService";
import ConditionEditor from "./optionEditor/ConditionEditor";
import FormFieldColor from "./formField/FormFieldColor";
import FormFieldIcon from "./formField/FormFieldIcon";
import FormCompositeFieldApproval from "./formField/FormCompositeFieldApproval";
import ActionsService from "../../../../../model/service/dataStorage/ActionsService";
import FormFieldCode from "./formField/FormFieldCode";

interface IProps extends IWidgetProps<IFormElementFunctions, IFieldOptions> {
    noLabel?: boolean
    customFieldName?: string | string[]
    onChange?: (value: any) => void
    className?: string,
    namePrefix?: string
    field?: IField
    fieldOptions?: IFieldOptions,
    findServiceByClassName: (className: string) => IRepositoryService
    findContentTypeByClassName: (className: string) => IContentType
    user: IUser,
    value?: any,
    noName?: boolean,
    noStyle?: boolean
}

interface IState {
    validating?: boolean
    debounce?: number
    disabled?: boolean
}


class FormElementField extends React.Component<IProps, IState> {


    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            disabled: false
        }
    }

    componentDidMount() {
        const state = FormElementField.getDerivedStateFromProps(this.props)
        this.setState({disabled: !!state?.disabled})
    }

    static getTokenValue(field: IField) {
        switch (field.mode) {
            case 'scalar':
                switch (FieldEditor.detectType(field)) {
                    case FormFieldType.FIELD_DATE:
                        return API_TOKEN.NOW
                }
                break
            case 'relation':
                if (field.type === RELATION_FIELD_TYPE.MANY_TO_ONE) {
                    switch (field.targetEntity) {
                        case UsersService.getRecordClassName():
                            return API_TOKEN.USER
                        case EmployeesService.getInstance().getRecordClassName():
                            return API_TOKEN.EMPLOYEE
                        case UnitsService.getInstance().getRecordClassName():
                            return API_TOKEN.UNIT
                        case CompaniesService.getInstance().getRecordClassName():
                            return API_TOKEN.COMPANY
                        case JobsService.getInstance().getRecordClassName():
                            return API_TOKEN.JOB
                    }
                }
        }
        return null
    }

    static renderField = (options: IFieldOptions, field: IField, props?: IProps) => {
        options = {...options, showClear: Utils.toBoolean(options.showClear), value: props?.value}
        const baseProps = {value: props?.value}
        switch (options.type) {
            case(FormFieldType.FIELD_TEXT):
                return FormFieldText.render(options)
            case(FormFieldType.FIELD_SELECT):
                return FormFieldSelect.render(options)
            case(FormFieldType.FIELD_NUMBER):
                return FormFieldNumber.render(options)
            case(FormFieldType.FIELD_COLOR):
                return FormFieldColor.render(options)
            case(FormFieldType.FIELD_BOOLEAN):
                return <Switch disabled={options.disabled} checked={props?.value}/>
            case(FormFieldType.FIELD_SLIDER):
                return FormFieldSlider.render(options, field.unit ? " " + field.unit : '')
            case(FormFieldType.FIELD_DATE):
                return FormFieldDate.render(options)
            case(FormFieldType.FIELD_DATE_RANGE):
                return FormFieldDateRange.render(options)
            case(FormFieldType.FIELD_AUTOCOMPLETE):
                return <FormFieldAutocomplete options={options}/>
            case(FormFieldType.FIELD_WYSIWYG):
                return FormFieldWYSIWYG.render(options)
            case(FormFieldType.FIELD_FILE):
                return FormFieldFilePicker.render(options, field)
            case(FormFieldType.FIELD_CONTENT_TYPE):
                return <FormFieldContentType {...props} {...baseProps} options={options} field={field}/>
            case(FormFieldType.FIELD_CONTENT_TYPE_CASCADE):
                return <FormFieldContentTypeCascade {...baseProps} options={options} field={field}/>
            case(FormFieldType.FIELD_COUNT):
                return FormFieldNumber.render({...options, min: 0})
            case(FormFieldType.FIELD_CURRENCY):
                return <FormFieldCurrency {...baseProps} options={options}/>
            case(FormFieldType.FIELD_PHONE_NUMBER):
                return <FormFieldPhoneNumber {...baseProps} options={options}/>
            case(FormFieldType.FIELD_URL):
                return FormFieldText.render({...options, prefix: <GlobalOutlined/>})
            case(FormFieldType.FIELD_EMAIL):
                return FormFieldText.render({...options, prefix: <MailOutlined/>})
            case(FormFieldType.FIELD_ICO):
                return FormFieldText.render({...options, prefix: <IdcardOutlined/>})
            case(FormFieldType.FIELD_BANK_ACCOUNT_NUMBER):
                return FormFieldText.render({...options, prefix: <BankOutlined/>})
            case(FormFieldType.FIELD_SIGNATURE):
                return <FormFieldSignature {...baseProps} options={options}/>
            case(FormFieldType.FIELD_RATE):
                return <FormFieldRate options={options}/>
            case(FormFieldType.FIELD_WORKFLOW_STATE):
                return <FormFieldWorkflowState {...baseProps} options={options}/>
            case(FormFieldType.FIELD_EMPLOYEE):
                return <FormFieldEmployee {...baseProps} options={options}/>
            case(FormFieldType.FIELD_COMPANY_STRUCTURE):
                return <FormFieldCompanyStructure {...baseProps} options={options}/>
            case(FormFieldType.FIELD_ICON):
                return <FormFieldIcon {...baseProps} options={options}/>
            case(FormFieldType.COMPOSITE_FIELD_APPROVAL):
                return <FormCompositeFieldApproval {...props} {...baseProps} options={options} field={field}/>
            case(FormFieldType.FIELD_CODE):
                return <FormFieldCode {...props} {...baseProps} options={options}/>
            default:
                console.log('Invalid field type', options.type, options, field)
                return <span>{'Invalid field type: ' + options.type}</span>
        }
    }

    getFieldOptions(field: IField) {
        const {findServiceByClassName, functions} = this.props
        const type = FieldEditor.detectType(field)
        let fieldOptions: IFieldOptions = {
            type,
            label: '',
            datePicker: 'date'
        }
        if (field.targetEntity && functions.getContentType) {
            const contentType = functions.getContentType()
            //const relationContentType = findContentTypeByClassName(field.targetEntity)
            let service: IRepositoryService = findServiceByClassName(field.targetEntity)
            if (!service) {
                throw new Error('Service for "' + field.targetEntity + '" is missing')
            }
            // if (EmployeesService.getInstance().getRecordClassName() === field.targetEntity && user.id) {
            //     fieldOptions = {
            //         ...fieldOptions,
            //         contentTypeAdditionalRows: [{fullName: 'Moje zaměstnanecké profily', id: user.id}]
            //     }
            // }
            const multiple = [
                RELATION_FIELD_TYPE.MANY_TO_MANY,
                RELATION_FIELD_TYPE.ONE_TO_MANY].includes(field.type)
            fieldOptions = {
                ...fieldOptions,
                contentTypeId: contentType ? contentType.uuid : undefined,
                contentTypeFullClassName: service.getRecordClassName(),
                contentTypePresenter: service.getDefaultPresenter().name,
                contentTypeMode: "auto",
                multiple,
                employeeMultiple: multiple,
                contentTypeAutocompleteField: service.getRepresentativeField && service.getRepresentativeField().name,
                contentTypeAutocompleteMode: API_FILTER_TYPE.LIKE,
                contentTypeAutocompleteMin: 2
            }
        }

        return {...fieldOptions, ...(this.props.fieldOptions || {})};
    }

    getValueProperty(type: string) {
        return type === FormFieldType.FIELD_BOOLEAN ? 'checked' : 'value'
    }

    static getValidator(type: string) {
        switch (type) {
            case FormFieldType.FIELD_PHONE_NUMBER:
                return {
                    validate: InputValidator.validatePhoneNumber,
                    message: 'Neplatné telefonní číslo'
                }
            case FormFieldType.FIELD_URL:
                return {
                    validate: InputValidator.validateURL,
                    message: 'Neplatná URL'
                }
            case FormFieldType.FIELD_EMAIL:
                return {
                    validate: InputValidator.validateEmail,
                    message: 'Neplatná emailová adresa'
                }
            case FormFieldType.FIELD_ICO:
                return {
                    validate: InputValidator.validateICO,
                    message: 'Neplatné IČO'
                }
            case FormFieldType.FIELD_BANK_ACCOUNT_NUMBER:
                return {
                    validate: InputValidator.validateBankNumber,
                    message: 'Neplatné číslo bankovního účtu',
                    async: true
                }
            default:
                return null
        }
    }

    static getDerivedStateFromProps(props: IWidgetProps<IFormElementFunctions, IFieldOptions>) {
        const {functions, preview, options} = props
        if (preview && functions.getCurrentValue) {
            return {disabled: ConditionEditor.evaluateConditions(options.disabledConditions, functions.getCurrentValue)}
        }
        return null
    }

    buildRules(options: IFieldOptions) {
        let rules = [] as Rule[]
        if (Utils.toBoolean(options.required)) {
            rules = [{required: true, message: options.requiredText}] as Rule[]
        }

        const validator = FormElementField.getValidator(options.type)

        if (validator) {
            const setLoading = (validating: boolean) => {
                this.setState({validating})
            }
            const setDebounce = (debounce: any) => {
                this.setState({debounce})
            }
            const getDebounce = () => {
                return this.state.debounce
            }
            rules.push(() => ({
                validator(_: any, value: string) {
                    if (validator.async && value) {
                        clearTimeout(getDebounce())
                        setLoading(true)
                        return new Promise<void>((resolve, reject) => {
                            setDebounce(setTimeout(() => {
                                const valid = validator.validate(value)
                                valid.then((result: any) => {
                                        setLoading(false)
                                        result ? resolve() : reject(new Error(validator.message))
                                    }
                                )
                            }, 800))
                        })
                    } else {
                        return !value || validator.validate(value) ? Promise.resolve() : Promise.reject(new Error(validator.message))
                    }
                },
                //validateTrigger: 'onSubmit' TODO validation gets triggered even on submit... maybe leave just on submit if its server validation...
            }))
        }

        return rules
    }

    setStyle(field: IField, options: IFieldOptions) {
        if (field.unit && !FormFieldType.UNIT_GROUP_CUSTOM.includes(options.type)) {
            options = {...options, style: {width: '70%'}}
        }
        return options;
    }

    render() {
        const {validating, disabled} = this.state
        const {
            id,
            functions,
            label,
            noLabel,
            customFieldName,
            className,
            preview,
            namePrefix,
            noStyle,
            noName
        } = this.props
        let field: IField
        if (this.props.field) {
            field = this.props.field
        } else {
            const fieldUuid = functions.getNode(id).field?.uuid
            field = _.findWhere(functions.getContentType().fields, {uuid: fieldUuid}) as IField
        }
        if (!field) {
            return 'Field is missing!'
        }
        let options: IFieldOptions = {
            ...(this.props.field ? {...this.getFieldOptions(field), ...field.options} : {}), ...this.props.options,
        }
        if (functions && functions.getContentType && functions.getContentType()) {
            options.contentTypeId = functions.getContentType().uuid
        }
        options = this.setStyle(field, options)

        if (disabled && preview) {
            options.disabled = true
        } else if (!options.disabled) {
            options.disabled = false
        }
        let rules = this.buildRules(options)
        const valuePropName = this.getValueProperty(options.type)
        let fieldName: string | string[] | undefined = customFieldName ? customFieldName : field.name
        if (namePrefix) {
            if (typeof fieldName !== 'object') {
                fieldName = [fieldName]
            }
            fieldName.unshift(namePrefix)
        }
        if (noName) {
            fieldName = undefined
        }
        const input = FormElementField.renderField(options, field, this.props)
        const inputForm = React.createElement(input.type, {...this.buildInputProps(input, options)})
        return this.isAllowed(options) ? (
            <div>
                <WidgetTool {...this.props} edit={true}/>
                {field.unit && !FormFieldType.UNIT_GROUP_CUSTOM.includes(options.type) ? (
                    <Form.Item label={noLabel ? '' : (options.label || label)} className={className}
                               validateStatus={validating ? 'validating' : undefined}
                               valuePropName={valuePropName}
                               name={fieldName}
                               rules={rules}
                               noStyle={noStyle}>
                        <Input.Group className={'d-flex'} compact>
                            <Form.Item name={fieldName} valuePropName={valuePropName} noStyle>
                                {inputForm}
                            </Form.Item>
                            <Form.Item noStyle>
                                <Button disabled>{field.unit}</Button>
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                ) : (
                    <Form.Item
                        hasFeedback={validating !== undefined}
                        validateStatus={validating ? 'validating' : undefined}
                        valuePropName={valuePropName}
                        className={className}
                        label={noLabel ? '' : (options.label || label)}
                        name={fieldName}
                        rules={rules}
                        noStyle={noStyle}
                    >
                        {inputForm}
                    </Form.Item>
                )}
            </div>
        ): ''
    }

    isAllowed(options: IFieldOptions) {
        const {resource, functions} = this.props
        const action = functions.getContentType?.()?.actions.find(a => options.readRightsAction === a.uuid)
        return !options.readRightsAction || (!resource || !action || ActionsService.isResourceAllowed(resource, action))
    }

    buildInputProps(input: JSX.Element, options: IFieldOptions) {
        const {onChange} = this.props
        const inputFormProps = {...input.props}
        if (onChange) {
            this.buildOnChangeProp(inputFormProps, options, onChange);
        }
        this.checkReadOnly(options, inputFormProps);
        return inputFormProps;
    }

    buildOnChangeProp(inputFormProps: any, options: IFieldOptions, onChange: (value: any) => void) {
        inputFormProps.onChange = (value: any) => {
            if (options.type === FormFieldType.FIELD_TEXT) {
                onChange(value.target.value)
            } else {
                onChange(value)
            }
        }
    }

    checkReadOnly(options: IFieldOptions, inputFormProps: any) {
        const {resource, functions} = this.props
        if (resource && options.editRightsAction) {
            const action = functions.getContentType?.()?.actions.find(a => a.uuid === options.editRightsAction)
            inputFormProps.disabled = !action || !ActionsService.isResourceAllowed(resource, action)
        }
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        user,
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(state, className),
        findContentTypeByClassName: (className: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', className)
    }
}

export default connect(mapStateToProps)(FormElementField)