import ICardWidget from "./card/ICardWidget";
import IRestServiceFilters from "../api/IRestServiceFilters";

export const CARD_RESERVED_NAMES = {
    EMPLOYEE_CARD: '#employee-card',
}

export default interface ICard{
    id?: number
    uuid: string
    name?: string
    route?: string
    widgets: ICardWidget[],
    contentType: string
    action?: string
    routePriority?: number
    conditions?: IRestServiceFilters
}