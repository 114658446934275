import React from "react";
import {Avatar, Button, List, Result, Row, Spin, Tooltip, Typography} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import {Link} from "react-router-dom";
import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    MailOutlined,
    WarningOutlined
} from "@ant-design/icons";
import moment from "moment";
import INotification, {STATES} from "model/interface/ui/INotification"
import {motion} from "framer-motion";
import selectors from "../../../../redux/selectors";
import {changeState, load} from "../../../../redux/actions/Notifications";

interface IState {
    detail: number[]
}

interface IProps {
    load: () => void,
    changeState: (notification: INotification, state: number) => void,
    findUnread: () => INotification[],
    findNotDeleted: (limit?: number) => INotification[],
    limit?: number
}


const getIcon = (icon: string) => {
    switch (icon) {
        case 'mail':
            return <MailOutlined/>;
        case 'alert':
            return <WarningOutlined/>;
        case 'check':
            return <CheckCircleOutlined/>
        default:
            return <MailOutlined/>;
    }
}

class NotificationList extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            detail: []
        }
    }

    changeState(notification: INotification, state: number, e: React.MouseEvent<HTMLElement, MouseEvent>) {
        this.props.changeState(notification, state)
        e.stopPropagation()
        e.preventDefault()
        return false
    }

    showDetail(id: number) {
        this.setState(state => ({
            detail: state.detail.includes(id) ? state.detail.filter(d => d !== id) : [...state.detail, id]
        }))
    }

    renderNotification(notification: INotification) {
        const isDetail = this.state.detail.includes(notification.id);
        return <>
            <div className={"d-flex flex-row  w-100  justify-content-between"}>
                <Row justify={'start'} className={'w-100'} style={{flex: 1, minWidth: 0}}>
                    <div className="pr-3">
                        <Avatar className={`ant-avatar-alert`} icon={getIcon("alert")}/>
                    </div>
                    <div style={{flex: 1, minWidth: 0}}
                         className={"d-flex flex-row  mr-3 w-100  justify-content-between align-items-center"}>
                        <Typography.Paragraph ellipsis={isDetail ? false : {rows: 3}}
                                              className={"text-gray-light mb-0 " + (isDetail ? 'overflow-hidden' : '')}>{notification.text}
                            <motion.div className={'overflow-hidden mt-2 text-dark'}
                                        initial={{
                                            height: isDetail ? "auto" : "0px",
                                            display: isDetail ? "block" : "none"
                                        }}
                                        transition={{duration: 0.5}}
                                        animate={{
                                            height: isDetail ? "auto" : "0px",
                                            transitionEnd: {
                                                display: isDetail ? "block" : "none",
                                            }
                                        }}
                            >
                                {notification.message.body}
                            </motion.div>
                        </Typography.Paragraph>
                    </div>
                </Row>
                <div className={"text-right"} onClick={e => {
                    e.stopPropagation();
                    e.preventDefault()
                }}>
                    <div>
                        {notification.message.body && (
                            <Tooltip title={!isDetail ? "Zobrazit detail upozornění" : "Skrýt detail upozornění"}>
                                <Button shape={"circle"} type={"link"}
                                        className={"ml-1 ant-btn-xs " + (isDetail ? '' : 'ant-btn-info')}
                                        onClick={() => this.showDetail(notification.id)}>
                                    {!isDetail ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                </Button>
                            </Tooltip>
                        )}
                        {notification.state === STATES.STATE_VISITED ? (
                            <Tooltip title={"Znovu označit jako nepřečtené"}>
                                <Button shape={"circle"} type={"link"} className={"ml-1 ant-btn-xs"}
                                        onClick={(e) => this.changeState(notification, STATES.STATE_NEW, e)}>
                                    <CheckOutlined/>
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title={"Označit jako přečtené"}>
                                <Button shape={"circle"} type={"link"} className={"ml-1 ant-btn-success ant-btn-xs"}
                                        onClick={(e) => this.changeState(notification, STATES.STATE_VISITED, e)}>
                                    <CheckOutlined/>
                                </Button>
                            </Tooltip>
                        )}
                        <Tooltip title={"Vymazat upozornění"}>
                            <Button shape={"circle"} type={"link"} className={"ml-1 ant-btn-danger ant-btn-xs"}
                                    onClick={(e) => this.changeState(notification, STATES.STATE_DELETED, e)}>
                                <CloseOutlined/>
                            </Button>
                        </Tooltip>
                    </div>
                    <div className="font-size-xs pr-1">{moment(notification.scheduledAt.raw).fromNow()}</div>
                </div>
            </div>
        </>
    }

    render() {
        const {limit} = this.props
        const notifications = this.props.findNotDeleted(limit || 50) // todo not limit, or implement pagination/scroll on load
        return notifications.length > 0 ? (
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={notifications.sort((a, b) => moment(a.scheduledAt.raw).isAfter(moment(b.scheduledAt.raw)) ? -1 : 1)} //DESC or should not be ? :D
                renderItem={notification => (
                    <List.Item
                        className={"w-100" + (notification.state === STATES.STATE_VISITED ? ' bg-gray-lightest opacity-0-6' : '')}>
                        <Spin spinning={notification.state === STATES.STATE_LOADING} className={'w-100'}
                              wrapperClassName={'w-100'}>
                            {notification.message.url ? (
                                <Link to={notification.message.url}>
                                    {this.renderNotification(notification)}
                                </Link>
                            ) : (
                                <div>
                                    {this.renderNotification(notification)}
                                </div>
                            )}
                        </Spin>
                    </List.Item>
                )}
            />
        ) : (
            <div className="empty-notification">
                <Result
                    status="success"
                    className={"opacity-0-5"}
                    title="Žádné notifikace"
                />
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findUnread: (): INotification[] => selectors.notifications.findUnread(state),
        findNotDeleted: (limit?: number): INotification[] => selectors.notifications.findNotDeleted(state, limit)
    }
}

const mapDispatchToProps =
    {
        load,
        changeState
    }

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
