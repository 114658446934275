import {
  NOTIFICATIONS_CHANGE_STATE,
  NOTIFICATIONS_LOAD,
  NOTIFICATIONS_MARK_AS_READ_ALL,
  NOTIFICATIONS_RELOAD,
  NOTIFICATIONS_SAVE,
  NOTIFICATIONS_SAVE_ONE,
} from '../constants/Notifications';
import IRestServiceCollectionResponse from "../../model/interface/api/IRestServiceCollectionResponse";
import INotification, {STATES} from "../../model/interface/ui/INotification";

export const load = () => {
  return {
    type: NOTIFICATIONS_LOAD
  }
};
export const reload = () => {
  return {
    type: NOTIFICATIONS_RELOAD
  }
};
export const save = (response: IRestServiceCollectionResponse) => {
  return {
    type: NOTIFICATIONS_SAVE,
    results: response.results
  }
};
export const saveOne = (notification: INotification) => {
  return {
    type: NOTIFICATIONS_SAVE_ONE,
    notification
  }
};
export const changeState = (notification: INotification, state: number) => {
  return {
    type: NOTIFICATIONS_CHANGE_STATE,
    notification,
    state
  }
}
export const remove = (notification: INotification) => {
  return {
    type: NOTIFICATIONS_CHANGE_STATE,
    notification,
    state: STATES.STATE_DELETED
  }
}
export const markAsReadAll = () => {
  return {
    type: NOTIFICATIONS_MARK_AS_READ_ALL
  }
};

export default {
  load,
  reload,
  changeState,
  remove,
  save,
  saveOne,
  markAsReadAll
}