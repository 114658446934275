import {Form, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";

export interface IFieldCodeOptions {
    allowCameraScan?: boolean,
    autoCloseScanOnResult?: boolean
}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {
}

class FieldCodeOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    static getDefaultOptions(): IFieldCodeOptions {
        return {allowCameraScan: true, autoCloseScanOnResult: true}
    }

    render() {
        return (
            <div>
                <Form.Item name={'allowCameraScan'} label={'Skenování pomoci kamery'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'autoCloseScanOnResult'} label={'Automatické ukončení skenování po nalezeném kódu'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldCodeOptionsEditor