import {applyMiddleware, compose, createStore} from "redux";
import reducers from "../reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import {RootStateOrAny} from "react-redux";
import {ISetupState} from "../reducers/Setup";
import {IAuthState} from "../reducers/Auth";
import {IUiState} from "../reducers/Ui";
import {INotificationState} from "../reducers/Notifications";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

export interface IAppState {
  setup: ISetupState,
  theme: any,
  auth: IAuthState,
  ui: IUiState,
  notifications: INotificationState
}

function configureStore(preloadedState: RootStateOrAny) {
 
  // @ts-ignore // TODO
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducers, preloadedState, composeEnhancers(
    applyMiddleware(...middlewares)
  ));

  sagaMiddleware.run(rootSaga, []);

  // @ts-ignore // TODO
  if (module.hot) {
    // @ts-ignore // TODO
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

// @ts-ignore // TODO
const store = configureStore();

export default store;

