import React from "react";
import {Button, message, Row, Table, Tooltip} from "antd";
import {CheckOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import IWidgetProps from "../../../../../model/interface/widget/IWidgetProps";
import IFormElementFunctions from "../../../../../model/interface/form/IFormElementFunctions";
import IEntityTableOptions from "../../../../../model/interface/form/elementOptions/IEntityTableOptions";
import IRestResource from "../../../../../model/interface/api/IRestResource";
import WidgetTool from "../../widget/WidgetTool";
import FormElementField from "./FormElementField";
import IFieldOptions from "../../../../../model/interface/form/elementOptions/IFieldOptions";
import IField from "../../../../../model/interface/dataStorage/IField";
import IFormStructureNode from "../../../../../model/interface/form/IFormStructureNode";
import Utils from "../../../../../utils";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import _ from "underscore";

interface IProps extends IWidgetProps<IFormElementFunctions, IEntityTableOptions> {
    value?: IRestResource[]
    onChange?: (resource?: IRestResource) => void,
    className: string,
    findContentTypeByClass: (className:string) => IContentType
}

interface IState {
    value?: IRestResource[]
    currentPage: number
}

const TEMP_FIELD_NAME = '_temp'
const TEMP_FIELD_INDEX = '___'

class FormElementEntityTable extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            currentPage: 1
        }
    }

    componentDidMount() {
        this.setState({value: this.props.value}, this.updateFormTable)
    }

    updateFormTable() {
        const {functions, id} = this.props
        const form = functions.getFormRef()
        const children = functions.getSortedChildren(id)
        let items = this.state.value || [];
        let formItems: { [name: string]: any } = {}
        form.current?.resetFields([this.getMainFieldTempName()])
        items.forEach((item, index) => {
            children.forEach(child => {
                if (child.field) {
                    formItems[this.getFieldTempName(child.field.name, index)] = item[child.field.name]
                }
            })
        })
        form.current?.setFieldsValue({[this.getMainFieldTempName()]: formItems})
    }

    getContentType() {
        const currentField = this.props.functions.getNode(this.props.id).field
        const field = typeof currentField === 'object'
            ? currentField
            : _.findWhere(this.props.functions.getContentType().fields, {uuid: currentField})
        return this.props.findContentTypeByClass(field!.targetEntity!)
    }

    getMainFieldTempName() {
        const {id, functions} = this.props
        const field = functions.getNode(id).field as IField
        return '_' + field.name + TEMP_FIELD_NAME
    }

    getFieldTempName(name: string, index: number) {
        return name + TEMP_FIELD_INDEX + index
    }

    buildColumns(): ColumnsType<IRestResource> {
        const {id, functions, preview} = this.props
        const {currentPage} = this.state
        const children = functions.getSortedChildren(id)

        const contentType = this.getContentType()

        return children.map(child => {
            const options = child.options as IFieldOptions
            return {
                title: preview ? options.label || child.field?.label :
                    <WidgetTool
                        {...this.props}
                        functions={{
                            ...this.props.functions,
                            editOptions: (id: string) => this.props.functions.editOptions(id, contentType),
                        }}
                        {...child} edit={true} id={child.id}/>,
                render: (text, record, index) => {
                    const currentIndex = ((currentPage - 1) * 10) + index
                    const field = _.findWhere(contentType.fields, {uuid: child.fieldUuid})
                    child.field = field
                    return child.field && (
                        <FormElementField
                            {...this.props}
                            functions={{
                                ...this.props.functions,
                                getContentType: () => contentType,
                                editOptions: (id: string) => this.props.functions.editOptions(id, this.getContentType()),
                                getNode: (id: string) => ({...this.props.functions.getNode(id), field})
                            }}
                            {...child}
                            field={field}
                            key={child.id}
                            id={child.id}
                            options={options}
                            noLabel={true}
                            preview={true}
                            className={'mb-0'}
                            onChange={() => this.updateTable(currentIndex)}
                            customFieldName={this.getCustomFieldName(child, currentIndex)}
                        />
                    )
                }
            }
        })
    }

    getCustomFieldName(child: IFormStructureNode, index: number) {
        return child.field && [
            this.getMainFieldTempName(),
            this.getFieldTempName(child.field.name, index)
        ];
    }

    updateParent() {
        if (this.props.onChange) {
            this.props.onChange(this.state.value)
        }
        this.updateFormTable()
    }

    updateTable = (index: number) => {
        const {functions, id} = this.props
        const form = functions.getFormRef()
        const children = functions.getSortedChildren(id)
        let items: IRestResource[] = this.state.value || [];
        children.forEach(child => {
            if (child.field) {
                const tempFieldName = this.getCustomFieldName(child, index)
                items[index] = {...(items[index] ? items[index] : {})}
                items[index][child.field.name] = tempFieldName && form.current?.getFieldValue(tempFieldName)
            }
        })
        this.setState({value: items})
    }

    handleDelete(index: number) {
        this.setState(
            state => ({value: state.value?.filter((item, localIndex) => localIndex !== index)}),
            this.updateParent
        )
    }

    handleAdd = () => {
        this.setState(state => ({value: [{
            uuid: Utils.uuid(),
            _class: this.props.className
        }, ...(state.value ? state.value : [])]}), this.updateParent)
    }

    handleValidate = (index: number) => {
        const {functions, id} = this.props
        const form = functions.getFormRef()
        const children = functions.getSortedChildren(id)

        form.current
            ?.validateFields(children.map(child => child.field && this.getCustomFieldName(child, index)) as [string[]])
            .then(() => message.success('Valid!')).catch(() => message.error('Invalid!'))
    }

    getActions(options: IEntityTableOptions): ColumnsType<IRestResource> {
        return [{
            title: options.add && <Row justify={"end"}>
                <Button size={"small"} type={'link'} icon={<PlusOutlined/>} onClick={this.handleAdd}>
                    Vytvořit nový
                </Button>
            </Row>,
            render: (_, resource, index) =>
                <Row key={index} justify={"end"} gutter={[10, 10]}>
                    {options.delete && <Tooltip title={'Smazat'}>
                        <Button size={"small"} type={'link'} danger
                                icon={<DeleteOutlined/>}
                                onClick={() => this.handleDelete(index)}/>
                    </Tooltip>}
                    {options.validate && <Tooltip title={'Validate'}>
                        <Button size={"small"} type={'link'}
                                icon={<CheckOutlined/>}
                                onClick={() => this.handleValidate(index)}/>
                    </Tooltip>}
                </Row>
        }]
    }

    updateCurrentPage(page: number) {
        this.setState({currentPage: page})
    }

    render() {
        const {id, functions, options} = this.props
        const {value} = this.state
        const children = functions.getSortedChildren(id)

        return (
            <div>
                {children.length > 0 && (
                    <Table
                        rowKey={'uuid'}
                        pagination={false}
                        size={'small'}
                        dataSource={value}
                        onChange={(pagination) => this.updateCurrentPage(pagination.current || 1)}
                        columns={[
                            ...this.buildColumns(),
                            ...(this.getActions(options))
                        ]}/>
                )}
            </div>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByClass: (className: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', className)
    }
}

export default connect(mapStateToProps)(FormElementEntityTable)