import React from "react";
import {Button} from "antd";
import {SearchOutlined} from "@ant-design/icons";

interface IProps {
    onReset?: () => void
    onConfirm: () => void
}

export default class FilterDropDown extends React.Component<IProps>{
    render() {
        const {children, onConfirm, onReset} = this.props

        return (
            <div className={'p-2'}>
                <div className={'mb-2'}>
                    {children}
                </div>
                <Button
                    type="primary"
                    onClick={onConfirm}
                    icon={<SearchOutlined/>}
                    size="small"
                    className={'mr-2'}
                >
                    Vyhledat
                </Button>
                <Button onClick={onReset} size="small">
                    Reset
                </Button>
            </div>
        );
    }
}