import React from 'react'
import {Spin} from 'antd';
import Error404 from "views/app-views/dynamic-router/Error404";
import Action from "views/app-views/dynamic-router/Action";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "redux/selectors";
import IRoute from "../../../model/interface/dataStorage/IRoute";
import Report from "../report/Report";
import Card from "../configuration/content-type/card/Card";
import ReportsService from "../../../model/service/dataStorage/ReportService";
import IReport from "../../../model/interface/dataStorage/IReport";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {RouteParametersFromUrl} from "../../../redux/selectors/Routes";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import _ from "underscore";
import View from "../view/View";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import NotFound from "../not-found";
import RoutesService from "../../../model/service/ui/RoutesService";

interface IProps extends RouteComponentProps{
    path: string
    findContentType: (uuid: string) => IContentType
    isModal?: boolean
    onActionFinish?: (result?: IActionResult) => void | any
    findOneByUrl:(url: string) => IRoute | null
}

export interface IRoutesRouterDisplay {
    routeParams?: RouteParametersFromUrl
}

interface IState {
    loading: boolean,
    report?: IReport
}

class RoutesRouter extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.load().then()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.path !== this.props.path){
            this.load().then()
        }
    }

    getRoute = () => {
        const {findOneByUrl, path} = this.props
        return findOneByUrl(path)
    }

    load () {
        const route = this.getRoute()

        switch(route?.type) {
            case('report'):
                return ReportsService.collectionList({
                    filters: {
                        route: {
                            field: "route",
                            type: "equal",
                            value: route.id
                        }
                    },
                    depth: 6
                }).then(response => {
                    this.setState({report: response.results[0], loading: false})
                })
            default:
                return new Promise<void>(resolve => this.setState({loading: false}, resolve))
        }
    }

    render() {
        const {loading, report} = this.state
        const {findContentType, isModal, path} = this.props
        const route = this.getRoute()
        const contentType = route?.contentType ? findContentType(route.contentType) : null

        const props: IProps & IRoutesRouterDisplay = {
            ...this.props,
            routeParams: route && RoutesService.extractParameters(route, path)
        }

        let content = <Error404/>
        if (route) {
            switch (route.type) {
                case('view'):
                    if (!route.view) {
                        throw new Error('View on route:' + route.id + ' with type "view" is missing')
                    }
                    content = <View standalone={true} uuid={route.view} {...props} />
                    break;
                case('action'):
                    const action = contentType && _.findWhere(contentType.actions, {uuid: route.action})
                    if (!action) {
                        throw new Error('Action on route:' + route.id + ' with type "action" is missing')
                    }
                    content = <Action action={action} isModal={isModal} {...props} />
                    break;
                case('report'):
                    if(report) {
                        content = <Report report={this.state.report!} {...props} />
                    }
                    break;
                case('card'):
                    const cards = contentType && contentType.cards.filter(c => route.cards?.includes(c.uuid))
                    if (!cards?.length) {
                        throw new Error('Card on route:' + route.id + ' with type "card" is missing')
                    }
                    content = <Card standAlone={!isModal} card={cards[0]} cards={cards} {...props} />
                    break;
                default: content = <NotFound></NotFound>
            }
        }

        return (
            <>
                <Spin spinning={loading}/>
                {!loading && route && (
                    <>
                        {content}
                    </>
                )}
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        findOneByUrl:(url: string) => selectors.routes.findOneByUrlWithIdentifier(state, url)
    }
}

export default connect(mapStateToProps)(withRouter(RoutesRouter))