import React, {RefObject} from "react";
import {Button, Col, Form, FormInstance, Image, Input, Modal, Row, Slider, Switch} from "antd";
import IReportWidgetImage from "../../../../../../model/interface/report/IReportWidgetImage";
import IWidgetOptions from "../../../../../../model/interface/widget/IWidgetOptions";
import FileUpload from "../../../../file-manager/FileUpload";
import IFile from "../../../../../../model/interface/file/IFile";
import ImageWidget from "../ImageWidget";
import Utils from "../../../../../../utils";

interface IProps {
    options: IReportWidgetImage
    onFinish: (values?: IWidgetOptions) => void
}

class ImageWidgetEditor extends React.Component<IProps, IReportWidgetImage> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options,
            preview: Utils.toBoolean(props.options.confirm),
            width: props.options.width ? Number(props.options.width) : props.options.width,
            height: props.options.height ? Number(props.options.height) : props.options.height
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then((values: IReportWidgetImage) => {
            this.props.onFinish({...this.state, ...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    setFile = (files?: IFile[]) => {
        this.setState(() => {
                const file = files ? files[0] : null
                return {file}
            },
            this.formRef.current?.resetFields
        )
    }

    valueChanged = (value: any) => {
        this.setState({...value})
    }

    updateWidth = (width: string) => {
        this.setState({width: width ? Number(width) : undefined}, this.formRef.current?.resetFields)
    }

    updateHeight = (height: string) => {
        this.setState({height: height ? Number(height) : undefined}, this.formRef.current?.resetFields)
    }

    render() {
        const {file, width, height, preview} = this.state
        const url = file ? ImageWidget.buildUrl(file, file?.currentVersion.width, file?.currentVersion.height) : ''

        return (
            <Modal
                className={'dir-ltr'}
                title={'Upravit obrazek'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form initialValues={this.state} ref={this.formRef}
                      onValuesChange={this.valueChanged}>
                    <Form.Item name={'file'}>
                        <FileUpload accept={'image/*'} onUpload={this.setFile}/>
                    </Form.Item>
                    {file && (
                        <>
                            <Form.Item name={'preview'} label={'Náhled obrázku'} valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                            <Row>
                                <Col span={16}>
                                    <Form.Item name={'width'} label={'Zvolte výšku'}>
                                        <Slider tipFormatter={value => value + ' px'}
                                                min={20} max={Number(file.currentVersion.width)}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Input className={'pl-4'} addonAfter={'px'} value={width} allowClear
                                           onChange={(e) => this.updateWidth(e.target.value)}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16}>
                                    <Form.Item name={'height'} label={'Zvolte výšku'}>
                                        <Slider tipFormatter={value => value + ' px'}
                                                min={20} max={Number(file.currentVersion.height)}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Input className={'pl-4'} addonAfter={'px'} value={height} allowClear
                                           onChange={(e) => this.updateHeight(e.target.value)}/>
                                </Col>
                                <Image src={url} preview={Utils.toBoolean(preview)} width={width} height={height}/>
                            </Row>
                        </>
                    )}
                </Form>
            </Modal>

        )
    }
}

export default ImageWidgetEditor