import React, {RefObject} from "react";
import {Button, Form, FormInstance, Modal, Switch} from "antd";
import IWidgetOptions from "../../../../../../model/interface/widget/IWidgetOptions";
import IFile from "../../../../../../model/interface/file/IFile";
import IReportWidgetResourceFinderByCode
    from "../../../../../../model/interface/report/IReportWidgetResourceFinderByCode";
import ContentTypePicker from "../../../../../shared/pickers/ContentTypePicker";
import {FIELD_TYPE} from "../../../../../../model/interface/dataStorage/IField";
import ActionPicker from "../../../../../shared/pickers/ActionPicker";
import {ActionTypes} from "../../../../../../model/interface/dataStorage/IAction";
import FieldPicker from "../../../../../shared/pickers/FieldPicker";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";

interface IProps {
    options: IReportWidgetResourceFinderByCode
    onFinish: (values?: IWidgetOptions) => void,
    findContentType: (uuid: string) => IContentType
}

interface IState extends IReportWidgetResourceFinderByCode {

}

class ResourceFinderByCodeWidgetEditor extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        this.formRef.current?.validateFields().then(values => {
            this.props.onFinish({...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    setFile = (files?: IFile[]) => {
        this.setState(() => {
                const file = files ? files[0] : null
                return {file}
            },
            this.formRef.current?.resetFields
        )
    }

    valueChanged = (value: any) => {
        this.setState({...value})
    }

    render() {
        const {options, findContentType} = this.props

        const initialValues: IReportWidgetResourceFinderByCode = {
            inputSearch: true,
            scanSearch: true,
            ...options
        }

        const {contentTypeUuid} = this.state

        console.log(contentTypeUuid)
        const contentType = contentTypeUuid && findContentType(contentTypeUuid)

        return (
            <Modal
                className={'dir-ltr'}
                title={'Upravit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>
                ]}
            >
                <Form initialValues={initialValues} ref={this.formRef}
                      onValuesChange={this.valueChanged}>
                    <Form.Item name={'contentTypeUuid'} label={'Typ obsahu'} rules={[{required: true}]}>
                        <ContentTypePicker fieldTypes={[FIELD_TYPE.CODE]}/>
                    </Form.Item>
                    {contentType && (
                        <>
                            <Form.Item name={'showAction'} label={'Akce pro zobrazení'} rules={[{required: true}]}>
                                <ActionPicker contentTypeUuid={contentType.uuid} types={[ActionTypes.SHOW]}/>
                            </Form.Item>
                            <Form.Item name={'codeField'} label={'Pole kódu'} rules={[{required: true}]}>
                                <FieldPicker allowTypes={[FIELD_TYPE.CODE]} output={'uuid'}
                                             serviceClassName={contentType.fullClassName}/>
                            </Form.Item>
                        </>
                    )}
                    <Form.Item name={'inputSearch'} label={'Povolit pole pro vyhledávání'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'scanSearch'} label={'Povolit skenování'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'showOnResult'} label={'Automatické ukončení skenování po nalezeném kódu'}
                               valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ResourceFinderByCodeWidgetEditor)