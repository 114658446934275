import React, {RefObject} from "react";
import {Button, Form, FormInstance, Input, Modal, Row, Select, Slider, Switch, Typography} from "antd";
import IFormOptions from "../../../../../model/interface/form/IFormOptions";
import ITextOptions from "../../../../../model/interface/form/elementOptions/ITextOptions";
import Utils from "../../../../../utils";
import Wysiwyg from "../../../../shared/input/Wysiwyg";

interface IProps {
    options: ITextOptions
    onFinish: (values?: IFormOptions) => void
}

interface IState extends ITextOptions {
    formRef: RefObject<FormInstance>
    wysiwyg: boolean
}

export const TEXT_ELEMENT_STYLE_UNDERLINE = 'underline'
export const TEXT_ELEMENT_STYLE_STRONG = 'strong'
export const TEXT_ELEMENT_STYLE_CODE = 'code'
export const TEXT_ELEMENT_STYLE_DELETE = 'delete'
export const TEXT_ELEMENT_STYLE_MARK = 'mark'
export const TEXT_ELEMENT_STYLE_DISABLED = 'disabled'

export const TEXT_ELEMENT_STYLES = [
    TEXT_ELEMENT_STYLE_UNDERLINE,
    TEXT_ELEMENT_STYLE_STRONG,
    TEXT_ELEMENT_STYLE_CODE,
    TEXT_ELEMENT_STYLE_DELETE,
    TEXT_ELEMENT_STYLE_MARK,
    TEXT_ELEMENT_STYLE_DISABLED
]

class TextWidgetEditor extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            color: '#000000',
            ...props.options,
            copyable: Utils.toBoolean(props.options.copyable),
            isTitle: Utils.toBoolean(props.options.isTitle),
            ellipsis: Utils.toBoolean(props.options.ellipsis),
            formRef: React.createRef(),
            wysiwyg: props.options.wysiwyg === undefined ? true : Utils.toBoolean(props.options.wysiwyg)
        }
    }

    valueUpdated = (changes: any) => {
        this.setState({...this.state, ...changes})
    }

    wysiwygSwitch = () => {
        if (this.state.wysiwyg && Utils.stripHtml(this.state.text)){
            Modal.confirm({
                title: 'Pozor ak vypnete wysiwyg, obsah bude smazan!',
                onOk: this.setWysiwyg,
                onCancel() {},
                type: 'warning'
            })
        }else{
            this.setWysiwyg()
        }
    }

    setWysiwyg = () => {
        this.setState({wysiwyg: !this.state.wysiwyg}, () => {
            !this.state.wysiwyg && this.setState({text: ''}, () => {
                this.state.formRef.current?.resetFields()
            })
        })
    }

    onOk = () => {
        this.state.formRef.current?.validateFields().then((values: ITextOptions) => {
            this.props.onFinish({...this.state, ...values})
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    render() {
        const {isTitle, text, wysiwyg, formRef} = this.state

        return (
            <Modal
                title={<Row justify={"space-between"}>
                    <Typography.Text>Upravit text</Typography.Text>
                    <Switch checkedChildren="Wysiwyg on" checked={wysiwyg} onChange={this.wysiwygSwitch}
                            unCheckedChildren="Wysiwyg off"/>
                </Row>}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}

                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form initialValues={this.state || {}} ref={formRef} onValuesChange={this.valueUpdated}>
                    <Form.Item name={'text'} label={'Obsah'} rules={[{required: true}]}>
                        {wysiwyg ? (<Wysiwyg mode={"medium"}/>)
                            : (<Input/>)}
                    </Form.Item>
                    {!wysiwyg && (
                        <div>
                            <Form.Item name={'isTitle'} label={'Nadpis'} valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                            {isTitle ? (
                                <Form.Item name={'titleLevel'} label={'Zvolte velikost nadpisu'}>
                                    <Slider defaultValue={1} min={1} max={5} tooltipVisible/>
                                </Form.Item>
                            ) : (
                                <Form.Item name={'copyable'} label={'Moznost kopirovat'} valuePropName={'checked'}>
                                    <Switch/>
                                </Form.Item>
                            )}
                            <Form.Item name={'color'} label={'Zvolte barvu textu'}>
                                <Input type={'color'}/>
                            </Form.Item>
                            <Form.Item name={'style'} label={'Zvolte styl textu'}>
                                <Select mode="multiple">
                                    {TEXT_ELEMENT_STYLES.map(style => {
                                        const textParams: any = {};
                                        textParams[style] = true
                                        return (
                                            <Select.Option key={style} value={style}>
                                                <Typography.Text {...textParams}>{text ? text : 'Ukazka'}</Typography.Text>
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name={'ellipsis'} label={'Orezat na jeden radek'} valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </div>
                    )}
                </Form>
            </Modal>

        )
    }
}

export default TextWidgetEditor