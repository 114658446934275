import React from "react";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import WidgetTool from "./WidgetTool";
import {Button} from "antd";
import {RollbackOutlined} from "@ant-design/icons";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IRoute from "../../../../model/interface/dataStorage/IRoute";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import IBackButtonOptions from "../../../../model/interface/widget/option/IBackButtonOptions";
import Utils from "../../../../utils";

interface IProps extends IWidgetProps<IWidgetPropsFunctions, IBackButtonOptions> {
    findRouteByUuid: (uuid: string) => IRoute
}

class BackButtonWidget extends React.Component<IProps> {

    render() {
        const {findRouteByUuid, options, history, resource} = this.props
        const route = options.route && findRouteByUuid(options.route)
        let url = ''
        if (route){
            url = route.url
            const params = url.match(/:([a-zA-Z_]+)/g)
            if (params && params.length){
                params.forEach(name => {
                    let id = Utils.parseObjectToIdentifier(resource?.[name.slice(1)])
                    if (id){
                        url = url.replace(name, id)
                        return
                    }
                    url = ''
                })
            }
        }
        return (
            <>
                <WidgetTool {...this.props} edit={true}/>
                <Button onClick={() => url ? history.push(url) : history.go(-1)}
                        icon={<RollbackOutlined/>}>Zpět</Button>
            </>
        );
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findRouteByUuid: (uuid: string) => selectors.routes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(BackButtonWidget)