import React from "react";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import Widget from "./Widget";

interface IProps extends IWidgetProps {
    className?: string
}

class WidgetChildren extends React.Component<IProps> {
    render() {
        const {id, functions, customWidget, className} = this.props
        const children = functions.getSortedChildren(id)
        const CustomWidget = customWidget

        return (
            <div className={className}>
                {children && children.map(child =>
                    CustomWidget ? (
                        <CustomWidget {...this.props}
                                      {...child}
                                      id={child.id}
                                      key={child.id}/>
                    ) : (
                        <Widget
                            {...this.props}
                            {...child}
                            id={child.id}
                            key={child.id}
                        />)
                )}
            </div>
        );
    }
}

export default WidgetChildren