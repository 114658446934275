import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IRoute from "model/interface/dataStorage/IRoute";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "model/interface/api/IRestServiceOptions";
import INotification from "../../interface/ui/INotification";

interface IRestRoutesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IRoute>
}

interface IRestMyNotificationsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestRoutesServiceCollectionResponse>,

    // collectionCreate(data: any): Promise<IRoute>,

    // resourceRetrieve(id: number | string): Promise<IRoute>,

    resourceUpdate(id: number | string, data: any): Promise<INotification>,

    // resourceDelete(id: number | string): Promise<void>,

    // collectionList(): Promise<IRestServiceCollectionResponse>,

    // getRoutesIndex(): Promise<{ [url: string]: IRoute }>,

    // getRouteTypes(): Promise<IRouteType[]>

    // getEmpty(): IRoute
}

const MyNotificationsService_COLLECTION = 'my-notifications'

const MyNotificationsService: IRestMyNotificationsService = {
    collectionList: function (options?: IRestServiceOptions): Promise<IRestRoutesServiceCollectionResponse> {
        return RestService.collectionList(MyNotificationsService_COLLECTION, options) as Promise<IRestRoutesServiceCollectionResponse>
    },
    // resourceRetrieve: function (id) {
    //     return RestService.resourceRetrieve(MyNotificationsService_COLLECTION, id) as Promise<IRoute>
    // },
    // resourceDelete: function (id) {
    //     return RestService.resourceDelete(MyNotificationsService_COLLECTION, id)
    // },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(MyNotificationsService_COLLECTION, id, data) as Promise<INotification>
    }
}

export default MyNotificationsService