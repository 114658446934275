import React, {RefObject} from "react";
import {Button, Form, FormInstance, Modal, Select, Switch} from "antd";
import IEntityTableOptions from "../../../../../../model/interface/form/elementOptions/IEntityTableOptions";
import IField, {RELATION_FIELD_TYPE} from "../../../../../../model/interface/dataStorage/IField";
import Utils from "../../../../../../utils";

interface IProps {
    options: IEntityTableOptions
    onFinish: (values?: IEntityTableOptions, field?: IField) => void
    fields: IField[]
}

class EntityTableEditor extends React.Component<IProps, IEntityTableOptions> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options
        }
    }

    formRef = React.createRef() as RefObject<FormInstance>

    onOk = () => {
        const {fields} = this.props
        this.formRef.current?.validateFields().then((values: IEntityTableOptions) => {
            this.props.onFinish({...this.state, ...values}, fields.find(field => values.fieldId === field.id))
        })
    }

    onCancel = () => {
        this.props.onFinish()
    }

    render() {
        let {fields} = this.props
        if (typeof fields === 'undefined') {
            fields = []
        }

        return (
            <Modal
                title={'Upravit zalozku'}
                okText={'Ulozit'}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                visible={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form initialValues={this.state || {}} ref={this.formRef}>
                    <Form.Item name={'fieldId'} label={'Podminene pole'} rules={[{required: true}]}>
                        <Select>
                            {fields.filter(field => Utils.isTargetEntityContentType(field.targetEntity) && [
                                RELATION_FIELD_TYPE.ONE_TO_MANY,
                                RELATION_FIELD_TYPE.MANY_TO_MANY
                            ].includes(field.type))
                                .map(field => {
                                    return (
                                        <Select.Option key={field.id} value={field.id as number}>
                                            {field.label} [{field.name}]
                                        </Select.Option>
                                    )
                                })}
                        </Select>
                    </Form.Item>
                    <Form.Item name={'add'} label={'Moznost vytvorit'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={'delete'} label={'Moznost mazat'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default EntityTableEditor